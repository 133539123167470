import { ElementRef, Input, OnDestroy } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { delay, filter, map, mergeMap } from 'rxjs/operators';
import { CalculateDialogComponent } from 'src/app/pump-simulation/calculate-dialog/calculate-dialog.component';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { removeRadioBtnFocus } from 'src/app/shared/util';
import { DisplayProductDataService } from '../display-product-data.service';
import { MODULE_ITEMS } from '../pump-data.model';
import { SaveButtonComponent } from '../save-button/save-button.component';
import { UpdateButtonComponent } from '../update-button/update-button.component';


@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  private formValueChange: Subscription;

  displayedColumns: string[] = ['approveForUse', 'version', 'status', 'published', 'author', 'comment', 'action'];

  selectedRowIndex = -1;
  moduleName = '';
  moduleUuid = '';
  showModuleForm = false;
  showVersionTable = true;
  tableLoading = true;
  isDirty = false;

  userLocalStorage = new UserLocalStorage();

  moduleData = [];
  moduleForm: UntypedFormGroup;
  isEmptyData = false;
  originalModuleFormValue: any;

  @Input() dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  lastVersion: any;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {

    this.initModuleForm();

    const routerSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.showVersionTable = true;
      this.moduleName = '';
      this.showModuleForm = false;
      this.selectedRowIndex = -1;
      this.isDirty = false;
    });
    this.subscriptions.push(routerSubscription);

    const dataSubscription = this.route.paramMap.pipe(
      map(params => params.get('uuid')),
      mergeMap(uuid => {
        this.moduleUuid = uuid;
        return this.displayProductData.getModuleItem(uuid);
      })
    ).subscribe(moduleData => {
      this.tableLoading = false;
      if (moduleData.versions.length === 0) {
        this.isEmptyData = true;
      } else {
        this.moduleName = moduleData.name;
        this.dataSource.data = moduleData.versions;
        this.moduleData = moduleData.versions;
        this.showVersionTable = false;
        this.lastVersion = this.moduleData.length;
      }
    });
    this.subscriptions.push(dataSubscription);
  }

  versionData() {
    return this.moduleData;
  }

  initModuleForm() {
    const data: any = {};
    MODULE_ITEMS.forEach(name => {
      data[`${name}`] = [null, Validators.required];
    });
    this.moduleForm = this.fb.group(data);
  }

  showSelectedVersionData(version: string) {
    if (this.formValueChange) {
      this.formValueChange.unsubscribe();
    }

    if (!this.isDirty) {
      // the form value not changed
      this.moduleFormValueChange(version);
    } else {
      const dialogRef = this.dialog.open(UpdateButtonComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isDirty = false;
          this.moduleFormValueChange(version);
        }
      });
    }

  }

  moduleFormValueChange(version) {
    this.showModuleForm = true;
    this.selectedRowIndex = Number(version);

    for (const item of this.moduleData) {
      if (item.version_number === Number(version)) {
        const data = {};
        MODULE_ITEMS.forEach(name => data[`${name}`] = item[`${name}`]);
        this.moduleForm.patchValue(data);
        this.originalModuleFormValue = this.moduleForm.value;
      }
    }

    this.formValueChange = this.moduleForm.valueChanges.subscribe((value) => {
      this.isDirty = JSON.stringify(this.originalModuleFormValue) !== JSON.stringify(value);
    });
  }

  showSelectedVersionRadioBtn(version: number) {
    const versionNumber = version + 1;
    if (this.showModuleForm && (versionNumber === this.selectedRowIndex)) {
      const dialogRef = this.dialog.open(CalculateDialogComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
        data: {
          message: 'Are you sure you want to publish this version?',
          type: 'confirm'
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const data = this.moduleData[version];
          data.version = this.moduleData[version].version_number;
          const url = `${this.moduleUuid}/${versionNumber}/live`;
          const selectSubscription = this.displayProductData.setModuleState(url, data).subscribe(item => {
            this.snackBar
              .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
              .afterDismissed()
              .pipe(delay(0))
              .subscribe(() => {
                this.ngOnInit();
                this.showVersionTable = true;
                this.moduleName = '';
                this.showModuleForm = false;
                this.selectedRowIndex = -1;
                this.isDirty = false;
              });
          });
          this.subscriptions.push(selectSubscription);
        } else {
          removeRadioBtnFocus(this.elementRef, version);
        }
      });
    } else {
      const dialogRef = this.dialog.open(CalculateDialogComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
        data: {
          message: 'You have to load a version before publishing it.',
          type: 'hint'
        }
      });

      dialogRef.afterClosed().subscribe(() => removeRadioBtnFocus(this.elementRef, version));
    }

  }

  onSave() {
    const dialogRef = this.dialog.open(SaveButtonComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateModule();
      }
    });
  }

  updateModule() {
    const user = this.userLocalStorage.loadUser();
    const data = this.moduleForm.value;
    data.author = user.user.username;
    data.uuid = this.moduleUuid;
    data.file = this.moduleName;
    const updateSubscription = this.displayProductData.updateModule(data).subscribe(() => {
      const url = `${data.uuid}/${this.lastVersion}/preview`;
      this.displayProductData.setModuleState(url, data).subscribe(item => {
        this.snackBar
          .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
          .afterDismissed()
          .pipe(delay(0))
          .subscribe(() => {
            this.ngOnInit();
            this.showVersionTable = true;
            this.moduleName = '';
            this.showModuleForm = false;
            this.selectedRowIndex = -1;
            this.isDirty = false;
          });
      });
    });
    this.subscriptions.push(updateSubscription);
  }

  canDeactivate() {
    return this.isDirty;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
