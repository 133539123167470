<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">
    <mat-card-header>
      <mat-card-title>
        Locations overview
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Location" #input>
      </mat-form-field>
      <div class="mat-elevation-z8">
        <div class="form-wrapper__table-wapper">
          <table mat-table [dataSource]="dataSource" matSort>
            
            <!-- ID Column -->
            <ng-container matColumnDef="id" sticky>
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
              <td mat-cell *matCellDef="let row"> {{row.id}} </td>
            </ng-container>
  
            <!-- Latitude Column -->
            <ng-container matColumnDef="lat" sticky>
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Latitude </th>
              <td mat-cell *matCellDef="let row"> {{row.lat}} </td>
            </ng-container>
        
            <!-- Longitude Column -->
            <ng-container matColumnDef="lon" sticky>
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Longitude </th>
              <td mat-cell *matCellDef="let row"> {{row.lon}} </td>
            </ng-container>
            
            <!-- Ins_range Column -->
            <ng-container matColumnDef="ins_0">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 0_ins </th>
              <td mat-cell *matCellDef="let row"> {{row.ins_0}} </td>
            </ng-container>
  
            <ng-container matColumnDef="ins_1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 1_ins </th>
              <td mat-cell *matCellDef="let row"> {{row.ins_1}} </td>
            </ng-container>
  
            <ng-container matColumnDef="ins_2">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 2_ins </th>
              <td mat-cell *matCellDef="let row"> {{row.ins_2}} </td>
            </ng-container>
  
            <ng-container matColumnDef="ins_3">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 3_ins </th>
              <td mat-cell *matCellDef="let row"> {{row.ins_3}} </td>
            </ng-container>
  
            <ng-container matColumnDef="ins_4">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 4_ins </th>
              <td mat-cell *matCellDef="let row"> {{row.ins_4}} </td>
            </ng-container>
  
            <ng-container matColumnDef="ins_5">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 5_ins </th>
              <td mat-cell *matCellDef="let row"> {{row.ins_5}} </td>
            </ng-container>
  
            <ng-container matColumnDef="ins_6">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 6_ins </th>
              <td mat-cell *matCellDef="let row"> {{row.ins_6}} </td>
            </ng-container>
  
            <ng-container matColumnDef="ins_7">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 7_ins </th>
              <td mat-cell *matCellDef="let row"> {{row.ins_7}} </td>
            </ng-container>
  
            <ng-container matColumnDef="ins_8">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 8_ins </th>
              <td mat-cell *matCellDef="let row"> {{row.ins_8}} </td>
            </ng-container>
  
            <ng-container matColumnDef="ins_9">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 9_ins </th>
              <td mat-cell *matCellDef="let row"> {{row.ins_9}} </td>
            </ng-container>
  
            <ng-container matColumnDef="ins_10">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 10_ins </th>
              <td mat-cell *matCellDef="let row"> {{row.ins_10}} </td>
            </ng-container>
  
            <ng-container matColumnDef="ins_11">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 11_ins </th>
              <td mat-cell *matCellDef="let row"> {{row.ins_11}} </td>
            </ng-container>
  
            <ng-container matColumnDef="ins_12">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 12_ins </th>
              <td mat-cell *matCellDef="let row"> {{row.ins_12}} </td>
            </ng-container>
  
            <!-- Rain_range Column -->
            <ng-container matColumnDef="rain_0">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 0_rain </th>
              <td mat-cell *matCellDef="let row"> {{row.rain_0}} </td>
            </ng-container>
  
            <ng-container matColumnDef="rain_1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 1_rain </th>
              <td mat-cell *matCellDef="let row"> {{row.rain_1}} </td>
            </ng-container>
  
            <ng-container matColumnDef="rain_2">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 2_rain </th>
              <td mat-cell *matCellDef="let row"> {{row.rain_2}} </td>
            </ng-container>
  
            <ng-container matColumnDef="rain_3">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 3_rain </th>
              <td mat-cell *matCellDef="let row"> {{row.rain_3}} </td>
            </ng-container>
  
            <ng-container matColumnDef="rain_4">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 4_rain </th>
              <td mat-cell *matCellDef="let row"> {{row.rain_4}} </td>
            </ng-container>
  
            <ng-container matColumnDef="rain_5">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 5_rain </th>
              <td mat-cell *matCellDef="let row"> {{row.rain_5}} </td>
            </ng-container>
  
            <ng-container matColumnDef="rain_6">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 6_rain </th>
              <td mat-cell *matCellDef="let row"> {{row.rain_6}} </td>
            </ng-container>
  
            <ng-container matColumnDef="rain_7">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 7_rain </th>
              <td mat-cell *matCellDef="let row"> {{row.rain_7}} </td>
            </ng-container>
  
            <ng-container matColumnDef="rain_8">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 8_rain </th>
              <td mat-cell *matCellDef="let row"> {{row.rain_8}} </td>
            </ng-container>
  
            <ng-container matColumnDef="rain_9">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 9_rain </th>
              <td mat-cell *matCellDef="let row"> {{row.rain_9}} </td>
            </ng-container>
  
            <ng-container matColumnDef="rain_10">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 10_rain </th>
              <td mat-cell *matCellDef="let row"> {{row.rain_10}} </td>
            </ng-container>
  
            <ng-container matColumnDef="rain_11">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 11_rain </th>
              <td mat-cell *matCellDef="let row"> {{row.rain_11}} </td>
            </ng-container>
  
            <ng-container matColumnDef="rain_12">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 12_rain </th>
              <td mat-cell *matCellDef="let row"> {{row.rain_12}} </td>
            </ng-container>
  
            <!-- Temp_range Column -->
            <ng-container matColumnDef="temp_0">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 0_temp </th>
              <td mat-cell *matCellDef="let row"> {{row.temp_0}} </td>
            </ng-container>
  
            <ng-container matColumnDef="temp_1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 1_temp </th>
              <td mat-cell *matCellDef="let row"> {{row.temp_1}} </td>
            </ng-container>
  
            <ng-container matColumnDef="temp_2">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 2_temp </th>
              <td mat-cell *matCellDef="let row"> {{row.temp_2}} </td>
            </ng-container>
  
            <ng-container matColumnDef="temp_3">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 3_temp </th>
              <td mat-cell *matCellDef="let row"> {{row.temp_3}} </td>
            </ng-container>
  
            <ng-container matColumnDef="temp_4">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 4_temp </th>
              <td mat-cell *matCellDef="let row"> {{row.temp_4}} </td>
            </ng-container>
  
            <ng-container matColumnDef="temp_5">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 5_temp </th>
              <td mat-cell *matCellDef="let row"> {{row.temp_5}} </td>
            </ng-container>
  
            <ng-container matColumnDef="temp_6">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 6_temp </th>
              <td mat-cell *matCellDef="let row"> {{row.temp_6}} </td>
            </ng-container>
  
            <ng-container matColumnDef="temp_7">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 7_temp </th>
              <td mat-cell *matCellDef="let row"> {{row.temp_7}} </td>
            </ng-container>
  
            <ng-container matColumnDef="temp_8">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 8_temp </th>
              <td mat-cell *matCellDef="let row"> {{row.temp_8}} </td>
            </ng-container>
  
            <ng-container matColumnDef="temp_9">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 9_temp </th>
              <td mat-cell *matCellDef="let row"> {{row.temp_9}} </td>
            </ng-container>
  
            <ng-container matColumnDef="temp_10">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 10_temp </th>
              <td mat-cell *matCellDef="let row"> {{row.temp_10}} </td>
            </ng-container>
  
            <ng-container matColumnDef="temp_11">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 11_temp </th>
              <td mat-cell *matCellDef="let row"> {{row.temp_11}} </td>
            </ng-container>
  
            <ng-container matColumnDef="temp_12">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 12_temp </th>
              <td mat-cell *matCellDef="let row"> {{row.temp_12}} </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
            <!-- Row shown when there is no matching data. -->
            <tr [ngClass]="isFirstInit ? 'firstInit' : ''" class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="20">No data matching the filter "{{input.value}}"</td>
            </tr>
          </table>
          <div class="form-wrapper__table-loading" *ngIf="tableLoading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</section>
