import { Action, createReducer, on } from "@ngrx/store";
import * as DataSourceActions from "./data-source.actions";

export const dataSourceFeatureKey = 'dataSource';

export interface DataSourceState {
  dataSourceName: String;
  uploadFile: any;
  useUploadedFile: String;
  copyData: String;
  enableNextStep: Boolean;
}

export const initialState: DataSourceState = {
  dataSourceName: '',
  uploadFile: null,
  useUploadedFile: '',
  copyData: '',
  enableNextStep: false
}

const reducer = createReducer(
  initialState,
  on(DataSourceActions.setDataSource, (state, action) => {
    return {
      ...state,
      dataSourceName: action.data,
      enableNextStep: action.data !== ''
    }
  }),
  on(DataSourceActions.setSourceUploadFile, (state, action) => {
    return {
      ...state,
      uploadFile: action.data
    }
  }),
  on(DataSourceActions.setSourceUseUploadedFile, (state, action) => {
    return {
      ...state,
      useUploadedFile: action.data
    }
  }),
  on(DataSourceActions.setSourceCopyData, (state, action) => {
    return {
      ...state,
      copyData: action.data
    }
  })
);

export function dataSourceReducer(
  state: DataSourceState,
  action: Action
): DataSourceState {
  return reducer(state, action);
}
