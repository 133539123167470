import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { delay, map, mergeMap, take } from 'rxjs/operators';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { v4 } from 'uuid';
import { PackagingItem } from '../controllers/controller.model';
import { CopyButtonComponent } from '../copy-button/copy-button.component';
import { DisplayProductDataService } from '../display-product-data.service';
import { checkConnectedProductName } from '../names.validator';
import { CONNECTED_ITEMS_CREATE } from '../pump-data.model';
import { PackagingState } from '../store/packaging/packaging.reducer';
import { selectPackaging } from '../store/packaging/packaging.selector';

@Component({
  selector: 'app-connected-items-new',
  templateUrl: './connected-items-new.component.html',
  styleUrls: ['./connected-items-new.component.scss']
})
export class ConnectedItemsNewComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  userLocalStorage = new UserLocalStorage();
  connectedForm: UntypedFormGroup;
  copiedItem = '';
  tableLoading = false;
  showLoading = false;

  packagingList = [];

  constructor(
    private store: Store,
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private displayProductData: DisplayProductDataService,
  ) { }

  ngOnInit() {
    this.createForm();

    const getPackagingList = this.store.pipe(
      select(selectPackaging),
      map((data: PackagingState) => data.data)
    ).subscribe((data: PackagingItem[]) => {
      this.packagingList = [...data].sort((a, b) => Number(a.subType.L) - Number(b.subType.L));
    });

    this.subscriptions.push(getPackagingList);
  }

  openCopyDialog(type: string) {
    const dialogRef = this.dialog.open(CopyButtonComponent, {
      width: '1000px',
      maxWidth: '96vw',
      data: type,
      minHeight: '60vh',
      maxHeight: '60vh',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.showLoading = true;
        this.copyItem(result);
      }
    });
  }

  copyItem(connectedItemUuid: string) {
    this.displayProductData.getConnectedProductItem(connectedItemUuid).subscribe(connectedData => {
      this.showLoading = false;
      this.copiedItem = connectedData.name;
      if (connectedData.versions && connectedData.versions.length > 1) {
        const latestVersionData = connectedData.versions[0];
        const connectedFormData: any = {};
        CONNECTED_ITEMS_CREATE.forEach(name => connectedFormData[`${name}`] = latestVersionData[`${name}`]);
        connectedFormData.product_name_1 = '';
        this.connectedForm.patchValue(connectedFormData);
      }
    });
  }

  createForm() {
    const data: any = {};
    CONNECTED_ITEMS_CREATE.forEach(name => {
      data[`${name}`] = [null, Validators.required];
    });
    data.product_name_1 = ['', [Validators.required, checkConnectedProductName.bind(this)]];
    this.connectedForm = this.fb.group(data);
  }

  onSubmit(data: any) {
    const user = this.userLocalStorage.loadUser();
    data.author = user.user.username;
    data.file = data.product_name_1;
    data.uuid = v4();
    data.preview = true;

    if ( this.connectedForm.value.packaging.subType ) {
      const packagingValue: any = {};
      packagingValue.name = data.packaging.uuid;
      packagingValue.w = data.packaging.subType.W;
      packagingValue.h = data.packaging.subType.H;
      packagingValue.l = data.packaging.subType.L;
      packagingValue.pack_weight = data.packaging.weight;
      data.packaging = packagingValue;
    } else {
      const result = this.packagingList.filter(item => item.name.includes(data.packaging));
      const packaging: any = {};
      packaging.name = result[0].uuid;
      packaging.w = `${result[0].subType.W}`;
      packaging.h = `${result[0].subType.H}`;
      packaging.l = `${result[0].subType.L}`;
      packaging.pack_weight = `${result[0].weight}`;
      data.packaging = packaging;
    }

    this.displayProductData.getConnectedProductList().pipe(
      take(1),
      mergeMap(allConnectedProducts => {
        data.id = allConnectedProducts.length;
        return this.displayProductData.createConnectedProductItem(data);
      })
    ).subscribe(item => {
      this.snackBar
      .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
      .afterDismissed()
      .pipe(delay(0))
      .subscribe(() => {
        const url = `${data.uuid}/1/preview`;
        this.displayProductData.reloadConnprodData();
        this.displayProductData.setConnectedProductState(url, data).subscribe(() => {
          this.router.navigate(['/data/connected-item', data.uuid]);
        });
      });
    });

  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
