import { Action, createReducer, on } from "@ngrx/store";
import { PackagingItem } from "../../controllers/controller.model";
import * as PackagingActions from './packaging.actions';

export const packagingFeatureKey = 'packaging';

export interface PackagingState {
  data: PackagingItem[];
  loading: Boolean;
}

export const initialState: PackagingState = {
  data: [],
  loading: true
}

const reducer = createReducer(
  initialState,
  on(PackagingActions.loadPackagings, (state, action) => {
    return {
      ...state,
      loading: true
    }
  }),
  on(PackagingActions.loadPackagingsSuccess, (state, action) => {
    return {
      ...state,
      data: action.data,
      loading: false
    }
  }),
  on(PackagingActions.loadPackagingsFailure, (state, action) => {
    return {
      ...state,
      loading: false
    }
  }),
  on(PackagingActions.createPackaging, (state, action) => {
    return {
      ...state,
      data: [{...action.data}, ...state.data]
    }
  }),
);

export function packagingReducer (
  state: PackagingState,
  action: Action
): PackagingState {
  return reducer(state, action);
};

