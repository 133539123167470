import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { delay, map, mergeMap, take } from 'rxjs/operators';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { v4 } from 'uuid';
import { PackagingItem } from '../controllers/controller.model';
import { CopyButtonComponent } from '../copy-button/copy-button.component';
import { DisplayProductDataService } from '../display-product-data.service';
import { checkAccessoryName } from '../names.validator';
import { ACCESSORY_ITEMS } from '../pump-data.model';
import { PackagingState } from '../store/packaging/packaging.reducer';
import { selectPackaging } from '../store/packaging/packaging.selector';

@Component({
  selector: 'app-accessory-new',
  templateUrl: './accessory-new.component.html',
  styleUrls: ['./accessory-new.component.scss']
})
export class AccessoryNewComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  userLocalStorage = new UserLocalStorage();
  accessoryForm: UntypedFormGroup;

  selectedRowIndex = -1;
  showLoading = false;
  copiedItem = '';
  accessoryData = [];
  packagingList = [];

  constructor(
    private store: Store,
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {
    this.initRecalculateForm();

    const getPackagingList = this.store.pipe(
      select(selectPackaging),
      map((data: PackagingState) => data.data)
    ).subscribe((data: PackagingItem[]) => {
      this.packagingList = [...data].sort((a, b) => Number(a.subType.L) - Number(b.subType.L));
    });

    this.subscriptions.push(getPackagingList);
  }

  openCopyDialog(type: string) {
    const dialogRef = this.dialog.open(CopyButtonComponent, {
      width: '1000px',
      maxWidth: '96vw',
      data: type,
      minHeight: '60vh',
      maxHeight: '60vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showLoading = true;
        this.copyItem(result);
      }
    });
  }

  copyItem(accessoryUuid: string) {
    this.displayProductData.getAccessoryItem(accessoryUuid)
      .subscribe((accessory: any) => {
        this.showLoading = false;
        this.copiedItem = accessory.name;
        const accessoryData = accessory.versions[0];
        const data = {};
        ACCESSORY_ITEMS.forEach(name => data[`${name}`] = accessoryData[`${name}`]);
        this.accessoryForm.patchValue(data);
      });
  }

  initRecalculateForm() {
    const data: any = {};
    ACCESSORY_ITEMS.forEach(name => {
      data[`${name}`] = [null, Validators.required];
    });
    data.name = ['', [Validators.required, checkAccessoryName.bind(this)]];
    this.accessoryForm = this.fb.group(data);
  }

  onSubmit(data) {

    const user = this.userLocalStorage.loadUser();
    data.author = user.user.username;
    data.uuid = v4();
    data.file = this.accessoryForm.value.name;
    data.preview = true;

    if ( this.accessoryForm.value.packaging.subType ) {
      const packagingValue: any = {};
      packagingValue.name = data.packaging.uuid;
      packagingValue.w = data.packaging.subType.W;
      packagingValue.h = data.packaging.subType.H;
      packagingValue.l = data.packaging.subType.L;
      packagingValue.pack_weight = data.packaging.weight;
      data.packaging = packagingValue;
    } else {
      const result = this.packagingList.filter(item => item.name.includes(data.packaging));
      const packaging: any = {};
      packaging.name = result[0].uuid;
      packaging.w = `${result[0].subType.W}`;
      packaging.h = `${result[0].subType.H}`;
      packaging.l = `${result[0].subType.L}`;
      packaging.pack_weight = `${result[0].weight}`;
      data.packaging = packaging;
    }

    this.displayProductData.getAccessoryList()
      .pipe(
        take(1),
        mergeMap(allAccessories => {
          data.id = allAccessories.length;
          return this.displayProductData.createAccessoryItem(data);
        })
      ).subscribe(item => {
        this.snackBar
          .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
          .afterDismissed()
          .pipe(delay(0))
          .subscribe(() => {
            const url = `${data.uuid}/1/preview`;
            this.displayProductData.reloadAccessoryData();
            this.displayProductData.setAccessoryState(url, data).subscribe(item => {
              this.router.navigate(['/data/accessory', data.uuid]); });

          });
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
