
export interface CityItem {
  id: string;
  name: string;
  uuid: string;
  lat: string;
  lon: string;
  country_id: string;
}

export class CityItem {

  constructor(
      public id: string,
      public name: string,
      public uuid: string,
      public lat: string,
      public lon: string,
      public country_id: string,
  ) { }

  static from(item: any) {
      return new CityItem(item.id, item.name, item.uuid, item.lat, item.lon, item.country_id);
  }

}

