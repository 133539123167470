<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Coefficient set
    </mat-panel-title>
    <mat-panel-description>
      {{ coefficientSetName }}
    </mat-panel-description>
  </mat-expansion-panel-header>

  <mat-form-field class="form-element" appearance="fill">
    <mat-label>Select coefficient set</mat-label>
    <input type="text" [errorStateMatcher]="matcher" placeholder="Search coefficient set" matInput
      [formControl]="coefficientSetControl" [matAutocomplete]="coefficientSetAuto"
      (focus)="onCoefficientSetInputFocus($event.target.value)" (blur)="updateCoefficientSet($event.target.value)"
      (input)="coefficientSetChanges.next($event.target.value)" />
    <mat-autocomplete #coefficientSetAuto="matAutocomplete" (optionSelected)="setCoefficientSetChangeAction($event)">
      <mat-option *ngFor="let option of coefficientSetSuggestions | async" [value]="option">
        {{ option}}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="coefficientSetControl.hasError('noOption')">
      Coefficient set not Found
    </mat-error>
  </mat-form-field>

  <div class="container inner-item" *ngIf="showCoefficientSetForm" novalidate>
    <form [formGroup]="coefficientSetForm">
      <mat-form-field class="form-element">
        <mat-label>maq</mat-label>
        <input matInput placeholder="maq" formControlName="maq">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>mcq</mat-label>
        <input matInput placeholder="mcq" formControlName="mcq">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>naq</mat-label>
        <input matInput placeholder="naq" formControlName="naq">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>ncq</mat-label>
        <input matInput placeholder="ncq" formControlName="ncq">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>mbq</mat-label>
        <input matInput placeholder="mbq" formControlName="mbq">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>mdq_</mat-label>
        <input matInput placeholder="mdq_" formControlName="mdq_">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>nbq</mat-label>
        <input matInput placeholder="nbq" formControlName="nbq">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>ndq_</mat-label>
        <input matInput placeholder="ndq_" formControlName="ndq_">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>mbi</mat-label>
        <input matInput placeholder="mbi" formControlName="mbi">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>mci</mat-label>
        <input matInput placeholder="mci" formControlName="mci">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>nbi</mat-label>
        <input matInput placeholder="nbi" formControlName="nbi">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>nci</mat-label>
        <input matInput placeholder="nci" formControlName="nci">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>mar_</mat-label>
        <input matInput placeholder="mar_" formControlName="mar_">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>mcr</mat-label>
        <input matInput placeholder="mcr" formControlName="mcr">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>nar_</mat-label>
        <input matInput placeholder="nar_" formControlName="nar_">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>ncr</mat-label>
        <input matInput placeholder="ncr" formControlName="ncr">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>mbr</mat-label>
        <input matInput placeholder="mbr" formControlName="mbr">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>mai</mat-label>
        <input matInput placeholder="mai" formControlName="mai">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>nbr</mat-label>
        <input matInput placeholder="nbr" formControlName="nbr">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>nai</mat-label>
        <input matInput placeholder="nai" formControlName="nai">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>correction factor</mat-label>
        <input matInput placeholder="correction factor" formControlName="corr_fact">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>npsh_a</mat-label>
        <input matInput placeholder="npsh_a" formControlName="npsh_a">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>npsh_exp</mat-label>
        <input matInput placeholder="npsh_exp" formControlName="npsh_exp">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>npsh_c</mat-label>
        <input matInput placeholder="npsh_c" formControlName="npsh_c">
      </mat-form-field>
    </form>
  </div>
</mat-expansion-panel>
