import { Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { DisplayProductDataService } from '../display-product-data.service';
import { EnclosureClassCreateComponent } from '../enclosure-class-create/enclosure-class-create.component';

@Component({
  selector: 'app-enclosure-class-select-new',
  templateUrl: './enclosure-class-select-new.component.html',
  styleUrls: ['./enclosure-class-select-new.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnclosureClassSelectNewComponent),
      multi: true
    }
  ]
})
export class EnclosureClassSelectNewComponent implements OnInit, OnDestroy, ControlValueAccessor {


  encClassList = [];
  encClassStore = '';
  private subscriptions: Subscription[] = [];

  @Output() selectionChange = new EventEmitter<MatSelectChange>();

  selectListControl = new UntypedFormControl({ value: '' });

  constructor(
    private dialog: MatDialog,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {
    const onGetDataSubscription = this.displayProductData.getEncClassList()
    .subscribe(allEncClassLists => this.encClassList = allEncClassLists);
    this.subscriptions.push(onGetDataSubscription);
  }

  encClassChangeAction(event) {

    if ( event.value === 'create' ) {
      const dialogRef = this.dialog.open(EnclosureClassCreateComponent, {
        width: '1000px',
        maxWidth: '96vw',
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.encClassList.unshift(result);
          this.selectListControl.setValue(result.name);
        } else {
          this.selectListControl.setValue(this.encClassStore);
        }
      });
    } else if ( event.value === 'empty' ) {
      this.encClassStore = '';
      this.selectListControl.reset('');
    } else {
      this.encClassStore = event.value;
    }

    this.selectionChange.emit(event);
  }

  // model --> view
  writeValue(value: string): void {
    if (value) {
      this.encClassStore = value;
      this.selectListControl.setValue(value, { emitEvent: false });
    } else {
      this.selectListControl.reset('');
    }
  }

  // view --> model
  registerOnChange(fn: (value: string) => void) {
    const onChangeSubscription = this.selectListControl.valueChanges.subscribe(fn);
    this.subscriptions.push(onChangeSubscription);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  onTouched: () => void = () => {};

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
