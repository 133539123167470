<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">
    <div class="top-wrapper">
      <button class="form-wrapper__copy-btn" mat-raised-button color="primary" type="button"
        (click)="openCopyDialog('pipe-part')">COPY</button>

      <span class="form-wrapper__copy-hint" *ngIf="copiedItem">{{copiedItem}} copied</span>
    </div>
    <mat-card-header>
      <mat-card-title>
        Pipe Part
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="form-wrapper__loading" *ngIf="showLoading">
        <mat-progress-spinner mode="indeterminate" [diameter]=20></mat-progress-spinner>
      </div>

      <div class="container" novalidate>
        <form [formGroup]="pipePartForm" (ngSubmit)="onSubmit(pipePartForm.value)" class="form">

          <mat-form-field class="form-element">
            <mat-label>diameter</mat-label>
            <input matInput placeholder="diameter" formControlName="diameter">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>elbow_90</mat-label>
            <input matInput placeholder="elbow_90" formControlName="elbow_90">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>check_valve_spring</mat-label>
            <input matInput placeholder="check_valve_spring" formControlName="check_valve_spring">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>comment</mat-label>
            <input matInput placeholder="comment" formControlName="comment">
          </mat-form-field>

          <div class="form-element">
            <button mat-raised-button color="primary" type="submit" class="button"
              [disabled]="!pipePartForm.valid">Submit Form</button>
          </div>

        </form>
      </div>

    </mat-card-content>
  </mat-card>
</section>
