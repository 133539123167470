import { createAction, props } from "@ngrx/store";
import { MaterialItem } from '../../controllers/controller.model';

export const loadMaterials = createAction('[Product Data] Load Materials');

export const loadMaterialsSuccess = createAction(
  '[Product Data] Load Materials Success',
  props<{ data: MaterialItem[]}>()
);

export const loadMaterialsFailure = createAction(
  '[Product Data] Load Materials Failure',
  props<{ error: any }>()
);

export const createMaterial = createAction(
  '[Product Data] Create New Material',
  props<{ data: MaterialItem }>()
);
