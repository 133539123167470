import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { now } from 'moment-timezone';
import { UserLocalStorage } from '../../login/user-local-store';
import { CalculateCoefficientService } from './calculate-coefficient.service';
import { CoefficientInputParameters, VersionStatus } from '../../pump-simulation/coefficient.model';
import { Observable, firstValueFrom } from 'rxjs';
import { updateParameters } from '../store/coefficient-form/coefficient-form.actions';
import { Store } from '@ngrx/store';
import { SizingApp } from 'src/app/display-product-data/pump-data.model';

@Injectable({
  providedIn: 'root'
})

/**
 * Service to save uploaded measurement data to an S3 bucket
 */
export class UploadService {

  SERVER_URL = environment.calculationUrl;
  VERSION_URL = environment.versioningUrl;
  IMPORT_URL = environment.importUrl;
  IMPORT_API_KEY = environment.importApiKey;
  userLocalStorage = new UserLocalStorage();
  FILENAME = environment.lorentzAssistName;

  constructor(
    private httpClient: HttpClient,
    private calculateCoefficientService: CalculateCoefficientService,
    private store: Store
  ) { }

  /**
   * This creates an initial version no. 1 using the original file content
   * in JSON format. If this is restored it has to be converted into
   * usable measurement data.
   *
   * @param filename the name of the measurement file to be saved
   * @param file the file in json format
   */
  public async createInitialVersion(filename: string, file) {

    const user = this.userLocalStorage.loadUser();
    const inputParameters = JSON.stringify(this.calculateCoefficientService.inputPartParameters);

    const values = {
      filename,
      version: 0,
      username: user!.user.username,
      notes: 'initial upload',
      changes: file,
      inputParams: inputParameters,
      status: VersionStatus.INITIAL,
      approver: user!.user.username,
      timestamp: now()

    };
    const uploadURL = `${this.VERSION_URL}versionMeasurementFile`;

    return await this.httpClient.post<any>(uploadURL, values).toPromise();
  }

  /**
   * Saves the current measurement, input and coefficient data as the next
   * version, while updating the current version to match the new one.
   *
   * @param status the status of the version - PROVISIONAL, PREVIEW and LIVE
   * @param notes any notes the user wishes to make on saving this version
   */
  public async createVersion(status: string, notes: string) {

    const user = this.userLocalStorage.loadUser();
    const inputParameters = JSON.stringify(this.calculateCoefficientService.inputParameters);
    const measurementData = JSON.stringify(this.calculateCoefficientService.convertedMeasurementData);
    const coefficients = JSON.stringify(this.calculateCoefficientService.coefficientResults);
    const npshData = JSON.stringify(this.calculateCoefficientService.convertedNPSHData);
    const interCoefficients = this.calculateCoefficientService.coefficientResults.intermediateCoefficentsJSON;
    const selectedCoefficients = this.calculateCoefficientService.selectedCoefficients;

    const values = {
      filename: this.calculateCoefficientService.inputParameters.pump,
      version: 0,
      username: user!.user.username,
      notes,
      changes: measurementData,
      inputParams: inputParameters,
      coefficients,
      selected_coefficients: selectedCoefficients,
      inter_coefficients: interCoefficients,
      npsh_data: npshData,
      status,
      approver: user!.user.username,
      timestamp: now()
    };
    const uploadURL = `${this.VERSION_URL}versionMeasurementFile`;
    return await this.httpClient.post<any>(uploadURL, values).toPromise();
  }


  /**
   * Persists the content of an uploaded measurement excel file to an S3 bucket
   *
   * @param pumpSystem string - the name of the pumpSystem to which this measurement belongs
   * @param file string - the measurement file content formatted as a json string
   */
  public async upload(pumpSystem: string, file) {
    const uploadURL = `${this.SERVER_URL}persist/measurementFile`;

    const parameter = {
      pumpSystem, file
    };
    return await this.httpClient.post<any>(uploadURL, parameter).toPromise().then(await this.createInitialVersion(pumpSystem, file));

  }

  /**
   * Persists the content of an uploaded Android file into the upload DB
   *
   * @param content string - the Android file content formatted as a json string
   */
  public async uploadAndroidFile(content: string, filename?: string) {
    const options = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'access-control-allow-origin': '*',
        'access-control-allow-headers': '*',
        'authorization': '*',
        'Authorization': '*',
        'Content-Type': 'application/json',
        'x-api-key': this.IMPORT_API_KEY,
      })
    };
    const uploadURL = `${this.IMPORT_URL}import/android`;
    const user = this.userLocalStorage.loadUser();

    const parameter = {
      username: user!.user.username,
      file: content,
      filename: filename? filename : this.FILENAME,
      status: 'LIVE',
      approver: user!.user.username,
      timestamp: now()
    };
    return await firstValueFrom(this.httpClient.post<any>(uploadURL, parameter, options));

  }

  /**
   * Persists the content of an uploaded Sizing app file containing the pump systems for the sizer
   * into the upload DB, and registers the sizing app for further reference and updates
   *
   * @param content string - the Pump system file content formatted as a json string
   */
  public async uploadSizingAppFile(content: string, filename?: string, title?: string): Promise<SizingApp> {
    const options = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'access-control-allow-origin': '*',
        'access-control-allow-headers': '*',
        'authorization': '*',
        'Authorization': '*',
        'Content-Type': 'application/json',
        'x-api-key': this.IMPORT_API_KEY,
      })
    };
    const uploadURL = `${this.IMPORT_URL}import/sizingApp`;
    const user = this.userLocalStorage.loadUser();

    const parameter = {
      username: user!.user.username,
      title: title,
      file: content,
      filename: filename? filename : this.FILENAME,
      status: 'LIVE',
      approver: user!.user.username,
      timestamp: now()
    };
    return await firstValueFrom(this.httpClient.post<SizingApp>(uploadURL, parameter, options));

  }

  /**
   * Persists the content of an uploaded Sizing app file containing the pump systems for the sizer
   * into the upload DB, and registers the sizing app for further reference and updates
   *
   * @param content string - the Pump system file content formatted as a json string
   */
  public async getAllApps(): Promise<Observable<SizingApp[]>> {
    const options = {
      headers: new HttpHeaders({
        'x-api-key': this.IMPORT_API_KEY,
      })
    };
    const uploadURL = `${this.IMPORT_URL}import/sizingApps`;

    return await this.httpClient.get<SizingApp[]>(uploadURL, options);

  }

  public async downloadAndroidFileFromDB() {

    const uploadURL = this.IMPORT_URL + 'export/android/db/live/csv';

    const options = {
      headers: new HttpHeaders({
        'x-api-key': this.IMPORT_API_KEY,
      })
    };

    return await this.httpClient.get<string>(uploadURL, options);

  }


  /**
   * Downloads the content of an uploaded android pumpsystem file
   *
   * @param filename string - the filename denotes the category of file required, e.g. PSK3 or SYSTEM
   * @param version string - which version is required, default is the current one
   */
  public async downloadAndroidFile(filename, version) {
    const uploadURL = this.IMPORT_URL + 'export/android/' + filename + '/​​' + version + '/json';

    const options = {
      headers: new HttpHeaders({
        'x-api-key': this.IMPORT_API_KEY,
      })
    };


    return await this.httpClient.get<string>(uploadURL, options);

  }

  /**
   * Downloads the content of an uploaded android pumpsystem file
   *
   * @param filename string - the filename denotes the category of file required, e.g. PSK3 or SYSTEM
   * @param version string - which version is required, default is the current one
   */
   public async getAllVersions() {
    const uploadURL = this.IMPORT_URL + 'export/pumps/version';

    const options = {
      headers: new HttpHeaders({
        'x-api-key': this.IMPORT_API_KEY,
      })
    };


    return await this.httpClient.get<any[]>(uploadURL, options);

  }

  public async downloadPumpSystemFile(state, format) {
    const uploadURL = this.IMPORT_URL + 'export/pumps/' + state + '/' + format;

    const options = {
      headers: new HttpHeaders({
        'x-api-key': this.IMPORT_API_KEY,
      })
    };

    return await this.httpClient.get<string>(uploadURL, options);

  }

  public async parseSelectedVersion(versionJSON: any) {
    if (versionJSON !== null) {
      this.calculateCoefficientService.resetDataToNull();
      const version = JSON.parse(versionJSON);
      const emptyObject = {};
      if (version.inputParams) {
        const inputParameters: CoefficientInputParameters = JSON.parse(version.inputParams);
        {
          inputParameters.excelBehaviour ? doNothing() : inputParameters.excelBehaviour = false;
          inputParameters.splitPower ? doNothing() : inputParameters.splitPower = 0;
          inputParameters.minLift ? doNothing() : inputParameters.minLift = 1;
          inputParameters.maxLift ? doNothing() : inputParameters.maxLift = 150;
        }
        this.calculateCoefficientService.inputParameters = inputParameters;
        this.store.dispatch(updateParameters({ isUpdateParameters: true }));
      }
      this.calculateCoefficientService.inputPartParameters = this.createInputPartParameters();
      if (version.coefficients && version.coefficients !== null && version.coefficients.toString() !== emptyObject.toString()) {
        this.calculateCoefficientService.convertedMeasurementData = JSON.parse(version.changes);
        const coefficientResults = JSON.parse(version.coefficients);
        coefficientResults.intermediateCoefficents = coefficientResults.inter_coefficients;
        this.calculateCoefficientService.coefficientResults = coefficientResults;
        if (!this.calculateCoefficientService.isEmpty(version.selected_coefficients) &&
          (!this.calculateCoefficientService.isEmpty(version.selected_coefficients.intermediateCoefficentsJSON))) {

          this.calculateCoefficientService.selectedCoefficients = version.selected_coefficients;

        } else {
          this.calculateCoefficientService.transformCoefficientsToSelectedCoefficients();
        }

        if (version.npsh_data && version.npsh_data !== null && version.npsh_data.toString() !== emptyObject.toString()) {
          this.calculateCoefficientService.convertedNPSHData = JSON.parse(version.npsh_data);
        }
      } else {
        this.calculateCoefficientService.measurementData = JSON.parse(version.changes);
        await this.calculateCoefficientService.calculateCoefficient();
      }
      return version;
    }
  }

  createInputPartParameters(): Partial<CoefficientInputParameters> {
    const controller = this.calculateCoefficientService.inputParameters.controller;
    const pump = this.calculateCoefficientService.inputParameters.pump;
    const type = this.calculateCoefficientService.inputParameters.type;
    const partParameters: Partial<CoefficientInputParameters> = {
      pump,
      controller,
      type
    };
    return partParameters;
  }
}
function doNothing() {

}


