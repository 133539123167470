<div class="upload-wrapper">
  <div class="upload-wrapper__input">
    <input
      type="file"
      #fileDropRef
      id="fileDropRef"
      multiple 
      (change)="fileBrowseHandler($event.target.files)"
      accept="image/gif,image/jpeg"
    />
    <h3>Drag and drop file here</h3>
    <h3>or</h3>
    <label for="fileDropRef">Browse for file</label>
  </div>

  <div class="upload-wrapper__list">
    <div class="upload-wrapper__item" *ngFor="let file of files; let i = index">
      <img src="{{ file?.url }}" alt="{{ file?.name }}" height="200" alt="file">
      <div class="info">
        <h4 class="name">
          {{ file?.name }} <span>({{ formatBytes(file?.size) }})</span>
        </h4>
        <mat-progress-spinner [ngClass]="file?.showLoading ? 'showSpinner' : ''" mode="indeterminate" [diameter]=20></mat-progress-spinner>
        <div>
          <button *ngIf="file?.showBtn" mat-flat-button color="primary" (click)="uploadFile(i)">UPLOAD</button>  
          <button mat-flat-button color="primary" (click)="deleteFile(i)">DELETE</button>
          <button *ngIf="!file?.showBtn" mat-flat-button color="primary" (click)="useFile(i)">USE</button>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>

</div>
