import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DisplayProductDataService } from '../display-product-data.service';
import { PartsListService } from '../parts-list.service';
import { PartsListFlatNode, PartsListNode } from '../pump-data.model';

@Component({
  selector: 'app-parts-list-select',
  templateUrl: './parts-list-select.component.html',
  styleUrls: ['./parts-list-select.component.scss']
})
export class PartsListSelectComponent implements OnInit {


  @Input() partsListData;
  @Output() updatePartslistTree = new EventEmitter();
  partsListName = '';
  originalTreeValue = '';
  flatNodeMap = new Map<PartsListFlatNode, PartsListNode>();
  nestedNodeMap = new Map<PartsListNode, PartsListFlatNode>();
  treeControl: FlatTreeControl<PartsListFlatNode>;
  treeFlattener: MatTreeFlattener<PartsListNode, PartsListFlatNode>;
  dataSource: MatTreeFlatDataSource<PartsListNode, PartsListFlatNode>;

  constructor(
    private database: PartsListService,
    private displayProductData: DisplayProductDataService,
  ) { }


  getLevel = (node: PartsListFlatNode) => node.level;

  isExpandable = (node: PartsListFlatNode) => node.expandable;

  getChildren = (node: PartsListNode): PartsListNode[] => node.children;

  // tslint:disable-next-line: variable-name
  hasChild = (_: number, _nodeData: PartsListFlatNode) => _nodeData.expandable;

  // tslint:disable-next-line: variable-name
  hasNoContent = (_: number, _nodeData: PartsListFlatNode) => _nodeData.name === '';

  transformer = (node: PartsListNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.name
      ? existingNode
      : new PartsListFlatNode();
    flatNode.name = node.name;
    flatNode.id = node.id;
    flatNode.material = node.material;
    flatNode.partsNumbers = node.partsNumbers;
    flatNode.level = level;
    flatNode.expandable = (node.children && node.children.length > 0);
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  ngOnInit() {

    if ( this.partsListData ) {
      this.database.updatePartsListTreeWithNumber(this.partsListData);
    }

    this.database.getCurrentPartsList().subscribe(result => {
      this.database.updatePartsListTreeWithNumber(result);
    });

    combineLatest([
      this.database.getPartsListUuid(), // uuid
      this.displayProductData.getPartsList() // all parts list
    ]).subscribe((result) => {

      result[1].forEach(item => {
        if ( item.uuid === result[0] ) {
          this.partsListName = item.name;
          if ( this.originalTreeValue === '' ) {

            if (this.partsListData !== undefined) {
              this.database.updatePartsListTreeWithNumber(this.partsListData);
            } else {
              this.database.updatePartsListTreeWithNumber(item);
            }

          } else {
            this.partsListData = item;
            this.database.updatePartsListTreeWithNumber(this.partsListData);
          }
        }
      });
    });

    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<PartsListFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.database.dataChange.pipe(
      tap(data => {
        if ( this.originalTreeValue === '' ) {
          this.originalTreeValue = JSON.stringify(data);
        }
      })
    ).subscribe(data => {
      this.dataSource.data = [];
      this.dataSource.data = data;
      const sendData: any = {};
      sendData.isPartsNumberChanged = this.originalTreeValue !== JSON.stringify(data);
      const partsData: any = {};
      partsData.name = '';

      if (this.partsListData !== undefined) {
        partsData.name = this.partsListData.name || '';
      } else {
        partsData.name = this.partsListName;
      }

      partsData.partsNumbers = this.handlePartsNumbers(data);
      sendData.partsValue = partsData;
      this.updatePartslistTree.emit(sendData);
    });
  }

  handlePartsNumbers(partsListData) {
    const data = [];
    partsListData.map(item => {
      const subItem: any = {};
      subItem.name = item.name;
      subItem.id = item.id;
      subItem.partsNumbers = item.partsNumbers;
      data.push(subItem);
      if ( item.children.length > 0 ) {
        const recursiveData = this.handlePartsNumbers(item.children);
        recursiveData.map(subData => data.push(subData));
      }
    });
    return data;
  }

  stringToNumber(value) {
    return Number(value);
  }

  /** Update the node when create */
  updateNumberOfPartsList(node: PartsListFlatNode, value: number) {

    if ( Number(node.partsNumbers) !== value ) {
      const nestedNode = this.flatNodeMap.get(node);
      this.database.updateNumberWithPartsListNode(nestedNode, value);
      this.treeControl.expand(node);
    }
  }

}
