import { createAction, props } from "@ngrx/store";
import { PumpSystem, PumpUnit } from "../../pump-data.model";


export const loadPumpSystems = createAction('[Product Data] Load Pump System');

export const resetPumpSytemFormValue = createAction('[Pump System Form] Reset All Pump System Values');

export const loadPumpSystemsSuccess = createAction(
  '[Product Data] Load Pump System Success',
  props<{ data: PumpSystem[] }>()
);

export const loadPumpSystemsFailure = createAction(
  '[Product Data] Load Pump System Failure',
  props<{ error: any }>()
);

export const setInitFormValue = createAction(
  '[Pump System Form] Set Pump System Form Init Value',
  props<{ data: any }>()
);

export const setPumpUnitValue = createAction(
  '[Pump System Form] Set Pump Unit Value',
  props<{ data: Partial<PumpUnit> }>()
);

export const setControllerSelect = createAction(
  '[Pump System Form] Set Controller Select Value',
  props<{ data: String }>()
);

export const setMotorSelect = createAction(
  '[Pump System Form] Set Motor Select Value',
  props<{ data: String }>()
);

export const setPumpEndSelect = createAction(
  '[Pump System Form] Set Pump End Select Value',
  props<{ data: String }>()
);

export const setPumpSytemCoefficientSetName = createAction(
  '[Pump System Form] Set Coefficient Set Select Value',
  props<{ data: String }>()
);
