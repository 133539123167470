import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { now } from 'moment-timezone';
import { combineLatest, first, map, mergeMap, Subscription } from 'rxjs';
import { setCalculationValue } from 'src/app/display-product-data/store/coefficient-set/coefficient-set.actions';
import { CalculateDialogComponent } from 'src/app/pump-simulation/calculate-dialog/calculate-dialog.component';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { removeRadioBtnFocus } from 'src/app/shared/util';
import { PumpSimulationService } from '../pump-simulation.service';
import { ShareDataService } from '../share-data.service';
import { CalculateCoefficientState } from '../store/calculate-coefficient/calculate-coefficient.reducer';
import { selectCalculateCoefficient } from '../store/calculate-coefficient/calculate-coefficient.selector';
import { setLoadSimulationStepper, setSimulationProcess } from '../store/charts-setting/charts-setting.actions';
import { ChartsSettingState } from '../store/charts-setting/charts-setting.reducer';
import { selectChartsSetting } from '../store/charts-setting/charts-setting.selector';
import { SimulationSettingState } from '../store/simulation-setting/simulation-setting.reducer';
import { selectSimulationSetting } from '../store/simulation-setting/simulation-setting.selector';
import { SystemFormState } from '../store/system-form/system-form.reducer';
import { selectSystemForm } from '../store/system-form/system-form.selector';

@Component({
  selector: 'app-simulation-version',
  templateUrl: './simulation-version.component.html',
  styleUrls: ['./simulation-version.component.scss']
})
export class SimulationVersionComponent implements OnInit, OnDestroy, AfterViewInit {

  private subscriptions: Subscription[] = [];
  tableLoading = true;
  filename = '';
  fileId = '';
  isEmptyVersionData = false;
  isShowStepper = false;
  selectedRowIndex = -1;
  userLocalStorage = new UserLocalStorage();
  displayedColumns: string[] = ['approveForUse', 'date', 'user', 'version', 'status', 'notes', 'action'];
  @Input() dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private store: Store,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private elementRef: ElementRef,
    private shareService: ShareDataService,
    private pumpSimulationService: PumpSimulationService,
  ) { }

  ngOnInit() {
    const initData = this.route.paramMap.pipe(
      map(params => params.get('name')),
      mergeMap(fileName => {
        this.selectedRowIndex = -1;
        this.isShowStepper = false;
        this.isEmptyVersionData = false;
        this.dataSource.data = [];
        this.tableLoading = true;
        this.fileId = fileName;
        this.filename = fileName?.replace(/_([^_]*)$/, '$1');
        return this.pumpSimulationService.getMeasurementFileVersionsByID(fileName);
      })
    ).subscribe(data => {

      (data.length === 0) ? this.isEmptyVersionData = true : this.isEmptyVersionData = false;

      this.tableLoading = false;

      const result = data.map(item => {
        item.date = moment(item.submission_time).utc().format('MM/DD/YYYY');
        return item;
      });
      this.dataSource.data = result;

    });
    this.subscriptions.push(initData);
  }

  selectVersionItem(version: number) {

    this.store.pipe(
      select(selectChartsSetting),
      map((data: ChartsSettingState) => data.isShowStepper),
      first()
    ).subscribe(isShowStepper => {
      if (isShowStepper) {
        const dialogRef = this.dialog.open(CalculateDialogComponent, {
          width: '600px',
          disableClose: true,
          minHeight: '20vh',
          data: {
            message: 'Are you sure you want to publish this version?',
            type: 'confirm'
          }
        });
        const versionDialogueSubscription = dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.setLiveVersion('LIVE', result.note);
          } else {
            removeRadioBtnFocus(this.elementRef, version);
          }
        });
        this.subscriptions.push(versionDialogueSubscription);
      } else {
        const dialogRef = this.dialog.open(CalculateDialogComponent, {
          width: '600px',
          disableClose: true,
          minHeight: '20vh',
          data: {
            message: 'You have to load a version before publishing it.',
            type: 'hint'
          }
        });
        dialogRef.afterClosed().subscribe(() => removeRadioBtnFocus(this.elementRef, version));
      }
    });
  }

  setLiveVersion(status: string, notes: string) {
    const initData = combineLatest([
      this.shareService.getConvertMeasurementData(),
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data)
      ),
      this.store.pipe(
        select(selectSimulationSetting),
        map((data: SimulationSettingState) => data)
      ),
      this.store.pipe(
        select(selectSystemForm),
        map((data: SystemFormState) => data)
      )
    ]).pipe(first()).subscribe(([measurements, coefficientData, simulationData, formData]) => {
      const user = this.userLocalStorage.loadUser();
      const handleName = formData.loadName.replace(/ /g, "_");
      const filename = coefficientData.filename;
      const parameter = {
        filename,
        version: formData.versionNumber,
        username: user.user.username,
        notes,
        changes: JSON.stringify(measurements),
        inputParams: JSON.stringify({
          pump: handleName,
          controller: formData.controller,
          type: formData.type,
          splitPower: simulationData.splitPower,
          minLift: simulationData.minLift,
          maxLift: simulationData.maxLift,
          excelBehaviour: simulationData.excelBehaviour,
        }),
        coefficients: JSON.stringify(coefficientData.allCoefficientsData),
        selected_coefficients: JSON.stringify(coefficientData.selectedCoefficientsData),
        displayName: handleName,
        inter_coefficients: coefficientData.allCoefficientsData.intermediateCoefficentsJSON,
        npsh_data: JSON.stringify(coefficientData.convertNPSHData),
        status,
        approver: user.user.username,
        timestamp: now()
      };
      const version = this.pumpSimulationService.createVersion(parameter).subscribe(() => {
        this.router.navigate(['/simulations/simulation', filename]).then(() => {
          window.location.reload();
        });
      });
      this.subscriptions.push(version);
    });
    this.subscriptions.push(initData);
  }

  showVersionData(name: string, versionKey: number) {
    if ( this.selectedRowIndex !== versionKey ) {// prevent same button click multiple times
      this.isShowStepper = true;
      this.selectedRowIndex = versionKey;
      this.store.dispatch(setSimulationProcess({ data: 'load' }));
      this.store.dispatch(setLoadSimulationStepper({ data: true }));
      this.shareService.setCalculate(false);
      this.shareService.setLoadVersionData({
        name,
        versionKey
      });
    }
  }

  deleteVersionData(name: string, versionKey: number) {

    const dialogRef = this.dialog.open(CalculateDialogComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
      data: {
        message: 'Are you sure you want to delete this version?',
        type: 'warn'
      }
    });

    const versionDialogue = dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const deleteVersion = this.pumpSimulationService.deleteMeasurementFileVersionByID
        (name, versionKey).subscribe((data) => {
          if (data.body) {
            this.snackBar.open(data.body, '', { duration: 1500, panelClass: 'hintMsg' })
          } else {
            this.ngOnInit();
            this.selectedRowIndex = -1;
          };

        });
        this.subscriptions.push(deleteVersion);
      }
    });
    this.subscriptions.push(versionDialogue);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // If the user changes the sort order, reset back to the first page.
    const pageSortSubscription = this.sort.sortChange.subscribe(() => this.paginator.firstPage());
    this.subscriptions.push(pageSortSubscription);
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
