import { createAction, props } from "@ngrx/store";
import { PackagingItem } from '../../controllers/controller.model';

export const loadPackagings = createAction('[Product Data] Load Packagings');

export const loadPackagingsSuccess = createAction(
  '[Product Data] Load Packagings Success',
  props<{ data: PackagingItem[]}>()
);

export const loadPackagingsFailure = createAction(
  '[Product Data] Load Packagings Failure',
  props<{ error: any }>()
);

export const createPackaging = createAction(
  '[Product Data] Create New Packaging',
  props<{ data: PackagingItem }>()
);
