<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">
    <mat-card-header>
      <mat-card-title>
        Parts Lists: {{ partslistName }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <mat-progress-spinner *ngIf="!showPartsListTree" mode="indeterminate" [diameter]=20></mat-progress-spinner>

      <div *ngIf="showPartsListTree">
        <button mat-raised-button color="primary" type="button" (click)="tree.treeControl.collapseAll()">Collapse All</button>
        <button mat-raised-button class="expandBtn" color="primary" type="button" (click)="tree.treeControl.expandAll()">Expand All</button>
        <button mat-raised-button class="expandBtn" color="primary" type="button" (click)="addPartListTreeNode()"> Create new item </button>


        <mat-tree #tree class="parts-tree" [dataSource]="dataSource" [treeControl]="treeControl">
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding [ngClass]="{'drop-above': dragNodeExpandOverArea === 'above' && dragNodeExpandOverNode === node,
                                        'drop-below': dragNodeExpandOverArea === 'below' && dragNodeExpandOverNode === node,
                                        'drop-center': dragNodeExpandOverArea === 'center' && dragNodeExpandOverNode === node}"
                            (change)="checklistSelection.toggle(node);"
                            draggable="true"
                            (dragstart)="handleDragStart($event, node);"
                            (dragover)="handleDragOver($event, node);"
                            (drop)="handleDrop($event, node);"
                            (dragend)="handleDragEnd();">
            
            <button mat-icon-button disabled></button>

            {{node.name}} ({{node.id}})
            
            <span *ngIf="node.material" class="parts-material"> {{ node.material }} </span>

            <div class="editWrapper">
              <button mat-icon-button color="primary" (click)="editPartListTreeNode(node)"><mat-icon>edit</mat-icon></button>
              <button mat-icon-button color="warn" (click)="deletePartListTreeNode(node)"><mat-icon class="deleteIcon">add_circle</mat-icon></button>
            </div>
            
          </mat-tree-node>
        
          <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
                        matTreeNodePadding [ngClass]="{'drop-above': dragNodeExpandOverArea === 'above' && dragNodeExpandOverNode === node,
                                      'drop-below': dragNodeExpandOverArea === 'below' && dragNodeExpandOverNode === node,
                                      'drop-center': dragNodeExpandOverArea === 'center' && dragNodeExpandOverNode === node}"
                          draggable="true"
                          (dragstart)="handleDragStart($event, node);"
                          (dragover)="handleDragOver($event, node);"
                          (drop)="handleDrop($event, node);"
                          (dragend)="handleDragEnd();">
          
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'toggle ' + node.filename">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>

            {{node.name}} ({{node.id}})

            <span *ngIf="node.material" class="parts-material"> {{ node.material }} </span>
            
            <div class="editWrapper">
              <button mat-icon-button color="primary" (click)="editPartListTreeNode(node)"><mat-icon>edit</mat-icon></button>
              <button mat-icon-button color="warn" (click)="deletePartListTreeNode(node)"><mat-icon class="deleteIcon">add_circle</mat-icon></button>
            </div>
          </mat-tree-node>
        </mat-tree>

        <div class="button-element">
          <button mat-raised-button color="primary" [disabled]="!isDirty" type="button" (click)="updatePartListTreeNode()">Update parts list</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</section>

<span #emptyItem></span>
