import { trigger, state, style, transition, animate } from '@angular/animations';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { Subscription, map } from 'rxjs';
import { fileSort } from 'src/app/shared/util';
import { ConnectedProductDetail, ConnectedProductEvent } from '../pump-data.model';
import { removeConnectedProductItem, setConnectedProductExpandedEvent } from '../store/connected-product/connected-product.actions';
import { ConnectedProductState } from '../store/connected-product/connected-product.reducer';
import { selectConnectedProduct } from '../store/connected-product/connected-product.seletor';

@Component({
  selector: 'app-pump-connected-product',
  templateUrl: './pump-connected-product.component.html',
  styleUrls: ['./pump-connected-product.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PumpConnectedProductComponent implements OnInit, AfterViewInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  displayedColumns: string[] = ['index', 'name'];
  @Input() dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  showTableHeader = false;
  expandedEvent: ConnectedProductEvent | null;

  constructor(
    private store: Store
  ) { }

  ngOnInit() {

    const tableData = this.store.pipe(
      select(selectConnectedProduct),
      map((data: ConnectedProductState) => data.prepareList)
    ).subscribe((data: ConnectedProductDetail[]) => {
      if (data.length !== 0) {
        this.showTableHeader = true;
      } else {
        this.showTableHeader = false;
      }
      this.dataSource.data = fileSort([...data]);
    });
    this.subscriptions.push(tableData);

    const getExpandedEvent = this.store.pipe(
      select(selectConnectedProduct),
      map((data: ConnectedProductState) => data.expandedEvent)
    ).subscribe((data) => this.expandedEvent = data);
    this.subscriptions.push(getExpandedEvent);
  }

  openItem() {
    this.store.dispatch(setConnectedProductExpandedEvent({ data: this.expandedEvent }));
  }

  addItem(event) {
    this.store.dispatch(removeConnectedProductItem({ data: event }));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

}
