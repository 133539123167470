import { createAction, props } from "@ngrx/store";

export const setFlowRateChartLoading = createAction(
  '[Charts Part] Load Flow Rate Chart',
  props<{ data: Boolean }>()
);

export const setFlowRateChartData = createAction(
  '[Charts Part] Set Flow Rate Chart Data',
  props<{ data: any }>()
);
