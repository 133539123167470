<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">

    <mat-card-header>
      <mat-card-title>
        Vapor pressure
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <mat-form-field class="form-wrapper__searchPart">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Vapor Pressure" #input>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="createNewVaporPressure()"> <mat-icon>add</mat-icon> Add New Vapor Pressure </button>
      
      <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" matSort>
        
            <!-- ID Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
              <td mat-cell *matCellDef="let row"> {{row.id}} </td>
            </ng-container>

            <!-- Progress Column -->
            <ng-container matColumnDef="temperature">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Temperature</th>
              <td mat-cell *matCellDef="let row"> {{row.temperature}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="pressure">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Pressure</th>
              <td mat-cell *matCellDef="let row"> {{row.pressure}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
            <!-- Row shown when there is no matching data. -->
            <tr [ngClass]="isFirstInit ? 'firstInit' : ''" class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
          </table>
          <div class="form-wrapper__table-loading" *ngIf="tableLoading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</section>


