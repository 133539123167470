import { User } from './user';
import * as moment from 'moment-timezone';

const keyLocalStorageUser = 'pm2-user';

/** stores and retrieves user from the browser local store */
export class UserLocalStorage {


  /** saves a user json representation in the local storage */
  saveUser(user: User) {
    const storage = {
      accessToken: user.accessToken,
      refreshToken: user.refreshToken,
      validUntil: moment(user.validUntil).toISOString(),
      refreshValidUntil: user.refreshTokenValidUntil !== null ? moment(user.refreshTokenValidUntil).toISOString() : '',
      user: user.user
    };
    localStorage.setItem(keyLocalStorageUser, JSON.stringify(storage));
  }

  /** delete a user from the local storage */
  deleteUser() {
    localStorage.removeItem(keyLocalStorageUser);
  }

  /**
   * load a user from the local storage
   *
   * @returns the user or null if there is not user stored or the user could not be created.
   */
  loadUser(): User | null {

    const storageValue = localStorage.getItem(keyLocalStorageUser);
    if (!storageValue || storageValue === 'null') {
      return null;
    }

    const jsonValue = JSON.parse(storageValue);

    let refreshValidUntil: Date | null = null;
    if (jsonValue.refreshValidUntil) {
      refreshValidUntil = moment(jsonValue.refreshValidUntil).toDate();
    }

    const accessValidUntil = moment(jsonValue.validUntil).toDate();

    const loadedUser = new User(jsonValue.accessToken, accessValidUntil, jsonValue.refreshToken, refreshValidUntil, jsonValue.user);

    if (!loadedUser.isTokenValid(new Date()) && !loadedUser.isRefreshTokenValid(new Date())) {
      this.deleteUser();
      return null;
    }
    return loadedUser;
  }
}
