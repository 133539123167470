import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { ChartsSettingState } from '../store/charts-setting/charts-setting.reducer';
import { selectChartsSetting } from '../store/charts-setting/charts-setting.selector';

@Component({
  selector: 'app-simulation-load',
  templateUrl: './simulation-load.component.html',
  styleUrls: ['./simulation-load.component.scss']
})
export class SimulationLoadComponent implements OnInit {

  isShowStepper$: Observable<Boolean>;

  constructor(
    private store: Store,
  ) { }

  ngOnInit() {
    this.isShowStepper$ = this.store.pipe(
      select(selectChartsSetting),
      map((data: ChartsSettingState) => data.isShowStepper)
    );
  }

}
