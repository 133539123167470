import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IntermediateInputValue } from '../pump-simulation/coefficient.model';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {


  saveData = {
    isSaveNewData: false,
    saveNotes: ''
  };

  versionData = {
    name: '',
    versionKey: -1
  };

  private isUploadFileDataEmpty: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isUseUploadedFileValueValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isCopyDataValueValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isRevertAllData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isRevertData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private updatedConvertMeasurementData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private isCalculateCoefficients: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private radioType: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private isSaveNewData: BehaviorSubject<any> = new BehaviorSubject<any>(this.saveData);
  private loadVersionData: BehaviorSubject<any> = new BehaviorSubject<any>(this.versionData);
  private isBackCreateSimulation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isBackLoadSimulation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private changedChartItems: BehaviorSubject<String[]> = new BehaviorSubject<String[]>(IntermediateInputValue);


  setUploadFileDataEmpty(value: boolean) {
    this.isUploadFileDataEmpty.next(value);
  }

  getUploadFileDataEmpty(): Observable<boolean> {
    return this.isUploadFileDataEmpty.asObservable();
  }

  setUseUploadedFile(value: boolean) {
    this.isUseUploadedFileValueValid.next(value);
  }

  getUseUploadedFile(): Observable<boolean> {
    return this.isUseUploadedFileValueValid.asObservable();
  }

  setCopyData(value: boolean) {
    this.isCopyDataValueValid.next(value);
  }

  getCopyData(): Observable<boolean> {
    return this.isCopyDataValueValid.asObservable();
  }

  setRevertAllData(value: boolean) {
    this.isRevertAllData.next(value);
  }

  getRevertAllData(): Observable<boolean> {
    return this.isRevertAllData.asObservable();
  }

  setRevertData(value: boolean) {
    this.isRevertData.next(value);
  }

  getRevertData(): Observable<boolean> {
    return this.isRevertData.asObservable();
  }

  setConvertMeasurementData(value: any) {
    this.updatedConvertMeasurementData.next(value);
  }

  getConvertMeasurementData(): Observable<any> {
    return this.updatedConvertMeasurementData.asObservable();
  }

  setCalculate(value: boolean) {
    this.isCalculateCoefficients.next(value);
  }

  getCalculate(): Observable<boolean> {
    return this.isCalculateCoefficients.asObservable();
  }

  setAllRadio(value: string) {
    this.radioType.next(value);
  }

  getAllRadio(): Observable<string> {
    return this.radioType.asObservable();
  }

  setSaveNewData(value: any) {
    this.isSaveNewData.next(value);
  }

  getSaveNewData(): Observable<any> {
    return this.isSaveNewData.asObservable();
  }

  setLoadVersionData(value: any) {
    this.loadVersionData.next(value);
  }

  getLoadVersionData(): Observable<any> {
    return this.loadVersionData.asObservable();
  }

  setCreateBack(value: boolean) {
    this.isBackCreateSimulation.next(value);
  }

  getCreateBack(): Observable<boolean> {
    return this.isBackCreateSimulation.asObservable();
  }

  setLoadBack(value: boolean) {
    this.isBackLoadSimulation.next(value);
  }

  getLoadBack(): Observable<boolean> {
    return this.isBackLoadSimulation.asObservable();
  }

  setChartItem(value: String[]) {
    this.changedChartItems.next(value);
  }

  getChartItem(): Observable<String[]> {
    return this.changedChartItems.asObservable();
  }
}
