import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, map, mergeMap, first, Subscription, distinctUntilChanged } from 'rxjs';
import { IntermediateItemNames, RecalculateFormItemNames } from 'src/app/pump-simulation/coefficient.model';
import { objectToIntermediateCoefficentMap, objectToIntermediateCoefficientsMap, objectToMap, objectToNumberMap } from 'src/app/shared/util';
import { PumpSimulationService } from '../pump-simulation.service';
import { ShareDataService } from '../share-data.service';
import { setCoefficientConvertMeasurementData, setCoefficientConvertNPSHData, setCoefficientMeasurementData, setCoefficientsData, setFilename, setOriginalConvertMeasurementData, setSelectedCoefficientsData } from '../store/calculate-coefficient/calculate-coefficient.actions';
import { CalculateCoefficientState } from '../store/calculate-coefficient/calculate-coefficient.reducer';
import { selectCalculateCoefficient } from '../store/calculate-coefficient/calculate-coefficient.selector';
import { ChartsSettingState } from '../store/charts-setting/charts-setting.reducer';
import { selectChartsSetting } from '../store/charts-setting/charts-setting.selector';
import { setCurrentCalculatedData, setCurrentMeasuredData } from '../store/charts/current-chart/current-chart.actions';
import { setFlowRateCalculatedData, setFlowRateMeasuredData } from '../store/charts/flow-rate-calculate/flow-rate-calculate.actions';
import { setIntermediateData, setIntermediateLoading, setRedrawChartItems } from '../store/charts/intermediate-chart/intermediate-chart.actions';
import { setNPSHCalculatedData, setNPSHMeasuredData } from '../store/charts/npsh-chart/npsh-chart.actions';
import { setRotationalCalculatedData, setRotationalMeasuredData } from '../store/charts/rotational-speed-chart/rotational-speed-chart.actions';
import { selectSimulationLoad } from '../store/simulation-load/simulation-load.selector';
import { SimulationLoadState } from '../store/simulation-load/simulation-load.reducer';
import { SimulationSettingState } from '../store/simulation-setting/simulation-setting.reducer';
import { selectSimulationSetting } from '../store/simulation-setting/simulation-setting.selector';
import { SystemFormState } from '../store/system-form/system-form.reducer';
import { selectSystemForm } from '../store/system-form/system-form.selector';
import { PumpSimulationHelperService } from '../pump-simulation-helper.service'
import { setSystemFormType, setLoadSystemFormNameAppendix, setLoadVersionNumber } from '../store/system-form/system-form.actions';
import { setSimulationExcelBehaviour, setSimulationMinLift, setSimulationMaxLift, setSimulationSplitPower } from '../store/simulation-setting/simulation-setting.actions';
import { setFlowRateChartData } from '../store/charts/flow-rate-chart/flow-rate-chart.actions';
import { setChartSettingsValue } from '../store/charts-setting/charts-setting.actions';

@Component({
  selector: 'app-simulation-result',
  templateUrl: './simulation-result.component.html',
  styleUrls: ['./simulation-result.component.scss']
})
export class SimulationResultComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  coefficientData: CalculateCoefficientState;
  filename: string;

  constructor(
    private store: Store,
    private shareService: ShareDataService,
    private pumpSimulationService: PumpSimulationService,
    private pumpSimulationHelperService: PumpSimulationHelperService,
  ) { }

  ngOnInit() {

    const initData = combineLatest([
      this.store.pipe(
        select(selectChartsSetting),
        map((data: ChartsSettingState) => data.process),
        distinctUntilChanged()
      ),
      this.shareService.getCalculate()
    ]).subscribe(([process, isDraw]) => {

      if (process === 'create' && !isDraw) { // create process and first time draw charts
        this.cleanData();
        this.drawCreateCharts();
      }

      if (process === 'create' && isDraw) { // create process and not first time draw charts
        this.drawCalculateCharts();
      }

      if (process === 'load' && !isDraw) {
        this.cleanData();
        this.drawLoadCharts();
      }

      if (process === 'load' && isDraw) {
        this.drawCalculateCharts();
      }
    })
    this.subscriptions.push(initData);

    const chartValue = this.store.pipe(
      select(selectChartsSetting),
      map((data: ChartsSettingState) => data.values),
      distinctUntilChanged()
    ).subscribe(data => {
      this.redrawChart(data); // This is detect the charts settings value changes.
    });
    this.subscriptions.push(chartValue);

  }

  drawLoadCharts() {
    this.store.pipe(
      select(selectSimulationLoad),
      map((data: SimulationLoadState) => data.values),
      first()
    ).subscribe(data => {
      if (data) {
        this.store.dispatch(setCoefficientMeasurementData({ data: data.changes }));
        const coefficients = JSON.parse(data.coefficients);
        coefficients.intermediateCoefficents = objectToIntermediateCoefficentMap(coefficients.intermediateCoefficentsJSON);
        this.store.dispatch(setFilename({ data: data.filename }));
        this.store.dispatch(setCoefficientsData({ data: coefficients }));

        // setting selectedCoefficients
        const autoValue = coefficients;
        const manualValue = !this.pumpSimulationHelperService.isEmpty(data.selected_coefficients) ?
          JSON.parse(data.selected_coefficients) : this.pumpSimulationHelperService.transformCoefficientsResultToSelectedCoefficients(autoValue);
        this.store.dispatch(setSelectedCoefficientsData({ data: manualValue }));

        const coefficientData = {};
        RecalculateFormItemNames.forEach(item => coefficientData[item] = manualValue[item].value)
        const mediateData = { ...coefficientData };
        mediateData['intermediateCoefficentsJSON'] = autoValue.intermediateCoefficentsJSON;
        this.loadData(data, coefficientData);
        this.loadNPSH(data, coefficientData);
        this.loadIntermediate(data, mediateData);
        this.store.dispatch(setRedrawChartItems({ data: IntermediateItemNames }));

        // Control panel and setting inputs
        const inputParamsData = JSON.parse(data.inputParams);
        this.store.dispatch(setSystemFormType({ data: inputParamsData.type }));
        this.store.dispatch(setLoadSystemFormNameAppendix({ data: inputParamsData.pump }));
        this.store.dispatch(setLoadVersionNumber({ data: data.version }));
        this.store.dispatch(setSimulationExcelBehaviour({ data: inputParamsData.excelBehaviour }));
        this.store.dispatch(setSimulationMinLift({ data: inputParamsData.minLift }));
        this.store.dispatch(setSimulationMaxLift({ data: inputParamsData.maxLift }));
        this.store.dispatch(setSimulationSplitPower({ data: inputParamsData.splitPower }));

        this.store.dispatch(setCoefficientConvertMeasurementData({ data: JSON.parse(data.changes) }));
        this.store.dispatch(setOriginalConvertMeasurementData({ data: JSON.parse(data.changes) }));
        this.drawLoadFlowRateChart(JSON.parse(data.changes), coefficients, inputParamsData);
        this.shareService.setConvertMeasurementData(JSON.parse(data.changes));
      }
    })
  }

  drawLoadFlowRateChart(loadMeasurementData, loadCoefficientsData, loadInputParamsData) {
    const parameter = {
      dataType: 'FLOW',
      measurementData: loadMeasurementData,
      coefficients: loadCoefficientsData,
      inputParameters: loadInputParamsData
    };
    const loadFlowRateChart = this.pumpSimulationService.getDataFor('getMeasuredDataFor', parameter).subscribe(data => {
      const result = objectToMap(data);
      this.store.dispatch(setFlowRateChartData({ data: result }));
    })
    this.subscriptions.push(loadFlowRateChart);
  }

  redrawChart(data: any) {
    this.store.dispatch(setFlowRateMeasuredData({ data: null }));
    this.store.dispatch(setFlowRateCalculatedData({ data: null }));
    this.store.dispatch(setCurrentMeasuredData({ data: null }));
    this.store.dispatch(setCurrentCalculatedData({ data: null }));
    this.store.dispatch(setRotationalMeasuredData({ data: null }));
    this.store.dispatch(setRotationalCalculatedData({ data: null }));
    this.store.pipe(
      select(selectChartsSetting),
      map((data: ChartsSettingState) => data.valueChangedItem),
      first()
    ).subscribe(changedItems => {
      if (changedItems.length > 0) {
        this.shareService.setChartItem(changedItems);
        this.redrawCommonGraphs(data);
        if (changedItems.includes('npsh_c') || changedItems.includes('npsh_a') || changedItems.includes('npsh_exp')) {
          this.getNPSH(data);
        }

        if (changedItems.includes('corr_fact')) {
          this.getNPSH(data);
          this.getIntermediate(data);
        }

        if (changedItems.includes('maq') || changedItems.includes('mcq') || changedItems.includes('naq') || changedItems.includes('ncq') || changedItems.includes('mbq') || changedItems.includes('mdq_') || changedItems.includes('nbq') || changedItems.includes('ndq_') || changedItems.includes('mbi') || changedItems.includes('mci') || changedItems.includes('nbi') || changedItems.includes('nci') || changedItems.includes('mar_') || changedItems.includes('mcr') || changedItems.includes('nar_') || changedItems.includes('ncr') || changedItems.includes('mbr') || changedItems.includes('mai') || changedItems.includes('nbr') || changedItems.includes('nai')) {
          this.redrawInterGraph(changedItems, data);
        }
      }
    });
  }

  redrawCommonGraphs(data: any) {
    this.handleParameters('FLOW', 'getMeasuredDataFor', 'FlowRateMeasuredData', data);
    this.handleParameters('FLOW', 'getCalculatedDataFor', 'FlowRateCalculatedData', data);
    this.handleParameters('CURRENT', 'getMeasuredDataFor', 'CurrentMeasuredData', data);
    this.handleParameters('CURRENT', 'getCalculatedDataFor', 'CurrentCalculatedData', data);
    this.handleParameters('ROTATIONAL_SPEED', 'getMeasuredDataFor', 'RotationalSpeedMeasuredData', data);
    this.handleParameters('ROTATIONAL_SPEED', 'getCalculatedDataFor', 'RotationalSpeedCalculatedData', data);
  }

  redrawInterGraph(items: String[], chartValue: any) {
    const charts = items.map(item => `${item.slice(1, 3)}Chart`);
    this.store.dispatch(setRedrawChartItems({ data: charts }));
    const intermediate = combineLatest([
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data)
      ),
      this.store.pipe(
        select(selectSimulationSetting),
        map((data: SimulationSettingState) => data)
      ),
      this.store.pipe(
        select(selectSystemForm),
        map((data: SystemFormState) => data)
      )
    ]).pipe(
      mergeMap(([coefficientData, simulationData, formData]) => {
        const result = { ...coefficientData.allCoefficientsData };
        if (chartValue) {
          RecalculateFormItemNames.forEach(name => {
            result[`${name}`] = chartValue[`${name}`];
          });
        }
        const parameter = {
          coefficients: result,
          inputParameters: {
            pump: formData.pumpName,
            controller: formData.controller,
            type: formData.type,
            splitPower: simulationData.splitPower,
            minLift: simulationData.minLift,
            maxLift: simulationData.maxLift,
            excelBehaviour: simulationData.excelBehaviour,
            corr_fact: result.corr_fact
          }
        };
        return this.pumpSimulationService.getIntermediate(parameter);
      }),
      first()
    ).subscribe(data => {
      const result = objectToIntermediateCoefficentMap(data);
      this.store.dispatch(setIntermediateData({ data: result }));
    });
    this.subscriptions.push(intermediate);
  }

  drawCalculateCharts() {
    const initData = combineLatest([
      this.shareService.getConvertMeasurementData(),
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data)
      ),
      this.store.pipe(
        select(selectSimulationSetting),
        map((data: SimulationSettingState) => data)
      ),
      this.store.pipe(
        select(selectSystemForm),
        map((data: SystemFormState) => data)
      )
    ]).pipe(
      first(),
      mergeMap(([measurements, coefficientData, simulationData, formData]) => {
        this.coefficientData = coefficientData;
        const parameter = {
          measurements, // not measurementData
          npshData: coefficientData.convertNPSHData,
          inputParameters: {
            pump: formData.pumpName,
            controller: formData.controller,
            type: formData.type,
            splitPower: simulationData.splitPower,
            minLift: simulationData.minLift,
            maxLift: simulationData.maxLift,
            excelBehaviour: simulationData.excelBehaviour,
          }
        };
        return this.pumpSimulationService.calculateCoefficient(parameter);
      }),
    ).subscribe(data => {

      data.intermediateCoefficents = objectToIntermediateCoefficientsMap(data.intermediateCoefficentsJSON);
      this.store.dispatch(setFilename({ data: this.coefficientData.filename }))
      this.store.dispatch(setCoefficientsData({ data }));

      let manualValue = this.pumpSimulationHelperService.transformCoefficientsResultToSelectedCoefficients(data);

      if (!this.pumpSimulationHelperService.isEmpty(this.coefficientData.selectedCoefficientsData
      )) {

        const selectedCoefficientsData = this.coefficientData.selectedCoefficientsData;
        manualValue = this.pumpSimulationHelperService.extractManualValues(selectedCoefficientsData, manualValue);

      }
      this.store.dispatch(setSelectedCoefficientsData({ data: manualValue }));

      this.coefficientData = null;
      this.getData();
      this.getNPSH();
      this.store.dispatch(setIntermediateLoading({ data: true }));
      this.getIntermediate();
      this.store.dispatch(setRedrawChartItems({ data: IntermediateItemNames }));
    });
    this.subscriptions.push(initData);
  }

  drawCreateCharts() {
    const initData = combineLatest([
      this.shareService.getConvertMeasurementData(),
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data)
      ),
      this.store.pipe(
        select(selectSimulationSetting),
        map((data: SimulationSettingState) => data)
      ),
      this.store.pipe(
        select(selectSystemForm),
        map((data: SystemFormState) => data)
      )
    ]).pipe(
      first(),
      mergeMap(([measurements, coefficientData, simulationData, formData]) => {
        this.coefficientData = coefficientData;
        const parameter = {
          measurements, // not measurementData
          npshData: coefficientData.convertNPSHData,
          inputParameters: {
            pump: formData.pumpName,
            controller: formData.controller,
            type: formData.type,
            splitPower: simulationData.splitPower,
            minLift: simulationData.minLift,
            maxLift: simulationData.maxLift,
            excelBehaviour: simulationData.excelBehaviour,
          }
        };
        return this.pumpSimulationService.calculateCoefficient(parameter);
      }),
    ).subscribe(data => {
      data.intermediateCoefficents = objectToIntermediateCoefficientsMap(data.intermediateCoefficentsJSON);
      this.store.dispatch(setFilename({ data: this.coefficientData.filename }));
      this.store.dispatch(setCoefficientsData({ data }));
      const manualValue = this.pumpSimulationHelperService.transformCoefficientsResultToSelectedCoefficients(data);
      this.store.dispatch(setSelectedCoefficientsData({ data: manualValue }));
      this.getData();
      this.getNPSH();
      this.store.dispatch(setIntermediateLoading({ data: true }));
      this.getIntermediate();
      this.store.dispatch(setRedrawChartItems({ data: IntermediateItemNames }));
    });
    this.subscriptions.push(initData);
  }

  getData() {
    this.handleParameters('FLOW', 'getMeasuredDataFor', 'FlowRateMeasuredData');
    this.handleParameters('FLOW', 'getCalculatedDataFor', 'FlowRateCalculatedData');
    this.handleParameters('CURRENT', 'getMeasuredDataFor', 'CurrentMeasuredData');
    this.handleParameters('CURRENT', 'getCalculatedDataFor', 'CurrentCalculatedData');
    this.handleParameters('ROTATIONAL_SPEED', 'getMeasuredDataFor', 'RotationalSpeedMeasuredData');
    this.handleParameters('ROTATIONAL_SPEED', 'getCalculatedDataFor', 'RotationalSpeedCalculatedData');
  }

  loadData(data: any, coefficients: any) {
    this.loadParameters('FLOW', 'getMeasuredDataFor', 'FlowRateMeasuredData', data, coefficients);
    this.loadParameters('FLOW', 'getCalculatedDataFor', 'FlowRateCalculatedData', data, coefficients);
    this.loadParameters('CURRENT', 'getMeasuredDataFor', 'CurrentMeasuredData', data, coefficients);
    this.loadParameters('CURRENT', 'getCalculatedDataFor', 'CurrentCalculatedData', data, coefficients);
    this.loadParameters('ROTATIONAL_SPEED', 'getMeasuredDataFor', 'RotationalSpeedMeasuredData', data, coefficients);
    this.loadParameters('ROTATIONAL_SPEED', 'getCalculatedDataFor', 'RotationalSpeedCalculatedData', data, coefficients);
  }

  getIntermediate(chartValue?: any) {
    const intermediate = combineLatest([
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data)
      ),
      this.store.pipe(
        select(selectSimulationSetting),
        map((data: SimulationSettingState) => data)
      ),
      this.store.pipe(
        select(selectSystemForm),
        map((data: SystemFormState) => data)
      )
    ]).pipe(
      mergeMap(([coefficientData, simulationData, formData]) => {
        const selectedCoefficients = { ...coefficientData.selectedCoefficientsData };
        const result = this.pumpSimulationHelperService.transformSelectedCoefficientsIntoCoefficients(selectedCoefficients);
        if (chartValue) {
          RecalculateFormItemNames.forEach(name => {
            result[`${name}`] = chartValue[`${name}`];
          });
        }
        const parameter = {
          coefficients: result,
          inputParameters: {
            pump: formData.pumpName,
            controller: formData.controller,
            type: formData.type,
            splitPower: simulationData.splitPower,
            minLift: simulationData.minLift,
            maxLift: simulationData.maxLift,
            excelBehaviour: simulationData.excelBehaviour,
            corr_fact: result.corr_fact
          }
        };
        return this.pumpSimulationService.getIntermediate(parameter);
      }),
      first()
    ).subscribe(data => {
      const result = objectToIntermediateCoefficentMap(data);
      this.store.dispatch(setIntermediateData({ data: result }));
    });
    this.subscriptions.push(intermediate);
  }

  loadIntermediate(data: any, values: any) {

    const parameter = {
      coefficients: values,
      inputParameters: JSON.parse(data.inputParams)
    };
    const intermediate = this.pumpSimulationService.getIntermediate(parameter).subscribe(data => {
      const result = objectToIntermediateCoefficentMap(data);
      this.store.dispatch(setIntermediateData({ data: result }));

    });
    this.subscriptions.push(intermediate);
  }

  getNPSH(chartValue?: any) {

    if (chartValue) {
      this.store.dispatch(setNPSHMeasuredData({ data: null }));
      this.store.dispatch(setNPSHCalculatedData({ data: null }));
    }

    const npshMeasure = combineLatest([
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data)
      ),
      this.store.pipe(
        select(selectSimulationSetting),
        map((data: SimulationSettingState) => data)
      ),
      this.store.pipe(
        select(selectSystemForm),
        map((data: SystemFormState) => data)
      )
    ]).pipe(
      mergeMap(([coefficientData, simulationData, formData]) => {
        const parameter = {
          measurementData: coefficientData.convertNPSHData,
          inputParameters: {
            pump: formData.pumpName,
            controller: formData.controller,
            type: formData.type,
            splitPower: simulationData.splitPower,
            minLift: simulationData.minLift,
            maxLift: simulationData.maxLift,
            excelBehaviour: simulationData.excelBehaviour,
          }
        };
        return this.pumpSimulationService.getDataFor('getNPSHMeasuredData', parameter);
      }),
      first()
    ).subscribe(data => {
      const result = objectToNumberMap(data);
      this.store.dispatch(setNPSHMeasuredData({ data: result }));
    });
    this.subscriptions.push(npshMeasure);

    const npshCalculate = combineLatest([
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data)
      ),
      this.store.pipe(
        select(selectSimulationSetting),
        map((data: SimulationSettingState) => data)
      ),
      this.store.pipe(
        select(selectSystemForm),
        map((data: SystemFormState) => data)
      )
    ]).pipe(
      mergeMap(([coefficientData, simulationData, formData]) => {
        const selectedCoefficients = { ...coefficientData.selectedCoefficientsData };
        const result = this.pumpSimulationHelperService.transformSelectedCoefficientsIntoCoefficients(selectedCoefficients);
        if (chartValue) {
          RecalculateFormItemNames.forEach(name => {
            result[`${name}`] = chartValue[`${name}`];
          });
        }
        const parameter = {
          measurementData: coefficientData.convertNPSHData,
          coefficients: result,
          inputParameters: {
            pump: formData.pumpName,
            controller: formData.controller,
            type: formData.type,
            splitPower: simulationData.splitPower,
            minLift: simulationData.minLift,
            maxLift: simulationData.maxLift,
            excelBehaviour: simulationData.excelBehaviour,
          }
        };
        return this.pumpSimulationService.getDataFor('getNPSHCalculatedData', parameter);
      }),
      first()
    ).subscribe(data => {
      const result = objectToNumberMap(data);
      this.store.dispatch(setNPSHCalculatedData({ data: result }));
    });
    this.subscriptions.push(npshCalculate);
  }

  loadNPSH(data: any, values: any) {
    const measureParameter = {
      measurementData: JSON.parse(data.npsh_data),
      inputParameters: JSON.parse(data.inputParams)
    };
    this.store.dispatch(setCoefficientConvertNPSHData({ data: measureParameter.measurementData }));
    const npshMeasure = this.pumpSimulationService.getDataFor('getNPSHMeasuredData', measureParameter).subscribe(data => {
      const result = objectToNumberMap(data);
      this.store.dispatch(setNPSHMeasuredData({ data: result }));
    });
    this.subscriptions.push(npshMeasure);

    const calculateParameter = {
      measurementData: JSON.parse(data.npsh_data),
      coefficients: values,
      inputParameters: JSON.parse(data.inputParams)
    };
    const npshCalculate = this.pumpSimulationService.getDataFor('getNPSHCalculatedData', calculateParameter).subscribe(data => {
      const result = objectToNumberMap(data);
      this.store.dispatch(setNPSHCalculatedData({ data: result }));
    });
    this.subscriptions.push(npshCalculate);
  }

  handleParameters(chartType: string, sourceType: string, dataType: string, chartValue?: any) {
    const parameters = combineLatest([
      this.shareService.getConvertMeasurementData(),
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data)
      ),
      this.store.pipe(
        select(selectSimulationSetting),
        map((data: SimulationSettingState) => data)
      ),
      this.store.pipe(
        select(selectSystemForm),
        map((data: SystemFormState) => data)
      )
    ]).pipe(
      mergeMap(([measurementData, coefficientData, simulationData, formData]) => {
        const selectedCoefficients = { ...coefficientData.selectedCoefficientsData };
        const result = !this.pumpSimulationHelperService.isEmpty(selectedCoefficients) ?
          this.pumpSimulationHelperService.transformSelectedCoefficientsIntoCoefficients(selectedCoefficients) :
          coefficientData.allCoefficientsData;
        if (chartValue) {
          RecalculateFormItemNames.forEach(name => {
            result[`${name}`] = chartValue[`${name}`];
          });
        }
        const parameter = {
          dataType: chartType,
          measurementData: measurementData,
          coefficients: result,
          inputParameters: {
            pump: formData.pumpName,
            controller: formData.controller,
            type: formData.type,
            splitPower: simulationData.splitPower,
            minLift: simulationData.minLift,
            maxLift: simulationData.maxLift,
            excelBehaviour: simulationData.excelBehaviour,
          }
        };
        return this.pumpSimulationService.getDataFor(sourceType, parameter);
      }),
      first()
    ).subscribe(data => {
      const result = objectToMap(data);
      switch (dataType) {
        case 'FlowRateMeasuredData':
          this.store.dispatch(setFlowRateMeasuredData({ data: result }));
          break;
        case 'FlowRateCalculatedData':
          this.store.dispatch(setFlowRateCalculatedData({ data: result }));
          break;
        case 'CurrentMeasuredData':
          this.store.dispatch(setCurrentMeasuredData({ data: result }));
          break;
        case 'CurrentCalculatedData':
          this.store.dispatch(setCurrentCalculatedData({ data: result }));
          break;
        case 'RotationalSpeedMeasuredData':
          this.store.dispatch(setRotationalMeasuredData({ data: result }));
          break;
        case 'RotationalSpeedCalculatedData':
          this.store.dispatch(setRotationalCalculatedData({ data: result }));
          break;
        default:
          break;
      }

    });
    this.subscriptions.push(parameters);
  }

  loadParameters(chartType: string, sourceType: string, dataType: string, result: any, values: any) {
    const parameter = {
      dataType: chartType,
      measurementData: JSON.parse(result.changes),
      coefficients: values,
      inputParameters: JSON.parse(result.inputParams)
    };
    const loadData = this.pumpSimulationService.getDataFor(sourceType, parameter).subscribe(data => {
      const result = objectToMap(data);
      switch (dataType) {
        case 'FlowRateMeasuredData':
          this.store.dispatch(setFlowRateMeasuredData({ data: result }));
          break;
        case 'FlowRateCalculatedData':
          this.store.dispatch(setFlowRateCalculatedData({ data: result }));
          break;
        case 'CurrentMeasuredData':
          this.store.dispatch(setCurrentMeasuredData({ data: result }));
          break;
        case 'CurrentCalculatedData':
          this.store.dispatch(setCurrentCalculatedData({ data: result }));
          break;
        case 'RotationalSpeedMeasuredData':
          this.store.dispatch(setRotationalMeasuredData({ data: result }));
          break;
        case 'RotationalSpeedCalculatedData':
          this.store.dispatch(setRotationalCalculatedData({ data: result }));
          break;
        default:
          break;
      }

    });
    this.subscriptions.push(loadData);
  }

  cleanData() {
    this.store.dispatch(setFlowRateMeasuredData({ data: null }));
    this.store.dispatch(setFlowRateCalculatedData({ data: null }));
    this.store.dispatch(setCurrentMeasuredData({ data: null }));
    this.store.dispatch(setCurrentCalculatedData({ data: null }));
    this.store.dispatch(setRotationalMeasuredData({ data: null }));
    this.store.dispatch(setRotationalCalculatedData({ data: null }));
    this.store.dispatch(setIntermediateData({ data: null }));
    this.store.dispatch(setIntermediateLoading({ data: true }));
    this.store.dispatch(setNPSHMeasuredData({ data: null }));
    this.store.dispatch(setNPSHCalculatedData({ data: null }));
    this.store.dispatch(setCoefficientsData({ data: null }));
    this.store.dispatch(setSelectedCoefficientsData({ data: null }));
    this.store.dispatch(setChartSettingsValue({ data: null }));
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}

