export class Queue<T> {
  _store: T[] = [];
  
  push(val: T) {
    this._store.push(val);
  }
  
  pop(): T | undefined {
    return this._store.shift();
  }

  hasElements(): Boolean | undefined {
    return this._store.length > 0;
  }
}
