import { Action, createReducer, on } from "@ngrx/store";
import * as FlowRateCalculateActions from "./flow-rate-calculate.actions";

export const flowRateCalculateFeatureKey = 'flowRateCalculate';

export interface FlowRateCalculateState {
  loading: Boolean;
  flowCalculatedData: any;
  flowMeasuredData: any
}

export const initialState: FlowRateCalculateState = {
  loading: true,
  flowCalculatedData: null,
  flowMeasuredData: null
}

const reducer = createReducer(
  initialState,
  on(FlowRateCalculateActions.setFlowRateCalculateLoading, (state, action) => {
    return {
      ...state,
      loading: action.data
    }
  }),
  on(FlowRateCalculateActions.setFlowRateMeasuredData, (state, action) => {
    return {
      ...state,
      loading: !state.flowCalculatedData,
      flowMeasuredData: action.data
    }
  }),
  on(FlowRateCalculateActions.setFlowRateCalculatedData, (state, action) => {
    return {
      ...state,
      loading: !state.flowMeasuredData,
      flowCalculatedData: action.data
    }
  })
);

export function flowRateCalculateReducer(
  state: FlowRateCalculateState,
  action: Action
): FlowRateCalculateState {
  return reducer(state, action);
}
