import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, AbstractControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { select, Store } from '@ngrx/store';
import { Subscription, Subject, Observable, startWith, map, combineLatest } from 'rxjs';
import { DisplayProductDataService } from 'src/app/display-product-data/display-product-data.service';
import { setPartsListName } from 'src/app/display-product-data/store/controller/controller.actions';
import { setPartsName, setPartsData, setFormValueChanged } from 'src/app/display-product-data/store/parts-list/parts-list.actions';
import { PartsListState } from 'src/app/display-product-data/store/parts-list/parts-list.reducer';
import { selectPartsList } from 'src/app/display-product-data/store/parts-list/parts-list.seletor';
import { isEmpty, nameSort } from '../../util';

@Component({
  selector: 'app-parts-list-name',
  templateUrl: './parts-list-name.component.html',
  styleUrls: ['./parts-list-name.component.scss']
})

export class PartsListNameComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  initPartsData: any = {};
  pumppartsList = [];
  pumppartsStore = '';
  dropdownList: string[] = [];
  pumppartsControl = new UntypedFormControl('', [
    this.optionNotFound.bind(this),
  ]);
  filteredOptions: Set<string>;
  public readonly pumppartsChanges: Subject<string> = new Subject<string>();
  public pumppartsSuggestions: Observable<string[]>;

  constructor(
    private store: Store,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {

    const getPartsList = combineLatest([
      this.displayProductData.getPartsList(),
      this.store.pipe(
        select(selectPartsList),
        map((data: PartsListState) => data.partsName)
      ),
      this.store.pipe(
        select(selectPartsList),
        map((data: PartsListState) => data.type)
      ),
      this.store.pipe(
        select(selectPartsList),
        map((data: PartsListState) => data.initPartsData)
      )
    ]).subscribe(([data, partsName, type, initData]) => {
      const results = nameSort([...data]);
      if (type === 'motor') {
        this.pumppartsList = results.filter(item => item.type === 'motor');
        this.pumppartsList.push({
          name: 'Not Available',
          uuid: '',
          type: 'motor',
          parts: null
        });
      } else if (type === 'pump_end') {
        this.pumppartsList = results.filter(item => item.type === 'pump_end');
        this.pumppartsList.push({
          name: 'Not Available',
          uuid: '',
          type: 'pump_end',
          parts: null
        });
      }

      this.dropdownList = this.pumppartsList.map(item => item.name);

      this.pumppartsSuggestions = this.pumppartsChanges.pipe(
        startWith(''),
        map((val: string) => this.filterResults(val))
      );

      if (partsName !== '') {
        const item = this.pumppartsList.find(item => item.name === partsName);
        if (item) {
          this.pumppartsControl.setValue(item.name);
        }
      }

      if (!isEmpty(initData)) {
        this.initPartsData = initData;
      }
    });
    this.subscriptions.push(getPartsList);
  }

  private filterResults(val: string) {
    if (val !== '') {
      return this.dropdownList.filter(item => item.includes(val));
    } else {
      return this.dropdownList;
    }
  }

  optionNotFound(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value) {
      this.filteredOptions = new Set(
        this.dropdownList.filter((option) => option.toLowerCase().indexOf(value.toLowerCase()) > -1)
      );
    }
    if (value && !this.filteredOptions.size) {
      return {
        noOption: true
      };
    }
    return null;
  }

  setPumppartsChangeAction(event: MatAutocompleteSelectedEvent) {
    if (this.initPartsData && this.initPartsData.name === event.option.value) {
      this.store.dispatch(setPartsName({ data: event.option.value }));
      this.store.dispatch(setPartsListName({ data: event.option.value }));
      this.store.dispatch(setPartsData({ data: this.initPartsData }));
      this.store.dispatch(setFormValueChanged({ data: false }))
    } else {
      const item = this.pumppartsList.find(item => item.name === event.option.value);
      if (item) {
        this.store.dispatch(setPartsName({ data: event.option.value }));
        this.store.dispatch(setPartsListName({ data: event.option.value }));
        this.store.dispatch(setPartsData({ data: item }));
      }
    }
  }

  onFocus(value: string) {
    if (value === '') {
      this.pumppartsChanges.next('');
    };
    this.optionNotFound(this.pumppartsControl);
  }

  updatePumpparts(value: string) {
    if (value === '') {
      this.pumppartsControl.reset('');
    } else {
      const item = this.pumppartsList.find(item => item.name === value);
      if (item) {
        this.pumppartsControl.setValue(item.name);
      } else {
        this.pumppartsControl.reset('');
      }
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
