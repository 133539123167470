<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">
    <div class="top-wrapper">

    <button class="form-wrapper__copy-btn" mat-raised-button color="primary" type="button"
      (click)="openCopyDialog('module')">COPY</button>

    <span class="form-wrapper__copy-hint" *ngIf="copiedItem">{{copiedItem}} copied</span>
    </div>
    <mat-card-header>
      <mat-card-title>
        Modules
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>

      <div class="form-wrapper__loading" *ngIf="showLoading">
        <mat-progress-spinner mode="indeterminate" [diameter]=20></mat-progress-spinner>
      </div>

      <div class="container" novalidate>
        <form [formGroup]="moduleForm" (ngSubmit)="onSubmit(moduleForm.value)" class="form">

          <mat-form-field class="form-element">
            <mat-label>name</mat-label>
            <input matInput placeholder="name" data-testid="name" formControlName="name">
            <mat-error *ngIf="moduleForm.get('name').hasError('required')">The field name is required</mat-error>
            <mat-error *ngIf="moduleForm.get('name').hasError('duplicateNames')">This name is already used</mat-error>
          </mat-form-field>

          <app-type-select-new data-testid="type" formControlName="type"></app-type-select-new>

          <mat-form-field class="form-element">
            <mat-label>status</mat-label>
            <input matInput placeholder="status" data-testid="status" formControlName="status">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no</mat-label>
            <input matInput placeholder="item_no" data-testid="item_no" formControlName="item_no">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>p_max</mat-label>
            <input matInput placeholder="p_max" data-testid="p_max" formControlName="p_max">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>comment</mat-label>
            <input matInput placeholder="comment" data-testid="comment" formControlName="comment">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>v_mpp</mat-label>
            <input matInput placeholder="v_mpp" data-testid="v_mpp" formControlName="v_mpp">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>v_oc</mat-label>
            <input matInput placeholder="v_oc" data-testid="v_oc" formControlName="v_oc">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>i_sc</mat-label>
            <input matInput placeholder="i_sc" data-testid="i_sc" formControlName="i_sc">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>pmax_coeff</mat-label>
            <input matInput placeholder="pmax_coeff" data-testid="pmax_coeff" formControlName="pmax_coeff">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>voc_coeff</mat-label>
            <input matInput placeholder="voc_coeff" data-testid="voc_coeff" formControlName="voc_coeff">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>isc_coeff</mat-label>
            <input matInput placeholder="isc_coeff" data-testid="isc_coeff" formControlName="isc_coeff">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>max_system_voltage</mat-label>
            <input matInput placeholder="max_system_voltage" data-testid="max_system_voltage"
              formControlName="max_system_voltage">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>width</mat-label>
            <input matInput placeholder="width" data-testid="width" formControlName="width">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>length</mat-label>
            <input matInput placeholder="length" data-testid="length" formControlName="length">
          </mat-form-field>

          <div class="form-element">
            <button mat-raised-button color="primary" type="submit" data-testid="submit" class="button"
              [disabled]="!moduleForm.valid">Submit Form</button>
          </div>

        </form>
      </div>
    </mat-card-content>
  </mat-card>
</section>
