import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, AbstractControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { v4 } from 'uuid';
import { PackagingItem } from '../controllers/controller.model';
import { DisplayProductDataService } from '../display-product-data.service';
import { PackagingState } from '../store/packaging/packaging.reducer';
import { selectPackaging } from '../store/packaging/packaging.selector';

@Component({
  selector: 'app-packaging-create',
  templateUrl: './packaging-create.component.html',
  styleUrls: ['./packaging-create.component.scss']
})

export class PackagingCreateComponent implements OnInit, OnDestroy, AfterContentChecked  {

  private subscriptions: Subscription[] = [];

  packagingForm: UntypedFormGroup;
  
  packagingList = [];
  createdPackagingName = '';
  copiedItem: string;

  dropdownList: string[] = [];

  packagingControl = new UntypedFormControl('', [
    this.optionNotFound.bind(this),
  ]);

  filteredOptions: Set<string>;

  constructor(
    private store: Store,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef,
    private displayProductData: DisplayProductDataService,
    private dialogRef: MatDialogRef<PackagingCreateComponent>
  ) {
    this.packagingForm = this.fb.group({
      w: new UntypedFormControl('', Validators.required),
      h: new UntypedFormControl('', Validators.required),
      l: new UntypedFormControl('', Validators.required),
      pack_weight: new UntypedFormControl('', Validators.required),
    });
  }

  ngOnInit() {
    
    const getPackagingList = this.store.pipe(
      select(selectPackaging),
      map((data: PackagingState) => data.data)
    ).subscribe((data: PackagingItem[]) => {
      this.packagingList = [...data].sort((a, b) => Number(a.subType.L) - Number(b.subType.L));
      this.dropdownList = this.packagingList.map(item => item.name);
    });

    this.subscriptions.push(getPackagingList);

    const formSubscription = this.packagingForm.valueChanges.subscribe((value) => {
      if (value.l === '' && value.h === '' && value.w === '' && value.pack_weight === '') {
        this.createdPackagingName = '';
      } else {
        this.createdPackagingName = `${value.l} (${value.h}x${value.w})-${value.pack_weight}`;
      }
    });
    this.subscriptions.push(formSubscription);
  }

  setPackagingChangeAction(event: MatAutocompleteSelectedEvent) {
    if (event.option.value === '') {
      this.copiedItem = '';
      this.packagingForm.patchValue({
        h: '',
        w: '',
        l: '',
        pack_weight: ''
      });
    } else {
      const details = this.packagingList.filter((data: PackagingItem) => data.name.includes(event.option.value));
      this.copiedItem = details[0].name;
      this.packagingForm.patchValue({
        h: details[0].subType.H,
        w: details[0].subType.W,
        l: details[0].subType.L,
        pack_weight: details[0].weight
      });
    };
  }

  optionNotFound(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    this.filteredOptions = new Set(
      this.dropdownList.filter((option) => option.toLowerCase().indexOf(value.toLowerCase()) >= 0)
    );
    if (value && !this.filteredOptions.size) {
      return {
        noOption: true
      };
    }
    return null;
  }

  updatePackaging(value: string) {
    if ( value === '' ) {
      this.copiedItem = '';
      this.packagingForm.patchValue({
        h: '',
        w: '',
        l: '',
        pack_weight: ''
      });
    } else {
      const details = this.packagingList.filter((data: PackagingItem) => data.name.includes(value));
      this.copiedItem = details[0].name;
      this.packagingForm.patchValue({
        h: details[0].subType.H,
        w: details[0].subType.W,
        l: details[0].subType.L,
        pack_weight: details[0].weight
      });
    }
  }

  onSubmit(result: any) {
    result.uuid = v4();
    const packagingNames = this.packagingList.map(item => item.name);
    if (packagingNames.includes(this.createdPackagingName)) {
      this.snackBar.open('This name is already used', '', {
        duration: 1500,
        panelClass: 'hintMsg'
      });
    } else {
      const newData = {
        name: this.createdPackagingName,
        subType: {
          W: `${result.w}`,
          H: `${result.h}`,
          L: `${result.l}`
        },
        uuid: result.uuid,
        weight: `${result.pack_weight}`,
      };
      this.displayProductData.createNewPackaging(result).subscribe(item => {
        this.snackBar
        .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
        .afterDismissed()
        .pipe(delay(0))
        .subscribe(() => {
          this.displayProductData.reloadPackaging();
          this.dialogRef.close(newData);
        });
      });
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
