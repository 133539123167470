<mat-form-field class="form-element" appearance="fill">
  <mat-label>material</mat-label>
  <input type="text" placeholder="Search material" matInput [formControl]="materialControl" [matAutocomplete]="auto"
    (focus)="onFocus($event.target.value)" (blur)="updateMaterial($event.target.value)"
    (input)="materialChanges.next($event.target.value)" />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setMaterialChangeAction($event)">
    <mat-option *ngFor="let option of materialSuggestions | async" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="materialControl.hasError('noOption')">
    Material not Found
  </mat-error>
</mat-form-field>