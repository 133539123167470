<mat-form-field class="form-item">
  <mat-label>Pump end</mat-label>
  <input type="text" placeholder="Search pump end" matInput [formControl]="pumpendControl"
    [matAutocomplete]="autoController" (focus)="onFocus()" (blur)="updatePumpend($event.target.value)"
    (input)="pumpendChanges.next($event.target.value)" />
  <mat-autocomplete #autoController="matAutocomplete" (optionSelected)="setPumpendChangeAction($event)">
    <mat-option *ngFor="let pumpend of pumpendSuggestions | async; let i = index" [value]="pumpend">
      {{ pumpend }}
      <button *ngIf="i !== 0" class="navigateBtn" (click)="navigateTo(pumpend)" mat-stroked-button>Go to</button>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="pumpendControl.hasError('required')">
    Pump end is Required
  </mat-error>
  <mat-error *ngIf="pumpendControl.hasError('noOption')">
    Pump end not Found
  </mat-error>
</mat-form-field>