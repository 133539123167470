import { Action, createReducer, on } from "@ngrx/store";
import { Accessory, AccessoryDetail, AccessoryEvent } from "../../pump-data.model";
import * as AccessoryActions from "./accessory.actions";

export const accessoryFeatureKey = 'accessory';

export interface AccessoryState {
  accessories: Accessory[];
  accessoryDetail: AccessoryDetail[];
  prepareList: Partial<AccessoryDetail>[];
  selectedList: Partial<AccessoryDetail>[];
  expandedEvent: AccessoryEvent | null;
  showSelectedTableHeader: Boolean;
  loading: Boolean;
  isCopied: Boolean;
  isAccessoryTableChanged: Boolean;
  originalAccessoryArray: string[];
  originalAccessoryArrayBeforeCopied: string[];
}

export const initialState: AccessoryState = {
  accessories: [],
  accessoryDetail: [],
  prepareList: [],
  selectedList: [],
  expandedEvent: null,
  showSelectedTableHeader: true,
  loading: false,
  isCopied: false,
  isAccessoryTableChanged: false,
  originalAccessoryArray: [],
  originalAccessoryArrayBeforeCopied: []
}

const reducer = createReducer(
  initialState,
  on(AccessoryActions.loadAccessories, (state, action) => {
    return {
      ...state,
      loading: true
    }
  }),
  on(AccessoryActions.loadAccessoriesSuccess, (state, action) => {
    return {
      ...state,
      accessories: action.data,
      loading: false
    }
  }),
  on(AccessoryActions.loadAccessoriesFailure, (state, action) => {
    return {
      ...state,
      loading: false
    }
  }),
  on(AccessoryActions.updateAccessory, (state, action) => {
    return {
      ...state,
      accessories: state.accessories.map((accessory) => accessory.uuid === action.data.uuid ? { ...action.data } : accessory)
    }
  }),
  on(AccessoryActions.createAccessory, (state, action) => {
    return {
      ...state,
      accessories: [{ ...action.data }, ...state.accessories]
    }
  }),
  on(AccessoryActions.loadAllAccessorySuccess, (state, action) => {
    return {
      ...state,
      accessoryDetail: action.data
    }
  }),
  on(AccessoryActions.loadAllAccessoryFailure, (state, action) => {
    return {
      ...state
    }
  }),
  on(AccessoryActions.setAccessorySelectedList, (state, action) => {
    return {
      ...state,
      selectedList: action.data
    }
  }),
  on(AccessoryActions.setAccessoryPrepareList, (state, action) => {
    return {
      ...state,
      prepareList: action.data
    }
  }),
  on(AccessoryActions.addAccessoryItem, (state, action) => {
    return {
      ...state,
      prepareList: [{ ...action.data }, ...state.prepareList],
      selectedList: state.selectedList.filter((item) => item.uuid !== action.data.uuid)
    }
  }),
  on(AccessoryActions.removeAccessoryItem, (state, action) => {
    return {
      ...state,
      selectedList: [{ ...action.data }, ...state.selectedList],
      prepareList: state.prepareList.filter((item) => item.uuid !== action.data.uuid)
    }
  }),
  on(AccessoryActions.setAccessoryExpandedEvent, (state, action) => {
    return {
      ...state,
      expandedEvent: action.data
    }
  }),
  on(AccessoryActions.setAccessoryCopyEvent, (state, action) => {
    return {
      ...state,
      isCopied: action.isCopied
    }
  }),
  on(AccessoryActions.setOriginalAccessoryArray, (state, action) => {
    return {
      ...state,
      originalAccessoryArray: action.data
    }
  }),
  on(AccessoryActions.setOriginalAccessoryArrayBeforeCopied, (state, action) => {
    return {
      ...state,
      originalAccessoryArrayBeforeCopied: action.data
    }
  })
);

export function accessoryReducer(
  state: AccessoryState,
  action: Action
): AccessoryState {
  return reducer(state, action);
}

