<h2 mat-dialog-title> Create New Enclosure Class </h2>
<mat-dialog-content>
  <form [formGroup]="encClassForm" class="form">
    <mat-form-field class="form-element">
      <mat-label>Name</mat-label>
      <input matInput placeholder="Name" formControlName="name" [errorStateMatcher]="matcher">
      <mat-error *ngIf="encClassForm.get('name').hasError('required')">The field name is required</mat-error>
      <mat-error *ngIf="encClassForm.get('name').hasError('duplicateNames')">This name is already used</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="greyBtn" mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" type="submit" class="button" data-testid="submit"
    [disabled]="!encClassForm.valid" (click)="onSubmit(encClassForm.value)"> Create </button>
</mat-dialog-actions>