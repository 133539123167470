import { createAction, props } from "@ngrx/store";

export const setFlowRateCalculateLoading = createAction(
  '[Charts Part] Load Flow Rate Calculate Chart',
  props<{ data: Boolean }>()
);

export const setFlowRateCalculatedData = createAction(
  '[Charts Part] Set Flow Rate Calculate Chart Calculated Data',
  props<{ data: any }>()
);

export const setFlowRateMeasuredData = createAction(
  '[Charts Part] Set Flow Rate Calculate Chart Measured Data',
  props<{ data: any }>()
);
