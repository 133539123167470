import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './login/auth-interceptor';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent} from './login/login.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ControllersComponent } from './display-product-data/controllers/controllers.component';
import { PumpEndsComponent } from './display-product-data/pump-ends/pump-ends.component';
import { DisplayProductDataService } from './display-product-data/display-product-data.service';
import { ControllerNewComponent } from './display-product-data/controller-new/controller-new.component';
import { MotorsComponent } from './display-product-data/motors/motors.component';
import { MotorNewComponent } from './display-product-data/motor-new/motor-new.component';
import { PumpEndNewComponent } from './display-product-data/pump-end-new/pump-end-new.component';
import { IsAuthenticatedDirective } from './login/is-authenticated.directive';
import { PumpSystemsComponent } from './display-product-data/pump-systems/pump-systems.component';
import { PumpSystemNewComponent } from './display-product-data/pump-system-new/pump-system-new.component';
import { CopyButtonComponent } from './display-product-data/copy-button/copy-button.component';
import { CitiesComponent } from './display-product-data/cities/cities.component';
import { CountriesComponent } from './display-product-data/countries/countries.component';
import { LocationComponent } from './display-product-data/location/location.component';
import { PoleMountsComponent } from './display-product-data/pole-mounts/pole-mounts.component';
import { ModulesComponent } from './display-product-data/modules/modules.component';
import { PoleMountsNewComponent } from './display-product-data/pole-mounts-new/pole-mounts-new.component';
import { PartsListComponent } from './display-product-data/parts-list/parts-list.component';
import { PartsListNewComponent } from './display-product-data/parts-list-new/parts-list-new.component';
import { AccessoryNewComponent } from './display-product-data/accessory-new/accessory-new.component';
import { AccessoryComponent } from './display-product-data/accessory/accessory.component';
import { ModuleNewComponent } from './display-product-data/module-new/module-new.component';
import { ConnectedItemsNewComponent } from './display-product-data/connected-items-new/connected-items-new.component';
import { ConnectedItemsComponent } from './display-product-data/connected-items/connected-items.component';
import { VaporPressureComponent } from './display-product-data/vapor-pressure/vapor-pressure.component';
import { DataSideNavComponent } from './display-product-data/data-side-nav/data-side-nav.component';
import { DisplayPumpSimulationService } from './pump-simulation/display-pump-simulation.service';
import { PumpSimulationService } from './pump-simulation/pump-simulation.service';
import { LoadEventService } from './pump-simulation/load-event.service';
import { ShareDataService } from './pump-simulation/share-data.service';
import { UploadService } from './upload-download/service/upload.service';
import { CalculateDialogComponent } from './pump-simulation/calculate-dialog/calculate-dialog.component';
import { VaporPressureNewComponent } from './display-product-data/vapor-pressure-new/vapor-pressure-new.component';
import { CityNewComponent } from './display-product-data/city-new/city-new.component';
import { CountryNewComponent } from './display-product-data/country-new/country-new.component';
import { PartsListDialogComponent } from './display-product-data/parts-list-dialog/parts-list-dialog.component';
import { PartsListService } from './display-product-data/parts-list.service';
import { MaterialCreateComponent } from './display-product-data/material-create/material-create.component';
import { MaterialOverviewComponent } from './display-product-data/material-overview/material-overview.component';
import { PackagingOverviewComponent } from './display-product-data/packaging-overview/packaging-overview.component';
import { PackagingCreateComponent } from './display-product-data/packaging-create/packaging-create.component';
import { PictureCreateComponent } from './display-product-data/picture-create/picture-create.component';
import { UploadDownloadDataComponent } from './upload-download/upload-download-data/upload-download-data.component';
import { PictureViewComponent } from './display-product-data/picture-view/picture-view.component';
import { PipePartOverviewComponent } from './display-product-data/pipe-part-overview/pipe-part-overview.component';
import { PipePartCreateComponent } from './display-product-data/pipe-part-create/pipe-part-create.component';
import { PartsListSelectComponent } from './display-product-data/parts-list-select/parts-list-select.component';
import { UploadDownloadDialogueComponent } from './upload-download/upload-download-dialogue/upload-download-dialogue.component';
import { EnclosureClassCreateComponent } from './display-product-data/enclosure-class-create/enclosure-class-create.component';
import { SentryErrorHandler } from './shared/logger';
import { ErrorHandler } from '@angular/core';
import { UpdateButtonComponent } from './display-product-data/update-button/update-button.component';
import { SaveButtonComponent } from './display-product-data/save-button/save-button.component';
import { ErrorButtonComponent } from './display-product-data/error-button/error-button.component';
import { EnclosureClassSelectNewComponent } from './display-product-data/enclosure-class-select-new/enclosure-class-select-new.component';
import { PipePartComponent } from './display-product-data/pipe-part/pipe-part.component';
import { DrawingViewComponent } from './display-product-data/drawing-view/drawing-view.component';
import { PictureSelectNewComponent } from './display-product-data/picture-select-new/picture-select-new.component';
import { PartsListAddNodeComponent } from './display-product-data/parts-list-add-node/parts-list-add-node.component';
import { PartsListEditNodeComponent } from './display-product-data/parts-list-edit-node/parts-list-edit-node.component';
import { TypeSelectNewComponent } from './display-product-data/type-select-new/type-select-new.component';
import { CoefficientDataCreateComponent } from './pump-simulation/coefficient-data-create/coefficient-data-create.component';
import { environment } from 'src/environments/environment';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { VersionTableComponent } from './display-product-data/version-table/version-table.component';
import { EffectsModule } from '@ngrx/effects';
import { MaterialSelectComponent } from './display-product-data/material-select/material-select.component';
import { MaterialSelectNewComponent } from './display-product-data/material-select-new/material-select-new.component';
import { NpshChartComponent } from './pump-simulation/charts/npsh-chart/npsh-chart.component';
import { AqChartComponent } from './pump-simulation/charts/aq-chart/aq-chart.component';
import { BqChartComponent } from './pump-simulation/charts/bq-chart/bq-chart.component';
import { CqChartComponent } from './pump-simulation/charts/cq-chart/cq-chart.component';
import { DqChartComponent } from './pump-simulation/charts/dq-chart/dq-chart.component';
import { AiChartComponent } from './pump-simulation/charts/ai-chart/ai-chart.component';
import { BiChartComponent } from './pump-simulation/charts/bi-chart/bi-chart.component';
import { CiChartComponent } from './pump-simulation/charts/ci-chart/ci-chart.component';
import { ArChartComponent } from './pump-simulation/charts/ar-chart/ar-chart.component';
import { BrChartComponent } from './pump-simulation/charts/br-chart/br-chart.component';
import { CrChartComponent } from './pump-simulation/charts/cr-chart/cr-chart.component';
import { RotationalSpeedChartComponent } from './pump-simulation/charts/rotational-speed-chart/rotational-speed-chart.component';
import { CurrentChartComponent } from './pump-simulation/charts/current-chart/current-chart.component';
import { DashboardComponent } from './pump-simulation/dashboard/dashboard.component';
import { SystemFormComponent } from './pump-simulation/system-form/system-form.component';
import { FlowRateChartNewComponent } from './pump-simulation/charts/flow-rate-chart-new/flow-rate-chart-new.component';
import { DataTableComponent } from './pump-simulation/data-table/data-table.component';
import { SimulationCreateComponent } from './pump-simulation/simulation-create/simulation-create.component';
import { SimulationStepperComponent } from './pump-simulation/simulation-stepper/simulation-stepper.component';
import { SimulationResultComponent } from './pump-simulation/simulation-result/simulation-result.component';
import { SourceOverviewComponent } from './pump-simulation/data-source/source-overview/source-overview.component';
import { SourceUploadFileComponent } from './pump-simulation/data-source/source-upload-file/source-upload-file.component';
import { SourceUseUploadedFileComponent } from './pump-simulation/data-source/source-use-uploaded-file/source-use-uploaded-file.component';
import { SourceCopyDataComponent } from './pump-simulation/data-source/source-copy-data/source-copy-data.component';
import { FlowRateChartCalculateComponent } from './pump-simulation/charts/flow-rate-chart-calculate/flow-rate-chart-calculate.component';
import { ControlPanelComponent } from './pump-simulation/control-panel/control-panel.component';
import { ChartsSettingComponent } from './pump-simulation/charts-setting/charts-setting.component';
import { SimulationLoadComponent } from './pump-simulation/simulation-load/simulation-load.component';
import { SimulationVersionComponent } from './pump-simulation/simulation-version/simulation-version.component';
import { UploadDownloadAccessoriesComponent } from './upload-download/upload-download-accessories/upload-download-accessories.component';
import { UploadDownloadPS2Component } from './upload-download/upload-download-ps2/upload-download-ps2.component';
import { UploadDownloadPSK3Component } from './upload-download/upload-download-psk3/upload-download-psk3.component';
import { UploadDownloadPumpsystemsComponent } from './upload-download/upload-download-pumpsystems/upload-download-pumpsystems.component';
import { UploadDownloadAndroidComponent } from './upload-download/upload-download-android/upload-download-android.component';
import { UploadDownloadSizingAppComponent } from './upload-download/upload-download-sizing-app/upload-download-sizing-app.component';
import { SizingAppPageComponent } from './upload-download/sizing-app-page/sizing-app-page.component';
import { UploadDownloadXylemPsk3Component } from './upload-download/upload-download-xylem-psk3/upload-download-xylem-psk3.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavigationComponent,
    ControllersComponent,
    PumpEndsComponent,
    ControllerNewComponent,
    MotorsComponent,
    MotorNewComponent,
    PumpEndNewComponent,
    IsAuthenticatedDirective,
    PumpSystemsComponent,
    PumpSystemNewComponent,
    CopyButtonComponent,
    CitiesComponent,
    CountriesComponent,
    LocationComponent,
    PoleMountsComponent,
    ModulesComponent,
    PoleMountsNewComponent,
    PartsListComponent,
    PartsListNewComponent,
    AccessoryNewComponent,
    AccessoryComponent,
    ModuleNewComponent,
    ConnectedItemsNewComponent,
    ConnectedItemsComponent,
    VaporPressureComponent,
    DataSideNavComponent,
    CalculateDialogComponent,
    VaporPressureNewComponent,
    CityNewComponent,
    CountryNewComponent,
    PartsListDialogComponent,
    MaterialCreateComponent,
    MaterialOverviewComponent,
    PackagingOverviewComponent,
    PackagingCreateComponent,
    PictureCreateComponent,
    UploadDownloadDataComponent,
    PictureViewComponent,
    PipePartOverviewComponent,
    PipePartCreateComponent,
    PartsListSelectComponent,
    UploadDownloadDialogueComponent,
    EnclosureClassCreateComponent,
    UpdateButtonComponent,
    SaveButtonComponent,
    ErrorButtonComponent,
    EnclosureClassSelectNewComponent,
    PipePartComponent,
    DrawingViewComponent,
    PictureSelectNewComponent,
    PartsListAddNodeComponent,
    PartsListEditNodeComponent,
    TypeSelectNewComponent,
    CoefficientDataCreateComponent,
    VersionTableComponent,
    MaterialSelectComponent,
    MaterialSelectNewComponent,
    NpshChartComponent,
    AqChartComponent,
    BqChartComponent,
    CqChartComponent,
    DqChartComponent,
    AiChartComponent,
    BiChartComponent,
    CiChartComponent,
    ArChartComponent,
    BrChartComponent,
    CrChartComponent,
    RotationalSpeedChartComponent,
    CurrentChartComponent,
    DashboardComponent,
    SystemFormComponent,
    FlowRateChartNewComponent,
    DataTableComponent,
    SimulationCreateComponent,
    SimulationStepperComponent,
    SimulationResultComponent,
    SourceOverviewComponent,
    SourceUploadFileComponent,
    SourceUseUploadedFileComponent,
    SourceCopyDataComponent,
    FlowRateChartCalculateComponent,
    ControlPanelComponent,
    ChartsSettingComponent,
    SimulationLoadComponent,
    SimulationVersionComponent,
    UploadDownloadAccessoriesComponent,
    UploadDownloadPS2Component,
    UploadDownloadPSK3Component,
    UploadDownloadPumpsystemsComponent,
    UploadDownloadAndroidComponent,
    UploadDownloadSizingAppComponent,
    SizingAppPageComponent,
    UploadDownloadXylemPsk3Component
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ScrollingModule,
    DragDropModule,
    // ngrx
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    UploadService,
    ShareDataService,
    LoadEventService,
    PartsListService,
    PumpSimulationService,
    DisplayProductDataService,
    DisplayPumpSimulationService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
