<h2 mat-dialog-title> Update the changes </h2>
<mat-dialog-content>
  <p> Confirm to update all the changes? </p>
  <form #modalForm="ngForm">
    <div>
      <mat-form-field>
        <input matInput name="comment" placeholder="Comment" ngModel>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="greyBtn" mat-button mat-dialog-close> Cancel </button>
  <button mat-raised-button color="primary" [mat-dialog-close]="modalForm.value"> Update </button>
</mat-dialog-actions>