<div class="mat-elevation-z8 table-wrapper">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="headPressure">
      <th mat-header-cell *matHeaderCellDef> headPressure </th>
      <td mat-cell *matCellDef="let row; let id = index">
        <span contenteditable="true" (keyup)="changeValue(id, 'headPressure', $event)"
          (blur)="updateList(id, 'headPressure', $event)">{{row.headPressure}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="current">
      <th mat-header-cell *matHeaderCellDef> current </th>
      <td mat-cell *matCellDef="let row; let id = index">
        <span (keyup)="changeValue(id, 'current', $event)" (blur)="updateList(id, 'current', $event)"
          contenteditable="true">{{row.current}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="voltage">
      <th mat-header-cell *matHeaderCellDef> voltage </th>
      <td mat-cell *matCellDef="let row; let id = index">
        <span contenteditable="true" (keyup)="changeValue(id, 'voltage', $event)"
          (blur)="updateList(id, 'voltage', $event)">{{row.voltage}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="volume">
      <th mat-header-cell *matHeaderCellDef> volume </th>
      <td mat-cell *matCellDef="let row; let id = index">
        <span contenteditable="true" (keyup)="changeValue(id, 'volume', $event)"
          (blur)="updateList(id, 'volume', $event)">{{row.volume}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="rotationalSpeed">
      <th mat-header-cell *matHeaderCellDef> rotationalSpeed </th>
      <td mat-cell *matCellDef="let row; let id = index">
        <span contenteditable="true" (keyup)="changeValue(id, 'rotationalSpeed', $event)"
          (blur)="updateList(id, 'rotationalSpeed', $event)">{{row.rotationalSpeed}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="measurementTime">
      <th mat-header-cell *matHeaderCellDef> measurementTime </th>
      <td mat-cell *matCellDef="let row; let id = index">
        <span contenteditable="true" (keyup)="changeValue(id, 'measurementTime', $event)"
          (blur)="updateList(id, 'measurementTime', $event)">{{row.measurementTime}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="power">
      <th mat-header-cell *matHeaderCellDef> power </th>
      <td mat-cell *matCellDef="let row; let id = index">
        <span contenteditable="true" (keyup)="changeValue(id, 'power', $event)"
          (blur)="updateList(id, 'power', $event)">{{row.power}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="flowRate">
      <th mat-header-cell *matHeaderCellDef> flowRate </th>
      <td mat-cell *matCellDef="let row; let id = index">
        <span contenteditable="true" (keyup)="changeValue(id, 'flowRate', $event)"
          (blur)="updateList(id, 'flowRate', $event)">{{row.flowRate}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="remove">
      <th mat-header-cell *matHeaderCellDef> remove </th>
      <td mat-cell *matCellDef="let row; let id = index">
        <button mat-stroked-button color="warn" (click)="remove(id)">Remove</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div *ngIf="isEmptyData" class="noVersions">
    No Data, Please click Add new item button and add data.
  </div>
  <div class="loading" *ngIf="tableLoading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>