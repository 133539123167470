import { createAction, props } from "@ngrx/store";
import { CalculationDetail, CoefficientSetItemDetail, SelectedCalculationDetail } from "../../pump-data.model";

export const loadCoefficientSets = createAction('[Product Data] Load Coefficient Sets');

export const loadCoefficientSetsSuccess = createAction(
  '[Product Data] Load Coefficient Sets Success',
  props<{ data: CoefficientSetItemDetail[]}>()
);

export const loadCoefficientSetsFailure = createAction(
  '[Product Data] Load Coefficient Sets Failure',
  props<{ error: any }>()
);

export const setCalculationValue = createAction(
  '[Product System Form] Set Coefficient Set Form Value',
  props<{ data: Partial<CalculationDetail> | Partial<SelectedCalculationDetail>}>()
);

export const setPumpSystemCopied = createAction(
  '[Product System Form] After Copied Pump System, Coefficient Set Value Need Updated',
  props<{ data: Boolean}>()
);

export const setCoefficientSetValueName = createAction(
  '[Product System Form] Set Coefficient Set Filename',
  props<{ data: String}>()
);

