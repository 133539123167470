import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, distinctUntilChanged, first, map, Observable, of, Subscription } from 'rxjs';
import { ChartConfiguration } from 'src/app/pump-simulation/chartConfiguration';
import { createConfiguration, plotData } from 'src/app/shared/util';
import { ShareDataService } from '../../share-data.service';
import { CalculateCoefficientState } from '../../store/calculate-coefficient/calculate-coefficient.reducer';
import { selectCalculateCoefficient } from '../../store/calculate-coefficient/calculate-coefficient.selector';
import { setIntermediateData } from '../../store/charts/intermediate-chart/intermediate-chart.actions';
import { IntermediateState } from '../../store/charts/intermediate-chart/intermediate-chart.reducer';
import { selectIntermediate } from '../../store/charts/intermediate-chart/intermediate-chart.selector';

declare const google: any;

const DQ_CHART_ID = 'dqChart';

@Component({
  selector: 'app-dq-chart',
  templateUrl: './dq-chart.component.html',
  styleUrls: ['./dq-chart.component.scss']
})
export class DqChartComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  xAxisToYaxisChart = null;
  showPlaceholder$: Observable<Boolean>;
  @ViewChild(DQ_CHART_ID, { static: true }) chartElement: ElementRef;

  constructor(
    private store: Store,
    private shareService: ShareDataService,
  ) { }

  ngOnInit() {
    const chartLoading = this.shareService.getChartItem().subscribe(data => {
      if (data.includes('mdq_') || data.includes('ndq_')) {
        this.showPlaceholder$ = of(true);
      }
    });
    this.subscriptions.push(chartLoading);

    const dqData = this.store.pipe(
      select(selectIntermediate),
      map((data: IntermediateState) => data.intermediateData),
      distinctUntilChanged()
    ).subscribe(data => {
      if (data) {
        this.handleDQGraph();
      }
    });
    this.subscriptions.push(dqData);
  }

  handleDQGraph() {
    const data = this.store.pipe(
      select(selectIntermediate),
      map((data: IntermediateState) => data.chartItems),
      first()
    ).subscribe(data => {
      if (data.includes('dqChart')) {
        this.showPlaceholder$ = of(true);
        this.createDQGraph();
      }
    });
    this.subscriptions.push(data);
  }

  createDQGraph() {

    const dqChartConfiguration = createConfiguration({ yAxis: 'DQ [m³/(hW²)]' });

    const dqGraph = combineLatest([
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data)
      ),
      this.store.pipe(
        select(selectIntermediate),
        map((data: IntermediateState) => data),
      ),
    ]).pipe(first()).subscribe(([measuredData, calculatedData]) => {

      const dqMeasuredData = new Map<number, number>();
      const dqCalculatedData = new Map<number, number>();
      const dqMeasuredDataMap = new Map();
      const dqCalculatedDataMap = new Map();

      for (const entry of measuredData.allCoefficientsData.intermediateCoefficents.entries()) {
        dqMeasuredData.set(entry[0], entry[1].dq);
        dqMeasuredDataMap.set(1, dqMeasuredData);
      }

      if (calculatedData.intermediateData) {

        for (const entry of calculatedData.intermediateData.entries()) {
          dqCalculatedData.set(entry[0], entry[1].dq);
          dqCalculatedDataMap.set(-1, dqCalculatedData);
        }
        this.showPlaceholder$ = of(false);
        google.charts.load('current', { packages: ['corechart'] });
        google.charts.setOnLoadCallback(this.drawChart(dqChartConfiguration, this.chartElement.nativeElement, dqCalculatedDataMap, dqMeasuredDataMap));
      }
    });
    this.subscriptions.push(dqGraph);
  }

  drawChart(
    chartConfiguration: ChartConfiguration,
    chart: ElementRef,
    calculation: Map<number, Map<number, number>>,
    measurements: Map<number, Map<number, number>>
  ) {
    return () => {
      let columnIndex = 1;
      let rowIndex = 0;
      const colors = chartConfiguration.colours;
      const data = new google.visualization.DataTable();
      data.addColumn('number', chartConfiguration.xAxisTitle);
      const options = chartConfiguration.options;

      let head;
      ({ head, rowIndex, columnIndex } = plotData(
        calculation, data, rowIndex, chartConfiguration,
        columnIndex, options, colors, 'calculated'));
      ({ head, rowIndex, columnIndex } = plotData(
        measurements, data, rowIndex, chartConfiguration,
        columnIndex, options, colors, 'measured'));

      this.xAxisToYaxisChart = new google.visualization.LineChart(chart);
      google.visualization.events.addListener(this.xAxisToYaxisChart, 'ready', () => {
        // remove loading
      });
      this.xAxisToYaxisChart.draw(data, options);
    };
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    if(this.xAxisToYaxisChart) {
      this.xAxisToYaxisChart.clearChart();
    }
    // this.store.dispatch(setIntermediateData({ data: null }));
  }
}
