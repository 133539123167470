<mat-dialog-content>
  <p *ngIf="message"> {{message}} </p>
  <form *ngIf="type !== 'hint' && type !== 'warn'" [formGroup]="eventForm" class="form">
    <mat-form-field class="form-element">
      <mat-label>Note</mat-label>
      <input matInput placeholder="Note" formControlName="note">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button *ngIf="type !== 'hint'" mat-raised-button mat-dialog-close> Cancel </button>
  <button *ngIf="type === 'switch' || type === 'hint' || type === 'warn'" mat-raised-button color="primary"
    (click)="onConfirmClick()">
    Confirm </button>
  <button *ngIf="type === 'confirm'" mat-raised-button color="primary" [mat-dialog-close]="eventForm.getRawValue()">
    Confirm </button>
</mat-dialog-actions>