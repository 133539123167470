import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { VaporPressureItem } from '../controllers/controller.model';
import { DisplayProductDataService } from '../display-product-data.service';
import { VaporPressureNewComponent } from '../vapor-pressure-new/vapor-pressure-new.component';

@Component({
  selector: 'app-vapor-pressure',
  templateUrl: './vapor-pressure.component.html',
  styleUrls: ['./vapor-pressure.component.scss']
})
export class VaporPressureComponent implements OnInit, AfterViewInit {

  vaporPressureForm: UntypedFormGroup;
  displayedColumns: string[] = ['id', 'temperature', 'pressure'];

  isFirstInit = true;
  tableLoading = true;
  tableData = [];
  @Input() dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {
    this.displayProductData.getVaporPressureList().subscribe((allVaporPressures: VaporPressureItem[]) => {
      this.tableLoading = false;
      this.tableData = allVaporPressures.sort((a, b) => Number(a.id) - Number(b.id));
      this.dataSource.data = this.tableData;
      this.isFirstInit = false;
    });
  }

  createNewVaporPressure() {
    const dialogRef = this.dialog.open(VaporPressureNewComponent, {
      width: '1000px',
      maxWidth: '96vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tableData.unshift(result);
        this.dataSource.data = this.tableData;
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.firstPage());
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
