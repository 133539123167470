<mat-progress-spinner *ngIf="!showLoadResult" mode="indeterminate" [diameter]=20></mat-progress-spinner>
<div class="mat-elevation-z8" *ngIf="showLoadResult">
  <mat-horizontal-stepper #loadStepper [selectedIndex]="3">
    <mat-step>
      <ng-template matStepLabel>System components</ng-template>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Data source</ng-template>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Data review</ng-template>
      <ng-container *ngIf="showLoadDataView">
        <app-flow-rate-chart-new></app-flow-rate-chart-new>
        <div class="actions">
          <button mat-raised-button color="primary" (click)="addNewLoadItemData()">Add new item</button>
          <button mat-raised-button *ngIf="(showRevertAllDataBtn$ | async)" (click)="revertAllData()"
            color="primary">Recover original
            data</button>
          <button mat-raised-button *ngIf="(showRevertDataBtn$ | async)" (click)="revertData()" color="primary">Revert
            removed
            item</button>
          <button mat-raised-button (click)="loadReviewStepper()" color="primary">Next</button>
        </div>
        <app-data-table></app-data-table>
      </ng-container>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Coefficients</ng-template>
      <app-simulation-result></app-simulation-result>
    </mat-step>

  </mat-horizontal-stepper>
</div>