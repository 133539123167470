import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthenticatedGuard } from './login/authenticated.guard';
import { ControllersComponent } from './display-product-data/controllers/controllers.component';
import { ControllerNewComponent } from './display-product-data/controller-new/controller-new.component';
import { MotorNewComponent } from './display-product-data/motor-new/motor-new.component';
import { MotorsComponent } from './display-product-data/motors/motors.component';
import { PumpEndNewComponent } from './display-product-data/pump-end-new/pump-end-new.component';
import { PumpEndsComponent } from './display-product-data/pump-ends/pump-ends.component';
import { PumpSystemNewComponent } from './display-product-data/pump-system-new/pump-system-new.component';
import { PumpSystemsComponent } from './display-product-data/pump-systems/pump-systems.component';
import { CitiesComponent } from './display-product-data/cities/cities.component';
import { CountriesComponent } from './display-product-data/countries/countries.component';
import { LocationComponent } from './display-product-data/location/location.component';
import { PoleMountsComponent } from './display-product-data/pole-mounts/pole-mounts.component';
import { PoleMountsNewComponent } from './display-product-data/pole-mounts-new/pole-mounts-new.component';
import { PartsListNewComponent } from './display-product-data/parts-list-new/parts-list-new.component';
import { PartsListComponent } from './display-product-data/parts-list/parts-list.component';
import { AccessoryNewComponent } from './display-product-data/accessory-new/accessory-new.component';
import { AccessoryComponent } from './display-product-data/accessory/accessory.component';
import { ConnectedItemsNewComponent } from './display-product-data/connected-items-new/connected-items-new.component';
import { ConnectedItemsComponent } from './display-product-data/connected-items/connected-items.component';
import { ModuleNewComponent } from './display-product-data/module-new/module-new.component';
import { ModulesComponent } from './display-product-data/modules/modules.component';
import { VaporPressureComponent } from './display-product-data/vapor-pressure/vapor-pressure.component';
import { DataSideNavComponent } from './display-product-data/data-side-nav/data-side-nav.component';
import { MaterialOverviewComponent } from './display-product-data/material-overview/material-overview.component';
import { PackagingOverviewComponent } from './display-product-data/packaging-overview/packaging-overview.component';
import { UploadDownloadDataComponent } from './upload-download/upload-download-data/upload-download-data.component';
import { PipePartOverviewComponent } from './display-product-data/pipe-part-overview/pipe-part-overview.component';
import { DirtyCheckGuard } from './guards/dirty-check.guard';
import { PipePartComponent } from './display-product-data/pipe-part/pipe-part.component';
import { StoreModule } from '@ngrx/store';
import { formReducer } from './upload-download/store/coefficient-form/coefficient-form.reducer';
import { dataReducer } from './upload-download/store/coefficient-data/coefficient-data.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AccessoryEffects } from './display-product-data/store/accessory/accessory.effects';
import { accessoryReducer } from './display-product-data/store/accessory/accessory.reducer';
import { PackagingEffects } from './display-product-data/store/packaging/packaging.effects';
import { packagingReducer } from './display-product-data/store/packaging/packaging.reducer';
import { MaterialEffects } from './display-product-data/store/material/material.effects';
import { materialReducer } from './display-product-data/store/material/material.reducer';
import { ConnectedProductEffects } from './display-product-data/store/connected-product/connected-product.effects';
import { connectedProductReducer } from './display-product-data/store/connected-product/connected-product.reducer';
import { PumpSystemEffects } from './display-product-data/store/pump-system/pump-system.effects';
import { pumpSystemReducer } from './display-product-data/store/pump-system/pump-system.reducer';
import { CoefficientSetEffects } from './display-product-data/store/coefficient-set/coefficient-set.effects';
import { coefficientSetReducer } from './display-product-data/store/coefficient-set/coefficient-set.reducer';
import { controllerReducer } from './display-product-data/store/controller/controller.reducer';
import { pumpUnitReducer } from './display-product-data/store/pump-unit/pump-unit.reducer';
import { partsListReducer } from './display-product-data/store/parts-list/parts-list.reducer';
import { DashboardComponent } from './pump-simulation/dashboard/dashboard.component';
import { SimulationCreateComponent } from './pump-simulation/simulation-create/simulation-create.component';
import { systemFormReducer } from './pump-simulation/store/system-form/system-form.reducer';
import { dataSourceReducer } from './pump-simulation/store/data-source/data-source.reducer';
import { simulationSettingReducer } from './pump-simulation/store/simulation-setting/simulation-setting.reducer';
import { calculateCoefficientReducer } from './pump-simulation/store/calculate-coefficient/calculate-coefficient.reducer';
import { flowRateReducer } from './pump-simulation/store/charts/flow-rate-chart/flow-rate-chart.reducer';
import { SimulationLoadComponent } from './pump-simulation/simulation-load/simulation-load.component';
import { flowRateCalculateReducer } from './pump-simulation/store/charts/flow-rate-calculate/flow-rate-calculate.reducer';
import { currentReducer } from './pump-simulation/store/charts/current-chart/current-chart.reducer';
import { rotationalSpeedReducer } from './pump-simulation/store/charts/rotational-speed-chart/rotational-speed-chart.reducer';
import { npshReducer } from './pump-simulation/store/charts/npsh-chart/npsh-chart.reducer';
import { intermediateReducer } from './pump-simulation/store/charts/intermediate-chart/intermediate-chart.reducer';
import { chartsSettingReducer } from './pump-simulation/store/charts-setting/charts-setting.reducer';
import { uploadDownloadReducer} from './upload-download/store/upload-download/upload-download.reducer';
import { simulationLoadReducer } from './pump-simulation/store/simulation-load/simulation-load.reducer';
import { UploadDownloadSizingAppComponent } from './upload-download/upload-download-sizing-app/upload-download-sizing-app.component';
import { sizingAppReducer } from './upload-download/store/sizing-app/sizing-app.reducer';



export const routes: Routes = [
  { path: 'data',
    component: DataSideNavComponent,
    canActivate: [AuthenticatedGuard],
    children: [
      { path: 'controller/new', component: ControllerNewComponent },
      { path: 'controller/:uuid', component: ControllersComponent, canDeactivate: [DirtyCheckGuard] },
      { path: 'motor/new', component: MotorNewComponent },
      { path: 'motor/:uuid', component: MotorsComponent, canDeactivate: [DirtyCheckGuard] },
      { path: 'pump-end/new', component: PumpEndNewComponent },
      { path: 'pump-end/:uuid', component: PumpEndsComponent, canDeactivate: [DirtyCheckGuard] },
      { path: 'pump-system/new', component: PumpSystemNewComponent },
      { path: 'pump-system/:uuid', component: PumpSystemsComponent, canDeactivate: [DirtyCheckGuard] },
      { path: 'city/all', component: CitiesComponent },
      { path: 'country/all', component: CountriesComponent },
      { path: 'location/all', component: LocationComponent },
      { path: 'pole-mount/new', component: PoleMountsNewComponent },
      { path: 'pole-mount/:uuid', component: PoleMountsComponent, canDeactivate: [DirtyCheckGuard] },
      { path: 'part-list/new', component: PartsListNewComponent },
      { path: 'part-list/:uuid', component: PartsListComponent, canDeactivate: [DirtyCheckGuard] },
      { path: 'accessory/new', component: AccessoryNewComponent },
      { path: 'accessory/:uuid', component: AccessoryComponent, canDeactivate: [DirtyCheckGuard] },
      { path: 'module/new', component: ModuleNewComponent },
      { path: 'module/:uuid', component: ModulesComponent, canDeactivate: [DirtyCheckGuard] },
      { path: 'connected-item/new', component: ConnectedItemsNewComponent },
      { path: 'connected-item/:uuid', component: ConnectedItemsComponent, canDeactivate: [DirtyCheckGuard] },
      { path: 'pipe-part/new', component: PipePartComponent },
      { path: 'pipe-part/:uuid', component: PipePartOverviewComponent, canDeactivate: [DirtyCheckGuard] },
      { path: 'packaging/all', component: PackagingOverviewComponent },
      { path: 'material/all', component: MaterialOverviewComponent },
      { path: 'vapor-pressure/all', component: VaporPressureComponent },
    ]
  },
  { path: 'simulations',
    component: DashboardComponent,
    canActivate: [AuthenticatedGuard],
    children: [
      { path: 'simulation/new', component: SimulationCreateComponent },
      { path: 'simulation/:name', component: SimulationLoadComponent },
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'upload', component: UploadDownloadDataComponent, canActivate: [AuthenticatedGuard] },
  { path: 'sizingApp', component: UploadDownloadSizingAppComponent, canActivate: [AuthenticatedGuard] },
  { path: '', redirectTo: '/data', pathMatch: 'full' },
  { path: '**', redirectTo: '/data', pathMatch: 'full' }
  ];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    StoreModule.forFeature('form', formReducer),
    StoreModule.forFeature('coefficienData', dataReducer),
    StoreModule.forFeature('accessory', accessoryReducer),
    StoreModule.forFeature('packaging', packagingReducer),
    StoreModule.forFeature('material', materialReducer),
    StoreModule.forFeature('connectedProduct', connectedProductReducer),
    StoreModule.forFeature('pumpSystem', pumpSystemReducer),
    StoreModule.forFeature('coefficientSet', coefficientSetReducer),
    StoreModule.forFeature('controller', controllerReducer),
    StoreModule.forFeature('pumpUnit', pumpUnitReducer),
    StoreModule.forFeature('partsList', partsListReducer),
    StoreModule.forFeature('systemForm', systemFormReducer),
    StoreModule.forFeature('dataSource', dataSourceReducer),
    StoreModule.forFeature('simulationSetting', simulationSettingReducer),
    StoreModule.forFeature('calculateCoefficient', calculateCoefficientReducer),
    StoreModule.forFeature('flowRateChart', flowRateReducer),
    StoreModule.forFeature('flowRateCalculate', flowRateCalculateReducer),
    StoreModule.forFeature('current', currentReducer),
    StoreModule.forFeature('rotationalSpeed', rotationalSpeedReducer),
    StoreModule.forFeature('NPSHChart', npshReducer),
    StoreModule.forFeature('intermediate', intermediateReducer),
    StoreModule.forFeature('chartsSetting', chartsSettingReducer),
    StoreModule.forFeature('uploadDownload', uploadDownloadReducer),
    StoreModule.forFeature('sizingApp', sizingAppReducer),
    StoreModule.forFeature('simulationLoad', simulationLoadReducer),
    EffectsModule.forFeature([AccessoryEffects, PackagingEffects, MaterialEffects, ConnectedProductEffects, PumpSystemEffects, CoefficientSetEffects]),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
