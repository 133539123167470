<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">
    <mat-card-header>
      <mat-card-title>
        Modules
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <mat-progress-spinner *ngIf="showVersionTable" mode="indeterminate" [diameter]=20></mat-progress-spinner>

      <app-version-table *ngIf="!showVersionTable" [versionData]="versionData()"
        (showSelectedVersionData)="showSelectedVersionData($event)"
        (showSelectedVersionRadioBtn)="showSelectedVersionRadioBtn($event)"></app-version-table>

      <div class="container mat-elevation-z8" novalidate *ngIf="showModuleForm">
        <form [formGroup]="moduleForm" class="form" (ngSubmit)="onSave()">

          <app-type-select-new formControlName="type"></app-type-select-new>

          <mat-form-field class="form-element">
            <mat-label>item_no</mat-label>
            <input matInput placeholder="item_no" formControlName="item_no">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>p_max</mat-label>
            <input matInput placeholder="p_max" formControlName="p_max">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>v_mpp</mat-label>
            <input matInput placeholder="v_mpp" formControlName="v_mpp">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>v_oc</mat-label>
            <input matInput placeholder="v_oc" formControlName="v_oc">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>i_sc</mat-label>
            <input matInput placeholder="i_sc" formControlName="i_sc">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>pmax_coeff</mat-label>
            <input matInput placeholder="pmax_coeff" formControlName="pmax_coeff">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>voc_coeff</mat-label>
            <input matInput placeholder="voc_coeff" formControlName="voc_coeff">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>isc_coeff</mat-label>
            <input matInput placeholder="isc_coeff" formControlName="isc_coeff">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>max_system_voltage</mat-label>
            <input matInput placeholder="max_system_voltage" formControlName="max_system_voltage">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>width</mat-label>
            <input matInput placeholder="width" formControlName="width">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>length</mat-label>
            <input matInput placeholder="length" formControlName="length">
          </mat-form-field>

          <div class="form-element">
            <button mat-raised-button color="primary" [disabled]="!isDirty" type="submit" data-testid="submit"
              class="button">Save Form</button>
          </div>

        </form>
      </div>
    </mat-card-content>
  </mat-card>
</section>