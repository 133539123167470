import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, of, Subscription } from 'rxjs';
import { concatAll, delay, filter, map, mergeMap, take, tap, toArray } from 'rxjs/operators';
import { CalculateDialogComponent } from 'src/app/pump-simulation/calculate-dialog/calculate-dialog.component';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { compareArray, removeRadioBtnFocus } from 'src/app/shared/util';
import { DirtyComponent } from '../dirty-component';
import { DisplayProductDataService } from '../display-product-data.service';
import { ErrorButtonComponent } from '../error-button/error-button.component';
import { PUMPSYSYTEM_ITEMS, PUMP_SYSTEM_STATUS, PUMP_SYSTEM_TYPES, ConnectedProductDetail, AccessoryDetail, CalculationDetail, CALCULATIONS_ITEMS, SelectedCalculationDetail } from '../pump-data.model';
import { SaveButtonComponent } from '../save-button/save-button.component';
import { setAccessorySelectedList, setAccessoryPrepareList } from '../store/accessory/accessory.actions';
import { AccessoryState } from '../store/accessory/accessory.reducer';
import { selectAccessory } from '../store/accessory/accessory.seletor';
import { setCalculationValue, setCoefficientSetValueName, setPumpSystemCopied } from '../store/coefficient-set/coefficient-set.actions';
import { CoefficientSetState, emptyCalculationValue } from '../store/coefficient-set/coefficient-set.reducer';
import { selectCoefficientSet } from '../store/coefficient-set/coefficient-set.selector';
import { setConnectedProductSelectedList, setConnectedProductPrepareList } from '../store/connected-product/connected-product.actions';
import { ConnectedProductState } from '../store/connected-product/connected-product.reducer';
import { selectConnectedProduct } from '../store/connected-product/connected-product.seletor';
import { ControllerState } from '../store/controller/controller.reducer';
import { selectController } from '../store/controller/controller.seletor';
import { PackagingState } from '../store/packaging/packaging.reducer';
import { selectPackaging } from '../store/packaging/packaging.selector';
import { setControllerSelect, setInitFormValue, setMotorSelect, setPumpEndSelect, setPumpSytemCoefficientSetName, setPumpUnitValue } from '../store/pump-system/pump-system.actions';
import { PumpSystemState } from '../store/pump-system/pump-system.reducer';
import { selectPumpSystem } from '../store/pump-system/pump-system.seletor';
import { setPumpUnitItem, setPumpUnitMaxTemp, setPumpUnitSuctionHead, setPumpUnitPicture, setPumpUnitPackaging } from '../store/pump-unit/pump-unit.actions';
import { PumpUnitState } from '../store/pump-unit/pump-unit.reducer';
import { selectPumpUnit } from '../store/pump-unit/pump-unit.seletor';
import { UpdateButtonComponent } from '../update-button/update-button.component';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-pump-systems',
  templateUrl: './pump-systems.component.html',
  styleUrls: ['./pump-systems.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PumpSystemsComponent implements OnInit, DirtyComponent, OnDestroy {

  private subscriptions: Subscription[] = [];
  showSubmitLoading = false;
  @Input() dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  matcher = new MyErrorStateMatcher();
  userLocalStorage = new UserLocalStorage();
  private formValueChange: Subscription;
  types: string[] = PUMP_SYSTEM_TYPES;
  displayedColumns: string[] = ['approveForUse', 'version', 'status', 'published', 'author', 'comment', 'action'];
  pumpSystemForm: UntypedFormGroup;
  originalPumpSystemFormValue: any;
  originalConnectedProductValue = [];
  originalConnectedProductValueBeforeCopied = [];
  originalAccessoryValue = [];
  originalAccessoryValueBeforeCopied = [];
  pumpSystemUuid = '';

  isDirty = false;
  isVersionValueChanged = false;
  pumpSystemName = '';
  tableLoading = true;
  showVersionTable = true;
  showPumpSystemForm = false;
  pumpSystemData = [];
  selectedRowIndex = -1;
  typeSelect = false;
  statusList = PUMP_SYSTEM_STATUS;
  defaultAccessoryList = [];
  accessoryList: Partial<AccessoryDetail>[];
  defaultConnectedProductList = [];
  connectedProductsList: Partial<ConnectedProductDetail>[];

  calculationsValue: Partial<CalculationDetail> | Partial<SelectedCalculationDetail>;
  controllerNameValue = '';
  motorNameNameValue = '';
  pumpEndNameValue = '';
  pumpUnitValues: any = {};

  constructor(
    private store: Store,
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private displayProductData: DisplayProductDataService,
  ) { }

  ngOnInit() {

    const pumpUnit = combineLatest([
      this.store.pipe(
        select(selectPumpUnit),
        map((data: PumpUnitState) => data.item)
      ),
      this.store.pipe(
        select(selectPumpUnit),
        map((data: PumpUnitState) => data.maxTemp)
      ),
      this.store.pipe(
        select(selectPumpUnit),
        map((data: PumpUnitState) => data.suctionHead)
      ),
      this.store.pipe(
        select(selectPumpUnit),
        map((data: PumpUnitState) => data.picture)
      ),
      this.store.pipe(
        select(selectPackaging),
        map((data: PackagingState) => data.data)
      ),
      this.store.pipe(
        select(selectPumpUnit),
        map((data: PumpUnitState) => data.packaging)
      )
    ]).subscribe(([itemValue, maxTempValue, suctionHeadValue, pictureValue, packagingData, packagingValue]) => {
      this.pumpUnitValues.pu_item = itemValue;
      this.pumpUnitValues.pu_max_temp = maxTempValue;
      this.pumpUnitValues.pu_suction_head = suctionHeadValue;
      this.pumpUnitValues.pu_picture = pictureValue;
      const item = [...packagingData].find(item => item.name === packagingValue);
      if (item) {
        this.pumpUnitValues.pu_packaging = item;
      } else {
        this.pumpUnitValues.pu_packaging = {
          uuid: '',
          name: '',
          weight: '',
          subType: {
            H: '',
            L: '',
            W: ''
          }
        }
      }
      const pumpUnit: any = {
        item: this.pumpUnitValues.pu_item,
        pu_max_temp: this.pumpUnitValues.pu_max_temp,
        parts: this.pumpUnitValues.pu_parts,
        picture: this.pumpUnitValues.pu_picture,
        suction_head: this.pumpUnitValues.pu_suction_head,
        packaging: this.pumpUnitValues.pu_packaging
      };
      this.pumpUnitValues.pu_unit = pumpUnit;
    });
    this.subscriptions.push(pumpUnit);

    const names = combineLatest([
      this.store.pipe(
        select(selectController),
        map((data: ControllerState) => data.controller)
      ),
      this.store.pipe(
        select(selectController),
        map((data: ControllerState) => data.motor)
      ),
      this.store.pipe(
        select(selectController),
        map((data: ControllerState) => data.pumpEnd)
      )
    ]).subscribe(([controllerName, motorName, pumpEndName]) => {
      this.controllerNameValue = `${controllerName}`;
      this.motorNameNameValue = `${motorName}`;
      this.pumpEndNameValue = `${pumpEndName}`;
    });
    this.subscriptions.push(names);

    const getCalculation = combineLatest([
      this.store.pipe(
        select(selectCoefficientSet),
        map((data: CoefficientSetState) => data.calculation),
      ),
      this.store.pipe(
        select(selectCoefficientSet),
        map((data: CoefficientSetState) => data.name)
      ),
      this.store.pipe(
        select(selectPumpSystem),
        map((data: PumpSystemState) => [data.isFormValueChanged, data.coefficientSetValue])
      ),
    ]).subscribe(([data, name, isChanged]) => {
      this.calculationsValue = {...data};
      this.calculationsValue.displayName = `${name}`;
      this.calculationsValue.filename = `${isChanged[1]}`;
      isChanged[0] ? this.isDirty = true : this.isDirty = false;
    });
    this.subscriptions.push(getCalculation);

    const connectedProductTableData = combineLatest([
      this.store.pipe(
        select(selectConnectedProduct),
        map((data: ConnectedProductState) => data.selectedList)
      ),
      this.store.pipe(
        select(selectConnectedProduct),
        map((data: ConnectedProductState) => data.isCopied)
      )
    ]).subscribe(([data, isCopied]) => {
      if (data.length !== 0 && !isCopied) {
        this.connectedProductsList = data;
        const connectedData = this.connectedProductsList.map(data => data.uuid);
        const difference = compareArray(connectedData, this.originalConnectedProductValue);
        this.isDirty = difference.length !== 0;
      }

      if (data.length === 0 && !isCopied) {
        this.isDirty = this.originalConnectedProductValue.length !== 0;
      }

      if (isCopied) {
        this.connectedProductsList = data;
        const connectedData = this.connectedProductsList.map(data => data.uuid);
        const difference = compareArray(connectedData, this.originalConnectedProductValueBeforeCopied);
        this.isDirty = difference.length !== 0;
      }
    });
    this.subscriptions.push(connectedProductTableData);

    const accessoryTableData = combineLatest([
      this.store.pipe(
        select(selectAccessory),
        map((data: AccessoryState) => data.selectedList)
      ),
      this.store.pipe(
        select(selectAccessory),
        map((data: AccessoryState) => data.isCopied)
      )
    ]).subscribe(([data, isCopied]) => {
      if (data.length !== 0 && !isCopied) {
        this.accessoryList = data;
        const accessoryData = this.accessoryList.map(data => data.uuid);
        const difference = compareArray(accessoryData, this.originalAccessoryValue);
        this.isDirty = difference.length !== 0;
      }

      if (data.length === 0 && !isCopied) {
        this.isDirty = this.originalAccessoryValue.length !== 0;
      }

      if (isCopied) {
        this.accessoryList = data;
        const accessoryData = this.accessoryList.map(data => data.uuid);
        const difference = compareArray(accessoryData, this.originalAccessoryValueBeforeCopied);
        this.isDirty = difference.length !== 0;
      }
    })
    this.subscriptions.push(accessoryTableData);

    const urlSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.showVersionTable = true;
      this.pumpSystemName = '';
      this.showPumpSystemForm = false;
      this.selectedRowIndex = -1;
      this.isDirty = false;
      this.defaultAccessoryList = [];
      this.defaultConnectedProductList = [];
      this.showSubmitLoading = false;
    });
    this.subscriptions.push(urlSubscription);

    const getAllData = this.route.paramMap.pipe(
      map(params => params.get('uuid')),
      mergeMap(uuid => {
        return combineLatest([
          this.displayProductData.getPumpSystemItem(uuid),
          this.displayProductData.getConnectedProductList().pipe(
            take(1),
            concatAll(),
            mergeMap(item => this.displayProductData.getConnectedProductItem(item.uuid)),
            toArray()
          ),
          this.displayProductData.getAccessoryList()
        ]);
      })
    ).subscribe(([pumpSystemData, connectedProductData, accessoryData]) => {

      const pumpSystemDataApi = JSON.parse(pumpSystemData['message']);
      this.pumpSystemName = pumpSystemDataApi.name;
      this.pumpSystemUuid = pumpSystemDataApi.uuid;
      this.tableLoading = false;
      this.showVersionTable = false;
      this.dataSource.data = pumpSystemDataApi.versions;
      this.pumpSystemData = pumpSystemDataApi.versions;

      for (const item of connectedProductData) {
        const tartget = item.versions[0];
        tartget.file = item.name;
        tartget.uuid = item.uuid;
        tartget.version = item.versions[0].version_number;
        this.defaultConnectedProductList.push(tartget);
      }

      for (const item of accessoryData) {
        let tartget: any = {...item};
        tartget.file = item.name;
        this.defaultAccessoryList.push(tartget);
      }

    });
    this.subscriptions.push(getAllData);

  }

  showVersionData(version: string) {
    if (this.formValueChange) {
      this.formValueChange.unsubscribe();
    }

    if (!this.isVersionValueChanged) {
      // the form value not changed
      this.setPumpSystemFormValue(version);
    } else {
      const dialogRef = this.dialog.open(UpdateButtonComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isDirty = false;
          this.setPumpSystemFormValue(version);
        }
      });
    }
  }

  showSelectedVersionRadioBtn(version: number) {
    if (this.showPumpSystemForm && (version === this.selectedRowIndex)) {
      const dialogRef = this.dialog.open(CalculateDialogComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
        data: {
          message: 'Are you sure you want to publish this version?',
          type: 'confirm'
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const data = this.pumpSystemData[version];
          const user = this.userLocalStorage.loadUser();
          data.author = user.user.username;
          data.comments = data.comments;
          const url = `${this.pumpSystemUuid}/${version}/live`;
          const setPumpSystemState = this.displayProductData.setPumpSystemState(url, data).subscribe(item => {
            this.snackBar
              .open(JSON.parse(item)['message'], '', { duration: 1500, panelClass: 'hintMsg' })
              .afterDismissed()
              .pipe(delay(0))
              .subscribe(() => {
                this.ngOnInit();
                this.showVersionTable = true;
                this.pumpSystemName = '';
                this.showPumpSystemForm = false;
                this.selectedRowIndex = -1;
                this.isDirty = false;
                this.defaultAccessoryList = [];
                this.defaultConnectedProductList = [];
              });
          });

          this.subscriptions.push(setPumpSystemState);
        } else {
          removeRadioBtnFocus(this.elementRef, version);
        }
      });
    } else {
      const dialogRef = this.dialog.open(CalculateDialogComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
        data: {
          message: 'You have to load a version before publishing it.',
          type: 'hint'
        }
      });

      dialogRef.afterClosed().subscribe(() => removeRadioBtnFocus(this.elementRef, version));
    }

  }

  setPumpSystemFormValue(version) {
    this.showPumpSystemForm = true;
    this.selectedRowIndex = Number(version);

    for (const allData of this.pumpSystemData) {
      if (allData.version_number === Number(version)) {
        const pumpSystemFormData: any = {};
        PUMPSYSYTEM_ITEMS.forEach(name => pumpSystemFormData[name] = allData[name] || '');

        const pumpUnit = allData.pu_unit;
        const pumpUnitValue: any = {
          item: '',
          packaging: '',
          maxTemp: '',
          suctionHead: '',
          picture: '',
        };
        if (pumpUnit) {
          this.store.dispatch(setPumpUnitItem({ data: pumpUnit.item }));
          this.store.dispatch(setPumpUnitMaxTemp({ data: pumpUnit.pu_max_temp }));
          this.store.dispatch(setPumpUnitSuctionHead({ data: pumpUnit.suction_head }));
          this.store.dispatch(setPumpUnitPicture({ data: pumpUnit.picture }));
          pumpUnitValue.item = pumpUnit.item;
          pumpUnitValue.maxTemp = pumpUnit.pu_max_temp;
          pumpUnitValue.suctionHead = pumpUnit.suction_head;
          pumpUnitValue.picture = pumpUnit.picture;
          const target = pumpUnit.packaging;
          if (target) {
            if (target.name) {
              this.store.dispatch(setPumpUnitPackaging({ data: target.name }));
              pumpUnitValue.packaging = target.name;
            } else {
              const packagingValue = `${target.l} (${target.h}x${target.w})-${target.pack_weight}`;
              this.store.dispatch(setPumpUnitPackaging({ data: packagingValue }));
              pumpUnitValue.packaging = packagingValue;
            }
          }
          this.store.dispatch(setPumpUnitValue({ data: pumpUnitValue }));
        }

        const coefficientSetName = allData.calculations.displayName;
        const coefficientSetFilename = allData.calculations.filename;

        if ( allData.controller && allData.motor && allData.pump_end && allData.calculations) {
          this.store.dispatch(setInitFormValue({ data: {
            pumpUnit: pumpUnitValue,
            controllerUuid: allData.controller.uuid,
            motorUuid: allData.motor.uuid,
            pumpEndUuid: allData.pump_end.uuid,
            coefficientSetValue: coefficientSetName,
            pumpSystemCoefficientSetName: coefficientSetFilename
          }}));
        }

        if (allData.calculations) {
          this.store.dispatch(setCalculationValue({ data: allData.calculations }));
          this.store.dispatch(setCoefficientSetValueName({ data: coefficientSetName }));
          this.store.dispatch(setPumpSytemCoefficientSetName({ data: coefficientSetFilename }));
          this.store.dispatch(setPumpSystemCopied({ data: true }));
        }

        if (allData.connectedProducts) {
          this.originalConnectedProductValue = allData.connectedProducts.map(x => x.uuid);
          this.originalConnectedProductValueBeforeCopied = allData.connectedProducts.map(x => x.uuid);
          this.store.dispatch(setConnectedProductSelectedList({ data: allData.connectedProducts }));
          const prepareList = [];
          this.defaultConnectedProductList.forEach(item => {
            const target = allData.connectedProducts.map(x => x.uuid);
            if(!target.includes(item.uuid)) {
              prepareList.push(item);
            }
          });
          this.store.dispatch(setConnectedProductPrepareList({ data: prepareList }));
        } else {
          this.originalConnectedProductValue = [];
          this.originalConnectedProductValueBeforeCopied = [];
          this.store.dispatch(setConnectedProductSelectedList({ data: [] }));
          this.store.dispatch(setConnectedProductPrepareList({ data: this.defaultConnectedProductList }));
        };
        if (allData.accessories) {
          this.originalAccessoryValue = allData.accessories.map(x => x.uuid);
          this.originalAccessoryValueBeforeCopied = allData.accessories.map(x => x.uuid);
          this.store.dispatch(setAccessorySelectedList({ data: allData.accessories }));
          const prepareList = [];
          this.defaultAccessoryList.forEach(item => {
            const target = allData.accessories.map(x => x.uuid);
            if(!target.includes(item.uuid)) {
              prepareList.push(item);
            }
          });
          this.store.dispatch(setAccessoryPrepareList({ data: prepareList }));
        } else {
          this.originalAccessoryValue = [];
          this.originalAccessoryValueBeforeCopied = [];
          this.store.dispatch(setAccessorySelectedList({ data: [] }));
          this.store.dispatch(setAccessoryPrepareList({ data: this.defaultAccessoryList }));
        }

        if (allData.controller !== undefined && allData.controller !== '') {
          pumpSystemFormData.controller_uuid = allData.controller.uuid;
          this.store.dispatch(setControllerSelect({ data: allData.controller.uuid }));
        } else {
          pumpSystemFormData.controller_uuid = '';
          allData.controller = {}
        }

        pumpSystemFormData.motor_uuid = '';
        if (allData.motor !== undefined && allData.motor !== '') {
          pumpSystemFormData.motor_uuid = allData.motor.uuid;
          this.store.dispatch(setMotorSelect({ data: allData.motor.uuid }));
        } else {
          pumpSystemFormData.motor_uuid = '';
          allData.motor = {}
        }

        if (allData.pump_end !== undefined && allData.pump_end !== '') {
          pumpSystemFormData.pump_end_uuid = allData.pump_end.uuid;
          this.store.dispatch(setPumpEndSelect({ data: allData.pump_end.uuid }));
        } else {
          pumpSystemFormData.pump_end_uuid = '';
          allData.pump_end = {}
        }

        this.pumpSystemForm = this.fb.group(pumpSystemFormData);
        this.originalPumpSystemFormValue = this.pumpSystemForm.value;
        if (this.types.includes(allData.type)) {
          this.typeSelect = true;
        } else {
          this.typeSelect = false;
        }
      }
    }

    this.formValueChange = this.pumpSystemForm.valueChanges.pipe(
      tap(value => {
        if (this.types.includes(value.type)) {
          this.typeSelect = true;
        } else {
          this.typeSelect = false;
        }
      })
    ).subscribe((value) => {
      this.isDirty = JSON.stringify(this.originalPumpSystemFormValue) !== JSON.stringify(value);
      this.isVersionValueChanged = JSON.stringify(this.originalPumpSystemFormValue) !== JSON.stringify(value);
    });
    this.subscriptions.push(this.formValueChange);

  }

  selectVersionItem(version: number) {
    if (this.showPumpSystemForm && (version === this.selectedRowIndex)) {
      const dialogRef = this.dialog.open(CalculateDialogComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
        data: {
          message: 'Are you sure you want to publish this version?',
          type: 'confirm'
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const data = this.pumpSystemData[version];
          const user = this.userLocalStorage.loadUser();
          data.author = user.user.username;
          data.comment = data.comments;
          const url = `${this.pumpSystemUuid}/${version}/live`;
          const setPumpSystemState = this.displayProductData.setPumpSystemState(url, data).subscribe(item => {
            this.snackBar
              .open(JSON.parse(item)['message'], '', { duration: 1500, panelClass: 'hintMsg' })
              .afterDismissed()
              .pipe(delay(0))
              .subscribe(() => {
                this.ngOnInit();
                this.showVersionTable = true;
                this.pumpSystemName = '';
                this.showPumpSystemForm = false;
                this.selectedRowIndex = -1;
                this.isDirty = false;
              });
          });

          this.subscriptions.push(setPumpSystemState);
        } else {
          removeRadioBtnFocus(this.elementRef, version);
        }
      });
    } else {
      const dialogRef = this.dialog.open(CalculateDialogComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
        data: {
          message: 'You have to load a version before publishing it.',
          type: 'hint'
        }
      });

      dialogRef.afterClosed().subscribe(() => removeRadioBtnFocus(this.elementRef, version));
    }

  }

  onSave() {
    const dialogRef = this.dialog.open(SaveButtonComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showSubmitLoading = true;
        this.updatePumpSystem(result['comment']);
      }
    });
  }

  updatePumpSystem(versionComment: string) {
    let data = this.pumpSystemForm.value;
    data.header = '';
    data.row = '';
    const user = this.userLocalStorage.loadUser();
    data.author = user.user.username;
    data.comment = versionComment;
    data.uuid = this.pumpSystemUuid;
    data.name = this.pumpSystemName;
    data.controller = this.controllerNameValue;
    data.motor_name = this.motorNameNameValue;
    data.pump_end_name = this.pumpEndNameValue;

    data = {...data, ...this.pumpUnitValues};

    const calculationData: any = {};
    CALCULATIONS_ITEMS.forEach(name => {
      if (this.calculationsValue[name]) {
        calculationData[name] = this.calculationsValue[name];
      } else {
        calculationData[name] = '';
      }
    });
    data.calculations = calculationData;

    const connectedProducts = [];
    if (this.connectedProductsList && this.connectedProductsList.length !== 0) {
      const connectedUuid = this.connectedProductsList.map(x => x.uuid);
      this.defaultConnectedProductList.forEach(item => {
        if (connectedUuid.includes(item.uuid)) {
          const subItem: any = {};
          subItem.name = item.file;
          subItem.version = item.version_number;
          connectedProducts.push(subItem);
        }
      });
      data.connectedProducts = connectedProducts;
    }

    const accessories = [];
    if (this.accessoryList && this.accessoryList.length !== 0) {
      const accessoryData = of(this.accessoryList).pipe(
        concatAll(),
        mergeMap(item => this.displayProductData.getAccessoryItem(item.uuid)),
        toArray()
      )
      .subscribe(result => {
        for (const item of result) {
          const tartget = {...item.versions[0]};
          tartget.name = item.name;
          tartget.version = item.versions[0].version_number;
          accessories.push(tartget);
        }
        data.accessories = accessories;
        this.updatePumpSystemWithData(data);
      });
      this.subscriptions.push(accessoryData);
    } else {
      // this.accessoryList value is undefined
      this.updatePumpSystemWithData(data);
    }

  }

  updatePumpSystemWithData(data: any) {
    this.displayProductData.updatePumpSystem(data).subscribe(
      item => {
        this.snackBar.open(JSON.parse(item)['message'], '', { duration: 1500, panelClass: 'hintMsg' })
          .afterDismissed()
          .pipe(delay(0))
          .subscribe(() => {
            this.ngOnInit();
            this.showVersionTable = true;
            this.pumpSystemName = '';
            this.showPumpSystemForm = false;
            this.selectedRowIndex = -1;
            this.isDirty = false;
            this.defaultAccessoryList = [];
            this.defaultConnectedProductList = [];
            this.showSubmitLoading = false;
          });
      },
      error => {
        this.dialog.open(ErrorButtonComponent, {
          width: '600px',
          data: JSON.parse(error.error),
          minHeight: '20vh',
        });
        this.showSubmitLoading = false;
      },
      () => { }
    );
  }

  canDeactivate() {
    return this.isDirty;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.store.dispatch(setCoefficientSetValueName({ data: 'Please select coefficient set' }));
    this.store.dispatch(setCalculationValue({ data: emptyCalculationValue }));
    this.store.dispatch(setCoefficientSetValueName({ data: '' }));
    this.store.dispatch(setPumpSytemCoefficientSetName({ data: '' }));
    this.store.dispatch(setPumpSystemCopied({ data: false }));
  }

}
