import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { map, first, Subscription } from 'rxjs';
import { CalculateDialogComponent } from 'src/app/pump-simulation/calculate-dialog/calculate-dialog.component';
import { ShareDataService } from '../share-data.service';
import { ChartsSettingState } from '../store/charts-setting/charts-setting.reducer';
import { selectChartsSetting } from '../store/charts-setting/charts-setting.selector';
import { setSimulationExcelBehaviour, setSimulationMaxLift, setSimulationMinLift, setSimulationSplitPower } from '../store/simulation-setting/simulation-setting.actions';
import { SimulationSettingState } from '../store/simulation-setting/simulation-setting.reducer';
import { selectSimulationSetting } from '../store/simulation-setting/simulation-setting.selector';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss']
})
export class ControlPanelComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  matcher = new MyErrorStateMatcher();
  excelBehaviour = new UntypedFormControl(false, [Validators.required]);
  minLift = new UntypedFormControl(5, [Validators.required, Validators.min(0), Validators.max(10000)]);
  maxLift = new UntypedFormControl(120, [Validators.required, Validators.min(0), Validators.max(10000)]);
  splitPower = new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(200000)]);

  isAllManulSelected = true;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private shareService: ShareDataService,
  ) { }

  ngOnInit() {
    this.store.pipe(
      select(selectSimulationSetting),
      map((data: SimulationSettingState) => data),
      first()
    ).subscribe(data => {
      this.excelBehaviour.setValue(data.excelBehaviour);
      this.minLift.setValue(data.minLift);
      this.maxLift.setValue(data.maxLift);
      this.splitPower.setValue(data.splitPower);
    })
  }

  onExcelBehaviour(value: Boolean) {
    this.store.dispatch(setSimulationExcelBehaviour({ data: value }));
  }

  onMinLift(value: Number) {
    this.store.dispatch(setSimulationMinLift({ data: Number(value) }));
  }

  onMaxLift(value: Number) {
    this.store.dispatch(setSimulationMaxLift({ data: Number(value) }));
  }

  onSplitPower(value: Number) {
    this.store.dispatch(setSimulationSplitPower({ data: Number(value) }));
  }

  onCalculate() {
    const dialogRef = this.dialog.open(CalculateDialogComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
      data: {
        message: 'Have you checked your configuration before calculating?',
        type: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.shareService.setCalculate(true);
      }
    });
  }

  onSwitch(type: string) {
    let dialogMsg = '';
    (type === 'auto') ? dialogMsg = 'Are you sure you would like to change all selections to auto?' :
      dialogMsg = 'Are you sure you would like to change all selections to manual?';

    const dialogRef = this.dialog.open(CalculateDialogComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
      data: {
        message: dialogMsg,
        type: 'switch'
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isAllManulSelected = !this.isAllManulSelected;
        this.shareService.setAllRadio(type);
      }
    });
  }

  onSave() {

    const dialogRef = this.dialog.open(CalculateDialogComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
      data: {
        message: '',
        type: 'confirm'
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.shareService.setSaveNewData({
          isSaveNewData: true,
          saveNotes: result.note
        });
      }
    });
  }

  onBack() {

    const getData = this.store.pipe(
      select(selectChartsSetting),
      map((data: ChartsSettingState) => data.process),
      first()
    ).subscribe(process => {
      if (process === 'create') {
        this.shareService.setCreateBack(true);
      } else {
        this.shareService.setLoadBack(true);
      }
    })
    this.subscriptions.push(getData);
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
