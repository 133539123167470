import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-parts-list-edit-node',
  templateUrl: './parts-list-edit-node.component.html',
  styleUrls: ['./parts-list-edit-node.component.scss']
})
export class PartsListEditNodeComponent implements OnInit {

  newPartsListNodeForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<PartsListEditNodeComponent>
  ) { }

  ngOnInit() {

    this.newPartsListNodeForm = this.fb.group({
      name: [this.data.name, Validators.required],
      material: this.data.material,
      id: [this.data.id, Validators.required]
    });

  }

  onSubmit(result) {
    result.id = `${result.id}`;
    this.dialogRef.close(result);
  }

}
