<p class="title">Control panel</p>

<div class="form-element">
  <mat-checkbox color="primary" [formControl]="excelBehaviour" (change)="onExcelBehaviour($event.checked)">Excel
    simulation
    (correction factor)
  </mat-checkbox>
</div>

<mat-form-field class="form-element">
  <mat-label>Min Lift:</mat-label>
  <input matInput class="right" type="number" placeholder="Min Lift:" [formControl]="minLift"
    (input)="onMinLift($event.target.value)">
  <span matSuffix>m</span>
  <mat-error *ngIf="minLift.hasError('required')">
    Min Lift is required.
  </mat-error>
  <mat-error *ngIf="minLift.hasError('min')">
    Min Lift value should be arrange from 0 ~ 10000.
  </mat-error>
  <mat-error *ngIf="minLift.hasError('max')">
    Min Lift value should be arrange from 0 ~ 10000.
  </mat-error>
</mat-form-field>

<mat-form-field class="form-element">
  <mat-label>Max Lift:</mat-label>
  <input matInput class="right" type="number" placeholder="Max Lift:" [formControl]="maxLift"
    (input)="onMaxLift($event.target.value)">
  <span matSuffix>m</span>
  <mat-error *ngIf="maxLift.hasError('required')">
    Max Lift is required
  </mat-error>
  <mat-error *ngIf="maxLift.hasError('min')">
    Max Lift value should be arrange from 0 ~ 10000.
  </mat-error>
  <mat-error *ngIf="maxLift.hasError('max')">
    Max Lift value should be arrange from 0 ~ 10000.
  </mat-error>
</mat-form-field>

<mat-form-field class="form-element">
  <mat-label>Split Power:</mat-label>
  <input matInput class="right" type="number" placeholder="Split Power:" [formControl]="splitPower"
    (input)="onSplitPower($event.target.value)">
  <span matSuffix>W</span>
  <mat-error *ngIf="splitPower.hasError('required')">
    Split Power is required
  </mat-error>
  <mat-error *ngIf="splitPower.hasError('min')">
    Split Power value should be arrange from 0 ~ 200000.
  </mat-error>
  <mat-error *ngIf="splitPower.hasError('max')">
    Split Power value should be arrange from 0 ~ 200000.
  </mat-error>
</mat-form-field>

<div class="actionBtn">
  <button mat-raised-button color="primary" (click)="onCalculate()">CALCULATE COEFFICIENT</button>
</div>
<div class="actionBtn special double">
  <button mat-raised-button color="primary" (click)="onBack()">Back</button>
  <button mat-raised-button color="warn" (click)="onSave()">SAVE</button>
</div>
<div class="actionBtn special">
  <button *ngIf="!isAllManulSelected" mat-raised-button color="primary" (click)="onSwitch('auto')">SELECT ALL
    AUTO</button>
  <button *ngIf="isAllManulSelected" mat-raised-button color="primary" (click)="onSwitch('manul')">SELECT ALL
    MANUAL</button>
</div>