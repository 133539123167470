import { ElementRef, Input } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { delay, filter, map, mergeMap } from 'rxjs/operators';
import { CalculateDialogComponent } from 'src/app/pump-simulation/calculate-dialog/calculate-dialog.component';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { removeRadioBtnFocus } from 'src/app/shared/util';
import { DirtyComponent } from '../dirty-component';
import { DisplayProductDataService } from '../display-product-data.service';
import { POLE_MOUNT_ITEMS } from '../pump-data.model';
import { SaveButtonComponent } from '../save-button/save-button.component';
import { UpdateButtonComponent } from '../update-button/update-button.component';

@Component({
  selector: 'app-pole-mounts',
  templateUrl: './pole-mounts.component.html',
  styleUrls: ['./pole-mounts.component.scss']
})
export class PoleMountsComponent implements OnInit, DirtyComponent {

  private formvValueChange: Subscription;
  displayedColumns: string[] = ['approveForUse', 'version', 'status', 'published', 'author', 'comment', 'action'];
  tableLoading = true;
  showVersionTable = true;
  showPolemountForm = false;
  isDirty = false;
  userLocalStorage = new UserLocalStorage();
  polemountForm: UntypedFormGroup;
  polemountName = '';
  polemountData = [];
  polemountUuid = '';
  selectedRowIndex = -1;

  lastVersion: any;
  originalPolemountFormValue: any;

  @Input() dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.showVersionTable = true;
      this.polemountName = '';
      this.showPolemountForm = false;
      this.selectedRowIndex = -1;
      this.isDirty = false;
    });

    this.route.paramMap.pipe(
      map(params => params.get('uuid')),
      mergeMap(uuid => {
        this.polemountUuid = uuid;
        return this.displayProductData.getPoleMountItem(uuid);
      })
    ).subscribe(poleMountData => {
      this.polemountName = poleMountData.name;
      this.dataSource.data = poleMountData.versions;
      this.polemountData = poleMountData.versions;
      this.showVersionTable = false;
      this.lastVersion = this.polemountData.length;
    });

    this.initPolemountFormForm();
  }

  versionData() {
    return this.polemountData;
  }

  private initPolemountFormForm() {
    const data = {};
    POLE_MOUNT_ITEMS.forEach(name => {
      data[`${name}`] = [null, Validators.required];
    });
    this.polemountForm = this.fb.group(data);
  }

  showSelectedVersionData(version: string) {
    if (this.formvValueChange) {
      this.formvValueChange.unsubscribe();
    }

    if (!this.isDirty) {
      // the form value not changed
      this.polemountFormValueChange(version);
    } else {
      const dialogRef = this.dialog.open(UpdateButtonComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isDirty = false;
          this.polemountFormValueChange(version);
        }
      });
    }

  }

  polemountFormValueChange(version) {
    this.showPolemountForm = true;
    this.selectedRowIndex = Number(version);

    for (const item of this.polemountData) {
      if (item.version_number === Number(version)) {
        const data = {};
        POLE_MOUNT_ITEMS.forEach(name => data[`${name}`] = item[`${name}`]);
        this.polemountForm.patchValue(data);

        this.originalPolemountFormValue = this.polemountForm.value;
      }
    }

    this.formvValueChange = this.polemountForm.valueChanges.subscribe((value) => {
      this.isDirty = JSON.stringify(this.originalPolemountFormValue) !== JSON.stringify(value);
    });
  }

  showSelectedVersionRadioBtn(version: number) {
    const versionNumber = version + 1;
    if (this.showPolemountForm && ( versionNumber === this.selectedRowIndex)) {
      const dialogRef = this.dialog.open(CalculateDialogComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
        data: {
          message: 'Are you sure you want to publish this version?',
          type: 'confirm'
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const data = this.polemountData[version];
          data.version = this.polemountData[version].version_number;
          const url = `${this.polemountUuid}/${versionNumber}/live`;
          this.displayProductData.setPoleMountState(url, data).subscribe(item => {
            this.snackBar
              .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
              .afterDismissed()
              .pipe(delay(0))
              .subscribe(() => {
                this.ngOnInit();
                this.showVersionTable = true;
                this.polemountName = '';
                this.showPolemountForm = false;
                this.selectedRowIndex = -1;
                this.isDirty = false;
              });
          });
        } else {
          removeRadioBtnFocus(this.elementRef, version);
        }
      });
    } else {
      const dialogRef = this.dialog.open(CalculateDialogComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
        data: {
          message: 'You have to load a version before publishing it.',
          type: 'hint'
        }
      });

      dialogRef.afterClosed().subscribe(() => removeRadioBtnFocus(this.elementRef, version));
    }

  }

  onSave() {
    const dialogRef = this.dialog.open(SaveButtonComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updatePoleMount(result.comment);
      }
    });
  }

  updatePoleMount(comment: any) {
    const user = this.userLocalStorage.loadUser();
    const data = this.polemountForm.value;
    data.author = user.user.username;
    data.comment = comment || 'new version';
    data.uuid = this.polemountUuid;
    data.file = this.polemountName;
    this.displayProductData.updatePoleMount(data).subscribe(item => {
      const url = `${data.uuid}/${this.lastVersion}/preview`;
      this.displayProductData.setPoleMountState(url, data).subscribe(item => {
        this.snackBar
          .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
          .afterDismissed()
          .pipe(delay(0))
          .subscribe(() => {
            this.ngOnInit();
            this.showVersionTable = true;
            this.polemountName = '';
            this.showPolemountForm = false;
            this.selectedRowIndex = -1;
            this.isDirty = false;
          });
      });
    });
  }

  canDeactivate() {
    return this.isDirty;
  }
}
