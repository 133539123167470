export interface PipePart {
  uuid: string;
  id: string;
  name?: string;
}

export interface PipePartDetail {
  uuid: string;
  name: string;
  versions: any;
}

export interface Accessory {
  uuid: string;
  name: string;
}

export interface AccessoryDetail {
  uuid: string;
  name: string;
  versions: any;
}

export interface PoleMount {
  uuid: string;
  name: string;
}

export interface PoleMountDetail {
  uuid: string;
  name: string;
  versions: any;
}

export interface Module {
  uuid: string;
  name: string;
}

export interface ModuleDetail {
  uuid: string;
  name: string;
  versions: any;
}

export interface ConnectedProduct {
  uuid: string;
  name: string;
}

export interface ConnectedProductDetail {
  uuid: string;
  name: string;
  versions: any;
}

export interface Controller {
  uuid: string;
  name: string;
}

export interface Motor {
  uuid: string;
  name: string;
}

export interface MotorDetail {
  uuid: string;
  name: string;
  versions: any;
}

export interface PumpEnd {
  uuid: string;
  name: string;
}

export interface PumpEndDetail {
  uuid: string;
  name: string;
  versions: any;
}

export interface PumpSystem {
  uuid: string;
  name: string;
}

export interface PumpSystemDetail {
  uuid: string;
  name: string;
  versions: any;
}

export interface CoefficientSet {
  uuid: string;
  name: string;
}

export interface CoefficientSetDetail {
  uuid: string;
  name: string;
  versions: any;
}

export interface CoefficientSetItemDetail {
  approval_state: string;
  coefficients: string;
  selected_coefficients: string;
  submission_time: number;
  username: string;
  version: number;
}

export interface SelectedCalculationDetail {
  corr_fact: { value: string, man: boolean };
  mai: { value: string, man: boolean };
  maq: { value: string, man: boolean };
  mar_: { value: string, man: boolean };
  mbi: { value: string, man: boolean };
  mbq: { value: string, man: boolean };
  mbr: { value: string, man: boolean };
  mci: { value: string, man: boolean };
  mcq: { value: string, man: boolean };
  mcr: { value: string, man: boolean };
  mdq_: { value: string, man: boolean };
  nai: { value: string, man: boolean };
  naq: { value: string, man: boolean };
  nar_: { value: string, man: boolean };
  nbi: { value: string, man: boolean };
  nbq: { value: string, man: boolean };
  nbr: { value: string, man: boolean };
  nci: { value: string, man: boolean };
  ncq: { value: string, man: boolean };
  ncr: { value: string, man: boolean };
  ndq_: { value: string, man: boolean };
  npsh_a: { value: string, man: boolean };
  npsh_c: { value: string, man: boolean };
  npsh_exp: { value: string, man: boolean };
  split_fact: string;
  filename: string;
  displayName: string;
}

export interface CalculationDetail {
  corr_fact: string;
  mai: string;
  maq: string;
  mar_: string;
  mbi: string;
  mbq: string;
  mbr: string;
  mci: string;
  mcq: string;
  mcr: string;
  mdq_: string;
  nai: string;
  naq: string;
  nar_: string;
  nbi: string;
  nbq: string;
  nbr: string;
  nci: string;
  ncq: string;
  ncr: string;
  ndq_: string;
  npsh_a: string;
  npsh_c: string;
  npsh_exp: string;
  split_fact: string;
  filename: string;
  displayName: string;
}

export interface PumpUnit {
  item: string,
  packaging: string,
  maxTemp: string,
  suctionHead: string,
  picture: string,
}

export interface PartsList {
  uuid: string;
  name: string;
  type: string;
  parts: any;
}

export class PartsListNode {
  name: string;
  id: string;
  material: string;
  partsNumbers?: string;
  children?: PartsListNode[];
  number: number;
}

export class PartsListFlatNode {
  name: string;
  id: string;
  material: string;
  partsNumbers?: string;
  children?: PartsListNode[];
  level: number;
  expandable: boolean;
}

export interface AccessoryEvent {
  uuid: string;
  name: string;
}

export interface ConnectedProductEvent {
  item_no_5: string;
  item_no_4: string;
  item_no_3: string;
  status: string;
  product_name_3: string;
  product_name_4: string;
  product_name_5: string;
  packaging: string;
  product_name_1: string;
  product_name_2: string;
  item_no_2: string;
  item_no_1: string;
  comment: string;
  id: string;
  type: string;
  explanation: string;
}

export const CONTROLLER_ITEMS: string[] = ['d', 'efficiency', 'i_factor', 'max_temp', 'h', 'h1', 'h2', 'name', 'd1', 'd2', 'max_i', 'w', 'max_p', 'min_temp', 'w1', 'w2', 'w3', 'net_weight', 'item', 'u_factor', 'picture', 'packaging', 'i_offset', 'economical_value_for_sort_order', 'ingress_protection_code', 'max_voc', 'uuid', 'min_cable_size', 'picture_url'];

export const MOTOR_ITEMS: string[] = ['parts_picture', 'max_speed', 'ac_poles', 'rated_power', 'min_set_speed', 'ins_class', 'cos_phi', 'name', 'diameter', 'packaging', 'diameterA', 'length', 'diameterB', 'diameterC', 'min_speed_Hz', 'partsUuid', 'min_speed', 'net_weight', 'min_set_speed_Hz', 'item', 'm_max_u', 'ingress_protection_code', 'uuid', 'max_subm', 'm_min_u', 'max_speed_Hz', 'material', 'parts_picture_url', 'parts'];

export const MOTOR_ITEMS_CREATE: string[] = ['max_speed', 'ac_poles', 'rated_power', 'min_set_speed', 'ins_class', 'cos_phi', 'name', 'diameter', 'packaging', 'diameterA', 'length', 'diameterB', 'diameterC', 'min_speed_Hz', 'min_speed', 'net_weight', 'min_set_speed_Hz', 'item', 'm_max_u', 'ingress_protection_code', 'max_subm', 'm_min_u', 'max_speed_Hz', 'material'];

export const MOTOR_ITEMS_UPDATE: string[] = ['max_speed', 'ac_poles', 'rated_power', 'min_set_speed', 'ins_class', 'cos_phi', 'name', 'diameter', 'packaging', 'diameterA', 'length', 'diameterB', 'diameterC', 'min_speed_Hz', 'partsUuid', 'min_speed', 'net_weight', 'min_set_speed_Hz', 'item', 'm_max_u', 'ingress_protection_code', 'max_subm', 'm_min_u', 'max_speed_Hz', 'material', 'parts_picture_url'];

export const PUMPENDS_ITEMS: string[] = ['diameter', 'packaging', 'parts_picture', 'length', 'diams',
  'net_weight', 'outlet', 'item', 'name', 'material', 'parts'];

export const PUMPENDS_DIAMS_ITEMS: string[] = ['diamD', 'diamG', 'diamH', 'diamE', 'diamF', 'diamK', 'diamL', 'diamI', 'diamJ', 'diamO', 'diamP', 'diamM', 'diamN', 'diamS', 'diamT', 'diamQ', 'diamR', 'diamW', 'diamX', 'diamU', 'diamV'];

export const PUMPSYSYTEM_ITEMS: string[] = ['no', 'field_no', 'prod_ulcsa', 'accessories', 'status', 'max_curves', 'prod_standard',
  'min_pv_pmax', 'max_lift', 'min_pv_vmp', 'id', 'opt_pv_vmp', 'max_pv_pmax', 'corr_fact', 'min_pv_vmp_exp',
  'version_number', 'comments', 'ps_item', 'pump', 'min_lift', 'picture', 'max_power', 'split_fact', 'pu_unit', 'pu_picture', 'app_type', 'min_borehole',
  'eventType', 'controller', 'calculations', 'type', 'live', 'preview', 'motor', 'pump_end', 'pu_item', 'pu_parts',
  'pu_max_temp', 'pu_suction_head', 'pu_packaging', 'm_min_speed', 'm_min_set_speed', 'm_max_speed', 'm_min_speed_Hz',
  'm_min_set_speed_Hz', 'm_max_speed_Hz', 'motor_parts','motor_parts_list_name','m_item','m_weight','m_material','cos_phi','ac_poles','m_min_u','m_max_u'];

export const PUMPSYSYTEM_ITEMS_CREATE: string[] = ['no', 'field_no', 'prod_ulcsa',
  'status', 'max_curves', 'prod_standard', 'min_pv_pmax', 'max_lift',
  'min_pv_vmp', 'opt_pv_vmp', 'max_pv_pmax', 'corr_fact', 'min_pv_vmp_exp', 'comments', 'ps_item',
  'pump', 'min_lift', 'picture', 'max_power', 'split_fact', 'app_type', 'min_borehole', 'eventType', 'type', 'pu_item',
  'pu_parts', 'pu_max_temp', 'pu_picture', 'pu_suction_head', 'pu_packaging', 'm_min_speed',
  'm_min_set_speed', 'm_max_speed', 'm_min_speed_Hz', 'm_min_set_speed_Hz', 'm_max_speed_Hz', 'motor_parts','motor_parts_list_name','m_item','m_weight','m_material','cos_phi','ac_poles','m_min_u','m_max_u'];

export const CONNECTED_ITEMS: string[] = ['version_number', 'item_no_5', 'item_no_4', 'item_no_3', 'status', 'product_name_3', 'product_name_4', 'product_name_5', 'packaging', 'product_name_1', 'product_name_2', 'item_no_2', 'item_no_1', 'comment', 'id', 'author', 'type', 'explanation', 'live', 'preview'];

export const CONNECTED_ITEMS_CREATE: string[] = ['item_no_5', 'item_no_4', 'item_no_3', 'status', 'product_name_3', 'product_name_4', 'product_name_5', 'packaging', 'product_name_1', 'product_name_2', 'item_no_2', 'item_no_1', 'id', 'type', 'explanation', 'comment'];

export const MODULE_ITEMS: string[] = ['voc_coeff', 'v_mpp', 'version_number', 'pmax_coeff', 'max_system_voltage', 'status', 'item_no', 'length', 'width', 'i_sc', 'comment', 'v_oc', 'isc_coeff', 'id', 'p_max', 'author', 'type', 'live', 'preview'];

export const MODULE_ITEMS_CREATE: string[] = ['type', 'item_no', 'p_max', 'status', 'comment',
  'v_mpp', 'v_oc', 'i_sc', 'pmax_coeff', 'voc_coeff', 'isc_coeff', 'max_system_voltage', 'width', 'length'];

export const POLE_MOUNT_ITEMS: string[] = ['c_mm', 'b_mm', 'pole_mount', 'a_mm', 'version_number', 'b_inch', 'status', 'modules_count', 'item_no', 'a_ft', 'c_ft', 'comment', 'price', 'id', 'author', 'live', 'preview'];

export const COEFFICIENT_SET_LOWERCASE: string[] = ['npsh_exp', 'npsh_a', 'npsh_c', 'mci', 'mar_', 'nar_', 'mai', 'nbi', 'mbi', 'nci', 'mdq_', 'ndq_', 'nai', 'mcq', 'maq', 'nbq', 'mbr', 'ncr', 'mbq', 'ncq', 'mcr', 'naq', 'nbr', 'corr_fact'];

export const ACCESSORY_ITEMS: string[] = ['product_name_3', 'product_name_4', 'product_name_5', 'product_name_10', 'product_name_6', 'product_name_7', 'product_name_8', 'product_name_9', 'product_name_1', 'product_name_2', 'item_no_2', 'item_no_1', 'item_no_9', 'item_no_8', 'item_no_7', 'item_no_6', 'item_no_5', 'item_no_4', 'item_no_3', 'item_no_10', 'packaging', 'type', 'status', 'comment', 'live', 'preview'];

export const PIPE_PART_ITEMS: string[] = ['diameter', 'elbow_90', 'check_valve_spring', 'comment'];

export const PUMP_SYSTEM_TYPES: string[] = ['Default', 'Custom', 'GRUNDFOS', 'Lowara'];

export const ACCESSORY_FILE_NAMES: string[] = ['lorentz_well_probe', 'lorentz_well_probe_v2', 'lorentz_water_sensor', 'lorentz_float_switch',
  'lorentz_pressure_switch',
  'lorentz_liquid_level_sensor',
  'lorentz_liquid_pressure_sensor',
  'lorentz_water_meter',
  'lorentz_sunswitch',
  'lorentz_surge_protector',
  'lorentz_splice_kit_6',
  'lorentz_splice_kit_10',
  'lorentz_pv_disconnect_440_20_1',
  'lorentz_pv_disconnect_440_40_1',
  'lorentz_pv_disconnect_440_40_3',
  'lorentz_pv_disconnect_440_20_6',
  'lorentz_pv_disconnect_440_40_6',
  'lorentz_pv_disconnect_200v',
  'lorentz_pv_disconnect_400v',
  'lorentz_pv_disconnect_900_40',
  'lorentz_pv_disconnect_1000_40_5',
  'lorentz_pv_combiner_1000_125_4',
  'lorentz_pv_protect_1000_125',
  'lorentz_sacrificial_anode_hr',
  'lorentz_sacrificial_anode_4',
  'lorentz_sacrificial_anode_6',
  'lorentz_stilling_tube_hr',
  'lorentz_stilling_tube_c',
  'lorentz_stilling_tube_adaptor',
  'lorentz_pp150_powerpack',
  'lorentz_pp200_powerpack',
  'lorentz_pp600h_powerpack',
  'lorentz_pp2000_powerpack',
  'lorentz_pp4000_powerpack',
  'lorentz_ps_boost_inline_filter',
  'lorentz_ps_boost_installation_kit',
  'lorentz_pm_accessory_bracket',
  'lorentz_smartpsu',
  'lorentz_smartstart',
  'lorentz_sunsensor',
  'lorentz_flow_sleeve_hr',
  'lorentz_flow_sleeve_csj',
  'lorentz_ps2_manual_speed_controller',
  'lorentz_pv_disconnect_1000_300_30',
  'lorentz_ps2_controller_plug_kit',
  'lorentz_ps2_100_mc4_solar_adapter',
  'lorentz_ps2_100_well_probe',
  'lorentz_ps2_100_motor_extension_cable',
  'lorentz_ps2_100_float_switch',
  'lorentz_surge_protector2',
  'lorentz_ps2_100_well_probe_extension_cable',
  'lorentz_retrofit_adaptors_for_hr',
  'lorentz_smartpsu_100',
  'lorentz_pp150s_powerpack',
  'lorentz_pp600s_powerpack',
  'lorentz_pp4000s_powerpack',
  'lorentz_lls_wlms',
  'lorentz_pp2000s_powerpack']

export interface PumpSystemStatus {
  id: string;
  content: string;
}

export const PUMP_SYSTEM_STATUS: PumpSystemStatus[] = [{ id: '0', content: 'current' }, { id: '1', content: 'discontinued' }, { id: '2', content: 'deleted' }, { id: '5', content: 'prototype engineering' }, { id: '11', content: 'S1' }, { id: '12', content: 'S2' }, { id: '13', content: 'S3' }, { id: '14', content: 'S4' }, { id: '15', content: 'S5' }, { id: '16', content: 'S6' }, { id: '17', content: 'S7' }, { id: '18', content: 'S8' }, { id: '19', content: 'S9' }, { id: '20', content: 'prototype' }];

export const CALCULATIONS_ITEMS: string[] = ['npsh_exp', 'npsh_a', 'npsh_c', 'mci', 'mar_', 'nar_', 'mai', 'nbi', 'mbi', 'nci', 'mdq_', 'ndq_', 'nai', 'mcq', 'maq', 'nbq', 'mbr', 'ncr', 'mbq', 'ncq', 'mcr', 'naq', 'nbr', 'corr_fact', 'split_fact', 'displayName', 'filename'];
export interface SizingApp {

  app_name: string;
  registry_key: string;
  version: number;
  title: string;
  approver: string;

}
