import { Action, createReducer, on } from "@ngrx/store";
import * as ChartsSettingActions from "./charts-setting.actions";

export const chartsSettingFeatureKey = 'chartsSetting';

export interface ChartsSettingState {
  process: String;
  isShowStepper: Boolean;
  valueChangedItem: String[];
  values: any;
}

export const initialState: ChartsSettingState = {
  process: '',
  isShowStepper: false,
  valueChangedItem: [],
  values: null
}

const reducer = createReducer(
  initialState,
  on(ChartsSettingActions.setSimulationProcess, (state, action) => {
    return {
      ...state,
      process: action.data
    }
  }),
  on(ChartsSettingActions.setLoadSimulationStepper, (state, action) => {
    return {
      ...state,
      isShowStepper: action.data
    }
  }),
  on(ChartsSettingActions.setValueChangedItem, (state, action) => {
    return {
      ...state,
      valueChangedItem: action.data
    }
  }),
  on(ChartsSettingActions.setChartSettingsValue, (state, action) => {
    return {
      ...state,
      values: action.data
    }
  })
);

export function chartsSettingReducer(
  state: ChartsSettingState,
  action: Action
): ChartsSettingState {
  return reducer(state, action);
}
