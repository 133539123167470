import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-calculate-dialog',
  templateUrl: './calculate-dialog.component.html',
  styleUrls: ['./calculate-dialog.component.scss']
})
export class CalculateDialogComponent implements OnInit {

  type = '';
  message = '';
  eventForm: UntypedFormGroup;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CalculateDialogComponent>
  ) { }

  ngOnInit() {
    this.message = this.data.message;
    this.type = this.data.type;
    this.eventForm = this.fb.group({
      note: '',
    });
  }

  onConfirmClick() {
    this.dialogRef.close(true);
  }

}
