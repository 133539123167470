<section class="wrapper">
  <div class="charts">
    <div class="flow-rate">
      <app-flow-rate-chart-calculate></app-flow-rate-chart-calculate>
    </div>
    <div class="content">
      <div class="three">
        <div class="rotational">
          <app-rotational-speed-chart></app-rotational-speed-chart>
        </div>
        <div class="npsh">
          <app-npsh-chart></app-npsh-chart>
        </div>
        <div class="current">
          <app-current-chart></app-current-chart>
        </div>
      </div>
      <div class="ten">
        <div>
          <app-aq-chart></app-aq-chart>
        </div>
        <div>
          <app-cq-chart></app-cq-chart>
        </div>
        <div>
          <app-bq-chart></app-bq-chart>
        </div>
        <div>
          <app-dq-chart></app-dq-chart>
        </div>
        <div>
          <app-bi-chart></app-bi-chart>
        </div>
        <div>
          <app-ci-chart></app-ci-chart>
        </div>
        <div>
          <app-ar-chart></app-ar-chart>
        </div>
        <div>
          <app-cr-chart></app-cr-chart>
        </div>
        <div>
          <app-br-chart></app-br-chart>
        </div>
        <div>
          <app-ai-chart></app-ai-chart>
        </div>
      </div>
    </div>
  </div>
  <div class="settings">
    <app-control-panel></app-control-panel>
    <app-charts-setting></app-charts-setting>
  </div>
</section>