import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { delay, filter, map, mergeMap } from 'rxjs/operators';
import { DirtyComponent } from '../dirty-component';
import { DisplayProductDataService } from '../display-product-data.service';
import { ErrorButtonComponent } from '../error-button/error-button.component';
import { PartsListService } from '../parts-list.service';
import { MOTOR_ITEMS_UPDATE } from '../pump-data.model';
import { SaveButtonComponent } from '../save-button/save-button.component';
import { setPartsListName } from '../store/controller/controller.actions';
import { ControllerState } from '../store/controller/controller.reducer';
import { selectController } from '../store/controller/controller.seletor';
import { PackagingState } from '../store/packaging/packaging.reducer';
import { selectPackaging } from '../store/packaging/packaging.selector';
import { setPartsName, setPartsData, setPartsPicture, setOriginalFormValue, setPartsType, initPartsData } from '../store/parts-list/parts-list.actions';
import { PartsListState } from '../store/parts-list/parts-list.reducer';
import { selectPartsList } from '../store/parts-list/parts-list.seletor';
import { MotorItem } from './motor.model';

@Component({
  selector: 'app-motors',
  templateUrl: './motors.component.html',
  styleUrls: ['./motors.component.scss']
})
export class MotorsComponent implements OnInit, OnDestroy, DirtyComponent {

  private subscriptions: Subscription[] = [];
  motor: Observable<MotorItem>;
  motorForm: UntypedFormGroup;
  packagingList = [];
  isDirty = false;
  motorUuid = '';
  motorPartsValue: any;
  showMotorForm = false;
  showPartslistTree = false;
  partslistName = 'Please select parts list';
  partsPicture = '';
  originalMotorFormValue: any;
  originalMotorData: any;

  constructor(
    private store: Store,
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private database: PartsListService,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {
    this.store.dispatch(setPartsType({ data: 'motor' }));
    const getData = combineLatest([
      this.store.pipe(
        select(selectPackaging),
        map((data: PackagingState) => data.data)
      ),
      this.store.pipe(
        select(selectPartsList),
        map((data: PartsListState) => data.partsPicture)
      ),
      this.store.pipe(
        select(selectController),
        map((data: ControllerState) => data.partsList)
      )
    ]).subscribe(([data, partsPicture, partsName]) => {

      this.packagingList = [...data].sort((a, b) => Number(a.subType.L) - Number(b.subType.L));

      if (partsPicture !== '') {
        this.partsPicture = `${partsPicture}`;
      };

      if (partsName !== '') {
        this.partslistName = `${partsName}`;
      }
    });
    this.subscriptions.push(getData);

    const urlData = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.showMotorForm = false;
      this.isDirty = false;
    });
    this.subscriptions.push(urlData);

    const motorData = this.route.paramMap.pipe(
      map(params => params.get('uuid')),
      mergeMap(uuid => {
        this.motorUuid = uuid;
        return this.displayProductData.getMotorItem(uuid);
      })
    )
    .subscribe((motorData) => {
      this.showMotorForm = true;
      this.originalMotorData = motorData;
      const motorFormValue = {};
      MOTOR_ITEMS_UPDATE.forEach(name => {
        motorFormValue[`${name}`] = motorData[`${name}`];
      });
      this.motorForm = this.fb.group(motorFormValue);
      this.originalMotorFormValue = this.motorForm.value;

      if (motorData.packaging) {
        const item = this.packagingList.find(item => item.uuid === motorData.packaging.uuid);
        if (item) {
          this.originalMotorFormValue.packaging = item.name;
        }
      }

      const motorFormValueSubscription = this.motorForm.valueChanges.subscribe((value) => {
        this.isDirty = JSON.stringify(this.originalMotorFormValue) !== JSON.stringify(value);
      });
      this.subscriptions.push(motorFormValueSubscription);

      const partsListValue: any = {
        name: '',
        picture: ''
      };
      if ( motorData.parts ) {
        this.partslistName = motorData.parts.name;
        this.showPartslistTree = true;
        this.database.setPartsListUuid(motorData.partsUuid);
        partsListValue.name = motorData.parts.name;
        this.store.dispatch(setPartsName({ data: motorData.parts.name }));
        this.store.dispatch(setPartsData({ data: motorData.parts }));
        this.store.dispatch(initPartsData({ data: motorData.parts }));
      } else {
        this.showPartslistTree = false;
        this.store.dispatch(setPartsListName({ data: 'Please select parts list' }));
        this.store.dispatch(setPartsName({ data: '' }));
        this.store.dispatch(setPartsData({ data: {} }));
        this.store.dispatch(initPartsData({ data: {} }));
      }

      if ( motorData.parts_picture && motorData.parts_picture !== '0' ) {
        partsListValue.picture = motorData.parts_picture;
      }
      this.store.dispatch(setOriginalFormValue({ data: partsListValue }));
      this.store.dispatch(setPartsListName({ data: partsListValue.name }));
      this.store.dispatch(setPartsPicture({ data: partsListValue.picture }));
    });
    this.subscriptions.push(motorData);
  }

  updatePartslistTree(event) {
    const isFormValueChanged = this.store.pipe(
      select(selectPartsList),
      map((data: PartsListState) => data.isFormValueChanged)
    ).subscribe((isFormValueChanged) => {
      if (isFormValueChanged || event.isPartsNumberChanged) {
        this.isDirty = true;
      } else {
        this.isDirty = false;
      }
    });
    this.subscriptions.push(isFormValueChanged);
    this.motorPartsValue = event.partsValue;
  }

  onSave() {
    const dialogRef = this.dialog.open(SaveButtonComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateMotor();
      }
    });
  }

  updateMotor() {
    const data = this.motorForm.value;
    if (this.motorPartsValue['partsNumbers']) {
      this.motorPartsValue['partsNumbers'] = [...new Map(this.motorPartsValue['partsNumbers']
      .map(i => [{name: i.name, id: i.id}, i])).values()];
    } else {
      this.motorPartsValue['partsNumbers'] = [];
    }
    data.parts = this.motorPartsValue;
    data.parts_picture = this.partsPicture;

    if ( this.motorForm.value.packaging.subType ) {
      const packagingValue: any = {};
      packagingValue.name = data.packaging.uuid;
      packagingValue.w = data.packaging.subType.W;
      packagingValue.h = data.packaging.subType.H;
      packagingValue.l = data.packaging.subType.L;
      packagingValue.pack_weight = data.packaging.weight;
      data.packaging = packagingValue;
    } else {
      const result = this.packagingList.filter(item => item.name.includes(data.packaging));
      const packaging: any = {};
      packaging.name = result[0].uuid;
      packaging.w = `${result[0].subType.W}`;
      packaging.h = `${result[0].subType.H}`;
      packaging.l = `${result[0].subType.L}`;
      packaging.pack_weight = `${result[0].weight}`;
      data.packaging = packaging;
    }

    this.displayProductData.updateMotor(this.motorUuid, data).subscribe(
      item => {
        this.snackBar.open(item, '', { duration: 1500, panelClass: 'hintMsg' })
        .afterDismissed()
        .pipe(delay(0))
        .subscribe(() => {
          this.ngOnInit();
          this.isDirty = false;
          this.showMotorForm = false;
        });
      },
      error => {
        const dialogRef = this.dialog.open(ErrorButtonComponent, {
          width: '600px',
          data: JSON.parse(error.error),
          minHeight: '20vh',
        });
        dialogRef.afterClosed().subscribe(() => {});
      },
      () => {}
    );
  }

  canDeactivate() {
    return this.isDirty;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.store.dispatch(setOriginalFormValue({ data: {
      name: '',
      picture: ''
    }}));
    this.store.dispatch(setPartsListName({ data: '' }));
    this.store.dispatch(setPartsPicture({ data: '' }));
    this.store.dispatch(setPartsName({ data: '' }));
    this.store.dispatch(setPartsData({ data: {} }));
    this.store.dispatch(initPartsData({ data: {} }));
  }
}
