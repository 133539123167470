import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { delay, mergeMap, take } from 'rxjs/operators';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { v4 } from 'uuid';
import { CopyButtonComponent } from '../copy-button/copy-button.component';
import { DisplayProductDataService } from '../display-product-data.service';
import { ErrorButtonComponent } from '../error-button/error-button.component';
import { PIPE_PART_ITEMS } from '../pump-data.model';

@Component({
  selector: 'app-pipe-part',
  templateUrl: './pipe-part.component.html',
  styleUrls: ['./pipe-part.component.scss']
})
export class PipePartComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  userLocalStorage = new UserLocalStorage();
  pipePartForm: UntypedFormGroup;

  selectedRowIndex = -1;
  showLoading = false;
  copiedItem = '';
  pipePartData = [];

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {
    this.initRecalculateForm();
  }

  openCopyDialog(type: string) {
    const dialogRef = this.dialog.open(CopyButtonComponent, {
      width: '1000px',
      maxWidth: '96vw',
      data: type,
      minHeight: '60vh',
      maxHeight: '60vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showLoading = true;
        this.copyItem(result);
      }
    });
  }

  copyItem(accessoryUuid: string) {
    const getPipePartItem = this.displayProductData.getPipePartItem(accessoryUuid)
    .subscribe((accessory: any) => {
      this.showLoading = false;
      this.copiedItem = accessory.name;
      const accessoryData = accessory.versions[0];
      const data = {};
      PIPE_PART_ITEMS.forEach(name => data[`${name}`] = accessoryData[`${name}`]);
      this.pipePartForm.patchValue(data);
    });
    this.subscriptions.push(getPipePartItem);
  }

  initRecalculateForm() {
    const data: any = {};
    PIPE_PART_ITEMS.forEach(name => {
      data[`${name}`] = [null, Validators.required];
    });
    this.pipePartForm = this.fb.group(data);
  }

  onSubmit(data) {

    const user = this.userLocalStorage.loadUser();
    data.author = user.user.username;
    data.uuid = v4();
    data.file = '';

    const getPipePartList = this.displayProductData.getPipePartList()
    .pipe(
      take(1),
      mergeMap(allPipeParts => {
        data.id = `${allPipeParts.length}`;
        return this.displayProductData.createPipePartItem(data);
      })
    ).subscribe(
      item => {
        this.snackBar
        .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
        .afterDismissed()
        .pipe(delay(0))
        .subscribe(() => {
          this.displayProductData.reloadPipePartData();
          this.router.navigate(['/data/pipe-part', data.uuid]);
        });
      },
      error => {
        this.dialog.open(ErrorButtonComponent, {
          width: '600px',
          data: JSON.parse(error.error),
          minHeight: '20vh',
        });
      },
      () => {}
    );
    this.subscriptions.push(getPipePartList);
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
