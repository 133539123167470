import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CoefficientSetItemDetail } from '../display-product-data/pump-data.model';
import { CoefficientSetItem, CoefficientNameItem } from './coefficient.model';

const HOST = environment.calculationUrl;
const VERSION = environment.versioningUrl;

@Injectable({
  providedIn: 'root'
})
export class DisplayPumpSimulationService {

  fileVersionData$: Observable<CoefficientSetItem[]>;
  fileNameData$: Observable<CoefficientNameItem[]>;

  constructor(
    private http: HttpClient
  ) {
    this.fileVersionData$ = this.http.get<CoefficientSetItem[]>(VERSION + 'getVersionedMeasurementFileKeys').pipe(shareReplay(1));

    this.fileNameData$ = this.http.get<CoefficientNameItem[]>(VERSION + 'getVersionedMeasurementFileNames').pipe(shareReplay(1));
  }

  getUploadedFileList(): Observable<string[]> {
    return this.http.get<string[]>(HOST + 'measurementFiles');
  }

  getUploadedFileItem(id: string): Observable<any> {
    return this.http.get<any>(HOST + `measurementFile/${id}`);
  }

  getAllMeasurementFileVersions(): Observable<CoefficientSetItem[]> {
    return this.fileVersionData$;
  }

  getAllMeasurementFileNames(): Observable<CoefficientNameItem[]> {
    return this.fileNameData$;
  }

  getMeasurementFileVersionByID(id: string, version: number): Observable<any> {
    return this.http.get<any>(VERSION + `versionedMeasurementFile/${id}/${version}`);
  }

  getMeasurementFileVersionsByID(id: string): Observable<any> {
    return this.http.get<any>(VERSION + `versionedMeasurementFiles/${id}`);
  }

  getLiveMeasurementFileVersionsByID(id: string): Observable<any> {
    return this.http.get<any>(VERSION + `liveVersionedMeasurementFiles/${id}`);
  }

  getPossibleLiveMeasurementFileVersions(id: string): Observable<any> {
    return this.http.get<any>(VERSION + `possibleLiveVersionedMeasurementFiles/${id}`);
  }

  getAllLiveMeasurementFileVersions(): Observable<CoefficientSetItemDetail[]> {
    return this.http.get<CoefficientSetItemDetail[]>(VERSION + `liveVersionedMeasurementFiles`);
  }

  deleteMeasurementFileVersionByID(id: string, version: number): Observable<any> {
    return this.http.delete<any>(VERSION + `deleteVersionedMeasurementFile/${id}/${version}`);
  }

}
