import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-version-table',
  templateUrl: './version-table.component.html',
  styleUrls: ['./version-table.component.scss']
})
export class VersionTableComponent implements OnInit, AfterViewInit {

  selectedRowIndex = -1;

  @Input() dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ['approveForUse', 'version', 'status', 'published', 'author', 'comment', 'action'];
  
  @Input() versionData: any[];
  @Output() showSelectedVersionData: EventEmitter<string> = new EventEmitter();
  @Output() showSelectedVersionRadioBtn: EventEmitter<string> = new EventEmitter();

  ngOnInit(): void {
    this.versionData.shift();
    this.dataSource.data = this.versionData;
  }

  selectVersionItem(version: string) {
    this.showSelectedVersionRadioBtn.emit(version);
  }

  showVersionData(version: number) {
    this.selectedRowIndex = version;
    this.showSelectedVersionData.emit(`${version}`);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.firstPage());
  }
}
