import { Action, createReducer, on } from "@ngrx/store";
import * as IntermediateActions from "./intermediate-chart.actions";

export const intermediateFeatureKey = 'intermediate';

export interface IntermediateState {
  loading: Boolean;
  intermediateData: any;
  chartItems: String[];
}

export const initialState: IntermediateState = {
  loading: true,
  intermediateData: null,
  chartItems: []
}

const reducer = createReducer(
  initialState,
  on(IntermediateActions.setIntermediateLoading, (state, action) => {
    return {
      ...state,
      loading: action.data
    }
  }),
  on(IntermediateActions.setIntermediateData, (state, action) => {
    return {
      ...state,
      intermediateData: action.data
    }
  }),
  on(IntermediateActions.setRedrawChartItems, (state, action) => {
    return {
      ...state,
      chartItems: action.data
    }
  })
);

export function intermediateReducer(
  state: IntermediateState,
  action: Action
): IntermediateState {
  return reducer(state, action);
}

