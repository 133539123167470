import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoginService } from './login.service';

@Directive({
  selector: '[appIsAuthenticated]'
})
export class IsAuthenticatedDirective implements OnDestroy {

  subscription: Subscription;

  private hasView = false;

  constructor(private loginService: LoginService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {

    this.subscription = this.loginService.isLoggedIn$.subscribe(
      (isLoggedIn) => {
        this.appAuthenticated = isLoggedIn;
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @Input() set appAuthenticated(condition: boolean) {
    if (condition && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!condition && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

}
