import { createAction, props } from "@ngrx/store";
import { ChartTableData, VersionTableData } from "../../../pump-simulation/coefficient.model";

export const CHART_DATA_TABLE = '[Coefficient Data] Set Flow Rate Chart Table Data';
export const VERSION_DATA_TABLE = '[Coefficient Data] Set Coefficient Data Version Table Data';

export const setChartTable = createAction(
  CHART_DATA_TABLE,
  props<{ chartTable: Partial<ChartTableData> }>()
);

export const setVersionTable = createAction(
  VERSION_DATA_TABLE,
  props<{ versionTable: Partial<VersionTableData> }>()
);

