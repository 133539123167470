<section class="content">
  <div class="upload-wrapper">
    <div class="upload-content">
      <button mat-raised-button color="primary">
        <mat-icon>add</mat-icon> Choose file
      </button>
      <input type="file" name="profile" (change)="onFileSelect($event)"
        accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
    </div>
    <div class="upload-info" *ngIf="showUploadFileName">
      <span> {{ uploadFileName }} </span>
    </div>
  </div>
  <div class="error-hint" *ngIf="theHeadersAreIncorrect">
    <button mat-icon-button>
      <mat-icon>priority_high</mat-icon>
    </button>
    <p>The imported file is incorrect because the headers have been changed. They must consist of the following fields:
      Δp, U1, I1, V, t and n </p>
  </div>
  <div class="error-hint" *ngIf="pressureIsNotAscending">
    <button mat-icon-button>
      <mat-icon>priority_high</mat-icon>
    </button>
    <p>The imported file is incorrect because the pressure blocks are not in ascending order. Please order the results
      with ascending pressure entries. </p>
  </div>
  <div class="error-hint" *ngIf="(isUploadFileDataEmpty$ | async)">
    <button mat-icon-button>
      <mat-icon>priority_high</mat-icon>
    </button>
    <p>Please upload file. </p>
  </div>
</section>