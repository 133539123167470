import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { catchError, concatAll, map, mergeMap, of, take, toArray } from "rxjs";
import { DisplayProductDataService } from "../../display-product-data.service";
import { Accessory, AccessoryDetail } from "../../pump-data.model";
import * as AccessoryActions from "./accessory.actions";
import { AccessoryState } from "./accessory.reducer";
import { selectAccessory } from "./accessory.seletor";


@Injectable()

export class AccessoryEffects {

  // Get all Accessory Names
  loadAccessories$ = createEffect(() => this.actions$.pipe(
    ofType(AccessoryActions.loadAccessories),
    mergeMap(() => this.displayProductData.getAccessoryList()
    .pipe(
      map((data: Accessory[]) => AccessoryActions.loadAccessoriesSuccess({ data })),
      catchError(error => of(AccessoryActions.loadAccessoriesFailure({ error })))
    ))
  ));

  // Get each Accessory item data and put them together
  loadAllAccessoryData$ = createEffect(() => this.actions$.pipe(
    ofType(AccessoryActions.loadAllAccessory),
    mergeMap(() => this.store.pipe(
      select(selectAccessory),
      map((data: AccessoryState) => data.accessories),
      take(1),
      concatAll(),
      mergeMap(item => this.displayProductData.getAccessoryItem(item.uuid)),
      toArray(),
      map((result: AccessoryDetail[]) => {
        const data = result.map(item => {
          return {
            ...item.versions[0],
            file: item.name,
            uuid: item.uuid
          }
        });
        return AccessoryActions.loadAllAccessorySuccess({ data });
      }),
      catchError(error => of(AccessoryActions.loadAllAccessoryFailure({ error })))
    ))
  ));

  constructor(
    private store: Store,
    private actions$: Actions,
    private displayProductData: DisplayProductDataService
  ){}
}


