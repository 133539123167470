import { GlobalUser } from './global-user';

const PARTNERID_LORENTZ = 910000001;

/** the user representation in the pm2 gui */
export class User {

  constructor(
    public accessToken: string,
    public validUntil: Date,
    public refreshToken: string,
    public refreshTokenValidUntil: Date | null,
    public user: GlobalUser) { }

  /**
   * Check if the token is not expired X seconds after the reference data.
   *
   * @param now reference Date
   * @param futureSeconds number of seconds
   */
  isTokenValidInFutureSeconds(now: Date, futureSeconds: number) {
    const future = new Date(now);
    future.setSeconds(now.getSeconds() + futureSeconds);
    return this.isTokenValid(future);
  }

  /** check if the token is valid, not expired */
  isTokenValid(now: Date): boolean {
    return this.validUntil > now;
  }

  isRefreshTokenValid(now: Date): boolean {
    if (this.refreshTokenValidUntil) {
      return this.refreshTokenValidUntil > now;
    } else {
      return false; // if we don't know the refesh lifetime
    }
  }

  hasRole(role: string): boolean {
    return this.user.roles.includes(role);
  }

  hasAnyRole(roles: string[]): boolean {
    return this.user.roles.reduce((hasRole: boolean, role: string) => hasRole || roles.includes(role), false);
  }

  isLorentzUser(): boolean {
    return this.user.partner.partnerId === PARTNERID_LORENTZ.toString();
  }
}
