import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { v4 } from 'uuid';
import { CityItem} from '../cities/city.model';
import { CountryItem } from "../countries/country.model";
import { TruncatedCoordinateItem } from "../location/coordinate.model";
import { DisplayProductDataService } from '../display-product-data.service';

@Component({
  selector: 'app-city-new',
  templateUrl: './city-new.component.html',
  styleUrls: ['./city-new.component.scss']
})
export class CityNewComponent implements OnInit, OnDestroy {

  cityForm: UntypedFormGroup;
  isCityDuplicate = false;
  cityData = [];
  tableLoading = true;
  coordinatesLoading = true;
  countryId = '';
  countryControl = new UntypedFormControl();
  latLonControl = new UntypedFormControl();
  filteredCountries$: Observable<CountryItem[]>;
  countries: CountryItem[] = [];
  coordinates = [];
  filteredCoordinates$: Observable<TruncatedCoordinateItem[]>;

  private subscriptions: Subscription[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private displayProductData: DisplayProductDataService,
    private dialogRef: MatDialogRef<CityNewComponent>
  ) { }

  ngOnInit() {
    this.createForm();
    const cityData = this.displayProductData.getCityList().subscribe((allCities: CityItem[]) => {
      this.tableLoading = false;
      allCities.forEach(item => this.cityData.push(`${item.name.toLowerCase()}-${item.lat}-${item.lon}`));
    });

    this.subscriptions.push(cityData);

    this.cityForm.valueChanges.subscribe(() => this.isCityDuplicate = false);

    this.filteredCountries$ = this.displayProductData.getCountryList().pipe(

      tap(() => this.tableLoading = false),
      map(countries => { return countries.sort((a, b) => (a.name) > (b.name) ? 1 : -1) })

    );

    const countryData = this.filteredCountries$.subscribe(() => {
      this.tableLoading = false;
    });

    this.subscriptions.push(countryData);

    this.filteredCoordinates$ = this.displayProductData.getCoordinateList().pipe(
      tap(() => this.coordinatesLoading = false),
      map(coordinates => {
        const parsedCoordinates: TruncatedCoordinateItem[] = [];
        coordinates.forEach(coordinateData => parsedCoordinates.push({ id: coordinateData.id, lat: coordinateData.lat,
          lon: coordinateData.lon, uuid: coordinateData.uuid }));
          return parsedCoordinates;
      }),
      map(coordinates => { return coordinates.sort((a, b) => Number(a.id) - Number(b.id)) })
    );

    const coordinateData = this.filteredCoordinates$.subscribe(() => { this.coordinatesLoading = false; })
    this.subscriptions.push(coordinateData);

  }

  createForm() {
    this.cityForm = this.fb.group({
      name: ['', Validators.required],
    });
    this.cityForm.addControl('latLonControl', this.latLonControl);
    this.cityForm.addControl('countryControl', this.countryControl);
  }

  onSubmit(result: { name: string; latLonControl: { lat: string; lon: string; }; countryControl: { id: string; }; }) {

    const details = `${result.name.trim().toLowerCase()}-${result.latLonControl.lat}-${result.latLonControl.lon}`;
    const isDuplicates = this.cityData.indexOf(details) > -1;
    if (isDuplicates) {
      this.isCityDuplicate = true;
    } else {
      const city = new CityItem(null, result.name, v4(), result.latLonControl.lat, result.latLonControl.lon,
        result.countryControl.id);

      this.displayProductData.createNewCity(city).subscribe(item => {
        this.snackBar
          .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
          .afterDismissed()
          .pipe(delay(0))
          .subscribe(() => this.dialogRef.close(result));
      });
    }
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
