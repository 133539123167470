import { Action, createReducer, on } from "@ngrx/store";
import * as SimulationLoadActions from "./simulation-load.actions";

export const simulationLoadFeatureKey = 'simulationLoad';

export interface SimulationLoadState {
  values: any;
}

export const initialState: SimulationLoadState = {
  values: null
}

const reducer = createReducer(
  initialState,
  on(SimulationLoadActions.setSimulationLoadData, (state, action) => {
    return {
      ...state,
      values: action.data
    }
  })
);

export function simulationLoadReducer(
  state: SimulationLoadState,
  action: Action
): SimulationLoadState {
  return reducer(state, action);
}
