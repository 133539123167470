<h2 mat-dialog-title> Create New Packaging </h2>
<mat-dialog-content>

  <mat-form-field class="form-element-middle" appearance="fill">
    <mat-label>packaging</mat-label>
    <input type="text" placeholder="packaging" matInput [formControl]="packagingControl" [matAutocomplete]="auto"
      (blur)="updatePackaging($event.target.value)" />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setPackagingChangeAction($event)">
      <mat-option *ngFor="let option of filteredOptions" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="packagingControl.hasError('noOption')">
      Packaging not Found
    </mat-error>
  </mat-form-field>

  <span class="form-wrapper__copy-hint" *ngIf="copiedItem"> {{copiedItem}} copied</span>

  <form [formGroup]="packagingForm" class="form">
    <mat-form-field class="form-element">
      <mat-label> Name </mat-label>
      <input matInput placeholder="Name" disabled [value]="createdPackagingName">
    </mat-form-field>
    <mat-form-field class="form-element form-element-start">
      <mat-label> Pack width </mat-label>
      <input type="number" formControlName="w" matInput placeholder="Pack width">
    </mat-form-field>
    <mat-form-field class="form-element form-element-end">
      <mat-label> Pack height </mat-label>
      <input type="number" min="0" formControlName="h" matInput placeholder="Pack height">
    </mat-form-field>
    <mat-form-field class="form-element form-element-start">
      <mat-label> Pack length </mat-label>
      <input type="number" min="0" formControlName="l" matInput placeholder="Pack length">
    </mat-form-field>
    <mat-form-field class="form-element form-element-end">
      <mat-label> Pack weight </mat-label>
      <input type="number" min="0" matInput formControlName="pack_weight" placeholder="Pack weight">
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="greyBtn" mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" type="submit" class="button" data-testid="submit"
    [disabled]="!packagingForm.valid" (click)="onSubmit(packagingForm.value)"> Create </button>
</mat-dialog-actions>