<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="approveForUse">
      <th mat-header-cell *matHeaderCellDef>Approve For Use</th>
      <td mat-cell *matCellDef="let row; let id = index">
        <mat-radio-button color="primary" [ngClass]="'mat-radio-' + id" (click)="selectVersionItem(id)">
        </mat-radio-button>
      </td>
    </ng-container>

    <!-- Version Column -->
    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Version</th>
      <td mat-cell *matCellDef="let row"> {{row.version_number}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let row"> {{row.status}} </td>
    </ng-container>

    <!-- Published Column -->
    <ng-container matColumnDef="published">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Published?</th>
      <td mat-cell *matCellDef="let row">
        <div *ngIf="row.live; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>LIVE</ng-template>
        <ng-template #elseBlock>PREVIEW</ng-template>
      </td>
    </ng-container>

    <!-- Author Column -->
    <ng-container matColumnDef="author">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Author</th>
      <td mat-cell *matCellDef="let row"> {{row.author}} </td>
    </ng-container>

    <!-- Comment Column -->
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef>Comment</th>
      <td mat-cell *matCellDef="let row"> {{row.comment}} </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let row">
        <button mat-button color="primary" (click)="showVersionData(row.version_number)"> LOAD </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; let id = index; columns: displayedColumns;"
      [ngClass]="{'highlight': selectedRowIndex === row.version_number}">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>