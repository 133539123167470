<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">

    <mat-card-header>
      <mat-card-title>
        Pipe Part
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>

      <mat-progress-spinner *ngIf="showVersionTable" mode="indeterminate" [diameter]=20></mat-progress-spinner>

      <app-version-table *ngIf="!showVersionTable" [versionData]="versionData()"
        (showSelectedVersionData)="showSelectedVersionData($event)"
        (showSelectedVersionRadioBtn)="showSelectedVersionRadioBtn($event)"></app-version-table>

      <div class="container mat-elevation-z8" novalidate *ngIf="showPipePartForm">
        <form [formGroup]="pipePartForm" class="form" (ngSubmit)="onSave()">

          <mat-form-field class="form-element">
            <mat-label>diameter</mat-label>
            <input matInput placeholder="diameter" formControlName="diameter">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>elbow_90</mat-label>
            <input matInput placeholder="elbow_90" formControlName="elbow_90">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>check_valve_spring</mat-label>
            <input matInput placeholder="check_valve_spring" formControlName="check_valve_spring">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>comment</mat-label>
            <input matInput placeholder="comment" formControlName="comment">
          </mat-form-field>

          <div class="form-element">
            <button mat-raised-button color="primary" type="submit" class="button" [disabled]="!isDirty">Save
              Form</button>
          </div>

        </form>
      </div>

    </mat-card-content>
  </mat-card>
</section>
