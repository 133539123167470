import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, AbstractControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { select, Store } from '@ngrx/store';
import { Subscription, Subject, Observable, startWith, map } from 'rxjs';
import { PumpSimulationService } from '../../pump-simulation.service';
import { ShareDataService } from '../../share-data.service';
import { setSourceUseUploadedFile } from '../../store/data-source/data-source.actions';
import { DataSourceState } from '../../store/data-source/data-source.reducer';
import { selectDataSource } from '../../store/data-source/data-source.selector';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-source-use-uploaded-file',
  templateUrl: './source-use-uploaded-file.component.html',
  styleUrls: ['./source-use-uploaded-file.component.scss']
})
export class SourceUseUploadedFileComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  theSelectedFileIsEmpty = false;
  uploadedFileList = [];
  dropdownList: string[] = [];
  uploadedFileControl = new UntypedFormControl(null);
  filteredOptions: Set<string>;
  public readonly uploadedFileChanges: Subject<string> = new Subject<string>();
  public uploadedFileSuggestions: Observable<string[]>;
  matcher = new MyErrorStateMatcher();

  constructor(
    private store: Store,
    private shareService: ShareDataService,
    private pumpSimulationService: PumpSimulationService,
  ) { }

  ngOnInit() {

    const getUploadedFileList = this.pumpSimulationService.getUploadedFileList().subscribe((data: string[]) => {
      this.uploadedFileList = [...data].sort();
      this.dropdownList = this.uploadedFileList;

      this.uploadedFileSuggestions = this.uploadedFileChanges.pipe(
        startWith(''),
        map((val: string) => this.filterResults(val))
      );
    });
    this.subscriptions.push(getUploadedFileList);

    const getDataSourceName = this.store.pipe(
      select(selectDataSource),
      map((data: DataSourceState) => data.dataSourceName)
    ).subscribe(name => {
      if (name === 'Use uploaded file') {
        this.uploadedFileControl.setValidators([
          Validators.required,
          this.optionNotFound.bind(this),
        ]);
      } else {
        this.uploadedFileControl.setValidators(null);
      }
      this.uploadedFileControl.updateValueAndValidity();
    });
    this.subscriptions.push(getDataSourceName);
  }

  private filterResults(val: string) {
    if (val !== '') {
      return this.dropdownList.filter(item => item.toLowerCase().includes(val.toLowerCase()));
    } else {
      return this.dropdownList;
    }
  }

  updateUploadedFile(value: string) {
    if (value === '') {
      this.optionNotFound(this.uploadedFileControl);
    } else {
      const item = this.uploadedFileList.find(item => item === value);
      if (item) {
        this.uploadedFileControl.setValue(value);
      } else {
        this.optionNotFound(this.uploadedFileControl);
      }
    };
  }

  setUploadedFileChangeAction(event: MatAutocompleteSelectedEvent) {
    this.store.dispatch(setSourceUseUploadedFile({ data: event.option.value}));
  }

  optionNotFound(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value) {
      this.filteredOptions = new Set(
        this.dropdownList.filter((option) => option.toLowerCase() === value.toLowerCase())
      );
    }
    if (value && !this.filteredOptions.size) {
      this.shareService.setUseUploadedFile(false);
      return {
        noOption: true
      };
    }
    this.shareService.setUseUploadedFile(true);
    return null;
  }

  onFocus() {
    this.uploadedFileChanges.next('');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
