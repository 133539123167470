<form [formGroup]="loginForm">
  <mat-card appearance="outlined" class="form-wrapper">

    <mat-card-header>
      <mat-card-title i18n="login|dialog title login@@titleLogin">LORENTZ Compass</mat-card-title>
      <mat-card-subtitle *ngIf="message">{{ message }}</mat-card-subtitle>
      <mat-card-subtitle *ngIf="badCredentials" i18n="@@loginErrorHint">The user name and/or password is incorrect. Please click "forgotten your password?".</mat-card-subtitle>
    </mat-card-header>

    <section class="login-content">
      <mat-card-content>
        <mat-form-field appearance="fill">
          <input matInput class="username-input" formControlName="username" name="username" type="text"
            i18n-placeholder="@@loginUsername" placeholder="partnerNET email" required>
        </mat-form-field>

        <mat-form-field appearance="fill" >
          <input matInput class="password-input" formControlName="password" name="password" [type]="hide ? 'password' : 'text'" i18n-placeholder="@@loginPassword" placeholder="partnerNET password" required>
          <mat-icon class="password-show" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>

      </mat-card-content>


      <mat-card-actions>
        <a href="https://partnernet.lorentz.de/en/partnernet_login.html?logout=1&passwordReset=1" target="_blank" class="forget-password">
          <span i18n="@@forgetPassword">Forgotton your password?</span>
        </a>

        <button class="login-button" type="submit" mat-raised-button color="primary" (click)="login()"
          i18n="login|label login button@@labelLogin">LOGIN</button>

      </mat-card-actions>

      <div class="spinner-wrapper" *ngIf="running">
        <mat-progress-spinner class="loginspinner" [diameter]="40" color="primary" mode="indeterminate"></mat-progress-spinner>
      </div>
    </section>
  </mat-card>
  <mat-card appearance="outlined" class="item-wrapper">
    <a href="https://www.lorentz.de/imprint-and-privacy" target="_blank" class="policy-item"><span i18n="@@policy">Imprint & Privacy policy</span></a>
  </mat-card>
</form>


