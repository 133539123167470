import { createAction, props } from "@ngrx/store";

export const setUploadDownloadDataSource = createAction(
  '[SizingApp Data Source] Set SizingApp Data Source',
  props<{ data: string }>()
);

export const setPS2UploadDownloadData = createAction(
  '[UploadDownload Data Source] Set PS2 UploadDownload data',
  props<{ data: [any], file: string, version: string }>()
);

export const setPSK3UploadDownloadData = createAction(
  '[UploadDownload Source] Set PSK3 UploadDownload data',
  props<{ data: [any], file: string, version: string }>()
);

export const setPumpSystemUploadDownloadData = createAction(
  '[UploadDownload Data Source] Set PumpSystem UploadDownload data',
  props<{ data: [any], file: string, version: string }>()
);

