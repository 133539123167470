import { Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { map, Observable, startWith, Subject, Subscription } from 'rxjs';
import { PackagingItem } from '../controllers/controller.model';
import { PackagingCreateComponent } from '../packaging-create/packaging-create.component';
import { PackagingState } from '../store/packaging/packaging.reducer';
import { selectPackaging } from '../store/packaging/packaging.selector';

@Component({
  selector: 'app-packaging-select',
  templateUrl: './packaging-select.component.html',
  styleUrls: ['./packaging-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PackagingSelectComponent),
      multi: true
    }
  ]
})
export class PackagingSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

  private subscriptions: Subscription[] = [];

  packagingList = [];
  packagingStore = '';
  dropdownList: string[] = [];

  packagingControl = new UntypedFormControl('', [
    this.optionNotFound.bind(this),
  ]);

  filteredOptions: Set<string>;

  public readonly packagingChanges: Subject<string> = new Subject<string>();
  public packagingSuggestions: Observable<string[]>;

  @Output() autocompleteChange = new EventEmitter<MatAutocompleteSelectedEvent>();
  @Output() inputChange = new EventEmitter<string>();

  packagingWidth = '';
  packagingHeight = '';
  packagingLength = '';
  packagingWeight = '';

  constructor(
    private store: Store,
    private dialog: MatDialog
  ) { }

  ngOnInit() {

    const getPackagingList = this.store.pipe(
      select(selectPackaging),
      map((data: PackagingState) => data.data)
    ).subscribe((data: PackagingItem[]) => {
      this.packagingList = [...data].sort((a, b) => Number(a.subType.L) - Number(b.subType.L));
      this.dropdownList = this.packagingList.map(item => item.name);
      this.dropdownList.unshift('');
      this.dropdownList.push('Add new packaging');

      this.packagingSuggestions = this.packagingChanges.pipe(
        startWith(''),
        map((val: string) => this.filterResults(val))
      );
    });

    this.subscriptions.push(getPackagingList);
  }

  private filterResults(val: string) {
    if (val !== '') {
      return this.dropdownList.filter(item => item.includes(val));
    } else {
      return this.dropdownList;
    }

  }

  optionNotFound(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    this.filteredOptions = new Set(
      this.dropdownList.filter((option) => option.toLowerCase().indexOf(value.toLowerCase()) >= 0)
    );
    if (value && !this.filteredOptions.size) {
      return {
        noOption: true
      };
    }
    return null;
  }

  setPackagingChangeAction(event: MatAutocompleteSelectedEvent) {
    if ( event.option.value === 'Add new packaging' ) {
      const dialogRef = this.dialog.open(PackagingCreateComponent, {
        width: '1000px',
        maxWidth: '96vw',
        disableClose: true,
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.packagingList.unshift(result);
          this.dropdownList = this.packagingList.map(item => item.name);
          this.dropdownList.unshift('');
          this.dropdownList.push('Add new packaging');
          this.assignPackagingValues(result);
          this.packagingControl.setValue(result.name);
        } else {
          this.packagingControl.setValue(this.packagingStore);
          this.assignPackagingValuesWithName(this.packagingStore);
        }
      });
    } else if ( event.option.value === '' ) {
      this.packagingStore = '';
      this.emptyValue();
      this.packagingControl.reset('');
    } else {
      this.packagingStore = event.option.value;
      this.assignPackagingValuesWithName(event.option.value);
    }
    this.autocompleteChange.emit(event);
  }

  assignPackagingValuesWithName(name: string) {
    this.packagingList.forEach(item => {
      if ( item.name === name ) {
        this.packagingWidth = item.subType.W;
        this.packagingHeight = item.subType.H;
        this.packagingLength = item.subType.L;
        this.packagingWeight = item.weight;
      }
    });
  }

  assignPackagingValues(data) {
    this.packagingWidth = data.subType.W;
    this.packagingHeight = data.subType.H;
    this.packagingLength = data.subType.L;
    this.packagingWeight = data.weight;
  }

  emptyValue() {
    this.packagingWidth = '';
    this.packagingHeight = '';
    this.packagingLength = '';
    this.packagingWeight = '';
  }

  onFocus(value: string) {
    if (value === '') {
      this.packagingChanges.next('');
    };
  }

  updatePackaging(value: string) {
    if (value === '') {
      this.packagingControl.reset('');
      this.emptyValue();
    } else {
      
      const item = this.packagingList.find(item => item.name === value);
      if (item) {
        this.assignPackagingValuesWithName(value);
        this.inputChange.emit(value);
      } else {
        this.packagingControl.reset('');
        this.emptyValue();
      }
    };
  }

  // model --> view
  writeValue(value): void {
    if (value) {
      this.assignPackagingValues(value);
      const item = this.packagingList.find(item => item.uuid === value.uuid);
      if (item) {
        this.packagingStore = item.name;
        this.packagingControl.setValue(item.name, { emitEvent: false });
      }
    } else {
      this.packagingControl.reset('');
      this.emptyValue();
    }
  }

  // view --> model
  registerOnChange(fn: (value: string) => void) {
    const onChangeSubscription = this.packagingControl.valueChanges.subscribe(fn);
    this.subscriptions.push(onChangeSubscription);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  onTouched: () => void = () => {};

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

}
