import { createAction, props } from "@ngrx/store";

export const setOriginalFormValue = createAction(
  '[Parts List Form] Set Original Parts List Form Value',
  props<{ data: any }>()
);

export const setPartsPicture = createAction(
  '[Parts List Form] Set Parts List Picture',
  props<{ data: String }>()
);

export const setPartsType = createAction(
  '[Parts List Form] Set Parts List Type',
  props<{ data: String }>()
);

export const setPartsName = createAction(
  '[Parts List Form] Set Parts List Name',
  props<{ data: String }>()
);

export const setPartsData = createAction(
  '[Parts List Form] Set Parts Data',
  props<{ data: any }>()
);

export const initPartsData = createAction(
  '[Parts List Form] Init Parts Data',
  props<{ data: any }>()
);

export const updatePartsData = createAction(
  '[Parts List Form] Update Parts Data',
  props<{ data: any }>()
);

export const setFormValueChanged = createAction(
  '[Parts List Form] Set Parts List Form Value Changed',
  props<{ data: Boolean }>()
);
