import { Action, createReducer, on } from "@ngrx/store";
import { DataSource, ProfileFormParameters, ProfileFormStepper } from "../../../pump-simulation/coefficient.model";
import * as fromCoefficientFormActions from "./coefficient-form.actions";

export const formFeatureKey = 'form';

export interface FormState {
  parameters: ProfileFormParameters,
  stepper: ProfileFormStepper,
  dataSource: DataSource,
  isUpdateParameters: boolean
}

export const initialFormState: FormState = {
  parameters: {
    pump: '',
    controller: '',
    type: '',
    splitPower: 0,
    minLift: 0,
    maxLift: 0,
    excelBehaviour: false
  },
  stepper: {
    stepIndexNow: 0,
    stepIndexNext: 1,
    stepForwardDirection: true,
    enableStepOneBtn: true,
    showRevertBtn: false
  },
  dataSource: {
    name: '',
    uploadFileName: '',
    uploadNewData: null,
    useUploadedData: null,
    useSetName: ''
  },
  isUpdateParameters: false
};

const reducer = createReducer(
  initialFormState,
  on(fromCoefficientFormActions.setParameters, (state, action) => {
    return {
      ...state,
      parameters: {...state.parameters, ...action.parameters}
    }
  }),
  on(fromCoefficientFormActions.setStepper, (state, action) => {
    return {
      ...state,
      stepper: {...state.stepper, ...action.stepper}
    }
  }),
  on(fromCoefficientFormActions.setDataSource, (state, action) => {
    return {
      ...state,
      dataSource: {...state.dataSource, ...action.dataSource}
    }
  }),
  on(fromCoefficientFormActions.updateParameters, (state, action) => {
    return {
      ...state,
      isUpdateParameters: action.isUpdateParameters
    }
  })
);

export function formReducer(
  state: FormState,
  action: Action
): FormState {
  return reducer(state, action);
}

