import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalculateDialogComponent } from '../../pump-simulation/calculate-dialog/calculate-dialog.component';

@Component({
  selector: 'app-upload-download-dialogue',
  templateUrl: './upload-download-dialogue.component.html',
  styleUrls: ['./upload-download-dialogue.component.scss']
})
export class UploadDownloadDialogueComponent implements OnInit {

  eventForm: UntypedFormGroup;
  message = '';
  type = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CalculateDialogComponent>
  ) { }

  ngOnInit() {
    this.message = this.data.message;
    this.type = this.data.type;

    this.eventForm = this.fb.group({
      note: '',
    });
  }

  onConfirmClick() {
    this.dialogRef.close(true);
  }


}
