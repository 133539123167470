import { createAction, props } from "@ngrx/store";

export const setDataSource = createAction(
  '[Simulation Data Source] Set Simulation Data Source',
  props<{ data: String }>()
);

export const setSourceUploadFile = createAction(
  '[Simulation Data Source] Set Data Source of Upload File',
  props<{ data: any }>()
);

export const setSourceUseUploadedFile = createAction(
  '[Simulation Data Source] Set Data Source of Use Uploaded File',
  props<{ data: String }>()
);

export const setSourceCopyData = createAction(
  '[Simulation Data Source] Set Data Source of Copy Data From System',
  props<{ data: String }>()
);
