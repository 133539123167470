import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PartsListService } from '../parts-list.service';

@Component({
  selector: 'app-parts-list-add-node',
  templateUrl: './parts-list-add-node.component.html',
  styleUrls: ['./parts-list-add-node.component.scss']
})
export class PartsListAddNodeComponent implements OnInit {

  newPartsListNodeForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private database: PartsListService,
    private dialogRef: MatDialogRef<PartsListAddNodeComponent>
  ) { }

  ngOnInit() {

    this.newPartsListNodeForm = this.fb.group({
      name: ['', Validators.required],
      material: '',
      id: ['', Validators.required]
    });
  }

  onSubmit(result) {
    result.id = `${result.id}`;
    this.database.addPartsListNode(result);
    this.dialogRef.close(result);
  }

}
