import { Action, createReducer, on } from "@ngrx/store";
import * as PartsListActions from "./parts-list.actions";

export const partsListFeatureKey = 'partsList';

export interface PartsListState {
  initFormData: {
    name: String;
    picture: String;
  };
  partsName: String;
  partsPicture: String;
  partsData: any;
  initPartsData: any;
  updatePartsData: any;
  type: String;
  isFormValueChanged: Boolean;
}

export const initialState: PartsListState = {
  initFormData: {
    name: '',
    picture: ''
  },
  partsName: '',
  partsPicture: '',
  partsData: {},
  initPartsData: {},
  updatePartsData: {},
  type: '',
  isFormValueChanged: false
}

const reducer = createReducer(
  initialState,
  on(PartsListActions.setOriginalFormValue, (state, action) => {
    return {
      ...state,
      initFormData: action.data
    }
  }),
  on(PartsListActions.setPartsType, (state, action) => {
    return {
      ...state,
      type: action.data
    }
  }),
  on(PartsListActions.setPartsName, (state, action) => {
    return {
      ...state,
      partsName: action.data,
      isFormValueChanged: JSON.stringify(state.initFormData) !== JSON.stringify({
        name: action.data,
        picture: state.initFormData.picture
      })
    }
  }),
  on(PartsListActions.setPartsPicture, (state, action) => {
    return {
      ...state,
      partsPicture: action.data,
      isFormValueChanged: JSON.stringify(state.initFormData) !== JSON.stringify({
        name: state.initFormData.name,
        picture: action.data
      })
    }
  }),
  on(PartsListActions.setPartsData, (state, action) => {
    return {
      ...state,
      partsData: action.data
    }
  }),
  on(PartsListActions.initPartsData, (state, action) => {
    return {
      ...state,
      initPartsData: action.data
    }
  }),
  on(PartsListActions.updatePartsData, (state, action) => {
    return {
      ...state,
      updatePartsData: action.data
    }
  }),
  on(PartsListActions.setFormValueChanged, (state, action) => {
    return {
      ...state,
      isFormValueChanged: action.data
    }
  })
);

export function partsListReducer(
  state: PartsListState,
  action: Action
): PartsListState {
  return reducer(state, action);
}

