import { Action, createReducer, on } from "@ngrx/store";
import * as ControllerActions from "./controller.actions";

export const controllerFeatureKey = 'controller';

export interface ControllerState {
  controller: String;
  motor: String;
  pumpEnd: String;
  partsList: String;
}

export const initialState: ControllerState = {
  controller: '',
  motor: '',
  pumpEnd: '',
  partsList: ''
}

const reducer = createReducer(
  initialState,
  on(ControllerActions.setControlllerName, (state, action) => {
    return {
      ...state,
      controller: action.data
    }
  }),
  on(ControllerActions.setPumpEndName, (state, action) => {
    return {
      ...state,
      pumpEnd: action.data
    }
  }),
  on(ControllerActions.setMotorName, (state, action) => {
    return {
      ...state,
      motor: action.data
    }
  }),
  on(ControllerActions.setPartsListName, (state, action) => {
    return {
      ...state,
      partsList: action.data
    }
  })
);

export function controllerReducer(
  state: ControllerState,
  action: Action
): ControllerState {
  return reducer(state, action);
}
