import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject, Subscription, tap } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CalculationDetail, CoefficientSetItemDetail, COEFFICIENT_SET_LOWERCASE, SelectedCalculationDetail } from 'src/app/display-product-data/pump-data.model';
import { setCalculationValue, setCoefficientSetValueName, setPumpSystemCopied } from 'src/app/display-product-data/store/coefficient-set/coefficient-set.actions';
import { CoefficientSetState, emptyCalculationValue } from 'src/app/display-product-data/store/coefficient-set/coefficient-set.reducer';
import { selectCoefficientSet } from 'src/app/display-product-data/store/coefficient-set/coefficient-set.selector';
import { setPumpSytemCoefficientSetName } from '../../../display-product-data/store/pump-system/pump-system.actions';
import { DisplayPumpSimulationService } from '../../../pump-simulation/display-pump-simulation.service';
import { filenameSort } from '../../util';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

const IMPORTED_SET = 'imported set';
const CURRENT_SET = 'current set: ';
const IMPORTED_SET_AND_VERSION = 'imported set - 1';
@Component({
  selector: 'app-coefficient-set-select',
  templateUrl: './coefficient-set-select.component.html',
  styleUrls: ['./coefficient-set-select.component.scss']
})
export class CoefficientSetSelectComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  coefficientSetForm: UntypedFormGroup;
  showCoefficientSetForm = false;
  coefficientSetName: String = 'Please select coefficient set';

  currentCoefficientData = {};
  matcher = new MyErrorStateMatcher();
  coefficientSetList = [];
  coefficientSetDropdownList: string[] = [];
  coefficientSetControl = new UntypedFormControl('', [
    this.coefficientSetOptionNotFound.bind(this),
  ]);
  coefficientSetFilteredOptions: Set<string>;
  public readonly coefficientSetChanges: Subject<string> = new Subject<string>();
  public coefficientSetSuggestions: Observable<string[]>;

  constructor(
    private store: Store,
    private fb: UntypedFormBuilder,
    private simulationService: DisplayPumpSimulationService,
  ) { }

  ngOnInit() {

    const initData = this.simulationService.getAllLiveMeasurementFileVersions().pipe(
      tap(data => {
        data.map(async detail => {
          if (!this.isEmpty(detail.selected_coefficients)) { detail.coefficients = await this.transformSelectedCoefficientsIntoCoefficients(detail) }
        })
      }),
    ).subscribe((data: CoefficientSetItemDetail[]) => {
      this.coefficientSetList = filenameSort([...data]).filter(item => item.version !== 0);

      this.coefficientSetDropdownList = this.coefficientSetList.map(item => `${item.displayName} - ${item.version}`);
      this.coefficientSetSuggestions = this.coefficientSetChanges.pipe(
        startWith(''),
        map((val: string) => this.coefficientSetFilterResults(val))
      );
    });
    this.subscriptions.push(initData);

    const getCalculation = combineLatest([
      this.store.pipe(
        select(selectCoefficientSet),
        map((data: CoefficientSetState) => data.calculation),
      ),
      this.store.pipe(
        select(selectCoefficientSet),
        map((data: CoefficientSetState) => data.isCopied),
      ),
      this.store.pipe(
        select(selectCoefficientSet),
        map((data: CoefficientSetState) => data.name)
      )
    ]).subscribe(([data, isCopied, displayName]) => {
      if (isCopied) {
        this.currentCoefficientData = data;
        this.coefficientSetName = (displayName && displayName !== 'undefined') && (displayName !== IMPORTED_SET_AND_VERSION) ? CURRENT_SET + displayName : CURRENT_SET + IMPORTED_SET_AND_VERSION;
        this.coefficientSetControl.setValue(displayName && displayName !== 'undefined' ? displayName : IMPORTED_SET_AND_VERSION);
        this.showCoefficientSetForm = true;
        const currentData = {};
        COEFFICIENT_SET_LOWERCASE.forEach(name => {
          let value = this.currentCoefficientData[name];
          if (!this.isEmpty(value) && !this.isEmpty(displayName) && displayName !== IMPORTED_SET) {
            value = value['value'];
          }
          currentData[name] = [{ value, disabled: true }, Validators.required];
        });
        this.coefficientSetForm = this.fb.group(currentData);

        if ((!displayName || displayName === 'undefined' || displayName === IMPORTED_SET_AND_VERSION) && !this.coefficientSetDropdownList.includes(IMPORTED_SET_AND_VERSION)) {
          this.coefficientSetDropdownList.push(IMPORTED_SET_AND_VERSION);
          this.coefficientSetList.push({ filename: IMPORTED_SET, displayName: IMPORTED_SET, version: 1, approval_state: 'LIVE', coefficients: JSON.stringify(this.currentCoefficientData) });
        }
      }
    });
    this.subscriptions.push(getCalculation);
  }

  private async transformSelectedCoefficientsIntoCoefficients(selectedCoefficientsString: any) {
    const selectedCoefficients: SelectedCalculationDetail = await selectedCoefficientsString.selected_coefficients;

    try {
      const coefficient = await this.createCoefficient(selectedCoefficients, selectedCoefficientsString);
      return coefficient;
    } catch (error) {
      console.error(error);
    }
  }

  async createCoefficient(selectedCoefficients: any, selectedCoefficientsString: any) {

    if (selectedCoefficients.corr_fact) {
      const coefficient: CalculationDetail = {
        corr_fact: selectedCoefficients.corr_fact.value,
        filename: selectedCoefficientsString.filename,
        displayName: selectedCoefficients.displayName,
        mai: selectedCoefficients.mai.value,
        maq: selectedCoefficients.maq.value,
        mar_: selectedCoefficients.mar_.value,
        mbi: selectedCoefficients.mbi.value,
        mbq: selectedCoefficients.mbq.value,
        mbr: selectedCoefficients.mbr.value,
        mci: selectedCoefficients.mci.value,
        mcq: selectedCoefficients.mcq.value,
        mcr: selectedCoefficients.mcr.value,
        mdq_: selectedCoefficients.mdq_.value,
        nai: selectedCoefficients.nai.value,
        naq: selectedCoefficients.naq.value,
        nar_: selectedCoefficients.nar_.value,
        nbi: selectedCoefficients.nbi.value,
        nbq: selectedCoefficients.nbq.value,
        nbr: selectedCoefficients.nbr.value,
        nci: selectedCoefficients.nci.value,
        ncq: selectedCoefficients.ncq.value,
        ncr: selectedCoefficients.ncr.value,
        ndq_: selectedCoefficients.ndq_.value,
        npsh_a: selectedCoefficients.npsh_a.value,
        npsh_c: selectedCoefficients.npsh_c.value,
        npsh_exp: selectedCoefficients.npsh_exp.value,
        split_fact: selectedCoefficientsString.split_fact
      }
      return JSON.stringify(coefficient);
    }
  }

  private isEmpty(obj: Object): boolean {
    return !obj || !Object.keys(obj).some(x => obj[x] !== void 0);
  }

  private coefficientSetFilterResults(val: string) {
    if (val !== '') {
      return this.coefficientSetDropdownList.filter(item => item.includes(val));
    } else {
      return this.coefficientSetDropdownList;
    }
  }

  coefficientSetOptionNotFound(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value) {
      this.coefficientSetFilteredOptions = new Set(
        this.coefficientSetDropdownList.filter((option) => option.toLowerCase().indexOf(value.toLowerCase()) > -1)
      );
    }
    if (value && !this.coefficientSetFilteredOptions.size) {
      return {
        noOption: true
      };
    }
    return null;
  }

  setCoefficientSetChangeAction(event: MatAutocompleteSelectedEvent) {
    const coefficientSetSelected = this.coefficientSetList.find(item =>
      `${item.displayName} - ${item.version}` === event.option.value
    );
    const displayName = coefficientSetSelected.displayName + ' - ' +coefficientSetSelected.version;
    const coefficientSetData = (this.isEmpty(displayName) || displayName === IMPORTED_SET_AND_VERSION) ? JSON.parse(coefficientSetSelected.coefficients) : JSON.parse(coefficientSetSelected.selected_coefficients);
    this.showCoefficientSetForm = true;
    const data = {};
    COEFFICIENT_SET_LOWERCASE.forEach(name => {
      let value = coefficientSetData[name];
      if (!this.isEmpty(value) && !this.isEmpty(displayName) && displayName !== IMPORTED_SET_AND_VERSION) {
        value = value['value'];
      }

      data[name] = [{ value, disabled: true }, Validators.required];
    });
    const calculationData: any = {};
    COEFFICIENT_SET_LOWERCASE.forEach(name => {
      calculationData[name] = coefficientSetData[name];
    });
    this.store.dispatch(setPumpSytemCoefficientSetName({ data: coefficientSetSelected.filename }));
    this.store.dispatch(setCoefficientSetValueName({ data: displayName }));
    this.store.dispatch(setCalculationValue({ data: emptyCalculationValue }));
    this.store.dispatch(setCalculationValue({ data: calculationData }));
    this.coefficientSetForm = this.fb.group(data);
  }

  onCoefficientSetInputFocus(value: string) {
    this.store.dispatch(setPumpSystemCopied({ data: false }));
    if (value === '') {
      this.coefficientSetChanges.next('');
    };
    this.coefficientSetOptionNotFound(this.coefficientSetControl);
  }

  updateCoefficientSet(value: string) {
    if (value === '') {
      this.coefficientSetControl.reset('');
      this.showCoefficientSetForm = false;
    } else {
      const item = this.coefficientSetList.find(item => `${item.displayName} - ${item.version}` === value);
      if (item) {
        this.coefficientSetControl.setValue(`${item.displayName} - ${item.version}`);
      } else {
        this.coefficientSetControl.reset('');
        this.showCoefficientSetForm = false;
      }
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.coefficientSetControl.reset('');
    this.coefficientSetName = '';
    this.showCoefficientSetForm = false;
    this.coefficientSetDropdownList = [];
    this.coefficientSetList = [];
  }

}
