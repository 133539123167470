<div class="form-element">

  <h2>Accessory Data upload</h2>

  <div class="upload-wrapper">
    <button mat-raised-button color="primary"><mat-icon>add</mat-icon> Upload accessory data
    <input
      type="file"
      name="profile1"
      (change)="onFileSelectAccessory($event)"
      accept=".csv,.json,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    ></button>
  </div>
  <div class="upload-info" *ngIf="showUploadFileName">
    <span> {{ uploadFileName }}  </span>
  </div>
</div>

<form [formGroup]="profileForm" class="form" (ngSubmit)="onPublishAllLastVersions()">
  <ng-template matStepLabel>System components</ng-template>

  <div class="form-element">
    <button mat-raised-button color="primary" type="submit">Publish Accessory Changes</button>
  </div>
</form>
