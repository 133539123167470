import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { delay } from 'rxjs/operators';
import { v4 } from 'uuid';
import { DisplayProductDataService } from '../display-product-data.service';

@Component({
  selector: 'app-picture-create',
  templateUrl: './picture-create.component.html',
  styleUrls: ['./picture-create.component.scss']
})
export class PictureCreateComponent {

  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  files: any[] = [];
  uploadData: any = {};

  constructor(
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<PictureCreateComponent>,
    private displayProductData: DisplayProductDataService
  ) { }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  useFile(index: number) {
    const picture: any = {};
    picture.uuid = this.uploadData.uuid;
    picture.name = this.uploadData.name;
    this.dialogRef.close(picture);
  }

  uploadFile(index: number) {
    this.files[index].showLoading = true;
    this.uploadData.uuid = v4();

    this.uploadData.name = this.files[index].name.split('.')[0];
    const dataType = this.files[index].name.split('.')[1];

    this.uploadData.type = dataType;
    this.uploadData.imageType = dataType === 'jpg'? 'jpeg': dataType;

    this.uploadData.file = this.files[index];
    this.displayProductData.createNewPicture(this.uploadData).subscribe(item => {
      this.snackBar
      .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
      .afterDismissed()
      .pipe(delay(0))
      .subscribe(() => {
        this.files[index].showBtn = false;
        this.files[index].showLoading = false;
      });
    });
  }
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(allFiles: Array<any>) {
    for (const item of allFiles) {
      item.progress = 0;
      const reader = new FileReader();
      reader.readAsDataURL(item); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        item.url = event.target.result;
      };
      item.showBtn = true;
      item.showLoading = false;
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = '';
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
