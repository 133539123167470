import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, FormGroupDirective, NgForm, UntypedFormGroup, Validators, AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { select, Store } from '@ngrx/store';
import { Subscription, Subject, Observable, startWith, map, combineLatest } from 'rxjs';
import { DisplayProductDataService } from 'src/app/display-product-data/display-product-data.service';
import { PUMPENDS_ITEMS } from 'src/app/display-product-data/pump-data.model';
import { PumpEndsItem } from 'src/app/display-product-data/pump-ends/pump-end.model';
import { setPumpEndName } from 'src/app/display-product-data/store/controller/controller.actions';
import { setPumpEndSelect } from 'src/app/display-product-data/store/pump-system/pump-system.actions';
import { PumpSystemState } from 'src/app/display-product-data/store/pump-system/pump-system.reducer';
import { selectPumpSystem } from 'src/app/display-product-data/store/pump-system/pump-system.seletor';
import { nameSort } from '../../util';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-pump-end-select',
  templateUrl: './pump-end-select.component.html',
  styleUrls: ['./pump-end-select.component.scss']
})
export class PumpEndSelectComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  matcher = new MyErrorStateMatcher();
  pumpendForm: UntypedFormGroup;
  pumpend: Observable<PumpEndsItem>;
  showPumpendsForm = false;
  pumpEndsName = 'Please select pump end';
  pumpendList: PumpEndsItem[] = [];
  pumppartsList = [];
  pumpendFilteredOptions: Set<string>;
  pumpendDropdownList: string[] = [];
  pumpendControl = new UntypedFormControl('', [
    Validators.required,
    this.pumpendOptionNotFound.bind(this),
  ]);

  public readonly pumpendChanges: Subject<string> = new Subject<string>();
  public pumpendSuggestions: Observable<string[]>;

  constructor(
    private store: Store,
    private fb: UntypedFormBuilder,
    private displayProductData: DisplayProductDataService,
  ) { }

  ngOnInit() {

    const pumpendUuid = combineLatest([
      this.displayProductData.getPumpEndsList(),
      this.store.pipe(
        select(selectPumpSystem),
        map((data: PumpSystemState) => data.pumpEndUuid)
      )
    ]).subscribe(([data, pumpEndUuid]) => {

      this.pumpendList = nameSort(data);
      this.pumpendDropdownList = this.pumpendList.map(item => item.name);

      this.pumpendSuggestions = this.pumpendChanges.pipe(
        startWith(''),
        map((val: string) => this.pumpendFilterResults(val))
      );

      if (pumpEndUuid !== '') {
        const pumpend = this.pumpendList.find(item => item.uuid === pumpEndUuid);
        if (pumpend) {
          this.pumpEndsName = pumpend.name;
          this.pumpendControl.setValue(pumpend.name);
          this.store.dispatch(setPumpEndName({ data: pumpend.name }));
        }
        const getPumpEndDetail = this.displayProductData.getPumpEndItem(`${pumpEndUuid}`).subscribe(pumpendData => {
          this.showPumpendsForm = true;
          const pumpendFormValue = {};
          PUMPENDS_ITEMS.forEach(name => {
            const value = pumpendData[`${name}`];
            pumpendFormValue[`${name}`] = [{ value, disabled: true }, Validators.required];
          });
          if (pumpendData.parts) {
            pumpendFormValue['parts'] = [{ value: pumpendData.parts.name, disabled: true }, Validators.required];
          } else {
            pumpendFormValue['parts'] = [{ value: '', disabled: true }, Validators.required];
          }
          this.pumpendForm = this.fb.group(pumpendFormValue);
        });
        this.subscriptions.push(getPumpEndDetail);
        this.pumpend = this.displayProductData.getPumpEndItem(`${pumpEndUuid}`);
      }
    });
    this.subscriptions.push(pumpendUuid);

  }

  private pumpendFilterResults(val: string) {
    if (val !== '') {
      return this.pumpendDropdownList.filter(item => item.toLowerCase().includes(val.toLowerCase()));
    } else {
      return this.pumpendDropdownList;
    }
  }

  pumpendOptionNotFound(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value) {
      this.pumpendFilteredOptions = new Set(
        this.pumpendDropdownList.filter((option) => option.toLowerCase() === value.toLowerCase())
      );
    }
    if (value && !this.pumpendFilteredOptions.size) {
      return {
        noOption: true
      };
    }
    return null;
  }

  onPumpendInputFocus() {
    this.pumpendChanges.next('');
  }

  updatePumpend(value: string) {
    if (value === '') {
      this.pumpendOptionNotFound(this.pumpendControl);
      this.pumpEndsName = '';
      this.showPumpendsForm = false;
    } else {
      const item = this.pumpendList.find(item => item.name === value);
      if (item) {
        this.pumpendControl.setValue(value);
      } else {
        this.pumpendOptionNotFound(this.pumpendControl);
        this.pumpEndsName = '';
        this.showPumpendsForm = false;
      }
    };
  }

  setPumpendChangeAction(event: MatAutocompleteSelectedEvent) {
    this.pumpEndsName = event.option.value;
    this.showPumpendsForm = true;
    const pumpend = this.pumpendList.find(item => item.name === event.option.value);
    const getPumpEndDetail = this.displayProductData.getPumpEndItem(pumpend.uuid).subscribe(pumpendData => {
      const pumpendFormValue = {};
      PUMPENDS_ITEMS.forEach(name => {
        const value = pumpendData[`${name}`];
        pumpendFormValue[`${name}`] = [{ value, disabled: true }, Validators.required];
      });
      if (pumpendData.parts) {
        pumpendFormValue['parts'] = [{ value: pumpendData.parts.name, disabled: true }, Validators.required];
      } else {
        pumpendFormValue['parts'] = [{ value: '', disabled: true }, Validators.required];
      }
      this.pumpendForm = this.fb.group(pumpendFormValue);
    });
    this.subscriptions.push(getPumpEndDetail);
    this.store.dispatch(setPumpEndName({ data: event.option.value }));
    this.store.dispatch(setPumpEndSelect({ data: pumpend.uuid }));
    this.pumpend = this.displayProductData.getPumpEndItem(pumpend.uuid);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

}
