import { createAction, props } from "@ngrx/store";

export const setPumpUnitItem = createAction(
  '[Pump Unit Form] Set Pump Unit Item',
  props<{ data: String }>()
);

export const setPumpUnitPackaging = createAction(
  '[Pump Unit Form] Set Pump Unit Packaging',
  props<{ data: String }>()
);

export const setPumpUnitMaxTemp = createAction(
  '[Pump Unit Form] Set Pump Unit Max Temperature',
  props<{ data: String }>()
);

export const setPumpUnitSuctionHead = createAction(
  '[Pump Unit Form] Set Pump Unit Suction Head',
  props<{ data: String }>()
);

export const setPumpUnitPicture = createAction(
  '[Pump Unit Form] Set Pump Unit Picture',
  props<{ data: String }>()
);
