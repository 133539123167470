import { AfterViewInit, OnDestroy } from '@angular/core';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { map, Observable, Subscription } from 'rxjs';
import { PackagingItem } from '../controllers/controller.model';
import { PackagingCreateComponent } from '../packaging-create/packaging-create.component';
import { PackagingState } from '../store/packaging/packaging.reducer';
import { selectPackaging } from '../store/packaging/packaging.selector';

@Component({
  selector: 'app-packaging-overview',
  templateUrl: './packaging-overview.component.html',
  styleUrls: ['./packaging-overview.component.scss']
})
export class PackagingOverviewComponent implements OnInit, AfterViewInit, OnDestroy {

  tableLoading$: Observable<Boolean>;
  tableData: PackagingItem[] = [];

  getPackagingList: Subscription;

  displayedColumns: string[] = ['name', 'itemH', 'itemW', 'itemL', 'weight'];
  @Input() dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private store: Store,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getPackagingList = this.store.pipe(
      select(selectPackaging),
      map((data: PackagingState) => data.data)
    ).subscribe((data: PackagingItem[]) => {
      this.tableData = [...data].sort((a, b) => Number(a.subType.L) - Number(b.subType.L));
      this.dataSource.data = this.tableData;
    });

    this.tableLoading$ = this.store.pipe(
      select(selectPackaging),
      map((data: PackagingState) => data.loading)
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.firstPage());
  }

  createNewPackaging() {
    const dialogRef = this.dialog.open(PackagingCreateComponent, {
      width: '1000px',
      maxWidth: '96vw',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tableData.unshift(result);
        this.dataSource.data = this.tableData;
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnDestroy() {
    if (this.getPackagingList) {
      this.getPackagingList.unsubscribe();
    }
  }
}
