import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDividerModule } from '@angular/material/divider';
import { MatTreeModule } from '@angular/material/tree';
import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PumpSystemTypeComponent } from './components/pump-system-type/pump-system-type.component';
import { ControllerSelectComponent } from './components/controller-select/controller-select.component';
import { MotorSelectComponent } from './components/motor-select/motor-select.component';
import { CoefficientSetSelectComponent } from './components/coefficient-set-select/coefficient-set-select.component';
import { UploadFileSelectComponent } from './components/upload-file-select/upload-file-select.component';
import { AccessorySelectComponent } from './components/accessory-select/accessory-select.component';
import { PumpUnitSelectComponent } from './components/pump-unit-select/pump-unit-select.component';
import { ConnectedProductsSelectComponent } from './components/connected-products-select/connected-products-select.component';
import { PackagingSelectComponent } from '../display-product-data/packaging-select/packaging-select.component';
import { InsulationClassSelectComponent } from './components/insulation-class-select/insulation-class-select.component';
import { PackagingDisabledComponent } from '../display-product-data/packaging-disabled/packaging-disabled.component';
import { PumpConnectedProductComponent } from '../display-product-data/pump-connected-product/pump-connected-product.component';
import { PumpAccessoryComponent } from '../display-product-data/pump-accessory/pump-accessory.component';
import { PumpEndSelectComponent } from './components/pump-end-select/pump-end-select.component';
import { PackagingStoreComponent } from '../display-product-data/packaging-store/packaging-store.component';
import { PictureStoreComponent } from '../display-product-data/picture-store/picture-store.component';
import { PartsListNameComponent } from './components/parts-list-name/parts-list-name.component';
import { PartsListPictureComponent } from './components/parts-list-picture/parts-list-picture.component';
import { PartsListTreeComponent } from './components/parts-list-tree/parts-list-tree.component';
import { CoefficientControllerSelectComponent } from './components/coefficient-controller-select/coefficient-controller-select.component';
import { CoefficientPumpEndSelectComponent } from './components/coefficient-pump-end-select/coefficient-pump-end-select.component';
import { SimulationControllerComponent } from './components/simulation-controller/simulation-controller.component';
import { SimulationPumpEndComponent } from './components/simulation-pump-end/simulation-pump-end.component';

const materialComponents = [
  MatCardModule,
  MatFormFieldModule,
  MatProgressSpinnerModule,
  MatInputModule,
  MatProgressBarModule,
  MatIconModule,
  MatToolbarModule,
  MatButtonModule,
  MatTooltipModule,
  MatExpansionModule,
  MatSlideToggleModule,
  MatAutocompleteModule,
  MatListModule,
  MatCheckboxModule,
  MatSelectModule,
  MatSnackBarModule,
  MatDialogModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatDividerModule,
  MatTreeModule,
  MatMenuModule,
  MatStepperModule,
  MatRadioModule,
  MatSidenavModule,
  MatBadgeModule,
  MatSliderModule,
  MatTabsModule,
  MatBottomSheetModule
];

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    materialComponents
  ],
  exports: [
    materialComponents,
    PumpSystemTypeComponent,
    ControllerSelectComponent,
    MotorSelectComponent,
    CoefficientSetSelectComponent,
    UploadFileSelectComponent,
    AccessorySelectComponent,
    PumpUnitSelectComponent,
    ConnectedProductsSelectComponent,
    PackagingSelectComponent,
    InsulationClassSelectComponent,
    PackagingDisabledComponent,
    PumpConnectedProductComponent,
    PumpAccessoryComponent,
    PumpEndSelectComponent,
    PackagingStoreComponent,
    PictureStoreComponent,
    PartsListNameComponent,
    PartsListPictureComponent,
    PartsListTreeComponent,
    CoefficientControllerSelectComponent,
    CoefficientPumpEndSelectComponent,
    SimulationControllerComponent,
    SimulationPumpEndComponent
  ],
  declarations: [
    PumpSystemTypeComponent,
    ControllerSelectComponent,
    MotorSelectComponent,
    CoefficientSetSelectComponent,
    UploadFileSelectComponent,
    AccessorySelectComponent,
    PumpUnitSelectComponent,
    ConnectedProductsSelectComponent,
    PackagingSelectComponent,
    InsulationClassSelectComponent,
    PackagingDisabledComponent,
    PumpConnectedProductComponent,
    PumpAccessoryComponent,
    PumpEndSelectComponent,
    PackagingStoreComponent,
    PictureStoreComponent,
    PartsListNameComponent,
    PartsListPictureComponent,
    PartsListTreeComponent,
    CoefficientControllerSelectComponent,
    CoefficientPumpEndSelectComponent,
    SimulationControllerComponent,
    SimulationPumpEndComponent
  ]
})
export class SharedModule { }
