import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { now } from 'moment-timezone';
import { combineLatest, first, map, Subscription } from 'rxjs';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { PumpSimulationService } from '../pump-simulation.service';
import { ShareDataService } from '../share-data.service';
import { setSelectedCoefficientsData } from '../store/calculate-coefficient/calculate-coefficient.actions';
import { CalculateCoefficientState } from '../store/calculate-coefficient/calculate-coefficient.reducer';
import { selectCalculateCoefficient } from '../store/calculate-coefficient/calculate-coefficient.selector';
import { setChartSettingsValue, setValueChangedItem } from '../store/charts-setting/charts-setting.actions';
import { ChartsSettingState } from '../store/charts-setting/charts-setting.reducer';
import { selectChartsSetting } from '../store/charts-setting/charts-setting.selector';
import { SimulationSettingState } from '../store/simulation-setting/simulation-setting.reducer';
import { selectSimulationSetting } from '../store/simulation-setting/simulation-setting.selector';
import { SystemFormState } from '../store/system-form/system-form.reducer';
import { selectSystemForm } from '../store/system-form/system-form.selector';
import { SelectedCoefficientResultRow } from '../../pump-simulation/coefficient.model';

@Component({
  selector: 'app-charts-setting',
  templateUrl: './charts-setting.component.html',
  styleUrls: ['./charts-setting.component.scss']
})
export class ChartsSettingComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  userLocalStorage = new UserLocalStorage();
  data = [
    { id: "corr_fact", title: "Correction Factor", class: "special", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "maq", title: "maq", class: "left", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "mcq", title: "mcq", class: "right", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "naq", title: "naq", class: "left", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "ncq", title: "ncq", class: "right", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "mbq", title: "mbq", class: "left", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "mdq_", title: "mdq_", class: "right", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "nbq", title: "nbq", class: "left", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "ndq_", title: "ndq_", class: "right", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "mbi", title: "mbi", class: "left", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "mci", title: "mci", class: "right", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "nbi", title: "nbi", class: "left", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "nci", title: "nci", class: "right", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "mar_", title: "mar_", class: "left", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "mcr", title: "mcr", class: "right", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "nar_", title: "nar_", class: "left", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "ncr", title: "ncr", class: "right", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "mbr", title: "mbr", class: "left", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "mai", title: "mai", class: "right", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "nbr", title: "nbr", class: "left", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "nai", title: "nai", class: "right", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "npsh_a", title: "npsh_a", class: "special", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "npsh_exp", title: "npsh_exp", class: "special", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
    { id: "npsh_c", title: "npsh_c", class: "special", radios: [{ name: 'aut.', checked: true }, { name: 'man.', checked: false }], values: [{ value: 0, disabled: true }, { value: 0, disabled: true }] },
  ];

  constructor(
    private store: Store,
    private router: Router,
    private shareService: ShareDataService,
    private pumpSimulationService: PumpSimulationService,
  ) { }

  ngOnInit() {

    this.getData();

    const checkSwitch = combineLatest([
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data.selectedCoefficientsData),
        first()
      ),
    this.shareService.getAllRadio()]).subscribe(([calculatateCoefficient, data]) => {

      if (data !== '') {
        const auto = (data === 'auto')
        const loadData = [];
        const difference = [];
        for (let i = 0; i < this.data.length; i++) {
          const item = { ...this.data[i] };
          item.radios.forEach(subItem => {
            subItem.name === 'aut.'? subItem.checked = auto : subItem.checked = !auto});

            if (item.radios[1].checked) {
            item.values[1].disabled = false;
          } else {
            item.values[1].disabled = true;
          }
          if (item.values[0].value !== item.values[1].value) {
            difference.push(item.id);
          }
          loadData.push(item);
        }
        this.data = loadData;
        this.shareService.setChartItem([]);
        if (difference.length !== 0) {
          this.store.dispatch(setValueChangedItem({ data: difference }));
          this.store.dispatch(setChartSettingsValue({ data: this.handleSaveValue() }));
          this.setSelectedCoefficientData(calculatateCoefficient, loadData);
        }
      }

    });
    this.subscriptions.push(checkSwitch);

    const saveData = combineLatest([
      this.store.pipe(
        select(selectChartsSetting),
        map((data: ChartsSettingState) => data.process),
        first()
      ),
      this.shareService.getSaveNewData()
    ]).subscribe(([process, data]) => {
      if (process === 'create' && data.isSaveNewData) {
        this.onCreateSave(data);
      }

      if (process === 'load' && data.isSaveNewData) {
        this.onLoadSave(data);
      }
    });
    this.subscriptions.push(saveData);
  }

  onLoadSave(data: any) {
    const initData = combineLatest([
      this.shareService.getConvertMeasurementData(),
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data)
      ),
      this.store.pipe(
        select(selectSimulationSetting),
        map((data: SimulationSettingState) => data)
      ),
      this.store.pipe(
        select(selectSystemForm),
        map((data: SystemFormState) => data)
      )
    ]).pipe(first()).subscribe(([measurements, coefficientData, simulationData, formData]) => {
      const user = this.userLocalStorage.loadUser();
      const handleName = formData.loadName.replace(/ /g, "_");
      const parameter = {
        filename: coefficientData.filename,
        version: formData.versionNumber,
        username: user.user.username,
        notes: data.saveNotes,
        changes: JSON.stringify(measurements),
        inputParams: JSON.stringify({
          pump: handleName,
          controller: formData.controller,
          type: formData.type,
          splitPower: simulationData.splitPower,
          minLift: simulationData.minLift,
          maxLift: simulationData.maxLift,
          excelBehaviour: simulationData.excelBehaviour,
        }),
        coefficients: JSON.stringify(coefficientData.allCoefficientsData),
        selected_coefficients: this.handleSaveData(coefficientData),
        inter_coefficients: coefficientData.allCoefficientsData.intermediateCoefficentsJSON,
        npsh_data: JSON.stringify(coefficientData.convertNPSHData),
        displayName: handleName,
        status: 'PROVISIONAL',
        approver: user.user.username,
        timestamp: now()
      };
      this.onSaveData(parameter);

    });
    this.subscriptions.push(initData);
  }

  onCreateSave(data: any) {
    const initData = combineLatest([
      this.shareService.getConvertMeasurementData(),
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data)
      ),
      this.store.pipe(
        select(selectSimulationSetting),
        map((data: SimulationSettingState) => data)
      ),
      this.store.pipe(
        select(selectSystemForm),
        map((data: SystemFormState) => data)
      )
    ]).pipe(first()).subscribe(([measurements, coefficientData, simulationData, formData]) => {
      const user = this.userLocalStorage.loadUser();
      const handleName = formData.pumpName.replace(/ /g, "_");
      const parameter = {
        filename: coefficientData.filename,
        version: 0,
        username: user.user.username,
        notes: data.saveNotes,
        changes: JSON.stringify(measurements),
        inputParams: JSON.stringify({
          pump: handleName,
          controller: formData.controller,
          type: formData.type,
          splitPower: simulationData.splitPower,
          minLift: simulationData.minLift,
          maxLift: simulationData.maxLift,
          excelBehaviour: simulationData.excelBehaviour,
        }),
        coefficients: JSON.stringify(coefficientData.allCoefficientsData),
        selected_coefficients: this.handleSaveData(coefficientData),
        displayName: handleName,
        inter_coefficients: coefficientData.allCoefficientsData.intermediateCoefficentsJSON,
        npsh_data: JSON.stringify(coefficientData.convertNPSHData),
        status: 'PROVISIONAL',
        approver: user.user.username,
        timestamp: now()
      };
      this.onSaveData(parameter);
    });
    this.subscriptions.push(initData);
  }

  handleSaveData(data) {
    let sendData = {};
    const values = this.handleSaveValue();
    for (let i = 0; i < this.data.length; i++) {
      sendData[`${this.data[i].id}`] = {
        value: values[`${this.data[i].id}`],
        man: this.data[i].radios[1].checked
      }
    }
    sendData['intermediateCoefficentsJSON'] = data.allCoefficientsData.intermediateCoefficentsJSON;
    return JSON.stringify(sendData);
  }

  handleSaveValue() {
    let newData = {};
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].radios[0].checked) {
        newData[`${this.data[i].id}`] = this.data[i].values[0].value;
      } else {
        newData[`${this.data[i].id}`] = this.data[i].values[1].value;
      }
    }
    return newData;
  }

  onSaveData(data: any) {
    const fileName = data.filename;
    const version = this.pumpSimulationService.createVersion(data).subscribe(() => {
      this.router.navigate(['/simulations/simulation', fileName]).then(() => {
        window.location.reload();
      });
    });
    this.subscriptions.push(version);
  };

  getData() {
    const data = combineLatest([
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data.allCoefficientsData)
      ), this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data.selectedCoefficientsData),
      )]).subscribe(([coefficientresult, selectedCoefficients]) => {

        const loadData = [];
        if (coefficientresult && selectedCoefficients && selectedCoefficients !== undefined) {
          for (let i = 0; i < this.data.length; i++) {
            const item = { ...this.data[i] };
            const fieldName = this.data[i].id;
            item.radios = [{ name: 'aut.', checked: !selectedCoefficients[fieldName].man }, { name: 'man.', checked: selectedCoefficients[fieldName].man }];
            item.values = [{ value: coefficientresult[fieldName], disabled: true }, { value: selectedCoefficients[fieldName].value, disabled: !selectedCoefficients[fieldName].man }];

            loadData.push(item);
          }
          this.data = loadData;
        }
      });
    this.subscriptions.push(data);
  }

  radioChange(event: MatRadioChange, item) {
    this.store.pipe(
      select(selectCalculateCoefficient),
      map((data: CalculateCoefficientState) => data.selectedCoefficientsData),
      first()

    ).subscribe(selectedCoefficient => {
      if (event.value === 'man.') {
        this.data.forEach(i => {
          if (i.id === item.id) {
            i.values[1].disabled = false;
          }
        });
      } else {
        this.data.forEach(i => {
          if (i.id === item.id) {
            i.values[1].disabled = true;
          }
        });
      }
      const loadData = [];
      for (let i = 0; i < this.data.length; i++) {
        const o = { ...this.data[i] };
        if (this.data[i].id === item.id) {
          if (event.value === 'man.') {
            o.radios[0].checked = false;
            o.radios[1].checked = true;
          } else {
            o.radios[0].checked = true;
            o.radios[1].checked = false;
          }
          if (o.values[0].value !== o.values[1].value) {
            this.store.dispatch(setValueChangedItem({ data: [`${o.id}`] }));
            this.store.dispatch(setChartSettingsValue({ data: this.handleSaveValue() }));
          }
        }
        loadData.push(o);
      }
      this.setSelectedCoefficientData(selectedCoefficient, loadData);
      this.data = loadData;
    })
  }

  onUpdate(event: any, item) {
    this.store.pipe(
      select(selectCalculateCoefficient),
      map((data: CalculateCoefficientState) => data.selectedCoefficientsData),
      first()

    ).subscribe(selectedCoefficients => {
      const loadData = [];
      for (let i = 0; i < this.data.length; i++) {
        const o = { ...this.data[i] };
        if (this.data[i].id === item.id) {
          o.values[1].value = Number(event.target.value);
        }
        loadData.push(o);
      }
      this.data = loadData;

      this.store.dispatch(setValueChangedItem({ data: [`${item.id}`] }));
      this.store.dispatch(setChartSettingsValue({ data: this.handleSaveValue() }));
      this.setSelectedCoefficientData(selectedCoefficients, loadData);
    });
  }

  private setSelectedCoefficientData(selectedCoefficients: SelectedCoefficientResultRow, loadData: any[]) {
    const allCoefficientsData = [];
    // mimic structure of coefficientData so that handleSaveData works:
    const intermediateCoefficientsData = selectedCoefficients.intermediateCoefficentsJSON;
    allCoefficientsData['intermediateCoefficentsJSON'] = intermediateCoefficientsData;
    loadData['allCoefficientsData'] = allCoefficientsData;
    const savedData = JSON.parse(this.handleSaveData(loadData));
    this.store.dispatch(setSelectedCoefficientsData({ data: savedData }));
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
