import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { delay, filter, map, mergeMap } from 'rxjs/operators';
import { CalculateDialogComponent } from 'src/app/pump-simulation/calculate-dialog/calculate-dialog.component';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { removeRadioBtnFocus } from 'src/app/shared/util';
import { DirtyComponent } from '../dirty-component';
import { DisplayProductDataService } from '../display-product-data.service';
import { PIPE_PART_ITEMS } from '../pump-data.model';
import { SaveButtonComponent } from '../save-button/save-button.component';
import { UpdateButtonComponent } from '../update-button/update-button.component';

@Component({
  selector: 'app-pipe-part-overview',
  templateUrl: './pipe-part-overview.component.html',
  styleUrls: ['./pipe-part-overview.component.scss']
})
export class PipePartOverviewComponent implements OnInit, DirtyComponent, OnDestroy {

  private subscriptions: Subscription[] = [];

  private formvValueChange: Subscription;

  userLocalStorage = new UserLocalStorage();
  displayedColumns: string[] = ['approveForUse', 'version', 'status', 'published', 'author', 'comment', 'action'];
  @Input() dataSource = new MatTableDataSource();

  tableLoading = true;
  showVersionTable = true;
  showPipePartForm = false;
  pipePartForm: UntypedFormGroup;
  pipePartName = '';
  pipePartUuid = '';
  pipePartData = [];
  selectedRowIndex = -1;


  isDirty = false;
  originalPipePartFormValue: any;
  lastVersion: any;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {

    const urlSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.showVersionTable = true;
      this.pipePartName = '';
      this.showPipePartForm = false;
      this.selectedRowIndex = -1;
      this.isDirty = false;
    });

    this.subscriptions.push(urlSubscription);

    const getPipePartItem = this.route.paramMap.pipe(
      map(params => params.get('uuid')),
      mergeMap(uuid => {
        this.pipePartUuid = uuid;
        return this.displayProductData.getPipePartItem(uuid);
      })
    ).subscribe(pipePartData => {
      this.tableLoading = false;
      this.pipePartName = pipePartData.name;
      this.dataSource.data = pipePartData.versions;
      this.pipePartData = pipePartData.versions;
      this.showVersionTable = false;
      this.lastVersion = this.pipePartData.length;
    });

    this.subscriptions.push(getPipePartItem);

    this.initPipePartForm();
  }

  versionData() {
    return this.pipePartData;
  }

  initPipePartForm() {
    const data = {};
    PIPE_PART_ITEMS.forEach(name => {
      data[`${name}`] = [null, Validators.required];
    });
    this.pipePartForm = this.fb.group(data);
  }

  showSelectedVersionData(version: string) {
    if (this.formvValueChange) {
      this.formvValueChange.unsubscribe();
    }

    if (!this.isDirty) {
      // the form value not changed
      this.pipePartFormValueChange(version);
    } else {
      const dialogRef = this.dialog.open(UpdateButtonComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isDirty = false;
          this.pipePartFormValueChange(version);
        }
      });
    }

  }

  pipePartFormValueChange(version) {
    this.showPipePartForm = true;
    this.selectedRowIndex = Number(version);

    for (const item of this.pipePartData) {
      if (item.version_number === Number(version)) {

        const accessoryFormValue = {};
        PIPE_PART_ITEMS.forEach(name => accessoryFormValue[`${name}`] = item[`${name}`]);
        this.pipePartForm.patchValue(accessoryFormValue);
        this.originalPipePartFormValue = this.pipePartForm.value;

      }
    }

    this.formvValueChange = this.pipePartForm.valueChanges.subscribe((value) => {
      this.isDirty = JSON.stringify(this.originalPipePartFormValue) !== JSON.stringify(value);
    });

    this.subscriptions.push(this.formvValueChange);
  }

  showSelectedVersionRadioBtn(version: number) {
    const versionNumber = version + 1;
    if (this.showPipePartForm && (versionNumber === this.selectedRowIndex)) {
      const dialogRef = this.dialog.open(CalculateDialogComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
        data: {
          message: 'Are you sure you want to publish this version?',
          type: 'confirm'
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const data = this.pipePartData[version];
          const url = `${this.pipePartUuid}/${versionNumber}/live`;
          this.displayProductData.setPipePartState(url, data).subscribe(item => {
            this.snackBar
              .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
              .afterDismissed()
              .pipe(delay(0))
              .subscribe(() => {
                this.ngOnInit();
                this.showVersionTable = true;
                this.pipePartName = '';
                this.showPipePartForm = false;
                this.selectedRowIndex = -1;
                this.isDirty = false;
              });
          });
        } else {
          removeRadioBtnFocus(this.elementRef, version);
        }
      });
    } else {
      const dialogRef = this.dialog.open(CalculateDialogComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
        data: {
          message: 'You have to load a version before publishing it.',
          type: 'hint'
        }
      });

      dialogRef.afterClosed().subscribe(() => removeRadioBtnFocus(this.elementRef, version));
    }

  }

  onSave() {
    const dialogRef = this.dialog.open(SaveButtonComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updatePipePart();
      }
    });
  }

  updatePipePart() {
    const user = this.userLocalStorage.loadUser();
    const data = this.pipePartForm.value;
    data.author = user.user.username;
    data.uuid = this.pipePartUuid;
    data.file = this.pipePartName;
    data.id = `${this.pipePartData[this.selectedRowIndex - 1].id}`;

    const updatePipePartSubscription = this.displayProductData.updatePipePart(data).subscribe(() => {
      const url = `${data.uuid}/${this.lastVersion}/preview`;
      this.displayProductData.setPipePartState(url, data).subscribe(item => {
        this.snackBar
          .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
          .afterDismissed()
          .pipe(delay(0))
          .subscribe(() => {
            this.ngOnInit();
            this.showVersionTable = true;
            this.pipePartName = '';
            this.showPipePartForm = false;
            this.selectedRowIndex = -1;
            this.isDirty = false;
          });
      });
    });
    this.subscriptions.push(updatePipePartSubscription);
  }

  canDeactivate() {
    return this.isDirty;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
