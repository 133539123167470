import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, AbstractControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { select, Store } from '@ngrx/store';
import { Subject, Observable, Subscription, map, startWith } from 'rxjs';
import { CoefficientNameItem } from 'src/app/pump-simulation/coefficient.model';
import { PumpSimulationService } from '../../pump-simulation.service';
import { ShareDataService } from '../../share-data.service';
import { setSourceCopyData } from '../../store/data-source/data-source.actions';
import { DataSourceState } from '../../store/data-source/data-source.reducer';
import { selectDataSource } from '../../store/data-source/data-source.selector';
import { SystemFormState } from '../../store/system-form/system-form.reducer';
import { selectSystemForm } from '../../store/system-form/system-form.selector';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-source-copy-data',
  templateUrl: './source-copy-data.component.html',
  styleUrls: ['./source-copy-data.component.scss']
})
export class SourceCopyDataComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  matcher = new MyErrorStateMatcher();
  coefficientsSetList = [];
  dropdownSetList: string[] = [];
  coefficientsSetControl = new UntypedFormControl(null);
  filteredSetOptions: Set<string>;
  public readonly coefficientsSetChanges: Subject<string> = new Subject<string>();
  public coefficientsSetSuggestions: Observable<string[]>;
  coefficientSetName$: Observable<String>;

  constructor(
    private store: Store,
    private shareService: ShareDataService,
    private pumpSimulationService: PumpSimulationService,
  ) { }

  ngOnInit() {

    this.coefficientSetName$ = this.store.pipe(
      select(selectSystemForm),
      map((data: SystemFormState) => data.pumpName)
    );

    const getAllMeasurementFileNames = this.pumpSimulationService.getAllMeasurementFileNames().subscribe((data: CoefficientNameItem[]) => {
      const result = data.map(item => item.filename)
      this.coefficientsSetList = [...result].sort();
      this.dropdownSetList = this.coefficientsSetList;
      this.coefficientsSetSuggestions = this.coefficientsSetChanges.pipe(
        startWith(''),
        map((val: string) => this.filterSetResults(val))
      );
    });
    this.subscriptions.push(getAllMeasurementFileNames);

    const getCopyData = this.store.pipe(
      select(selectDataSource),
      map((data: DataSourceState) => data.dataSourceName)
    ).subscribe(name => {
      if (name === 'Copy data from system') {
        this.coefficientsSetControl.setValidators([
          Validators.required,
          this.coefficientsSetNotFound.bind(this),
        ]);
      } else {
        this.coefficientsSetControl.setValidators(null);
      }
      this.coefficientsSetControl.updateValueAndValidity();
    });
    this.subscriptions.push(getCopyData);
  }

  private filterSetResults(val: string) {
    if (val !== '') {
      return this.dropdownSetList.filter(item => item.includes(val));
    } else {
      return this.dropdownSetList;
    }
  }

  coefficientsSetNotFound(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value) {
      this.filteredSetOptions = new Set(
        this.dropdownSetList.filter((option) => option.toLowerCase() === value.toLowerCase())
      );
    }
    if (value && !this.filteredSetOptions.size) {
      this.shareService.setCopyData(false);
      return {
        noOption: true
      };
    }
    this.shareService.setCopyData(true);
    return null;
  }

  onFocus() {
    this.coefficientsSetChanges.next('');
  }

  updateCoefficients(value: string) {
    if (value === '') {
      this.coefficientsSetNotFound(this.coefficientsSetControl);
    } else {
      const item = this.coefficientsSetList.find(item => item === value);
      if (item) {
        this.coefficientsSetControl.setValue(value);
      } else {
        this.coefficientsSetNotFound(this.coefficientsSetControl);
      }
    };
  }

  setCoefficientsChangeAction(event: MatAutocompleteSelectedEvent) {
    this.store.dispatch(setSourceCopyData({ data: event.option.value}));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
