import { Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { first, map, Observable, Subscription } from 'rxjs';
import { ChartConfiguration } from 'src/app/pump-simulation/chartConfiguration';
import { createConfiguration, plotData } from 'src/app/shared/util';
import { setNPSHMeasuredData, setNPSHCalculatedData } from '../../store/charts/npsh-chart/npsh-chart.actions';
import { NPSHState } from '../../store/charts/npsh-chart/npsh-chart.reducer';
import { selectNPSH } from '../../store/charts/npsh-chart/npsh-chart.selector';

declare const google: any;

const NPSH_CHART_ID = 'npshChart';

@Component({
  selector: 'app-npsh-chart',
  templateUrl: './npsh-chart.component.html',
  styleUrls: ['./npsh-chart.component.scss']
})
export class NpshChartComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  xAxisToYaxisChart = null;
  showPlaceholder$: Observable<Boolean>;
  @ViewChild(NPSH_CHART_ID, { static: true }) chartElement: ElementRef;

  constructor(
    private store: Store,
  ) { }

  ngOnInit() {
    this.showPlaceholder$ = this.store.pipe(
      select(selectNPSH),
      map((data: NPSHState) => data.loading)
    );

    const npsh = this.store.pipe(
      select(selectNPSH),
      map((data: NPSHState) => data)
    ).subscribe(data => {
      if (data.npshMeasuredData && data.npshCalculatedData) {
        this.createNPSHGraph();
      }
    });
    this.subscriptions.push(npsh);
  }

  createNPSHGraph() {

    const flowChartConfiguration = createConfiguration({
      xAxis: 'flow [m3/h]',
      yAxis: 'npsh [m]',
      height: 300
    });

    const npshCalculatedData = new Map();
    const npshMeasuredData = new Map();

    const npshGraph = this.store.pipe(
      select(selectNPSH),
      map((data: NPSHState) => data),
      first()
    ).subscribe(data => {
      if (data.npshMeasuredData && data.npshCalculatedData) {
        google.charts.load('current', { packages: ['corechart'] });
        google.charts.setOnLoadCallback(this.drawChart(flowChartConfiguration, this.chartElement.nativeElement, npshCalculatedData.set(-1, data.npshCalculatedData), npshMeasuredData.set(1, data.npshMeasuredData)));
      }
    });
    this.subscriptions.push(npshGraph);
  }

  drawChart(
    chartConfiguration: ChartConfiguration,
    chart: ElementRef,
    calculation: Map<number, Map<number, number>>,
    measurements: Map<number, Map<number, number>>
  ) {
    return () => {
      let columnIndex = 1;
      let rowIndex = 0;
      const colors = chartConfiguration.colours;
      const data = new google.visualization.DataTable();
      data.addColumn('number', chartConfiguration.xAxisTitle);
      const options = chartConfiguration.options;

      let head;
      ({ head, rowIndex, columnIndex } = plotData(
        calculation, data, rowIndex, chartConfiguration,
        columnIndex, options, colors, 'calculated'));
      ({ head, rowIndex, columnIndex } = plotData(
        measurements, data, rowIndex, chartConfiguration,
        columnIndex, options, colors, 'measured'));

      this.xAxisToYaxisChart = new google.visualization.LineChart(chart);
      google.visualization.events.addListener(this.xAxisToYaxisChart, 'ready', () => {
        // remove loading
      });
      this.xAxisToYaxisChart.draw(data, options);
    };
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    if(this.xAxisToYaxisChart) {
      this.xAxisToYaxisChart.clearChart();
    }
    this.store.dispatch(setNPSHMeasuredData({ data: null }));
    this.store.dispatch(setNPSHCalculatedData({ data: null }));
  }
}
