import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { select, Store } from '@ngrx/store';
import { combineLatest, first, map, mergeMap, Observable, Subscription } from 'rxjs';
import { CoefficientDataCreateComponent } from 'src/app/pump-simulation/coefficient-data-create/coefficient-data-create.component';
import { objectToMap } from 'src/app/shared/util';
import { PumpSimulationService } from '../pump-simulation.service';
import { ShareDataService } from '../share-data.service';
import { setCoefficientConvertMeasurementData } from '../store/calculate-coefficient/calculate-coefficient.actions';
import { CalculateCoefficientState } from '../store/calculate-coefficient/calculate-coefficient.reducer';
import { selectCalculateCoefficient } from '../store/calculate-coefficient/calculate-coefficient.selector';
import { setFlowRateChartData } from '../store/charts/flow-rate-chart/flow-rate-chart.actions';
import { setSimulationLoadData } from '../store/simulation-load/simulation-load.actions';
import { SimulationSettingState } from '../store/simulation-setting/simulation-setting.reducer';
import { selectSimulationSetting } from '../store/simulation-setting/simulation-setting.selector';
import { SystemFormState } from '../store/system-form/system-form.reducer';
import { selectSystemForm } from '../store/system-form/system-form.selector';


const SIMULATION_CREATE_STEPPER = 'loadStepper';

@Component({
  selector: 'app-simulation-stepper',
  templateUrl: './simulation-stepper.component.html',
  styleUrls: ['./simulation-stepper.component.scss']
})
export class SimulationStepperComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  @ViewChild(SIMULATION_CREATE_STEPPER) private loadStepper!: MatStepper;
  showRevertDataBtn$: Observable<Boolean>;
  showRevertAllDataBtn$: Observable<Boolean>;
  showLoadResult = false;
  showLoadDataView = false;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private shareService: ShareDataService,
    private pumpSimulationService: PumpSimulationService,
  ) { }

  ngOnInit() {
    const data = this.shareService.getLoadVersionData().subscribe(data => {
      if (data.versionKey > 0) {
        this.showLoadResult = false;
        this.getData(data);
      }
    });
    this.subscriptions.push(data);

    const stepperBack = this.shareService.getLoadBack().subscribe(data => {
      if (data && this.loadStepper) {
        this.loadStepper.previous();
        this.showLoadDataView = true;
      }
    });
    this.subscriptions.push(stepperBack);

    this.showRevertDataBtn$ = this.store.pipe(
      select(selectCalculateCoefficient),
      map((data: CalculateCoefficientState) => data.showRevertDataBtn)
    );

    this.showRevertAllDataBtn$ = this.store.pipe(
      select(selectCalculateCoefficient),
      map((data: CalculateCoefficientState) => data.showRevertAllDataBtn)
    );
  }

  revertAllData() {
    this.shareService.setRevertAllData(true);
  }

  revertData() {
    this.shareService.setRevertData(true);
  }

  loadReviewStepper() {
    this.shareService.setCalculate(true);
    this.loadStepper.next();
  }

  getData(data: any) {
    const result = this.pumpSimulationService.getMeasurementFileVersionByID(data.name, data.versionKey).subscribe(data => {
      if (data) {
        const version = JSON.parse(data);
        this.store.dispatch(setSimulationLoadData({ data: version }));
        this.showLoadResult = true;
      };
    });
    this.subscriptions.push(result);
  }

  addNewLoadItemData() {
    const dialogRef = this.dialog.open(CoefficientDataCreateComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
    });

    const addItemDialog = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateMeasurementData(result);
      }
    });
    this.subscriptions.push(addItemDialog);
  }

  updateMeasurementData(data: any) {
    this.shareService.getConvertMeasurementData().pipe(
      first()
    ).subscribe(measurementData => {
      if (measurementData.length > 0) {
        data.id = measurementData[measurementData.length - 1].id + 1;
        measurementData.push(data);
        measurementData.sort((a, b) => a.current - b.current);
        measurementData.sort((a, b) => a.headPressure - b.headPressure);
        this.store.dispatch(setCoefficientConvertMeasurementData({ data: measurementData }));
        this.handleTableData(measurementData);
      }
    });
  }

  handleTableData(changedData: any) {
    this.shareService.setConvertMeasurementData(changedData);
    combineLatest([
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data)
      ),
      this.store.pipe(
        select(selectSimulationSetting),
        map((data: SimulationSettingState) => data)
      ),
      this.store.pipe(
        select(selectSystemForm),
        map((data: SystemFormState) => data)
      )
    ]).pipe(
      mergeMap(([coefficientData, simulationData, formData]) => {
        const parameter = {
          dataType: 'FLOW',
          measurementData: changedData,
          coefficients: coefficientData.allCoefficientsData,
          inputParameters: {
            pump: formData.pumpName,
            controller: formData.controller,
            type: formData.type,
            splitPower: simulationData.splitPower,
            minLift: simulationData.minLift,
            maxLift: simulationData.maxLift,
            excelBehaviour: simulationData.excelBehaviour,
          }
        };
        return this.pumpSimulationService.getDataFor('getMeasuredDataFor', parameter);
      }),
      first()
    ).subscribe(data => {
      const result = objectToMap(data);
      this.store.dispatch(setFlowRateChartData({ data: result }));
    });
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

}
