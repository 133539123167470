import { Action, createReducer, on } from "@ngrx/store";
import * as RotationalSpeedActions from "./rotational-speed-chart.actions";

export const rotationalSpeedFeatureKey = 'rotationalSpeed';

export interface RotationalSpeedState {
  loading: Boolean;
  rotationalCalculatedData: any;
  rotationalMeasuredData: any
}

export const initialState: RotationalSpeedState = {
  loading: true,
  rotationalCalculatedData: null,
  rotationalMeasuredData: null
}

const reducer = createReducer(
  initialState,
  on(RotationalSpeedActions.setRotationalSpeedLoading, (state, action) => {
    return {
      ...state,
      loading: action.data
    }
  }),
  on(RotationalSpeedActions.setRotationalMeasuredData, (state, action) => {
    return {
      ...state,
      loading: !state.rotationalCalculatedData,
      rotationalMeasuredData: action.data
    }
  }),
  on(RotationalSpeedActions.setRotationalCalculatedData, (state, action) => {
    return {
      ...state,
      loading: !state.rotationalMeasuredData,
      rotationalCalculatedData: action.data
    }
  })
);

export function rotationalSpeedReducer(
  state: RotationalSpeedState,
  action: Action
): RotationalSpeedState {
  return reducer(state, action);
}
