import { Component, OnInit, Input, OnDestroy, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { delay, filter, map, mergeMap } from 'rxjs/operators';
import { CalculateDialogComponent } from 'src/app/pump-simulation/calculate-dialog/calculate-dialog.component';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { removeRadioBtnFocus } from 'src/app/shared/util';
import { PackagingItem } from '../controllers/controller.model';
import { DirtyComponent } from '../dirty-component';
import { DisplayProductDataService } from '../display-product-data.service';
import { ACCESSORY_ITEMS } from '../pump-data.model';
import { SaveButtonComponent } from '../save-button/save-button.component';
import { PackagingState } from '../store/packaging/packaging.reducer';
import { selectPackaging } from '../store/packaging/packaging.selector';
import { UpdateButtonComponent } from '../update-button/update-button.component';


@Component({
  selector: 'app-accessory',
  templateUrl: './accessory.component.html',
  styleUrls: ['./accessory.component.scss']
})
export class AccessoryComponent implements OnInit, OnDestroy, DirtyComponent {

  private formValueChange: Subscription;
  private subscriptions: Subscription[] = [];

  userLocalStorage = new UserLocalStorage();
  displayedColumns: string[] = ['approveForUse', 'version', 'status', 'published', 'author', 'comment', 'action'];
  @Input() dataSource = new MatTableDataSource();

  showVersionTable = true;
  showAccessoryForm = false;
  accessoryForm: UntypedFormGroup;
  accessoryName = '';
  accessoryUuid = '';
  accessoryData = [];
  selectedRowIndex = -1;

  packagingList = [];

  isDirty = false;
  originalAccessoryFormValue: any;
  version: string;
  versions: [];
  lastVersion: number;

  constructor(
    private store: Store,
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {

    const getPackagingList = this.store.pipe(
      select(selectPackaging),
      map((data: PackagingState) => data.data)
    ).subscribe((data: PackagingItem[]) => this.packagingList = [...data].sort((a, b) => Number(a.subType.L) - Number(b.subType.L)));

    this.subscriptions.push(getPackagingList);

    const urlSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.showVersionTable = true;
      this.accessoryName = '';
      this.showAccessoryForm = false;
      this.selectedRowIndex = -1;
      this.isDirty = false;
    });
    this.subscriptions.push(urlSubscription);

    const getDataSubscription = this.route.paramMap.pipe(
      map(params => params.get('uuid')),
      mergeMap(uuid => {
        this.accessoryUuid = uuid;
        return this.displayProductData.getAccessoryItem(uuid);
      })
    ).subscribe(accessoryData => {
      this.accessoryName = accessoryData.name;
      this.dataSource.data = accessoryData.versions;
      this.accessoryData = accessoryData.versions;
      this.lastVersion = this.accessoryData.length;
      this.showVersionTable = false;
    });
    this.subscriptions.push(getDataSubscription);

    this.initAccessoryForm();
  }

  initAccessoryForm() {
    const accessoryFormValue = {};
    ACCESSORY_ITEMS.forEach(name => {
      accessoryFormValue[`${name}`] = [null, Validators.required];
    });
    this.accessoryForm = this.fb.group(accessoryFormValue);
  }

  versionData() {
    return this.accessoryData;
  }

  showSelectedVersionData(version: string) {
    if ( this.formValueChange ) {
      this.formValueChange.unsubscribe();
    }

    if (!this.isDirty) {
      // the form value not changed
      this.accessoryFormValueChange(version);
    } else {
      const dialogRef = this.dialog.open(UpdateButtonComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isDirty = false;
          this.accessoryFormValueChange(version);
        }
      });
    }
  }

  accessoryFormValueChange(version) {
    this.showAccessoryForm = true;
    this.selectedRowIndex = Number(version);
    for (const item of this.accessoryData) {
      if (item.version_number === Number(version)) {

        const accessoryFormValue = {};
        ACCESSORY_ITEMS.forEach(name => accessoryFormValue[`${name}`] = item[`${name}`]);
        this.accessoryForm.patchValue(accessoryFormValue);
        this.originalAccessoryFormValue = this.accessoryForm.value;

        const packageItem = this.packagingList.find(item => item.uuid === this.accessoryForm.value.packaging.uuid);
        this.originalAccessoryFormValue.packaging = packageItem.name;
      }
    }

    this.formValueChange = this.accessoryForm.valueChanges.subscribe((value) => {

      if ( this.accessoryForm.get('packaging').value.uuid ) {
        const result = this.packagingList.filter(item => item.uuid === this.accessoryForm.get('packaging').value.uuid);
        if ( result.length > 0 ) {
          value.packaging = result[0].name;
        } else {
          value.packaging = '';
        }
      } else {
        const result = this.packagingList.filter(item => item.name.includes(this.accessoryForm.get('packaging').value));
        if (result.length !== 0) {
          value.packaging = result[0].name;
        }
      }

      this.isDirty = JSON.stringify(this.originalAccessoryFormValue) !== JSON.stringify(value);
    });
  }

  showSelectedVersionRadioBtn(version: number) {
    const versionNumber = version + 1;
    if ( this.showAccessoryForm && ( versionNumber === this.selectedRowIndex )) {
      const dialogRef = this.dialog.open(CalculateDialogComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
        data: {
          message: 'Are you sure you want to publish this version?',
          type: 'confirm'
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const data = this.accessoryData[version];
          const url = `${this.accessoryUuid}/${versionNumber}/live`;
          this.displayProductData.setAccessoryState(url, data).subscribe(item => {
            this.snackBar
              .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
              .afterDismissed()
              .pipe(delay(0))
              .subscribe(() => {
                this.ngOnInit();
                this.showVersionTable = true;
                this.accessoryName = '';
                this.showAccessoryForm = false;
                this.selectedRowIndex = -1;
                this.isDirty = false;
              });
          });
        } else {
          removeRadioBtnFocus(this.elementRef, version);
        }
      });
    } else {
      const dialogRef = this.dialog.open(CalculateDialogComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
        data: {
          message: 'You have to load a version before publishing it.',
          type: 'hint'
        }
      });

      dialogRef.afterClosed().subscribe(() => removeRadioBtnFocus(this.elementRef, version));
    }

  }

  onSave() {
    const dialogRef = this.dialog.open(SaveButtonComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateAccessory();
      }
    });
  }

  updateAccessory() {
    const user = this.userLocalStorage.loadUser();
    const data = this.accessoryForm.value;
    data.author = user.user.username;
    data.uuid = this.accessoryUuid;
    data.file = this.accessoryName;
    data.preview = true;

    if (this.accessoryForm.value.packaging.subType) {
      const packagingValue: any = {};
      packagingValue.name = data.packaging.uuid;
      packagingValue.w = data.packaging.subType.W;
      packagingValue.h = data.packaging.subType.H;
      packagingValue.l = data.packaging.subType.L;
      packagingValue.pack_weight = data.packaging.weight;
      data.packaging = packagingValue;
    } else {
      const result = this.packagingList.filter(item => item.name.includes(data.packaging));
      const packaging: any = {};
      packaging.name = result[0].uuid;
      packaging.w = `${result[0].subType.W}`;
      packaging.h = `${result[0].subType.H}`;
      packaging.l = `${result[0].subType.L}`;
      packaging.pack_weight = `${result[0].weight}`;
      data.packaging = packaging;
    }

    this.displayProductData.updateAccessory(data).subscribe(item => {
      const url = `${data.uuid}/${this.lastVersion}/preview`;
      this.displayProductData.setAccessoryState(url, data).subscribe(item => {

        this.snackBar
          .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
          .afterDismissed()
          .pipe(delay(0))
          .subscribe(() => {
            this.ngOnInit();
            this.showVersionTable = true;
            this.accessoryName = '';
            this.showAccessoryForm = false;
            this.selectedRowIndex = -1;
            this.isDirty = false;
          });
      });
    // });
   });
  }

  canDeactivate() {
    return this.isDirty;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}

