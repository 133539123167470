import { createAction, props } from "@ngrx/store";

export const setSizingAppDataSource = createAction(
  '[SizingApp Data Source] Set SizingApp Data Source',
  props<{ data: string }>()
);

export const setSizingAppUploadData = createAction(
  '[SizingApp Data Source] Set SizingApp Upload data',
  props<{ data: [any], file: string, version: string }>()
);
