<h2 mat-dialog-title> Create New City </h2>
<mat-dialog-content class="dialog">
  <form [formGroup]="cityForm">

    <mat-form-field class="form-element">
      <mat-label> City Name </mat-label>
      <input matInput placeholder="City Name" formControlName="name">
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label> Select Country </mat-label>
      <mat-select type="text" placeholder="Select Country" [formControl]="countryControl">
        <mat-option *ngFor="let country of filteredCountries$ | async" [value]="country">
          {{ country.name }}
        </mat-option>
      </mat-select>
      <div class="form-wrapper__table-loading" *ngIf="coordinatesLoading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label> Latitude - Longitude must already exist in the system</mat-label>

      <mat-select type="text" placeholder="Latitude and Longitude must already exist in the system" [formControl]="latLonControl">
        <mat-option *ngFor="let coordinateItem of filteredCoordinates$ | async" [value]="coordinateItem" >
          {{ coordinateItem.lat }}, {{ coordinateItem.lon }}
        </mat-option>
      </mat-select>
      <div class="form-wrapper__table-loading" *ngIf="coordinatesLoading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>

    </mat-form-field>

    <div class="errorMsg" *ngIf="isCityDuplicate"><button mat-icon-button>
        <mat-icon>priority_high</mat-icon>
      </button>
      <p>The city already exists, please check the name and coordinates. </p>
    </div>
  </form>
</mat-dialog-content>
<div class="form-wrapper__table-loading" *ngIf="tableLoading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<mat-dialog-actions>
  <button class="greyBtn" mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" type="submit" data-testid="submit" [disabled]="!cityForm.valid"
    (click)="onSubmit(cityForm.value)"> Create </button>
</mat-dialog-actions>
