import { Action, createReducer, on } from "@ngrx/store";
import * as UploadDownloadActions from "./upload-download.actions";

export const uploadDownloadFeatureKey = 'uploadDownload';

export interface UploadDownloadState {
  dataSourceName: string;
  uploadedData: [any];
  file: string;
  version: string

}

export const initialState: UploadDownloadState = {
  dataSourceName: '',
  uploadedData: null,
  file: '',
  version: ''

}

const reducer = createReducer(
  initialState,
  on(UploadDownloadActions.setUploadDownloadDataSource, (state, action) => {
    return {
      ...state,
      dataSourceName: action.data,

    }
  }),
  on(UploadDownloadActions.setPS2UploadDownloadData, (state, action) => {
    console.log(state)
    return {
      ...state,
      uploadedData: action.data,
      file: action.file,
      version: action.version
    }
  }),
  on(UploadDownloadActions.setPSK3UploadDownloadData, (state, action) => {
    return {
      ...state,
      uploadedData: action.data,
      file: action.file,
      version: action.version
    }
  }),
  on(UploadDownloadActions.setPumpSystemUploadDownloadData, (state, action) => {
    return {
      ...state,
      uploadedData: action.data,
      file: action.file,
      version: action.version
    }
  }),

);

export function uploadDownloadReducer(
  state: UploadDownloadState,
  action: Action
): UploadDownloadState {
  return reducer(state, action);
}
