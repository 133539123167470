import { createAction, props } from "@ngrx/store";

export const setRotationalSpeedLoading = createAction(
  '[Charts Part] Load Rotational Speed Chart',
  props<{ data: Boolean }>()
);

export const setRotationalCalculatedData = createAction(
  '[Charts Part] Set Rotational Speed Chart Calculated Data',
  props<{ data: any }>()
);

export const setRotationalMeasuredData = createAction(
  '[Charts Part] Set Rotational Speed Chart Measured Data',
  props<{ data: any }>()
);
