<section>
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

    <!-- Index Column -->
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let event">
        <mat-checkbox color="primary" [checked]="false" (change)='addItem(event)'></mat-checkbox>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let event" (click)="toggleExpansion(event)">
        {{event.file}}</td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let event" [attr.colspan]="displayedColumns.length">
        <div class="element-detail" [@detailExpand]="event === expandedEvent ? 'expanded' : 'collapsed'">
          <div class="element-description container" *ngIf="showAccessoryForm">
            <form *ngIf="accessory | async; else accessoryLoading" [formGroup]="accessoryForm">
              <mat-form-field class="form-element">
                <mat-label>item_no_1</mat-label>
                <input matInput placeholder="item_no_1" formControlName="item_no_1">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>item_no_2</mat-label>
                <input matInput placeholder="item_no_2" formControlName="item_no_2">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>item_no_3</mat-label>
                <input matInput placeholder="item_no_3" formControlName="item_no_3">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>item_no_4</mat-label>
                <input matInput placeholder="item_no_4" formControlName="item_no_4">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>item_no_5</mat-label>
                <input matInput placeholder="item_no_5" formControlName="item_no_5">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>item_no_6</mat-label>
                <input matInput placeholder="item_no_6" formControlName="item_no_6">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>item_no_7</mat-label>
                <input matInput placeholder="item_no_7" formControlName="item_no_7">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>item_no_8</mat-label>
                <input matInput placeholder="item_no_8" formControlName="item_no_8">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>item_no_9</mat-label>
                <input matInput placeholder="item_no_9" formControlName="item_no_9">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>item_no_10</mat-label>
                <input matInput placeholder="item_no_10" formControlName="item_no_10">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>product_name_1</mat-label>
                <input matInput placeholder="product_name_1" formControlName="product_name_1">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>product_name_2</mat-label>
                <input matInput placeholder="product_name_2" formControlName="product_name_2">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>product_name_3</mat-label>
                <input matInput placeholder="product_name_3" formControlName="product_name_3">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>product_name_4</mat-label>
                <input matInput placeholder="product_name_4" formControlName="product_name_4">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>product_name_5</mat-label>
                <input matInput placeholder="product_name_5" formControlName="product_name_5">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>product_name_6</mat-label>
                <input matInput placeholder="product_name_6" formControlName="product_name_6">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>product_name_7</mat-label>
                <input matInput placeholder="product_name_7" formControlName="product_name_7">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>product_name_8</mat-label>
                <input matInput placeholder="product_name_8" formControlName="product_name_8">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>product_name_9</mat-label>
                <input matInput placeholder="product_name_9" formControlName="product_name_9">
              </mat-form-field>
            </form>
            <ng-template #accessoryLoading>
              <mat-progress-spinner mode="indeterminate" [diameter]=20></mat-progress-spinner>
            </ng-template>
          </div>
        </div>
      </td>
    </ng-container>

    <tr [ngClass]="showTableHeader ? '' : 'hideContent'" mat-header-row *matHeaderRowDef="displayedColumns">
    </tr>
    <tr mat-row *matRowDef="let event; columns: displayedColumns;" class="element-row"
      [class.expanded-row]="expandedEvent === event">
    </tr>
    <tr mat-row *matRowDef="let event; columns: ['expandedDetail']" class="detail-row" (click)="toggleExpansion(event)">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</section>