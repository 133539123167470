import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription, Observable, map, combineLatest, mergeMap, first } from 'rxjs';
import { DataSourceState } from '../store/data-source/data-source.reducer';
import { SystemFormState } from '../store/system-form/system-form.reducer';
import { selectSystemForm } from '../store/system-form/system-form.selector';
import { selectDataSource } from '../store/data-source/data-source.selector';
import { selectCalculateCoefficient } from '../store/calculate-coefficient/calculate-coefficient.selector';
import { selectSimulationSetting } from '../store/simulation-setting/simulation-setting.selector';
import { MatStepper } from '@angular/material/stepper';
import { ShareDataService } from '../share-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PumpSimulationService } from '../pump-simulation.service';
import { CalculateCoefficientState } from '../store/calculate-coefficient/calculate-coefficient.reducer';
import { SimulationSettingState } from '../store/simulation-setting/simulation-setting.reducer';
import { objectToMap } from 'src/app/shared/util';
import { setFlowRateChartData } from '../store/charts/flow-rate-chart/flow-rate-chart.actions';
import { setCoefficientConvertMeasurementData, setCoefficientConvertNPSHData, setCoefficientMeasurementData, setOriginalConvertMeasurementData } from '../store/calculate-coefficient/calculate-coefficient.actions';
import { setSimulationMaxLift, setSimulationMinLift } from '../store/simulation-setting/simulation-setting.actions';
import { setSourceUploadFile } from '../store/data-source/data-source.actions';
import { MatDialog } from '@angular/material/dialog';
import { CoefficientDataCreateComponent } from 'src/app/pump-simulation/coefficient-data-create/coefficient-data-create.component';


const SIMULATION_CREATE_STEPPER = 'createStepper';

@Component({
  selector: 'app-simulation-create',
  templateUrl: './simulation-create.component.html',
  styleUrls: ['./simulation-create.component.scss']
})
export class SimulationCreateComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  @ViewChild(SIMULATION_CREATE_STEPPER) private createStepper!: MatStepper;
  enableFirstStepNextBtn$: Observable<Boolean>;
  enableSecondStepNextBtn$: Observable<Boolean>;
  showRevertDataBtn$: Observable<Boolean>;
  showRevertAllDataBtn$: Observable<Boolean>;
  stepperLoading = false;
  showResult = false;
  convertedNPSHData = [];
  convertedMeasurementData = [];

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private shareService: ShareDataService,
    private pumpSimulationService: PumpSimulationService,
  ) { }

  ngOnInit() {
    this.enableFirstStepNextBtn$ = this.store.pipe(
      select(selectSystemForm),
      map((data: SystemFormState) => !data.enableNextStep)
    );

    this.enableSecondStepNextBtn$ = this.store.pipe(
      select(selectDataSource),
      map((data: DataSourceState) => !data.enableNextStep)
    );

    this.showRevertDataBtn$ = this.store.pipe(
      select(selectCalculateCoefficient),
      map((data: CalculateCoefficientState) => data.showRevertDataBtn)
    );

    this.showRevertAllDataBtn$ = this.store.pipe(
      select(selectCalculateCoefficient),
      map((data: CalculateCoefficientState) => data.showRevertAllDataBtn)
    );

    const stepperBack = this.shareService.getCreateBack().subscribe(data => {
      if (data && this.createStepper) {
        this.createStepper.previous();
        this.showResult = false;
      }
    });
    this.subscriptions.push(stepperBack);
  }

  sourceStepper() {
    const stepper = this.store.pipe(
      select(selectDataSource),
      map((data: DataSourceState) => data.dataSourceName),
      first()
    ).subscribe(value => {
      if (value !== '') {
        switch (value) {
          case 'Upload file':
            this.handleUploadFile();
            break;
          case 'Use uploaded file':
            this.handleUseUploadedFile();
            break;
          default:
            this.createStepper.next();
            break;
        }
      };
    });
    this.subscriptions.push(stepper);
  }

  handleUploadFile() {
    this.stepperLoading = true;
    const upload = this.store.pipe(
      select(selectDataSource),
      map((data: DataSourceState) => data.uploadFile),
      first()
    ).subscribe((data) => {
      if (data) {
        this.handleUploadFileData();
      } else {
        this.shareService.setUploadFileDataEmpty(true);
      }
    });
    this.subscriptions.push(upload);
  };

  handleUploadFileData() {

    const uploadData = combineLatest([
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data)
      ),
      this.store.pipe(
        select(selectSimulationSetting),
        map((data: SimulationSettingState) => data)
      ),
      this.store.pipe(
        select(selectSystemForm),
        map((data: SystemFormState) => data)
      )
    ]).pipe(
      mergeMap(([coefficientData, simulationData, formData]) => {
        const parameter = {
          dataType: 'FLOW',
          measurementData: coefficientData.convertMeasurementData,
          coefficients: coefficientData.allCoefficientsData,
          inputParameters: {
            pump: formData.pumpName,
            controller: formData.controller,
            type: formData.type,
            splitPower: simulationData.splitPower,
            minLift: simulationData.minLift,
            maxLift: simulationData.maxLift,
            excelBehaviour: simulationData.excelBehaviour,
          }
        };
        return this.pumpSimulationService.getDataFor('getMeasuredDataFor', parameter);
      }),
      first()
    ).subscribe(data => {
      const result = objectToMap(data);
      this.store.dispatch(setFlowRateChartData({ data: result }));
      this.stepperLoading = false;
      this.createStepper.next();
    });
    this.subscriptions.push(uploadData);
  }

  handleUseUploadedFile() {
    this.stepperLoading = true;
    const useUpload = combineLatest([
      this.store.pipe(
        select(selectDataSource),
        map((data: DataSourceState) => data.useUploadedFile)
      ),
      this.shareService.getUseUploadedFile()
    ]).pipe(
      first()
    ).subscribe(([data, isValid]) => {
      if (data === '') {
        this.snackBar.open('Please select uploaded file', '', { duration: 1500, panelClass: 'hintMsg' });
      }
      if (isValid && data !== '') {
        this.handleUseUploadedFileData(data);
      }
    });
    this.subscriptions.push(useUpload);
  };

  handleUseUploadedFileData(value: String) {
    const data = this.pumpSimulationService.getUploadedFileItem(`${value}`).subscribe(result => {
      this.store.dispatch(setSourceUploadFile({ data: result }));
      this.store.dispatch(setCoefficientMeasurementData({ data: result }));
      let i = 0;
      for (const row of result) {
        i++;
        this.convertMeasurementData(row, i);
        this.convertNPSHData(row);
      }
      this.store.dispatch(setCoefficientConvertNPSHData({ data: this.convertedNPSHData }));
      this.store.dispatch(setCoefficientConvertMeasurementData({ data: this.convertedMeasurementData }));
      this.store.dispatch(setOriginalConvertMeasurementData({ data: this.convertedMeasurementData }));

      this.store.dispatch(setCoefficientMeasurementData({ data: result }));
      const headPressureTypes = this.convertedMeasurementData.map((row: { headPressure: number; }) => row.headPressure);
      const subResult = new Set(headPressureTypes);
      const headData = [...subResult];
      let firstLine = true;
      for (const head of headData) {
        if (firstLine) {
          this.store.dispatch(setSimulationMinLift({ data: Number(head) }));
          firstLine = false;
        }
        this.store.dispatch(setSimulationMaxLift({ data: Number(head) }));

      }

      this.handleUploadFileData();
    });
    this.subscriptions.push(data);
  }

  revertAllData() {
    this.shareService.setRevertAllData(true);
  }

  revertData() {
    this.shareService.setRevertData(true);
  }

  createReviewStepper() {
    this.createStepper.next();
    this.showResult = true;
  }

  convertExcelData(data: any[]) {
    let i = 0;
    for (const row of data) {
      i++;
      this.convertMeasurementData(row, i);
      this.convertNPSHData(row);
    }
    this.store.dispatch(setCoefficientConvertNPSHData({ data: this.convertedNPSHData }));
    this.store.dispatch(setCoefficientConvertMeasurementData({ data: this.convertedMeasurementData }));
    this.store.dispatch(setOriginalConvertMeasurementData({ data: this.convertedMeasurementData }));
  }

  convertNPSHData(row: any) {
    const convertedNPSHRow = {
      flowRate: row.Q,
      suctionHead: row.m
    };
    if ((convertedNPSHRow.suctionHead) &&
      (convertedNPSHRow.flowRate.toString().indexOf('[Q]') < 0 && convertedNPSHRow.suctionHead.toString().indexOf('[m]') < 0)) {
        this.convertedNPSHData.push(convertedNPSHRow);
    }
  }

  convertMeasurementData(row: any, _id: number) {
    const convertedMeasurementRow = {
      id: _id,
      current: row.I1,
      voltage: row.U1,
      volume: row.V,
      rotationalSpeed: row.n,
      measurementTime: row.t,
      headPressure: row.Δp * 10,
      power: row.U1 * row.I1,
      flowRate: row.V / row.t * 3600 / 1000	// [m^3/h]
    };

    if (!isFinite(convertedMeasurementRow.flowRate)) { convertedMeasurementRow.flowRate = 0; }

    if ((convertedMeasurementRow.current &&
      convertedMeasurementRow.headPressure &&
      convertedMeasurementRow.rotationalSpeed &&
      convertedMeasurementRow.voltage)
      && (convertedMeasurementRow.current.toString().indexOf('[A]') < 0 &&
        convertedMeasurementRow.headPressure.toString().indexOf('[bar]') < 0 &&
        convertedMeasurementRow.voltage.toString().indexOf('[V]') < 0)) {
          this.convertedMeasurementData.push(convertedMeasurementRow);
    }
  }

  addNewCreateItemData() {
    const dialogRef = this.dialog.open(CoefficientDataCreateComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
    });

    const addItemDialog = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateMeasurementData(result);
      }
    });
    this.subscriptions.push(addItemDialog);
  }

  updateMeasurementData(data: any) {
    this.shareService.getConvertMeasurementData().pipe(
      first()
    ).subscribe(measurementData => {
      if (measurementData.length > 0) {
        data.id = measurementData[measurementData.length - 1].id + 1;
        measurementData.push(data);
        measurementData.sort((a, b) => a.current - b.current);
        measurementData.sort((a, b) => a.headPressure - b.headPressure);
        this.store.dispatch(setCoefficientConvertMeasurementData({ data: measurementData }));
        this.handleTableData(measurementData);
      }
    });
  }

  handleTableData(changedData: any) {
    this.shareService.setConvertMeasurementData(changedData);
    combineLatest([
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data)
      ),
      this.store.pipe(
        select(selectSimulationSetting),
        map((data: SimulationSettingState) => data)
      ),
      this.store.pipe(
        select(selectSystemForm),
        map((data: SystemFormState) => data)
      )
    ]).pipe(
      mergeMap(([coefficientData, simulationData, formData]) => {
        const parameter = {
          dataType: 'FLOW',
          measurementData: changedData,
          coefficients: coefficientData.allCoefficientsData,
          inputParameters: {
            pump: formData.pumpName,
            controller: formData.controller,
            type: formData.type,
            splitPower: simulationData.splitPower,
            minLift: simulationData.minLift,
            maxLift: simulationData.maxLift,
            excelBehaviour: simulationData.excelBehaviour,
          }
        };
        return this.pumpSimulationService.getDataFor('getMeasuredDataFor', parameter);
      }),
      first()
    ).subscribe(data => {
      const result = objectToMap(data);
      this.store.dispatch(setFlowRateChartData({ data: result }));
    });
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
