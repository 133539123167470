<mat-form-field class="form-element" appearance="fill">
  <mat-label>parts picture</mat-label>
  <input type="text" placeholder="Search parts picture" matInput [formControl]="partspictureControl"
    [matAutocomplete]="auto" (focus)="onFocus($event.target.value)" (blur)="updatePartspicture($event.target.value)"
    (input)="partspictureChanges.next($event.target.value)" />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setPartspictureChangeAction($event)">
    <mat-option *ngFor="let option of partspictureSuggestions | async" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="partspictureControl.hasError('noOption')">
    Parts picture not Found
  </mat-error>
</mat-form-field>
<button class="form-button" mat-raised-button color="primary" type="button" (click)="openViewDialog()">VIEW</button>