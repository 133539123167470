import { Input, OnDestroy } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { nameSort } from 'src/app/shared/util';
import { ControllerItem } from '../controllers/controller.model';
import { DisplayProductDataService } from '../display-product-data.service';
import { MotorItem } from '../motors/motor.model';
import { Accessory, ConnectedProduct, ModuleDetail, PipePart, PoleMount } from '../pump-data.model';
import { PumpEndsItem } from '../pump-ends/pump-end.model';
import { PumpSystemItem } from '../pump-systems/pump-system.model';

@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss']
})
export class CopyButtonComponent implements OnInit, AfterViewInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  displayedColumns: string[] = ['name'];
  tableLoading = true;

  @Input() dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<CopyButtonComponent>,
    private displayProductData: DisplayProductDataService,
    @Inject(MAT_DIALOG_DATA) public copyType: string,
  ) { }

  ngOnInit() {
    if (this.copyType === 'controller') {
      const getControllerList = this.displayProductData.getControllerList().subscribe((allControllers: ControllerItem[]) => {
        this.tableLoading = false;
        this.dataSource.data = nameSort(allControllers);
      });
      this.subscriptions.push(getControllerList);
    } else if (this.copyType === 'motor') {
      const getMotorList = this.displayProductData.getMotorList().subscribe((allMotorItems: MotorItem[]) => {
        this.tableLoading = false;
        this.dataSource.data = nameSort(allMotorItems);
      });
      this.subscriptions.push(getMotorList);
    } else if (this.copyType === 'pump-end') {
      const getPumpEndsList = this.displayProductData.getPumpEndsList().subscribe((allPumpEndsItems: PumpEndsItem[]) => {
        this.tableLoading = false;
        this.dataSource.data = nameSort(allPumpEndsItems);
      });
      this.subscriptions.push(getPumpEndsList);
    } else if (this.copyType === 'pump-system') {
      const getPumpSystemList = this.displayProductData.getPumpSystemList().subscribe((allPumpSystemsItems: PumpSystemItem[]) => {
        this.tableLoading = false;
        this.dataSource.data = nameSort(allPumpSystemsItems);
      });
      this.subscriptions.push(getPumpSystemList);
    } else if (this.copyType === 'pole-mount') {
      const getPoleMountList = this.displayProductData.getPoleMountList().subscribe((allPolemounts: PoleMount[]) => {
        this.tableLoading = false;
        this.dataSource.data = nameSort(allPolemounts);
      });
      this.subscriptions.push(getPoleMountList);
    } else if (this.copyType === 'parts-list') {
      const getPartsList = this.displayProductData.getPartsList().subscribe((allPartlists: PoleMount[]) => {
        this.tableLoading = false;
        this.dataSource.data = nameSort(allPartlists);
      });
      this.subscriptions.push(getPartsList);
    } else if (this.copyType === 'accessory') {
      const getAccessoryList = this.displayProductData.getAccessoryList().subscribe((allAccessories: Accessory[]) => {
        this.tableLoading = false;
        this.dataSource.data = nameSort(allAccessories);
      });
      this.subscriptions.push(getAccessoryList);
    } else if (this.copyType === 'connected-item') {
      const getConnectedProductList = this.displayProductData.getConnectedProductList().subscribe((allProducts: ConnectedProduct[]) => {
        this.tableLoading = false;
        this.dataSource.data = nameSort(allProducts);
      });
      this.subscriptions.push(getConnectedProductList);
    } else if (this.copyType === 'module') {
      const getModuleList = this.displayProductData.getModuleList().subscribe((allModules: ModuleDetail[]) => {
        this.tableLoading = false;
        this.dataSource.data = nameSort(allModules);
      });
      this.subscriptions.push(getModuleList);
    } else if (this.copyType === 'pipe-part') {
      const getPipePartList = this.displayProductData.getPipePartList().subscribe((allPipeParts: PipePart[]) => {
        this.tableLoading = false;
        allPipeParts.forEach(item => item.name = item.id);
        this.dataSource.data = nameSort(allPipeParts);
      });
      this.subscriptions.push(getPipePartList);
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.firstPage());
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
