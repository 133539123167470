import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { DisplayProductDataService } from '../display-product-data.service';
import { ControllerItem, PackagingItem } from '../controllers/controller.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CopyButtonComponent } from '../copy-button/copy-button.component';
import { v4 } from 'uuid';
import { CONTROLLER_ITEMS } from '../pump-data.model';
import { checkControllerName } from '../names.validator';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { Router } from '@angular/router';
import { delay, map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { PackagingState } from '../store/packaging/packaging.reducer';
import { selectPackaging } from '../store/packaging/packaging.selector';


@Component({
  selector: 'app-controller-new',
  templateUrl: './controller-new.component.html',
  styleUrls: ['./controller-new.component.scss']
})
export class ControllerNewComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  controllerForm: UntypedFormGroup;
  controller: Observable<ControllerItem>;

  userLocalStorage = new UserLocalStorage();
  copiedItem: string;
  showLoading = false;

  packagingList = [];

  constructor(
    private store: Store,
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {

    const controllerFormValue: any = {};
    CONTROLLER_ITEMS.forEach(name => {
      controllerFormValue[`${name}`] = [null, Validators.required];
    });
    controllerFormValue.name = ['', [Validators.required, checkControllerName.bind(this)]];
    this.controllerForm = this.fb.group(controllerFormValue);

    const getPackagingList = this.store.pipe(
      select(selectPackaging),
      map((data: PackagingState) => data.data)
    ).subscribe((data: PackagingItem[]) => {
      this.packagingList = [...data].sort((a, b) => Number(a.subType.L) - Number(b.subType.L));
    });

    this.subscriptions.push(getPackagingList);
  }

  openCopyDialog(type: string) {
    const dialogRef = this.dialog.open(CopyButtonComponent, {
      width: '1000px',
      maxWidth: '96vw',
      data: type,
      minHeight: '60vh',
      maxHeight: '60vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showLoading = true;
        this.copyItem(result);
      }
    });
  }

  copyItem(controllerUuid: string) {

    this.displayProductData.getControllerItem(controllerUuid).subscribe((controller: ControllerItem) => {

      this.showLoading = false;
      this.copiedItem = controller.name;

      const controllerFormValue: any = {};
      CONTROLLER_ITEMS.forEach(name => {
        controllerFormValue[`${name}`] = controller[`${name}`];
      });
      controllerFormValue.name = '';
      this.controllerForm.patchValue(controllerFormValue);

    });
  }

  onSubmit(data: any) {
    const user = this.userLocalStorage.loadUser();
    data.author = user.user.username;
    data.uuid = v4();

    if ( this.controllerForm.value.packaging.subType ) {
      const packagingValue: any = {};
      packagingValue.name = data.packaging.uuid;
      packagingValue.w = data.packaging.subType.W;
      packagingValue.h = data.packaging.subType.H;
      packagingValue.l = data.packaging.subType.L;
      packagingValue.pack_weight = data.packaging.weight;
      data.packaging = packagingValue;
    } else {
      const result = this.packagingList.filter(item => item.name.includes(data.packaging));
      const packaging: any = {};
      packaging.name = result[0].uuid;
      packaging.w = `${result[0].subType.W}`;
      packaging.h = `${result[0].subType.H}`;
      packaging.l = `${result[0].subType.L}`;
      packaging.pack_weight = `${result[0].weight}`;
      data.packaging = packaging;
    }

    this.displayProductData.createNewController(data).subscribe(item => {
      this.snackBar
      .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
      .afterDismissed()
      .pipe(delay(0))
      .subscribe(() => {
        this.displayProductData.reloadControllerData();
        this.router.navigate(['/data/controller', data.uuid]);
      });
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}

