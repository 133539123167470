
        <div class="part-left">
          <div class="form-element">

            <h2>Data upload/download PSK3</h2>

            </div>
            <div class="form-element">
              <div class="upload-wrapper">
              <button mat-raised-button color="primary"><mat-icon>add</mat-icon> Upload file
              <input
                type="file"
                name="profile"
                (change)="onFileSelect($event, 'LORENTZ_ASSIST')"
                accept=".csv,.json,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              ></button>
            </div>
            <div class="upload-info" *ngIf="showUploadFileName">
              <span> {{ uploadFileName }}  </span>
            </div>
          </div>


            <ng-template matStepLabel>System components</ng-template>

            <div class="form-element">
              <button mat-raised-button color="primary" type="button" (click)="profileFormSubmit('LORENTZ_ASSIST')">View Uploaded PSK3 File</button>
            </div>

        </div>





