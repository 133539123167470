<div class="mat-elevation-z8 tableWrapper">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- Approve For Use Column -->
    <ng-container matColumnDef="approveForUse">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Approve For Use </th>
      <td mat-cell *matCellDef="let row; let id = index">
        <mat-radio-button *ngIf="row.version !== 0 && row.version !== 1 && row.approval_state !== 'LIVE'"
          [ngClass]="'mat-radio-' + id" color="primary" (click)="selectVersionItem(id)">
        </mat-radio-button>
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
      <td mat-cell *matCellDef="let row"> {{row.date}} </td>
    </ng-container>

    <!-- User Column -->
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> User </th>
      <td mat-cell *matCellDef="let row"> {{row.username}} </td>
    </ng-container>

    <!-- Version Column -->
    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Version </th>
      <td mat-cell *matCellDef="let row"> {{row.version}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td mat-cell *matCellDef="let row"> {{row.approval_state}} </td>
    </ng-container>

    <!-- Notes Column -->
    <ng-container matColumnDef="notes">
      <th class="notes" mat-header-cell *matHeaderCellDef mat-sort-header> Notes </th>
      <td mat-cell *matCellDef="let row"> {{row.notes}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let row">
        <button mat-button color="primary" *ngIf="row.version !== 0 && row.approval_state !== 'INITIAL'"
          (click)="showVersionData(row.filename, row.version)">LOAD</button>
        <button *ngIf="row.version !== 0 && row.version !== 1 && row.approval_state !== 'LIVE'" mat-button
          color="primary" (click)="deleteVersionData(row.filename, row.version)">DELETE</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; let id = index; columns: displayedColumns;"
      [ngClass]="{'highlight': selectedRowIndex === row.version}" [ngClass]="id !== 0 ? '' : 'removeContent'"></tr>

  </table>

  <div *ngIf="isEmptyVersionData" class="noVersions">
    No versions found
  </div>
  <div class="table-loading" *ngIf="tableLoading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>