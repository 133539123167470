import { Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subscription, Subject, Observable, startWith, map } from 'rxjs';
import { nameSort } from 'src/app/shared/util';
import { MaterialItem } from '../controllers/controller.model';
import { MaterialCreateComponent } from '../material-create/material-create.component';
import { MaterialState } from '../store/material/material.reducer';
import { selectMaterial } from '../store/material/material.selector';

@Component({
  selector: 'app-material-select',
  templateUrl: './material-select.component.html',
  styleUrls: ['./material-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialSelectComponent),
      multi: true
    }
  ]
})
export class MaterialSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

  private subscriptions: Subscription[] = [];

  materialList = [];
  materialStore = '';
  dropdownList: string[] = [];

  materialControl = new UntypedFormControl('', [
    this.optionNotFound.bind(this),
  ]);

  filteredOptions: Set<string>;

  public readonly materialChanges: Subject<string> = new Subject<string>();
  public materialSuggestions: Observable<string[]>;

  @Output() autocompleteChange = new EventEmitter<MatAutocompleteSelectedEvent>();
  @Output() inputChange = new EventEmitter<string>();

  constructor(
    private store: Store,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    const getMaterial = this.store.pipe(
      select(selectMaterial),
      map((data: MaterialState) => data.data)
    ).subscribe((data: MaterialItem[]) => {
      this.materialList = nameSort([...data]);
      this.dropdownList = this.materialList.map(item => item.name);
      this.dropdownList.unshift('');
      this.dropdownList.push('Add new material');

      this.materialSuggestions = this.materialChanges.pipe(
        startWith(''),
        map((val: string) => this.filterResults(val))
      );
    });
    this.subscriptions.push(getMaterial);
  }

  private filterResults(val: string) {
    if (val !== '') {
      return this.dropdownList.filter(item => item.includes(val));
    } else {
      return this.dropdownList;
    }
  }

  optionNotFound(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    this.filteredOptions = new Set(
      this.dropdownList.filter((option) => option.toLowerCase().indexOf(value.toLowerCase()) > -1)
    );
    if (value && !this.filteredOptions.size) {
      return {
        noOption: true
      };
    }
    return null;
  }

  setMaterialChangeAction(event: MatAutocompleteSelectedEvent) {
    if ( event.option.value === 'create' ) {
      const dialogRef = this.dialog.open(MaterialCreateComponent, {
        width: '1000px',
        maxWidth: '96vw',
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.materialList.unshift(result);
          this.dropdownList = this.materialList.map(item => item.name);
          this.dropdownList.unshift('');
          this.dropdownList.push('Add new packaging');
          this.materialControl.setValue(result.name);
        } else {
          this.materialControl.setValue(this.materialStore);
        }
      });
    } else if ( event.option.value === '' ) {
      this.materialStore = '';
      this.materialControl.reset('');
    } else {
      this.materialStore = event.option.value;
    }

    this.autocompleteChange.emit(event);
  }

  onFocus(value: string) {
    if (value === '') {
      this.materialChanges.next('');
    };
    this.optionNotFound(this.materialControl);
  }

  updateMaterial(value: string) {
    if (value === '') {
      this.materialControl.reset('');
    } else {
      
      const item = this.materialList.find(item => item.name === value);
      if (item) {
        this.inputChange.emit(value);
      } else {
        this.materialControl.reset('');
      }
    };
  }

  // model --> view
  writeValue(value: string): void {
    if (value) {
      this.materialStore = value;
      this.materialControl.setValue(value, { emitEvent: false });
    } else {
      this.materialControl.reset('');
    }
  }

  // view --> model
  registerOnChange(fn: (value: string) => void) {
    const onChangeSubscription = this.materialControl.valueChanges.subscribe(fn);
    this.subscriptions.push(onChangeSubscription);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  onTouched: () => void = () => {};

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
