import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { combineLatest, Subscription } from 'rxjs';
import { DisplayProductDataService } from '../display-product-data.service';
import { PartsListDialogComponent } from '../parts-list-dialog/parts-list-dialog.component';
import { PartsListService } from '../parts-list.service';
import { PartsListFlatNode, PartsListNode } from '../pump-data.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { delay, filter, map, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DirtyComponent } from '../dirty-component';
import { ErrorButtonComponent } from '../error-button/error-button.component';
import { Queue } from './queue';
import { PartsListAddNodeComponent } from '../parts-list-add-node/parts-list-add-node.component';
import { PartsListEditNodeComponent } from '../parts-list-edit-node/parts-list-edit-node.component';


@Component({
  selector: 'app-parts-list',
  templateUrl: './parts-list.component.html',
  styleUrls: ['./parts-list.component.scss']
})

export class PartsListComponent implements OnInit, OnDestroy, DirtyComponent {

  private subscriptions: Subscription[] = [];

  showPartsListTree = false;

  partslistName = '';
  partslistMaterial = '';
  partslistType = '';
  partslistUuid = '';


  flatNodeMap = new Map<PartsListFlatNode, PartsListNode>();
  nestedNodeMap = new Map<PartsListNode, PartsListFlatNode>();
  selectedParent: PartsListFlatNode | null = null;

  treeControl: FlatTreeControl<PartsListFlatNode>;

  treeFlattener: MatTreeFlattener<PartsListNode, PartsListFlatNode>;

  dataSource: MatTreeFlatDataSource<PartsListNode, PartsListFlatNode>;
  checklistSelection = new SelectionModel<PartsListFlatNode>(true /* multiple */);

  dragNode: any;
  dragNodeExpandOverWaitTimeMs = 300;
  dragNodeExpandOverNode: any;
  dragNodeExpandOverTime: number;
  dragNodeExpandOverArea: string;

  pumppartsList = [];
  @ViewChild('emptyItem') emptyItem: ElementRef;

  isDirty = false;
  originalPartsListTreeValue = '';

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private database: PartsListService,
    private displayProductData: DisplayProductDataService
  ) { }

  getLevel = (node: PartsListFlatNode) => node.level;

  isExpandable = (node: PartsListFlatNode) => node.expandable;

  getChildren = (node: PartsListNode): PartsListNode[] => node.children;

  // tslint:disable-next-line: variable-name
  hasChild = (_: number, _nodeData: PartsListFlatNode) => _nodeData.expandable;

  // tslint:disable-next-line: variable-name
  hasNoContent = (_: number, _nodeData: PartsListFlatNode) => _nodeData.name === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: PartsListNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.name
      ? existingNode
      : new PartsListFlatNode();
    flatNode.name = node.name;
    flatNode.id = node.id;
    flatNode.material = node.material;
    flatNode.level = level;
    flatNode.children = node.children;
    flatNode.expandable = (node.children && node.children.length > 0);
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  ngOnInit() {

    const urlSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.isDirty = false;
      this.originalPartsListTreeValue = '';
    });
    this.subscriptions.push(urlSubscription);

    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<PartsListFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


    const getData = combineLatest([
      this.route.paramMap.pipe(
        map(params => params.get('uuid')),
      ),
      this.displayProductData.getPartsList()
    ]).subscribe(([key, data]) => {
      this.database.updatePartsListTree(key);
      data.forEach(item => {
        if (item.uuid === key) {
          this.partslistUuid = item.uuid;
          this.partslistName = item.name;
          this.partslistType = item.type;
          this.partslistMaterial = item.material;
          this.showPartsListTree = true;
        }
      });
    });

    this.subscriptions.push(getData);

    this.database.dataChange.pipe(
      tap(data => {
        if ( this.originalPartsListTreeValue === '' && data.length > 0 ) {
          this.originalPartsListTreeValue = JSON.stringify(data);
        }
      })
    ).subscribe(data => {
      this.dataSource.data = [];
      this.dataSource.data = data;
      this.isDirty = this.originalPartsListTreeValue !== JSON.stringify(data);
    });
  }

  addPartListTreeNode() {
    const dialogRef = this.dialog.open(PartsListAddNodeComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.treeControl.expand(this.treeControl.dataNodes[0]);
      }
    });
  }

  updatePartListTreeNode() {
    // Pop up warning message if trying to update parts with zero nodes
    if (!this.database.data || this.database.data.length === 0) {
      this.snackBar.open('The parts are empty so cannot be updated. Please create a new item.', '', { duration: 1500, panelClass: 'hintMsg' });
      return
    }

    if (this.isDirty) {
      const node = {
        name: '',
        id: 0,
        material: '',
        level: 0,
        expandable: false
      };
      const dialogRef = this.dialog.open(PartsListDialogComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
        data: {
          type: 'Update',
          node
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          // Recursive access to the parts
          const queue = new Queue<PartsListNode>();
          // Insert parts root on the queue
          queue.push(this.database.data[0]);
          const parts: PartsListNode[] = [];

          // Access to the children while there is at least one element.
          while (queue.hasElements()) {
            const currentNode: PartsListNode = queue.pop();
            parts.push(currentNode);
            currentNode.children.forEach(child => {
              queue.push(child);
            });
          }

          const postData = {
            name: this.partslistName,
            type: this.partslistType,
            parts
          };
          this.displayProductData.updatePartlist(this.partslistUuid, postData).subscribe(
            result => {
              this.snackBar.open(result, '', { duration: 1500, panelClass: 'hintMsg' })
              .afterDismissed()
              .pipe(delay(0))
              .subscribe(() => {
                this.displayProductData.reloadPartListData();
                this.partslistUuid = '';
                this.partslistName = '';
                this.partslistType = '';
                this.partslistMaterial = '';
                this.showPartsListTree = false;
                this.originalPartsListTreeValue = '';
                this.isDirty = false;
              });
            },
            error => {
              this.dialog.open(ErrorButtonComponent, {
                width: '600px',
                data: JSON.parse(error),
                minHeight: '20vh',
              });
            },
            () => {}
          );
        }
      });
    } else {
      this.snackBar.open('There is no changes, please notice it', '', { duration: 1500, panelClass: 'hintMsg' });
    }
  }

  /** Delete the node when ceate */
  deletePartListTreeNode(node: PartsListFlatNode) {
    const dialogRef = this.dialog.open(PartsListDialogComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
      data: {
        type: 'Delete',
        node
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.database.deletePartsListNode(this.flatNodeMap.get(node));
      }
    });
  }

  editPartListTreeNode(node: PartsListFlatNode) {
    const dialogRef = this.dialog.open(PartsListEditNodeComponent, {
      width: '1000px',
      maxWidth: '96vw',
      disableClose: true,
      data: node,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const nestedNode = this.flatNodeMap.get(node);
        this.database.updatePartsListNode(nestedNode, result.name, result.id, result.material);
        this.treeControl.expand(node);
      }
    });
  }

  handleDragStart(event, node) {
    // Required by Firefox (https://stackoverflow.com/questions/19055264/why-doesnt-html5-drag-and-drop-work-in-firefox)
    event.dataTransfer.setData('foo', 'bar');
    event.dataTransfer.setDragImage(this.emptyItem.nativeElement, 0, 0);
    this.dragNode = node;
    this.treeControl.collapse(node);
  }

  handleDragOver(event, node) {
    event.preventDefault();

    // Handle node expand
    if (node === this.dragNodeExpandOverNode) {
      if (this.dragNode !== node && !this.treeControl.isExpanded(node)) {
        if ((new Date().getTime() - this.dragNodeExpandOverTime) > this.dragNodeExpandOverWaitTimeMs) {
          this.treeControl.expand(node);
        }
      }
    } else {
      this.dragNodeExpandOverNode = node;
      this.dragNodeExpandOverTime = new Date().getTime();
    }

    // Handle drag area
    const percentageY = event.offsetY / event.target.clientHeight;
    if (percentageY < 0.25) {
      this.dragNodeExpandOverArea = 'above';
    } else if (percentageY > 0.75) {
      this.dragNodeExpandOverArea = 'below';
    } else {
      this.dragNodeExpandOverArea = 'center';
    }
  }

  handleDrop(event, node) {
    event.preventDefault();
    if (node !== this.dragNode) {
      let newItem: PartsListNode;
      if (this.dragNodeExpandOverArea === 'above') {
        newItem = this.database.copyPastePartsListNodeAbove(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
      } else if (this.dragNodeExpandOverArea === 'below') {
        newItem = this.database.copyPastePartsListNodeBelow(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
      } else {
        newItem = this.database.copyPastePartsListNode(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
      }
      this.database.deletePartsListNode(this.flatNodeMap.get(this.dragNode));
      this.treeControl.expandDescendants(this.nestedNodeMap.get(newItem));
    }
    this.dragNode = null;
    this.dragNodeExpandOverNode = null;
    this.dragNodeExpandOverTime = 0;
  }

  handleDragEnd() {
    this.dragNode = null;
    this.dragNodeExpandOverNode = null;
    this.dragNodeExpandOverTime = 0;
  }

  canDeactivate() {
    return this.isDirty;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
