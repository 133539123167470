import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { delay, mergeMap, take } from 'rxjs/operators';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { v4 } from 'uuid';
import { DisplayProductDataService } from '../display-product-data.service';

@Component({
  selector: 'app-pipe-part-create',
  templateUrl: './pipe-part-create.component.html',
  styleUrls: ['./pipe-part-create.component.scss']
})
export class PipePartCreateComponent implements OnInit {

  pipePartForm: UntypedFormGroup;
  userLocalStorage = new UserLocalStorage();

  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<PipePartCreateComponent>,
    private displayProductData: DisplayProductDataService,
  ) { }

  ngOnInit() {

    this.pipePartForm = this.fb.group({
      diameter: [null, Validators.required],
      elbow_90: ['', Validators.required],
      check_valve_spring: [null, Validators.required],
      comment: ['', Validators.required]
    });
  }

  onSubmit(data: any) {
    const user = this.userLocalStorage.loadUser();
    data.author = user.user.username;
    data.file = data.product_name_1;
    data.uuid = v4();

    this.displayProductData.getPipePartList()
      .pipe(
        take(1),
        mergeMap(allPipeParts => {
          data.id = allPipeParts.length;
          return this.displayProductData.createPipePartItem(data);
        })
      ).subscribe(item => {
        this.snackBar
          .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
          .afterDismissed()
          .pipe(delay(0))
          .subscribe(() => {
            const url = `${data.uuid}/1/preview`;
            this.displayProductData.setPipePartState(url, data).subscribe(() => {
              this.dialogRef.close(data);
            });
          });
      });
  }

}
