<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      pump end
    </mat-panel-title>
    <mat-panel-description>
      {{ pumpEndsName }}
    </mat-panel-description>
  </mat-expansion-panel-header>

  <mat-form-field class="form-element" appearance="fill">
    <mat-label>Select pump end</mat-label>
    <input type="text" placeholder="Search pump end" matInput [formControl]="pumpendControl"
      [matAutocomplete]="pumpendAuto" (focus)="onPumpendInputFocus()" (blur)="updatePumpend($event.target.value)"
      (input)="pumpendChanges.next($event.target.value)" />
    <mat-autocomplete #pumpendAuto="matAutocomplete" (optionSelected)="setPumpendChangeAction($event)">
      <mat-option *ngFor="let option of pumpendSuggestions | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="pumpendControl.hasError('required')">The field name is required</mat-error>
    <mat-error *ngIf="pumpendControl.hasError('noOption')">Pump end not Found</mat-error>
  </mat-form-field>

  <div class="container inner-item" *ngIf="showPumpendsForm" novalidate>
    <form *ngIf="pumpend | async; else pumpendLoading" [formGroup]="pumpendForm">
      <mat-form-field class="form-element">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="name">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>Item</mat-label>
        <input matInput placeholder="Item" formControlName="item">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>material</mat-label>
        <input matInput placeholder="material" formControlName="material">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>Net Weight</mat-label>
        <input matInput placeholder="Net Weight" formControlName="net_weight">
        <span matSuffix>kg</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>length</mat-label>
        <input matInput placeholder="length" formControlName="length">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>diameter</mat-label>
        <input matInput placeholder="diameter" formControlName="diameter">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>parts picture</mat-label>
        <input matInput placeholder="parts picture" formControlName="parts_picture">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>parts list name</mat-label>
        <input matInput placeholder="parts list name" formControlName="parts">
      </mat-form-field>

    </form>

    <ng-template #pumpendLoading>
      <mat-progress-spinner mode="indeterminate" [diameter]=20></mat-progress-spinner>
    </ng-template>
  </div>
</mat-expansion-panel>