import { Action, createReducer, on } from "@ngrx/store";
import { ConnectedProduct, ConnectedProductDetail, ConnectedProductEvent } from "../../pump-data.model";
import * as ConnectedProductActions from "./connected-product.actions";

export const connectedProductFeatureKey = 'connectedProduct';

export interface ConnectedProductState {
  connectedProducts: ConnectedProduct[];
  connectedProductDetail: ConnectedProductDetail[];
  pumpSystemUuid: String;
  prepareList: Partial<ConnectedProductDetail>[];
  selectedList: Partial<ConnectedProductDetail>[];
  expandedEvent: ConnectedProductEvent | null;
  showSelectedTableHeader: Boolean;
  loading: Boolean;
  packaging: String;
  isCopied: Boolean;
}

const initialState: ConnectedProductState = {
  connectedProducts: [],
  connectedProductDetail: [],
  pumpSystemUuid: '',
  prepareList: [],
  selectedList: [],
  expandedEvent: null,
  showSelectedTableHeader: true,
  loading: false,
  packaging: '',
  isCopied: false
}

const reducer = createReducer(
  initialState,
  on(ConnectedProductActions.loadConnectedProducts, (state, action) => {
    return {
      ...state,
      loading: true
    }
  }),
  on(ConnectedProductActions.loadConnectedProductsSuccess, (state, action) => {
    return {
      ...state,
      connectedProducts: action.data,
      loading: false
    }
  }),
  on(ConnectedProductActions.loadConnectedProductsFailure, (state, action) => {
    return {
      ...state,
      loading: false
    }
  }),
  on(ConnectedProductActions.updateConnectedProduct, (state, action) => {
    return {
      ...state,
      connectedProducts: state.connectedProducts.map((accessory) => accessory.uuid === action.data.uuid ? { ...action.data } : accessory)
    }
  }),
  on(ConnectedProductActions.createConnectedProduct, (state, action) => {
    return {
      ...state,
      connectedProducts: [{ ...action.data }, ...state.connectedProducts]
    }
  }),
  on(ConnectedProductActions.loadAllConnectedProductSuccess, (state, action) => {
    return {
      ...state,
      connectedProductDetail: action.data
    }
  }),
  on(ConnectedProductActions.loadAllConnectedProductFailure, (state, action) => {
    return {
      ...state
    }
  }),
  on(ConnectedProductActions.setConnectedProductPackaing, (state, action) => {
    return {
      ...state,
      packaging: action.packaging
    }
  }),
  on(ConnectedProductActions.setConnectedProductPrepareList, (state, action) => {
    return {
      ...state,
      prepareList: action.data,
    }
  }),
  on(ConnectedProductActions.setConnectedProductSelectedList, (state, action) => {
    return {
      ...state,
      selectedList: action.data,
    }
  }),
  on(ConnectedProductActions.setPumpSystemUuid, (state, action) => {
    return {
      ...state,
      pumpSystemUuid: action.pumpSystemUuid
    }
  }),
  on(ConnectedProductActions.addConnectedProductItem, (state, action) => {
    return {
      ...state,
      prepareList: [{ ...action.data }, ...state.prepareList],
      selectedList: state.selectedList.filter((item) => item.uuid !== action.data.uuid)
    }
  }),
  on(ConnectedProductActions.removeConnectedProductItem, (state, action) => {
    return {
      ...state,
      selectedList: [{ ...action.data }, ...state.selectedList],
      prepareList: state.prepareList.filter((item) => item.uuid !== action.data.uuid)
    }
  }),
  on(ConnectedProductActions.setConnectedProductExpandedEvent, (state, action) => {
    return {
      ...state,
      expandedEvent: action.data
    }
  }),
  on(ConnectedProductActions.setConnectedProductCopyEvent, (state, action) => {
    return {
      ...state,
      isCopied: action.isCopied
    }
  })
);

export function connectedProductReducer(
  state: ConnectedProductState,
  action: Action
): ConnectedProductState {
  return reducer(state, action);
}

