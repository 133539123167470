import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, AbstractControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { select, Store } from '@ngrx/store';
import { Subscription, Subject, Observable, startWith, map, combineLatest } from 'rxjs';
import { DisplayProductDataService } from 'src/app/display-product-data/display-product-data.service';
import { PictureCreateComponent } from 'src/app/display-product-data/picture-create/picture-create.component';
import { PictureViewComponent } from 'src/app/display-product-data/picture-view/picture-view.component';
import { setPartsPicture } from 'src/app/display-product-data/store/parts-list/parts-list.actions';
import { PartsListState } from 'src/app/display-product-data/store/parts-list/parts-list.reducer';
import { selectPartsList } from 'src/app/display-product-data/store/parts-list/parts-list.seletor';
import { environment } from 'src/environments/environment';
import { nameSort } from '../../util';

@Component({
  selector: 'app-parts-list-picture',
  templateUrl: './parts-list-picture.component.html',
  styleUrls: ['./parts-list-picture.component.scss']
})
export class PartsListPictureComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  pictureList = [];
  pictureStore = '';
  pictureNamesList = [];
  dropdownList: string[] = [];
  partspictureControl = new UntypedFormControl('', [
    this.optionNotFound.bind(this),
  ]);
  filteredOptions: Set<string>;
  public readonly partspictureChanges: Subject<string> = new Subject<string>();
  public partspictureSuggestions: Observable<string[]>;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit(): void {
    const getPictureAndDrawingList = combineLatest([
      this.displayProductData.getPictureAndDrawingList(),
      this.store.pipe(
        select(selectPartsList),
        map((data: PartsListState) => data.partsPicture)
      ),
    ]).subscribe(([result, picture]) => {
      const pictureNames = nameSort(result);
      this.pictureList = pictureNames;//.filter(item => item.type === 'picture');
      this.pictureList.forEach(item => this.pictureNamesList.push(item.name));
      this.dropdownList = this.pictureList.map(item => item.name);
      this.dropdownList.unshift('Not Available');
      this.dropdownList.unshift('');
      if (this.pictureStore !== '' && !this.dropdownList.includes(this.pictureStore)) {
        this.dropdownList.unshift(this.pictureStore);
      }
      this.dropdownList.push('Add new picture');
      this.partspictureSuggestions = this.partspictureChanges.pipe(
        startWith(''),
        map((val: string) => this.filterResults(val))
      );
      if (picture !== '' && picture !== 'Add new picture') {
        const item = this.pictureList.find(item => item.name === picture);
        if (item) {
          this.partspictureControl.setValue(item.name);
        } else {
          this.pictureStore = `${picture}`;
          // this.dropdownList.unshift(`${picture}`);
          this.partspictureControl.setValue(picture);
        }
      }
    });
    this.subscriptions.push(getPictureAndDrawingList);
  }

  private filterResults(val: string) {
    if (val !== '') {
      return this.dropdownList.filter(item => item.includes(val));
    } else {
      return this.dropdownList;
    }
  }

  optionNotFound(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value) {
      this.filteredOptions = new Set(
        this.dropdownList.filter((option) => option.toLowerCase().indexOf(value.toLowerCase()) > -1)
      );
    }
    if (value && !this.filteredOptions.size) {
      return {
        noOption: true
      };
    }
    return null;
  }

  onFocus(value: string) {
    if (value === '') {
      this.partspictureChanges.next('');
    };
    this.optionNotFound(this.partspictureControl);
  }

  updatePartspicture(value: string) {
    if (value === '') {
      this.partspictureControl.reset('');
    } else {
      this.partspictureControl.setValue(value);
    };
  }

  setPartspictureChangeAction(event: MatAutocompleteSelectedEvent) {
    if (event.option.value === 'Add new picture') {
      const dialogRef = this.dialog.open(PictureCreateComponent, {
        width: '1000px',
        maxWidth: '96vw',
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.pictureList.unshift(result);
          this.dropdownList = this.pictureList.map(item => item.name);
          this.dropdownList.unshift('Not Available');
          this.dropdownList.unshift('');
          this.dropdownList.push('Add new picture');
          this.partspictureControl.setValue(result.name);
        } else {
          this.partspictureControl.setValue(this.pictureStore);
        }
      });
    } else if (event.option.value === '') {
      this.pictureStore = '';
      this.partspictureControl.reset('');
    }

    this.store.dispatch(setPartsPicture({ data: event.option.value }));
  }

  openViewDialog() {

    const picture = this.partspictureControl.value;
    if (picture === 'notAvailable') {
      this.snackBar.open('Please choose picture first', '', {
        duration: 1500,
        panelClass: 'hintMsg'
      });
    } else if (picture) {
      this.pictureList.filter(item => {
        if (item.name === picture) {
          const extension = item.type === 'picture' ? 'jpg' : 'gif';
          const typePath = item.type === 'picture' ? 'Pictures' : 'Drawings';
          const imgUrl = `https://catalog-data-images-${environment.stage}.s3-eu-west-1.amazonaws.com/${typePath}/${item.uuid}/${item.name}.${extension}`;
          this.displayProductData.getImage(imgUrl).subscribe(
            () => {
              this.dialog.open(PictureViewComponent, {
                width: '1000px',
                maxWidth: '90vw',
                minHeight: '60vh',
                maxHeight: '90vh',
                data: {
                  url: imgUrl,
                  title: item.name
                },
              });
            },
            error => {
              this.snackBar.open('This picture is not available, please notice it.', '', {
                duration: 1500,
                panelClass: 'hintMsg'
              });
            },
            () => { }
          );
        }
      });
    } else {
      this.snackBar.open('Please choose picture first', '', {
        duration: 1500,
        panelClass: 'hintMsg'
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
