import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as XLSX from 'xlsx';
import { UploadDownloadDialogueComponent } from '../upload-download-dialogue/upload-download-dialogue.component';
import { UploadService } from '../service/upload.service';
import { DisplayProductDataService } from '../../display-product-data/display-product-data.service';
import { environment } from '../../../environments/environment';
import { map, Subscription } from 'rxjs';
import { PumpSystem } from '../../display-product-data/pump-data.model';
import { UserLocalStorage } from '../../login/user-local-store';
import { setPSK3UploadDownloadData } from '../store/upload-download/upload-download.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-upload-download-psk3',
  templateUrl: './upload-download-psk3.component.html',
  styleUrls: ['./upload-download-psk3.component.scss']
})
export class UploadDownloadPSK3Component implements OnInit, OnDestroy, AfterViewInit {

  displayedColumns: string[] = [];
  @Input() dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  profileForm: UntypedFormGroup;
  uploadFileName: string;
  showUploadFileName = false;
  uploadedFile: any = [];
  file: File;
  arrayBuffer: any;
  fileList: any;
  userLocalStorage = new UserLocalStorage();
  customFilename: string = '';

  selectUploadFile: any;
  uploadFileContent = '';
  uploadFileVersion = '';
  fileTableHeader = [];
  filename: any = 'PSK3';
  version: any;
  showFileContentLoading = false;
  showFileContentTable = true;
  FILENAME: any = environment.lorentzAssistName;
  subscriptions: Subscription[] = [];
  pumpSystemList: PumpSystem[] = [];
  versions: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private uploadService: UploadService,
    private displayProductDataService: DisplayProductDataService,
    private dialog: MatDialog,
    private store: Store
  ) { }

  ngOnInit() {
    this.initProfileForm();

    this.initImportData();

  }

  private async initImportData() {
    // reset the lists
    this.pumpSystemList = [];

    const pumpSystemDetailSubscription = this.displayProductDataService.getPumpSystemList().pipe(
      map(pumpSystemList => this.pumpSystemList = pumpSystemList)
    ).subscribe();
    this.subscriptions.push(pumpSystemDetailSubscription);

    const versionsSubscription = (await this.uploadService.getAllVersions()).pipe(
      map(versions => {
        this.versions = versions;
      })
    ).subscribe();
    this.subscriptions.push(versionsSubscription);

  }

  onFileSelect(event: { target: { files: string | any[]; }; }, filename?: string) {
    if (event.target.files.length > 0) {
      this.parseFile(event);
      const confirmationDialogueSubscription = this.handleConfirmationDialogue(event, filename);
      this.subscriptions.push(confirmationDialogueSubscription);
    }
  }

  async profileFormSubmit(filename?: string) {
    this.prepareFileContentForDisplay();
    const downloadAndroidFileSubscription = (await this.uploadService.downloadAndroidFile(filename? filename: this.filename, this.version)).subscribe(item => {
      this.extractFileToTable(item, true);
    });
    this.subscriptions.push(downloadAndroidFileSubscription);

  }



  private prepareFileContentForDisplay() {
    this.showFileContentLoading = true;
    this.showFileContentTable = true;
    this.uploadFileContent = '';
    this.uploadFileVersion = '';
    this.filename = this.FILENAME;
    this.version = 0;
  }

  private extractFileToTable(item: any, preview?: boolean) {
    this.showFileContentLoading = false;
    this.fileTableHeader = [];
    if (item && item.length !== 0) {
      this.uploadedFile = item;
      const uploadFileContent = JSON.stringify(this.uploadedFile);
      const uploadFileVersion = preview ? this.uploadedFile.version : this.uploadedFile.version.version;
      const data = preview ? JSON.parse(this.uploadedFile.file) : this.uploadedFile.entries;
      data.forEach((dataItem: {}) => {
        Object.keys(dataItem).map(value => this.fileTableHeader.push(value));
        this.fileTableHeader = [...new Set(this.fileTableHeader)];
      });
      this.showFileContentTable = false;
      this.displayedColumns = this.fileTableHeader;
      this.dataSource.data = data;
      this.store.dispatch(setPSK3UploadDownloadData({data, file: uploadFileContent, version: uploadFileVersion}));
    }
  }

  initProfileForm() {
    this.profileForm = this.fb.group({
      filename: [this.FILENAME, Validators.required],

    });
  }

  selectedUploadedFile(event: MatSelectChange) {
    this.selectUploadFile = event.value;
  }

  handleConfirmationDialogue(event, filename?: string) {
    const dialogRef = this.dialog.open(UploadDownloadDialogueComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
      data: {
        message: 'Are you sure you want to publish this version?',
        type: 'confirm'
      }
    });
    return dialogRef.afterClosed().subscribe((result) => {
      if (result) {

        try {
          this.uploadService.uploadAndroidFile(JSON.stringify(this.fileList), filename);
        } catch (err) { }
        this.showUploadFileName = true;
        this.uploadFileName = event.target.files[0].name;
        this.uploadFileName = this.uploadFileName.concat(' has been uploaded.');
        return true;
      } else {
        window.location.reload();
        return false;
      }
    });
  }

  parseFile(event) {

    this.file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = async (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.fileList = arraylist;

      // this.prepareFileContentForDisplay();
      // this.extractFileToTable(this.fileList, true);
    };
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // If the user changes the sort order, reset back to the first page.
    // this.sort.sortChange.subscribe(() => this.paginator.firstPage());
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

}
