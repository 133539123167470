<section class="main">

  <mat-card appearance="outlined">

    <mat-card-content>


      <div class="main-wrapper">

        <div class="part-left">

          <app-upload-download-sizing-app></app-upload-download-sizing-app>
        </div>


      <div class="part-right">


      </div>
    </div>

    </mat-card-content>
  </mat-card>






</section>

