import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { Subscription, concatAll, mergeMap, take, toArray, map } from 'rxjs';
import { CopyButtonComponent } from 'src/app/display-product-data/copy-button/copy-button.component';
import { DisplayProductDataService } from 'src/app/display-product-data/display-product-data.service';
import { ConnectedProduct, ConnectedProductDetail, ConnectedProductEvent } from 'src/app/display-product-data/pump-data.model';
import { addConnectedProductItem, setConnectedProductCopyEvent, setConnectedProductPackaing, setConnectedProductPrepareList, setConnectedProductSelectedList, setConnectedProductExpandedEvent } from 'src/app/display-product-data/store/connected-product/connected-product.actions';
import { ConnectedProductState } from 'src/app/display-product-data/store/connected-product/connected-product.reducer';
import { selectConnectedProduct } from 'src/app/display-product-data/store/connected-product/connected-product.seletor';
import { fileSort } from '../../util';

@Component({
  selector: 'app-connected-products-select',
  templateUrl: './connected-products-select.component.html',
  styleUrls: ['./connected-products-select.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ConnectedProductsSelectComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  @Input() dataSource = new MatTableDataSource();

  connectedProductList: ConnectedProduct[] = [];
  displayedColumns: string[] = ['index', 'name'];
  showConnectedForm = false;
  expandedEvent: ConnectedProductEvent | null;
  defaultConnectedProductList = [];

  showConnectedProductLoading = false;
  copiedConnectedProductName: string;
  showTableHeader = false;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private displayProductData: DisplayProductDataService,
  ) { }

  ngOnInit(): void {
    const getConnectedProductList = this.displayProductData.getConnectedProductList()
    .pipe(
      take(1),
      concatAll(),
      mergeMap(item => this.displayProductData.getConnectedProductItem(item.uuid)),
      toArray()
    )
    .subscribe(result => {
      for (const item of result) {
        const tartget = item.versions[0];
        tartget.file = item.name;
        tartget.uuid = item.uuid;
        tartget.version = item.versions[0].version_number;
        this.defaultConnectedProductList.push(tartget);
      }
    });
    this.subscriptions.push(getConnectedProductList);

    const selectedTableData = this.store.pipe(
      select(selectConnectedProduct),
      map((data: ConnectedProductState) => data.selectedList)
    ).subscribe((data: ConnectedProductDetail[]) => {
      if (data.length !== 0) {
        this.showTableHeader = true;
      } else {
        this.showTableHeader = false;
      }
      this.dataSource.data = fileSort([...data]);
    });
    this.subscriptions.push(selectedTableData);

    const getExpandedEvent = this.store.pipe(
      select(selectConnectedProduct),
      map((data: ConnectedProductState) => data.expandedEvent)
    ).subscribe((data) => this.expandedEvent = data);
    this.subscriptions.push(getExpandedEvent);
  }

  openItem() {
    this.store.dispatch(setConnectedProductExpandedEvent({ data: this.expandedEvent }));
    if (this.expandedEvent) {
      this.store.dispatch(setConnectedProductPackaing({ packaging: this.expandedEvent.packaging}));
    }
  }

  removeItem(event) {
    this.store.dispatch(addConnectedProductItem({ data: event }));
  }

  openCopyDialog(type: string) {
    this.copiedConnectedProductName = '';
    const dialogRef = this.dialog.open(CopyButtonComponent, {
      width: '1000px',
      maxWidth: '96vw',
      data: type,
      minHeight: '60vh',
      maxHeight: '60vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showConnectedProductLoading = true;
        this.copyConnectedProduct(result);
      }
    });
  }

  copyConnectedProduct(pumpSystemUuid: string) {
    this.copiedConnectedProductName = '';
    this.store.dispatch(setConnectedProductPrepareList({ data: [] }));
    this.store.dispatch(setConnectedProductSelectedList({ data: [] }));
    this.store.dispatch(setConnectedProductCopyEvent({ isCopied: true }));
    this.displayProductData.getPumpSystemItem(pumpSystemUuid)
      .subscribe((pumpSystemData: any) => {
        const pumpSystem = JSON.parse(pumpSystemData['message']);
        this.showConnectedProductLoading = false;
        this.copiedConnectedProductName = pumpSystem.name;
        const allData = pumpSystem.versions[0];
        if (allData.connectedProducts) {
          this.store.dispatch(setConnectedProductSelectedList({ data: allData.connectedProducts }));
          const prepareList = [];
          this.defaultConnectedProductList.forEach(item => {
            const target = allData.connectedProducts.map(x => x.uuid);
            if(!target.includes(item.uuid)) {
              prepareList.push(item);
            }
          });
          this.store.dispatch(setConnectedProductPrepareList({ data: prepareList }));
        } else {
          this.store.dispatch(setConnectedProductSelectedList({ data: [] }));
          this.store.dispatch(setConnectedProductPrepareList({ data: this.defaultConnectedProductList }));
        };
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
