import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { DisplayPumpSimulationService } from "../../../pump-simulation/display-pump-simulation.service";
import { CalculationDetail, CoefficientSetItemDetail, SelectedCalculationDetail } from "../../pump-data.model";
import * as CoefficientSetActions from './coefficient-set.actions';


@Injectable()

export class CoefficientSetEffects {

  // Get all Coefficients
  loadCoefficientSets$ = createEffect(() => this.actions$.pipe(
    ofType(CoefficientSetActions.loadCoefficientSets),
    mergeMap(() => this.simulationService.getAllLiveMeasurementFileVersions()
    .pipe( tap(data => { data.map(detail =>
      {if (!this.isEmpty(detail.selected_coefficients))
    { detail.coefficients = this.transformSelectedCoefficientsIntoCoefficients(detail.selected_coefficients) }})}),
      map((data: CoefficientSetItemDetail[]) => CoefficientSetActions.loadCoefficientSetsSuccess({ data })),
      catchError((error) => of(CoefficientSetActions.loadCoefficientSetsFailure({ error })))
    ))

  ));

  constructor(
    private actions$: Actions,
    private simulationService: DisplayPumpSimulationService,
  ){}

  private isEmpty(obj: Object): boolean {
    return !obj || !Object.keys(obj).some(x => obj[x] !== void 0);
  }

  private transformSelectedCoefficientsIntoCoefficients(selectedCoefficientsString: string){

    const selectedCoefficients: SelectedCalculationDetail = JSON.parse(selectedCoefficientsString);
    const coefficient: CalculationDetail = {
      corr_fact: selectedCoefficients.corr_fact.value,
      filename: selectedCoefficients.filename,
      displayName: selectedCoefficients.displayName,
      mai: selectedCoefficients.mai.value,
      maq: selectedCoefficients.maq.value,
      mar_: selectedCoefficients.mar_.value,
      mbi: selectedCoefficients.mbi.value,
      mbq: selectedCoefficients.mbq.value,
      mbr: selectedCoefficients.mbr.value,
      mci: selectedCoefficients.mci.value,
      mcq: selectedCoefficients.mcq.value,
      mcr: selectedCoefficients.mcr.value,
      mdq_: selectedCoefficients.mdq_.value,
      nai: selectedCoefficients.nai.value,
      naq: selectedCoefficients.naq.value,
      nar_: selectedCoefficients.nar_.value,
      nbi: selectedCoefficients.nbi.value,
      nbq: selectedCoefficients.nbq.value,
      nbr: selectedCoefficients.nbr.value,
      nci: selectedCoefficients.nci.value,
      ncq: selectedCoefficients.ncq.value,
      ncr: selectedCoefficients.ncr.value,
      ndq_: selectedCoefficients.ndq_.value,
      npsh_a: selectedCoefficients.npsh_a.value,
      npsh_c: selectedCoefficients.npsh_c.value,
      npsh_exp: selectedCoefficients.npsh_exp.value,
      split_fact: selectedCoefficients.split_fact
    }
    return JSON.stringify(coefficient);
  }

}
