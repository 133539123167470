import { AfterContentInit, Component, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LoginService } from './login.service';


const HTTP_UNAUTHORIZED = 401;
const HTTP_FORBIDDEN = 403;
const HTTP_GATEWAY_TIMEOUT = 504;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


export class LoginComponent implements AfterContentInit {

  loginForm = new UntypedFormGroup({
    username: new UntypedFormControl(),
    password: new UntypedFormControl()
  });

  badCredentials = false;
  message = '';
  running = false;
  hide = true;
  constructor(private loginService: LoginService, private renderer: Renderer2) { }

  ngAfterContentInit(): void {
    this.setFocusToUsernameInput();
  }

  login() {
    this.message = '';
    this.running = true;
    let abstractControl = this.loginForm.get('username');
    const username = abstractControl !== null ? abstractControl.value : '';
    abstractControl = this.loginForm.get('password');
    const password = abstractControl !== null ? abstractControl.value : '';
    this.loginService.login(username, password).subscribe(
      (_) => {
        this.running = false;
      },
      (error) => {
        if (error.status === HTTP_GATEWAY_TIMEOUT) {
          this.badCredentials = false;
          this.message = 'The login server currently is unreachable. Please try again later.';
        } else if (error.status === HTTP_UNAUTHORIZED || error.status === HTTP_FORBIDDEN) {
          this.badCredentials = true;
        } else {
          this.badCredentials = false;
          this.message = 'Authentication Error';
        }
        this.running = false;
      },
      () => {
        this.running = false;
        this.badCredentials = false;
      });
  }

  /** Sets the input focus to the username field to enable users to start typing the usersname without click on the field */
  private setFocusToUsernameInput() {
    const loginService = this;

    // defer settings the focus to the next tick to avoid
    // the angular error because of changed component after change
    // detection
    setTimeout(() => {
      const onElement = loginService.renderer.selectRootElement('.username-input');
      onElement.focus();
    }, 1);
  }
}
