<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      motor
    </mat-panel-title>
    <mat-panel-description>
      {{ motorName }}
    </mat-panel-description>
  </mat-expansion-panel-header>

  <mat-form-field class="form-element" appearance="fill">
    <mat-label>Select motor</mat-label>
    <input type="text" placeholder="Search motor" matInput [formControl]="motorControl" [matAutocomplete]="motorAuto"
      (focus)="onMotorFocus()" (blur)="updateMotor($event.target.value)"
      (input)="motorChanges.next($event.target.value)" />
    <mat-autocomplete #motorAuto="matAutocomplete" (optionSelected)="setMotorChangeAction($event)">
      <mat-option *ngFor="let option of motorSuggestions | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="motorControl.hasError('required')">The field name is required</mat-error>
    <mat-error *ngIf="motorControl.hasError('noOption')">Motor not Found</mat-error>
  </mat-form-field>

  <div class="container inner-item" *ngIf="showMotorForm" novalidate>
    <form *ngIf="motor | async; else motorLoading" [formGroup]="motorForm">
      <mat-form-field class="form-element">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="name">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>Item</mat-label>
        <input matInput placeholder="Item" formControlName="item">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>material</mat-label>
        <input matInput placeholder="material" formControlName="material">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>Net Weight</mat-label>
        <input matInput placeholder="Net Weight" formControlName="net_weight">
        <span matSuffix>kg</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>length</mat-label>
        <input matInput placeholder="length" formControlName="length">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>diameter</mat-label>
        <input matInput placeholder="diameter" formControlName="diameter">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>dimension A</mat-label>
        <input matInput placeholder="dimension A" formControlName="diameterA">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>dimension B</mat-label>
        <input matInput placeholder="dimension B" formControlName="diameterB">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>dimension C</mat-label>
        <input matInput placeholder="dimension C" formControlName="diameterC">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>max subm.</mat-label>
        <input matInput placeholder="max subm." formControlName="max_subm">
      </mat-form-field>

      <app-insulation-class-select formControlName="ins_class"></app-insulation-class-select>

      <mat-form-field class="form-element">
        <mat-label>encl. class</mat-label>
        <input matInput placeholder="encl. class" formControlName="ingress_protection_code">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>ac poles</mat-label>
        <input matInput placeholder="ac poles" formControlName="ac_poles">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>cos phi</mat-label>
        <input matInput placeholder="cos phi" formControlName="cos_phi">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>rated power</mat-label>
        <input matInput placeholder="rated power" formControlName="rated_power">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>motor min u</mat-label>
        <input matInput placeholder="motor min u" formControlName="m_min_u">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>motor max u</mat-label>
        <input matInput placeholder="motor max u" formControlName="m_max_u">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>max speed</mat-label>
        <input matInput placeholder="max speed" formControlName="max_speed">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>max speed Hz</mat-label>
        <input matInput placeholder="max speed Hz" formControlName="max_speed_Hz">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>min speed</mat-label>
        <input matInput placeholder="min speed" formControlName="min_speed">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>min speed Hz</mat-label>
        <input matInput placeholder="min speed Hz" formControlName="min_speed_Hz">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>min set speed Hz</mat-label>
        <input matInput placeholder="min set speed Hz" formControlName="min_set_speed_Hz">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>min set speed</mat-label>
        <input matInput placeholder="min set speed" formControlName="min_set_speed">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>parts picture</mat-label>
        <input matInput placeholder="parts_picture" formControlName="parts_picture">
      </mat-form-field>

    </form>

    <ng-template #motorLoading>
      <mat-progress-spinner mode="indeterminate" [diameter]=20></mat-progress-spinner>
    </ng-template>
  </div>

</mat-expansion-panel>