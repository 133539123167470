import { createAction, props } from "@ngrx/store";

export const setIntermediateLoading = createAction(
  '[Charts Part] Load Intermediate Chart',
  props<{ data: Boolean }>()
);

export const setIntermediateData = createAction(
  '[Charts Part] Set Intermediate Data',
  props<{ data: any }>()
);

export const setRedrawChartItems = createAction(
  '[Charts Part] Set Intermediate Graph Item Need Redraw',
  props<{ data: String[] }>()
);
