import { createFeatureSelector, createSelector } from "@ngrx/store";
import { partsListFeatureKey } from "./parts-list.reducer";


export const selectPartsListState = createFeatureSelector(partsListFeatureKey);

export const selectPartsList = createSelector(
  selectPartsListState,
  (state: any) => state
);
