import { createAction, props } from "@ngrx/store";

export const setControlllerName = createAction(
  '[Pump System Form] Set Controlller Name',
  props<{ data: String }>()
);

export const setPumpEndName = createAction(
  '[Pump System Form] Set Pump End Name',
  props<{ data: String }>()
);

export const setMotorName = createAction(
  '[Pump System Form] Set Motor Name',
  props<{ data: String }>()
);

export const setPartsListName = createAction(
  '[Pump System Form] Set Parts List Name',
  props<{ data: String }>()
);
