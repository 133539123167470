<h2 mat-dialog-title> Create New Country </h2>
<mat-dialog-content>
  <form [formGroup]="countryForm" class="dialog">
    <mat-form-field class="form-element">
      <mat-label> Country Name </mat-label>
      <input matInput placeholder="Country Name" formControlName="name">
      <mat-error *ngIf="countryForm.get('name').hasError('required')">The country name is required</mat-error>
      <mat-error *ngIf="countryForm.get('name').hasError('duplicateNames')">This country name already exists</mat-error>
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label> Capital </mat-label>
      <input matInput placeholder="Capital" formControlName="capital">
      <mat-error *ngIf="countryForm.get('capital').hasError('required')">The country capital is required</mat-error>
      <mat-error *ngIf="countryForm.get('capital').hasError('duplicateNames')">This capital already exists</mat-error>
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label> Water Temperature </mat-label>
      <input matInput min="0" type="number" placeholder="Water Temperature" formControlName="water_temp">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="greyBtn" mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" type="submit" data-testid="submit" [disabled]="!countryForm.valid" (click)="onSubmit(countryForm.value)"> Create </button>
</mat-dialog-actions>
