export interface CoefficientInputParameters {
  pump: string;
  controller: string;
  type: string;
  splitPower: number;
  minLift: number;
  maxLift: number;
  excelBehaviour: boolean;
  corr_fact?: number;
}

export interface IntermediateCoefficients {
  aq: number;
  bq: number;
  cq: number;
  dq: number;
  ai: number;
  bi: number;
  ci: number;
  ar: number;
  br: number;
  cr: number;
}

export interface MeasurementRow {
  id: number;
  current: number;
  voltage: number;
  volume: number;
  rotationalSpeed: number;
  measurementTime: number;
  headPressure: number;
  power: number;
  flowRate: number;
}

export interface NPSHRow {
  flowRate: number;
  suctionHead: number;
}

export interface CoefficientResultRow {
  intermediateCoefficentsJSON: {};
  maq: number;
  naq: number;
  mbq: number;
  nbq: number;
  mcq: number;
  ncq: number;
  mdq_: number;
  ndq_: number;
  mai: number;
  nai: number;
  mbi: number;
  nbi: number;
  mci: number;
  nci: number;
  mar_: number;
  nar_: number;
  mbr: number;
  nbr: number;
  mcr: number;
  ncr: number;
  corr_fact: number;
  npsh_a: number;
  npsh_exp: number;
  npsh_c: number;
  intermediateCoefficents: Map<number, IntermediateCoefficients>;
}

export interface SelectedCoefficientResultRow {
  intermediateCoefficentsJSON: {};
  maq: {value: number, man: boolean};
  naq: {value: number, man: boolean};
  mbq: {value: number, man: boolean};
  nbq: {value: number, man: boolean};
  mcq: {value: number, man: boolean};
  ncq: {value: number, man: boolean};
  mdq_: {value: number, man: boolean};
  ndq_: {value: number, man: boolean};
  mai: {value: number, man: boolean};
  nai: {value: number, man: boolean};
  mbi: {value: number, man: boolean};
  nbi: {value: number, man: boolean};
  mci: {value: number, man: boolean};
  nci: {value: number, man: boolean};
  mar_: {value: number, man: boolean};
  nar_: {value: number, man: boolean};
  mbr: {value: number, man: boolean};
  nbr: {value: number, man: boolean};
  mcr: {value: number, man: boolean};
  ncr: {value: number, man: boolean};
  corr_fact: {value: number, man: boolean};
  npsh_a: {value: number, man: boolean};
  npsh_exp: {value: number, man: boolean};
  npsh_c: {value: number, man: boolean};
  intermediateCoefficents: Map<number, IntermediateCoefficients>;
}

export class VersionStatus {
  static PROVISIONAL = 'PROVISIONAL';
  static INITIAL = 'INITIAL';
  static LIVE = 'LIVE';
}

export interface CoefficientSetItem {
  approval_state: string;
  filename: string;
  version: number;
  displayName: string;
}

export interface CoefficientNameItem {
  filename: string;
  displayName: string;
}

export const RecalculateFormItemNames: string[] = [
  'maq', 'mcq', 'naq', 'ncq', 'mbq', 'mdq_', 'nbq',
  'ndq_', 'mbi', 'mci', 'nbi', 'nci', 'mar_', 'mcr',
  'nar_', 'ncr', 'mbr', 'mai', 'nbr', 'nai',
  'corr_fact', 'npsh_a', 'npsh_exp', 'npsh_c'];

export const IntermediateInputValue: String[] = [
  'maq', 'mcq', 'naq', 'ncq', 'mbq', 'mdq_', 'nbq',
  'ndq_', 'mbi', 'mci', 'nbi', 'nci', 'mar_', 'mcr',
  'nar_', 'ncr', 'mbr', 'mai', 'nbr', 'nai'];

export const IntermediateItemNames: String[] = ['aqChart', 'cqChart', 'bqChart', 'dqChart', 'biChart', 'ciChart', 'arChart', 'crChart', 'brChart', 'aiChart']

export const PROFILEFORMPARTTWO_ITEMS = ['excelBehaviour', 'splitPower', 'minLift', 'maxLift'];

export interface ProfileFormParameters {
  pump: string,
  controller: string,
  type: string,
  splitPower: number,
  minLift: number,
  maxLift: number,
  excelBehaviour: boolean,
  corr_fact?: number,
}

export interface ProfileFormStepper {
  stepIndexNow: number,
  stepIndexNext: number,
  stepForwardDirection: boolean,
  enableStepOneBtn: boolean,
  showRevertBtn: boolean
}

export interface DataSource {
  name: string,
  uploadFileName: string,
  uploadNewData: any,
  useUploadedData: any,
  useSetName: string
}

export interface ChartTableData {
  isRevertDeletedData: boolean,
  isRevertAllDeletedData: boolean,
  deletedMeasurementData: MeasurementRow[],
  measurementData: MeasurementRow[],
  selectRowIndex: number,
  isRedrawFlowRateChart: boolean
}

export interface VersionTableData {
  name: string,
  versionKey: number
}
