import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { delay, filter, map, mergeMap } from 'rxjs/operators';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { DirtyComponent } from '../dirty-component';
import { DisplayProductDataService } from '../display-product-data.service';
import { ErrorButtonComponent } from '../error-button/error-button.component';
import { CONTROLLER_ITEMS } from '../pump-data.model';
import { SaveButtonComponent } from '../save-button/save-button.component';
import { PackagingState } from '../store/packaging/packaging.reducer';
import { selectPackaging } from '../store/packaging/packaging.selector';
import { ControllerItem, PackagingItem } from './controller.model';

@Component({
  selector: 'app-controllers',
  templateUrl: './controllers.component.html',
  styleUrls: ['./controllers.component.scss']
})

export class ControllersComponent implements OnInit, OnDestroy, DirtyComponent {

  private subscriptions: Subscription[] = [];

  controllerForm: UntypedFormGroup;
  controller: Observable<ControllerItem>;

  userLocalStorage = new UserLocalStorage();

  showControllerForm = false;
  isDirty = false;

  packagingList = [];

  originalControllerFormValue: any;

  constructor(
    private store: Store,
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {

    const getPackagingList = this.store.pipe(
      select(selectPackaging),
      map((data: PackagingState) => data.data)
    ).subscribe((data: PackagingItem[]) => this.packagingList = [...data].sort((a, b) => Number(a.subType.L) - Number(b.subType.L)));

    this.subscriptions.push(getPackagingList);

    const urlSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.showControllerForm = false;
      this.isDirty = false;
    });
    this.subscriptions.push(urlSubscription);

    const getDataSubscription = this.route.paramMap.pipe(
      map(params => params.get('uuid')),
      mergeMap(uuid => {
        return this.displayProductData.getControllerItem(uuid);
      })
    )
    .subscribe((controllerData) => {

      this.showControllerForm = true;

      const controllerFormValue: any = {};
      CONTROLLER_ITEMS.forEach(name => {
        const value = controllerData[`${name}`];
        controllerFormValue[`${name}`] = [value, Validators.required];
      });
      this.controllerForm = this.fb.group(controllerFormValue);

      this.originalControllerFormValue = this.controllerForm.value;
 
      const item = this.packagingList.find(item => item.uuid === this.controllerForm.value.packaging.uuid);
      this.originalControllerFormValue.packaging = item.name;

      this.controllerForm.valueChanges.subscribe((value) => {
 
        if ( this.controllerForm.get('packaging').value.uuid ) {
          const result = this.packagingList.filter(item => item.uuid === this.controllerForm.get('packaging').value.uuid);
          if ( result.length > 0 ) {
            value.packaging = result[0].name;
          } else {
            value.packaging = '';
          }
        } else {
          const result = this.packagingList.filter(item => item.name.includes(this.controllerForm.get('packaging').value));
          if (result.length !== 0) {
            value.packaging = result[0].name;
          }
        }

        this.isDirty = JSON.stringify(this.originalControllerFormValue) !== JSON.stringify(value);
      });
    });
    this.subscriptions.push(getDataSubscription);
  }

  onSave() {
    const dialogRef = this.dialog.open(SaveButtonComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateController();
      }
    });
  }

  updateController() {
    const user = this.userLocalStorage.loadUser();
    const data = this.controllerForm.value;
    data.author = user.user.username;

    if (this.controllerForm.value.packaging.subType) {
      const packagingValue: any = {};
      packagingValue.name = data.packaging.uuid;
      packagingValue.w = data.packaging.subType.W;
      packagingValue.h = data.packaging.subType.H;
      packagingValue.l = data.packaging.subType.L;
      packagingValue.pack_weight = data.packaging.weight;
      data.packaging = packagingValue;
    } else {
      const result = this.packagingList.filter(item => item.name.includes(data.packaging));
      const packaging: any = {};
      packaging.name = result[0].uuid;
      packaging.w = `${result[0].subType.W}`;
      packaging.h = `${result[0].subType.H}`;
      packaging.l = `${result[0].subType.L}`;
      packaging.pack_weight = `${result[0].weight}`;
      data.packaging = packaging;
    }

    this.displayProductData.updateController(data).subscribe(
      item => {
        this.snackBar.open(item, '', { duration: 1500, panelClass: 'hintMsg' })
        .afterDismissed()
        .pipe(delay(0))
        .subscribe(() => {
          this.ngOnInit();
          this.showControllerForm = false;
          this.isDirty = false;
        });
      },
      error => {
        this.dialog.open(ErrorButtonComponent, {
          width: '600px',
          data: JSON.parse(error.error),
          minHeight: '20vh',
        });
      },
      () => {}
    );
  }

  canDeactivate() {
    return this.isDirty;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
