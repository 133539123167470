import { AfterViewInit } from '@angular/core';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MaterialItem } from '../controllers/controller.model';
import { DisplayProductDataService } from '../display-product-data.service';
import { MaterialCreateComponent } from '../material-create/material-create.component';

@Component({
  selector: 'app-material-overview',
  templateUrl: './material-overview.component.html',
  styleUrls: ['./material-overview.component.scss']
})
export class MaterialOverviewComponent implements OnInit, AfterViewInit {

  materialForm: UntypedFormGroup;
  displayedColumns: string[] = ['name'];
  tableLoading = true;
  tableData = [];
  isFirstInit = true;
  @Input() dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    private displayProductData: DisplayProductDataService,
  ) { }

  ngOnInit() {
    this.displayProductData.getMaterialList().subscribe((allMaterials: MaterialItem[]) => {
      this.tableLoading = false;
      this.tableData = allMaterials;
      this.dataSource.data = this.tableData;
      this.isFirstInit = false;
    });
  }


  createNewMaterial() {
    const dialogRef = this.dialog.open(MaterialCreateComponent, {
      width: '1000px',
      maxWidth: '96vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tableData.unshift(result);
        this.dataSource.data = this.tableData;
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.firstPage());
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
