<h2 mat-dialog-title> Create New Vapor Pressure </h2>
<mat-dialog-content>
  <form [formGroup]="vaporPressureForm" class="form">

    <mat-form-field class="form-element">
      <mat-label>Temperature</mat-label>
      <input matInput type="number" placeholder="Temperature" formControlName="temperature">
      <mat-error *ngIf="vaporPressureForm.get('temperature').hasError('required')">The filed temperature is required</mat-error>
      <mat-error *ngIf="vaporPressureForm.get('temperature').hasError('duplicateNames')">This temperature is already used</mat-error>
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label>Pressure</mat-label>
      <input matInput type="number" placeholder="Pressure" formControlName="pressure">
      <mat-error *ngIf="vaporPressureForm.get('pressure').hasError('required')">The filed pressure is required</mat-error>
      <mat-error *ngIf="vaporPressureForm.get('pressure').hasError('duplicateNames')">This id pressure already used</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="greyBtn" mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" type="submit" class="button" data-testid="submit" [disabled]="!vaporPressureForm.valid" (click)="onSubmit(vaporPressureForm.value)"> Create </button>
</mat-dialog-actions>
