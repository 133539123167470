<section class="dashboard">
  <div class="nav-item">
    <div class="nav-item__search">
      <mat-form-field>
        <input type="text" placeholder="Search" matInput [formControl]="searchControl">
      </mat-form-field>
    </div>
    <mat-accordion>

      <mat-expansion-panel [expanded]="expandPanelPumpSystem">
        <mat-expansion-panel-header (click)="setExpandPanel('expandPanelPumpSystem')">
          <mat-panel-title>
            Pump systems
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item routerLink="/data/pump-system/new" routerLinkActive="active">
            <mat-icon>add</mat-icon> Add Pump systems
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item *ngFor="let pumpSystemItem of pumpSystemList | async"
            routerLink="/data/pump-system/{{pumpSystemItem.uuid}}" routerLinkActive="active">
            {{pumpSystemItem.diaplayName}}
          </mat-list-item>
          <mat-progress-spinner *ngIf="(pumpSystemList | async) === null" mode="indeterminate" [diameter]=20>
          </mat-progress-spinner>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="expandPanelController">
        <mat-expansion-panel-header (click)="setExpandPanel('expandPanelController')">
          <mat-panel-title>
            Controllers
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item routerLink="/data/controller/new" routerLinkActive="active">
            <mat-icon>add</mat-icon> Add New Controller
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item *ngFor="let controllerItem of controllerList | async"
            routerLink="/data/controller/{{controllerItem.uuid}}" routerLinkActive="active"> {{controllerItem.name}}
          </mat-list-item>
          <mat-progress-spinner *ngIf="(controllerList | async) === null" mode="indeterminate" [diameter]=20>
          </mat-progress-spinner>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="expandPanelMotor">
        <mat-expansion-panel-header (click)="setExpandPanel('expandPanelMotor')">
          <mat-panel-title>
            Motors
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item routerLink="/data/motor/new" routerLinkActive="active">
            <mat-icon>add</mat-icon> Add New Motor
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item *ngFor="let motorItem of motorList | async" routerLink="/data/motor/{{motorItem.uuid}}"
            routerLinkActive="active"> {{motorItem.name}} </mat-list-item>
          <mat-progress-spinner *ngIf="(motorList | async) === null" mode="indeterminate" [diameter]=20>
          </mat-progress-spinner>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="expandPanelPumpEnd">
        <mat-expansion-panel-header (click)="setExpandPanel('expandPanelPumpEnd')">
          <mat-panel-title>
            Pump ends
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item routerLink="/data/pump-end/new" routerLinkActive="active">
            <mat-icon>add</mat-icon> Add New Pump ends
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item *ngFor="let pumpEndItem of pumpEndsList | async"
            routerLink="/data/pump-end/{{pumpEndItem.uuid}}" routerLinkActive="active"> {{pumpEndItem.name}}
          </mat-list-item>
          <mat-progress-spinner *ngIf="(pumpEndsList | async) === null" mode="indeterminate" [diameter]=20>
          </mat-progress-spinner>
        </mat-nav-list>
      </mat-expansion-panel>


      <mat-expansion-panel class="nav-item__top" [expanded]="expandPanelPartsList">
        <mat-expansion-panel-header (click)="setExpandPanel('expandPanelPartsList')">
          <mat-panel-title>
            Parts list
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item routerLink="/data/part-list/new" routerLinkActive="active">
            <mat-icon>add</mat-icon> Add Parts list
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item *ngFor="let partItem of partsList | async" routerLink="/data/part-list/{{partItem.uuid}}"
            routerLinkActive="active"> {{partItem.name}} </mat-list-item>
          <mat-progress-spinner *ngIf="(partsList | async) === null" mode="indeterminate" [diameter]=20>
          </mat-progress-spinner>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="expandPanelAccessory">
        <mat-expansion-panel-header (click)="setExpandPanel('expandPanelAccessory')">
          <mat-panel-title>
            Accessory
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item routerLink="/data/accessory/new" routerLinkActive="active">
            <mat-icon>add</mat-icon> Add Accessory
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item *ngFor="let accessory of accessoryData$ | async"
            routerLink="/data/accessory/{{accessory.uuid}}" routerLinkActive="active"> {{accessory.name}}
          </mat-list-item>
          <mat-progress-spinner *ngIf="accessoryLoading | async" mode="indeterminate" [diameter]=20>
          </mat-progress-spinner>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="expandPanelModule">
        <mat-expansion-panel-header (click)="setExpandPanel('expandPanelModule')">
          <mat-panel-title>
            Modules
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item routerLink="/data/module/new" routerLinkActive="active">
            <mat-icon>add</mat-icon> Add Modules
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item *ngFor="let module of moduleList | async" routerLink="/data/module/{{module.uuid}}"
            routerLinkActive="active"> {{module.name}} </mat-list-item>
          <mat-progress-spinner *ngIf="(moduleList | async) === null" mode="indeterminate" [diameter]=20>
          </mat-progress-spinner>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="expandPanelPoleMount">
        <mat-expansion-panel-header (click)="setExpandPanel('expandPanelPoleMount')">
          <mat-panel-title>
            Pole mounts
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item routerLink="/data/pole-mount/new" routerLinkActive="active">
            <mat-icon>add</mat-icon> Add Pole Mount
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item *ngFor="let poleMount of poleMountList | async"
            routerLink="/data/pole-mount/{{poleMount.uuid}}" routerLinkActive="active"> {{poleMount.name}}
          </mat-list-item>
          <mat-progress-spinner *ngIf="(poleMountList | async) === null" mode="indeterminate" [diameter]=20>
          </mat-progress-spinner>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-expansion-panel class="nav-item__top singleLineHeight" routerLink="/data/city/all" routerLinkActive="active">
        <mat-expansion-panel-header [collapsedHeight]="customCollapsedHeight" [expandedHeight]="customExpandedHeight">
          <mat-panel-title>
            Cities
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>

      <mat-expansion-panel class="singleLineHeight" routerLink="/data/country/all" routerLinkActive="active">
        <mat-expansion-panel-header [collapsedHeight]="customCollapsedHeight" [expandedHeight]="customExpandedHeight">
          <mat-panel-title>
            Countries
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>

      <mat-expansion-panel class="singleLineHeight" routerLink="/data/location/all">
        <mat-expansion-panel-header [collapsedHeight]="customCollapsedHeight" [expandedHeight]="customExpandedHeight">
          <mat-panel-title>
            Locations
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="expandPanelPipePart">
        <mat-expansion-panel-header (click)="setExpandPanel('expandPanelPipePart')">
          <mat-panel-title>
            Pipe parts
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item routerLink="/data/pipe-part/new" routerLinkActive="active">
            <mat-icon>add</mat-icon> Add Pipe parts
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item *ngFor="let pipePart of pipePartList | async" routerLink="/data/pipe-part/{{pipePart.uuid}}"
            routerLinkActive="active"> {{pipePart.id}} </mat-list-item>
          <mat-progress-spinner *ngIf="(pipePartList | async) === null" mode="indeterminate" [diameter]=20>
          </mat-progress-spinner>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-expansion-panel class="singleLineHeight" routerLink="/data/material/all" routerLinkActive="active">
        <mat-expansion-panel-header [collapsedHeight]="customCollapsedHeight" [expandedHeight]="customExpandedHeight">
          <mat-panel-title>
            Materials
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>

      <mat-expansion-panel class="singleLineHeight" routerLink="/data/packaging/all" routerLinkActive="active">
        <mat-expansion-panel-header [collapsedHeight]="customCollapsedHeight" [expandedHeight]="customExpandedHeight">
          <mat-panel-title>
            Packaging
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="expandPanelConnected">
        <mat-expansion-panel-header (click)="setExpandPanel('expandPanelConnected')">
          <mat-panel-title>
            Connected products
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item routerLink="/data/connected-item/new" routerLinkActive="active">
            <mat-icon>add</mat-icon> Add Connected products
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item *ngFor="let connectedProduct of connectedProductList | async"
            routerLink="/data/connected-item/{{connectedProduct.uuid}}" routerLinkActive="active">
            {{connectedProduct.name}} </mat-list-item>
          <mat-progress-spinner *ngIf="(connectedProductList | async) === null" mode="indeterminate" [diameter]=20>
          </mat-progress-spinner>
        </mat-nav-list>
      </mat-expansion-panel>

      <!-- Fix bug when open side navbar in pump simulation -->
      <div class="hidden">
        <span *ngFor="let name of coefficientSetNameList | async">{{name}}</span>
      </div>

      <mat-expansion-panel class="singleLineHeight" routerLink="/data/vapor-pressure/all" routerLinkActive="active">
        <mat-expansion-panel-header [collapsedHeight]="customCollapsedHeight" [expandedHeight]="customExpandedHeight">
          <mat-panel-title>
            Vapor pressure
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="nav-content">
    <router-outlet></router-outlet>
  </div>
</section>