<mat-form-field class="form-element" appearance="fill">
  <mat-label>packaging</mat-label>
  <input type="text" placeholder="packaging" matInput [formControl]="packagingControl" />
</mat-form-field>

<div class="form-wrapper__packaging">
  <div class="form-wrapper__packaging-inner"></div>
  <mat-form-field class="form-element">
    <mat-label> Packaging Width </mat-label>
    <input [value]="packagingWidth" matInput disabled placeholder="Packaging Width">
  </mat-form-field>

  <mat-form-field class="form-element">
    <mat-label> Packaging Height </mat-label>
    <input [value]="packagingHeight" matInput disabled placeholder="Packaging Height">
  </mat-form-field>

  <mat-form-field class="form-element">
    <mat-label> Packaging Length </mat-label>
    <input [value]="packagingLength" matInput disabled placeholder="Packaging Length">
  </mat-form-field>

  <mat-form-field class="form-element">
    <mat-label> Packaging Weight </mat-label>
    <input [value]="packagingWeight" matInput disabled placeholder="Packaging Weight">
  </mat-form-field>
</div>