import { createAction, props } from "@ngrx/store";

export const setNPSHLoading = createAction(
  '[Charts Part] Load NPSH Chart',
  props<{ data: Boolean }>()
);

export const setNPSHCalculatedData = createAction(
  '[Charts Part] Set NPSH Chart Calculated Data',
  props<{ data: any }>()
);

export const setNPSHMeasuredData = createAction(
  '[Charts Part] Set NPSH Chart Measured Data',
  props<{ data: any }>()
);
