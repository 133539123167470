import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UploadDownloadDialogueComponent } from '../upload-download-dialogue/upload-download-dialogue.component';
import { UploadService } from '../service/upload.service';
import { map, Subscription } from 'rxjs';
import { PumpSystem, SizingApp } from '../../display-product-data/pump-data.model';
import { UserLocalStorage } from '../../login/user-local-store';
import { setSizingAppUploadData } from '../store/sizing-app/sizing-app.actions';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-upload-download-sizing-app',
  templateUrl: './upload-download-sizing-app.component.html',
  styleUrls: ['./upload-download-sizing-app.component.scss']
})
export class UploadDownloadSizingAppComponent implements OnInit, AfterViewInit, OnDestroy {


  @Component({
    selector: 'app-upload-download-psk3',
    templateUrl: './upload-download-psk3.component.html',
    styleUrls: ['./upload-download-psk3.component.scss']
  })


  displayedColumns: string[] = [];
  @Input() dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  profileForm: UntypedFormGroup;
  uploadFileName: string;
  showUploadFileName = false;
  sizingApps: SizingApp[] = [];
  file: File;
  arrayBuffer: any;
  fileList: any;
  userLocalStorage = new UserLocalStorage();
  customFilename: string = '';
  apps: SizingApp[] = [];

  selectUploadFile: any;
  uploadFileContent = '';
  uploadFileVersion = '';
  fileTableHeader = [];
  filename: any;
  version: any;
  showFileContentLoading = false;
  showFileContentTable = true;
  FILENAME: any = '';
  subscriptions: Subscription[] = [];
  pumpSystemList: PumpSystem[] = [];
  versions: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private uploadService: UploadService,
    private dialog: MatDialog,
    private store: Store
  ) { }

  ngOnInit() {
    this.initProfileForm();

    this.initImportData();

  }

  private async initImportData() {

    const versionsSubscription = (await this.uploadService.getAllVersions()).pipe(
      map(versions => {
        this.versions = versions;
        this.versions = this.versions.filter(data => data.filename !== 'PS3' && data.filename !== 'PSK3' && data.filename !== 'LORENTZ_ASSIST');
      })
    ).subscribe();
    this.subscriptions.push(versionsSubscription);

    const appsSubscription = (await this.uploadService.getAllApps()).pipe(
      map(apps => {
        this.apps = apps;
        console.log('the sizing apps are: '+JSON.stringify(apps))
      })
    ).subscribe();
    this.subscriptions.push(appsSubscription);

    await this.displayApps();

  }

  onFileSelect(event: { target: { files: string | any[]; }; }, filename?: string) {
    if (event.target.files.length > 0) {
      this.parseFile(event);
      const confirmationDialogueSubscription = this.handleConfirmationDialogue(event, filename);
      this.subscriptions.push(confirmationDialogueSubscription);
    }
  }

  async displayApps() {
    this.prepareFileContentForDisplay();
    const getAppsSubscription = (await this.uploadService.getAllApps()).subscribe(item => {
      this.extractFileToTable(item);
    });
    this.subscriptions.push(getAppsSubscription);

  }



  private prepareFileContentForDisplay() {
    this.showFileContentLoading = true;
    this.showFileContentTable = true;
    this.uploadFileContent = '';
    this.uploadFileVersion = '';
    this.filename = this.FILENAME;
    this.version = 0;
  }

  private extractFileToTable(item: any) {
    this.showFileContentLoading = false;
    this.fileTableHeader = [];
    if (item && item.length !== 0) {
      this.sizingApps = item.sort((a: { app_name: string; },b: { app_name: string; }) => a.app_name.localeCompare(b.app_name, 'en', { numeric: true }));
      console.log('app data is: ', item);
      const data = item;
      data.forEach((dataItem: SizingApp) => {
        Object.keys(dataItem).map(value => this.fileTableHeader.push(value));
        this.fileTableHeader = [...new Set(this.fileTableHeader)];
      });
      this.showFileContentTable = false;
      this.displayedColumns = this.fileTableHeader;
      this.dataSource.data = data;
      console.log('extracting this data: ', data);

    }
  }

  initProfileForm() {
    this.profileForm = this.fb.group({
      filename: [this.FILENAME, Validators.required],

    });
  }

  selectedUploadedFile(event: MatSelectChange) {
    this.selectUploadFile = event.value;
  }

  handleConfirmationDialogue(event: { target: any; }, filename?: string) {
    const dialogRef = this.dialog.open(UploadDownloadDialogueComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
      data: {
        message: 'Are you sure you want to publish this version?',
        type: 'confirm'
      }
    });
    return dialogRef.afterClosed().subscribe((result) => {
      if (result) {

        try {
          this.store.dispatch(setSizingAppUploadData({ data: [this.fileList], file: filename, version: this.version }));
          this.uploadService.uploadSizingAppFile(this.fileList, filename, event.target.files[0].name).then((data: SizingApp) => this.versions.push({ filename: data.app_name, version: data.registry_key }));
        } catch (err) { }
        this.showUploadFileName = true;
        this.uploadFileName = event.target.files[0].name;
        this.uploadFileName = this.uploadFileName.concat(' has been uploaded.');
        return true;
      } else {
        window.location.reload();
        return false;
      }
    });
  }

  parseFile(event: { target: any; }) {

    this.file = event.target.files[0];
    //const filename: string = event.target.files[0].name;
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = async (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);

        this.fileList = new TextDecoder('UTF8',{ fatal: false, ignoreBOM: true}).decode(data);
        if (this.fileList.indexOf('"') ===0){
          this.fileList = this.fileList.slice(1, -1);
        }
      console.log('size of array is ' + this.fileList.length)

    };
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // If the user changes the sort order, reset back to the first page.
    // this.sort.sortChange.subscribe(() => this.paginator.firstPage());
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

}

