import { Action, createReducer, on } from "@ngrx/store";
import { PumpSystem, PumpUnit } from "../../pump-data.model";
import * as PumpSystemActions from "./pump-system.actions";

export const pumpSystemFeatureKey = 'pumpSystem';

export const emptyPumpSystemFormData = {
  pumpSystem: [],
  initFormData: {
    pumpUnit: {
      item: '',
      packaging: '',
      maxTemp: '',
      suctionHead: '',
      picture: '',
    },
    controllerUuid: '',
    motorUuid: '',
    pumpEndUuid: '',
    coefficientSetValue: ''
  },
  pumpUnit: {
    item: '',
    packaging: '',
    maxTemp: '',
    suctionHead: '',
    picture: '',
  },
  controllerUuid: '',
  motorUuid: '',
  pumpEndUuid: '',
  coefficientSetValue: '',
  isFormValueChanged: false,
  controllerName: '',
  pumpEndName: ''
};

export interface PumpSystemState {
  pumpSystem: PumpSystem[],
  initFormData: {
    pumpUnit: Partial<PumpUnit>,
    controllerUuid: String,
    motorUuid: String,
    pumpEndUuid: String,
    coefficientSetValue: String
  },
  pumpUnit: Partial<PumpUnit>,
  controllerUuid: String,
  motorUuid: String,
  pumpEndUuid: String,
  coefficientSetValue: String,
  isFormValueChanged: Boolean,
  controllerName: String;
  pumpEndName: String;
}

const initialState: PumpSystemState = {
  pumpSystem: [],
  initFormData: {
    pumpUnit: {
      item: '',
      packaging: '',
      maxTemp: '',
      suctionHead: '',
      picture: '',
    },
    controllerUuid: '',
    motorUuid: '',
    pumpEndUuid: '',
    coefficientSetValue: ''
  },
  pumpUnit: {
    item: '',
    packaging: '',
    maxTemp: '',
    suctionHead: '',
    picture: '',
  },
  controllerUuid: '',
  motorUuid: '',
  pumpEndUuid: '',
  coefficientSetValue: '',
  isFormValueChanged: false,
  controllerName: '',
  pumpEndName: ''
}

const reducer = createReducer(
  initialState,
  on(PumpSystemActions.loadPumpSystems, (state, action) => {
    return {
      ...state,
      loading: true
    }
  }),
  on(PumpSystemActions.loadPumpSystemsSuccess, (state, action) => {
    return {
      ...state,
      pumpSystem: action.data,
      loading: false
    }
  }),
  on(PumpSystemActions.loadPumpSystemsFailure, (state, action) => {
    return {
      ...state,
      loading: false
    }
  }),
  on(PumpSystemActions.setInitFormValue, (state, action) => {
    return {
      ...state,
      initFormData: action.data
    }
  }),
  on(PumpSystemActions.setControllerSelect, (state, action) => {
    return {
      ...state,
      controllerUuid: action.data,
      isFormValueChanged: JSON.stringify(state.initFormData) !== JSON.stringify({
        pumpUnit: state.pumpUnit,
        controllerUuid: action.data,
        motorUuid: state.motorUuid,
        pumpEndUuid: state.pumpEndUuid,
        coefficientSetValue: state.coefficientSetValue
      })
    }
  }),
  on(PumpSystemActions.setMotorSelect, (state, action) => {
    return {
      ...state,
      motorUuid: action.data,
      isFormValueChanged: JSON.stringify(state.initFormData) !== JSON.stringify({
        pumpUnit: state.pumpUnit,
        controllerUuid: state.controllerUuid,
        motorUuid: action.data,
        pumpEndUuid: state.pumpEndUuid,
        coefficientSetValue: state.coefficientSetValue
      })
    }
  }),
  on(PumpSystemActions.setPumpEndSelect, (state, action) => {
    return {
      ...state,
      pumpEndUuid: action.data,
      isFormValueChanged: JSON.stringify(state.initFormData) !== JSON.stringify({
        pumpUnit: state.pumpUnit,
        controllerUuid: state.controllerUuid,
        motorUuid: state.motorUuid,
        pumpEndUuid: action.data,
        coefficientSetValue: state.coefficientSetValue
      })
    }
  }),
  on(PumpSystemActions.setPumpUnitValue, (state, action) => {
    return {
      ...state,
      pumpUnit: {...state.pumpUnit, ...action.data},
      isFormValueChanged: JSON.stringify(state.initFormData) !== JSON.stringify({
        pumpUnit: {...state.pumpUnit, ...action.data},
        controllerUuid: state.controllerUuid,
        motorUuid: state.motorUuid,
        pumpEndUuid: state.pumpEndUuid,
        coefficientSetValue: state.coefficientSetValue
      })
    }
  }),
  on(PumpSystemActions.setPumpSytemCoefficientSetName, (state, action) => {
    return {
      ...state,
      coefficientSetValue: action.data,
      isFormValueChanged: JSON.stringify(state.initFormData) !== JSON.stringify({
        pumpUnit: state.pumpUnit,
        controllerUuid: state.controllerUuid,
        motorUuid: state.motorUuid,
        pumpEndUuid: state.pumpEndUuid,
        coefficientSetValue: action.data
      })
    }
  })
);

export function pumpSystemReducer(
  state: PumpSystemState,
  action: Action
): PumpSystemState {
  return reducer(state, action);
}

