import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CoefficientInputParameters, CoefficientResultRow, IntermediateCoefficients, MeasurementRow, NPSHRow, SelectedCoefficientResultRow } from '../../pump-simulation/coefficient.model';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalculateCoefficientService {

  private _measurementData: any[];

  private _convertedNPSHData: NPSHRow[];

  private _convertedMeasurementData: MeasurementRow[];

  private _filename: string;

  private _inputParameters: CoefficientInputParameters = {
    pump: '',
    controller: '',
    type: '',
    splitPower: 0,
    minLift: 0,
    maxLift: 0,
    excelBehaviour: false,
  };

  private _coefficientResults: CoefficientResultRow;
  private _selectedCoefficients: SelectedCoefficientResultRow;
  private _inputPartParameters: Partial<CoefficientInputParameters>;
  headPressures: number[];

  set selectedCoefficients(selectedCoefficients: SelectedCoefficientResultRow) {
    if (selectedCoefficients) {
      this._selectedCoefficients = selectedCoefficients;
      if (!selectedCoefficients.intermediateCoefficents) {
        this._selectedCoefficients.intermediateCoefficents =
          this.objectToIntermediateCoefficientsMap(selectedCoefficients.intermediateCoefficentsJSON ?
            selectedCoefficients.intermediateCoefficentsJSON : this.coefficientResults.intermediateCoefficentsJSON);
      } else {
        this._selectedCoefficients.intermediateCoefficents = selectedCoefficients.intermediateCoefficents;
      }
      this._inputParameters.corr_fact = selectedCoefficients.corr_fact.value;

    }
  }

  get selectedCoefficients(): SelectedCoefficientResultRow {
    return this._selectedCoefficients;
  }

  set filename(filename: string) {
    this._filename = filename;
  }

  get filename() {
    return this._filename;
  }

  constructor(private httpClient: HttpClient) { }

  get convertedMeasurementData() {
    return this._convertedMeasurementData;
  }

  set convertedMeasurementData(convertedMeasurementData) {
    this._convertedMeasurementData = [];
    // this._convertedNPSHData = [];
    this._convertedMeasurementData = convertedMeasurementData;
  }

  set inputParameters(inputParameters: CoefficientInputParameters) {
    if (inputParameters) {
      inputParameters.excelBehaviour ? doNothing() : inputParameters.excelBehaviour = false;
      inputParameters.splitPower ? doNothing() : inputParameters.splitPower = 0;
      inputParameters.minLift ? doNothing() : inputParameters.minLift = 1;
          inputParameters.maxLift ? doNothing(): inputParameters.maxLift = 150;
    }
    this._inputParameters = inputParameters;
  }

  get inputParameters() {
    return this._inputParameters;
  }

  set inputPartParameters(inputPartParameters: Partial<CoefficientInputParameters>) {
    this._inputPartParameters = inputPartParameters;
    this._inputParameters.controller = inputPartParameters.controller;
    this._inputParameters.pump = inputPartParameters.pump;
    this._inputParameters.type = inputPartParameters.type;
    this._filename = inputPartParameters.pump;
  }

  get inputPartParameters() {
    return this._inputPartParameters;
  }

  set measurementData(measurementData: any[]) {
    this._convertedMeasurementData = [];
    this._convertedNPSHData = [];
    this._measurementData = measurementData;
    this.convertExcelData();
  }

  set coefficientResults(coefficientResults: CoefficientResultRow) {
    if (coefficientResults) {
      this._coefficientResults = coefficientResults;
      if (!coefficientResults.intermediateCoefficents) {
        this._coefficientResults.intermediateCoefficents =
          this.objectToIntermediateCoefficientsMap(coefficientResults.intermediateCoefficentsJSON);
      } else {
        this._coefficientResults.intermediateCoefficents = coefficientResults.intermediateCoefficents;
      }
      this._inputParameters.corr_fact = coefficientResults.corr_fact;

    }
  }

  get coefficientResults() {
    return this._coefficientResults;
  }

  get convertedNPSHData() {
    return this._convertedNPSHData;
  }

  set convertedNPSHData(convertedNPSHData: NPSHRow[]) {
    this._convertedNPSHData = [];
    this._convertedNPSHData = convertedNPSHData;
  }

  async calculateCoefficient() {
    this.coefficientResults = null;
    this.selectedCoefficients = null;

    if (this._convertedMeasurementData && this._inputParameters) {

      const parameter = {
        measurements: this._convertedMeasurementData,
        npshData: this._convertedNPSHData,
        inputParameters: this._inputParameters
      };

      const url = environment.calculationUrl + 'doCalculation';
      this.coefficientResults = await lastValueFrom(this.httpClient.post<CoefficientResultRow>(url, parameter));
      this.coefficientResults.intermediateCoefficents =
        this.objectToIntermediateCoefficientsMap(this.coefficientResults.intermediateCoefficentsJSON);
      if (this.isEmpty(this.selectedCoefficients)) {
        this.transformCoefficientsToSelectedCoefficients();
      }
      return this.coefficientResults;
    }

  }

  async calculateSelectedCoefficient() {
    this.coefficientResults = null;

    if (this._convertedMeasurementData && this._inputParameters) {

      const parameter = {
        measurements: this._convertedMeasurementData,
        npshData: this._convertedNPSHData,
        inputParameters: this._inputParameters
      };

      const url = environment.calculationUrl + 'doCalculation';

      this.coefficientResults = await lastValueFrom(this.httpClient.post<CoefficientResultRow>(url, parameter));
      this.coefficientResults.intermediateCoefficents =
        this.objectToIntermediateCoefficientsMap(this.coefficientResults.intermediateCoefficentsJSON);
      if (!this.isEmpty(this.selectedCoefficients)) {
        this.transformCoefficientsToExistingSelectedCoefficients();
      } else {
        this.transformCoefficientsToSelectedCoefficients();
      }

      return this.coefficientResults;
    }

  }


  transformCoefficientsToExistingSelectedCoefficients() {

    for (const k of Object.keys(this.selectedCoefficients)) {
      const coefficient: any = this.selectedCoefficients[k];
      if (coefficient.man === false) { this.selectedCoefficients[k] = { value: this.coefficientResults[k], man: false } }
    }

  }

  isEmpty(obj: Object): boolean {
    return !obj || !Object.keys(obj).some(x => obj[x] !== void 0);
  }

  transformSelectedCoefficientsIntoCoefficients(): CoefficientResultRow {

    const coefficient: CoefficientResultRow = {
      corr_fact: this._selectedCoefficients.corr_fact.value,
      mai: this._selectedCoefficients.mai.value,
      maq: this._selectedCoefficients.maq.value,
      mar_: this._selectedCoefficients.mar_.value,
      mbi: this._selectedCoefficients.mbi.value,
      mbq: this._selectedCoefficients.mbq.value,
      mbr: this._selectedCoefficients.mbr.value,
      mci: this._selectedCoefficients.mci.value,
      mcq: this._selectedCoefficients.mcq.value,
      mcr: this._selectedCoefficients.mcr.value,
      mdq_: this._selectedCoefficients.mdq_.value,
      nai: this._selectedCoefficients.nai.value,
      naq: this._selectedCoefficients.naq.value,
      nar_: this._selectedCoefficients.nar_.value,
      nbi: this._selectedCoefficients.nbi.value,
      nbq: this._selectedCoefficients.nbq.value,
      nbr: this._selectedCoefficients.nbr.value,
      nci: this._selectedCoefficients.nci.value,
      ncq: this._selectedCoefficients.ncq.value,
      ncr: this._selectedCoefficients.ncr.value,
      ndq_: this._selectedCoefficients.ndq_.value,
      npsh_a: this._selectedCoefficients.npsh_a.value,
      npsh_c: this._selectedCoefficients.npsh_c.value,
      npsh_exp: this._selectedCoefficients.npsh_exp.value,
      intermediateCoefficentsJSON: this._selectedCoefficients.intermediateCoefficentsJSON,
      intermediateCoefficents: this._selectedCoefficients.intermediateCoefficents
    }
    return coefficient;
  }

  transformCoefficientsToSelectedCoefficients() {

    const selectedCoefficients: SelectedCoefficientResultRow = {
      maq: { value: this.coefficientResults.maq, man: false },
      naq: { value: this.coefficientResults.naq, man: false },
      mbq: { value: this.coefficientResults.mbq, man: false },
      nbq: { value: this.coefficientResults.nbq, man: false },
      mcq: { value: this.coefficientResults.mcq, man: false },
      ncq: { value: this.coefficientResults.ncq, man: false },
      mdq_: { value: this.coefficientResults.mdq_, man: false },
      ndq_: { value: this.coefficientResults.ndq_, man: false },
      mai: { value: this.coefficientResults.mai, man: false },
      nai: { value: this.coefficientResults.nai, man: false },
      mbi: { value: this.coefficientResults.mbi, man: false },
      nbi: { value: this.coefficientResults.nbi, man: false },
      mci: { value: this.coefficientResults.mci, man: false },
      nci: { value: this.coefficientResults.nci, man: false },
      mar_: { value: this.coefficientResults.mar_, man: false },
      nar_: { value: this.coefficientResults.nar_, man: false },
      mbr: { value: this.coefficientResults.mbr, man: false },
      nbr: { value: this.coefficientResults.nbr, man: false },
      mcr: { value: this.coefficientResults.mcr, man: false },
      ncr: { value: this.coefficientResults.ncr, man: false },
      corr_fact: { value: this.coefficientResults.corr_fact, man: false },
      npsh_a: { value: this.coefficientResults.npsh_a, man: false },
      npsh_exp: { value: this.coefficientResults.npsh_exp, man: false },
      npsh_c: { value: this.coefficientResults.npsh_c, man: false },
      intermediateCoefficentsJSON: this.coefficientResults.intermediateCoefficentsJSON,
      intermediateCoefficents: this.coefficientResults.intermediateCoefficents
    }
    this.selectedCoefficients = selectedCoefficients;
  }

  transformCoefficientsResultToSelectedCoefficients(coefficients: CoefficientResultRow): SelectedCoefficientResultRow {
    const selectedCoefficients: SelectedCoefficientResultRow = {
      maq: { value: coefficients.maq, man: false },
      naq: { value: coefficients.naq, man: false },
      mbq: { value: coefficients.mbq, man: false },
      nbq: { value: coefficients.nbq, man: false },
      mcq: { value: coefficients.mcq, man: false },
      ncq: { value: coefficients.ncq, man: false },
      mdq_: { value: coefficients.mdq_, man: false },
      ndq_: { value: coefficients.ndq_, man: false },
      mai: { value: coefficients.mai, man: false },
      nai: { value: coefficients.nai, man: false },
      mbi: { value: coefficients.mbi, man: false },
      nbi: { value: coefficients.nbi, man: false },
      mci: { value: coefficients.mci, man: false },
      nci: { value: coefficients.nci, man: false },
      mar_: { value: coefficients.mar_, man: false },
      nar_: { value: coefficients.nar_, man: false },
      mbr: { value: coefficients.mbr, man: false },
      nbr: { value: coefficients.nbr, man: false },
      mcr: { value: coefficients.mcr, man: false },
      ncr: { value: coefficients.ncr, man: false },
      corr_fact: { value: coefficients.corr_fact, man: false },
      npsh_a: { value: coefficients.npsh_a, man: false },
      npsh_exp: { value: coefficients.npsh_exp, man: false },
      npsh_c: { value: coefficients.npsh_c, man: false },
      intermediateCoefficentsJSON: coefficients.intermediateCoefficentsJSON,
      intermediateCoefficents: coefficients.intermediateCoefficents
    }
    return selectedCoefficients;
  }

  resetDataToNull() {
    this.inputParameters = null;
    this.convertedMeasurementData = null;
    this.coefficientResults = null;
    this.selectedCoefficients = null;
    this.convertedNPSHData = null;
  }

  setMinMaxHeadPressure(message?: string) {
    const headPressureTypes = this.convertedMeasurementData.map
      ((row: { headPressure: number; }) => row.headPressure);
    const data = new Set(headPressureTypes);
    const headPressures: number[] = [...data];
    let firstLine = true;
    for (const head of headPressures) {

      if (firstLine) {
        this.inputParameters.minLift = head;
        firstLine = false;
      }
      this.inputParameters.maxLift = head;
      this.inputParameters.splitPower = 0;
    }

  }

  private convertExcelData() {
    let i = 0;
    for (const row of this._measurementData) {
      i++;
      this.convertMeasurementData(row, i);
      this.convertNPSHData(row);
    }
  }


  private convertNPSHData(row: any) {
    const convertedNPSHRow = {
      flowRate: row.Q,
      suctionHead: row.m
    };
    if ((convertedNPSHRow.suctionHead) &&
      (convertedNPSHRow.flowRate.toString().indexOf('[Q]') < 0 && convertedNPSHRow.suctionHead.toString().indexOf('[m]') < 0)) {
      this._convertedNPSHData.push(convertedNPSHRow);
    }
  }

  private convertMeasurementData(row: any, _id: number) {
    const convertedMeasurementRow = {
      id: _id,
      current: row.I1,
      voltage: row.U1,
      volume: row.V,
      rotationalSpeed: row.n,
      measurementTime: row.t,
      headPressure: row.Δp * 10,
      power: row.U1 * row.I1,
      flowRate: row.V / row.t * 3600 / 1000	// [m^3/h]
    };

    if (!isFinite(convertedMeasurementRow.flowRate)) { convertedMeasurementRow.flowRate = 0; }

    if ((convertedMeasurementRow.current &&
      convertedMeasurementRow.headPressure &&
      convertedMeasurementRow.rotationalSpeed &&
      convertedMeasurementRow.voltage)
      && (convertedMeasurementRow.current.toString().indexOf('[A]') < 0 &&
        convertedMeasurementRow.headPressure.toString().indexOf('[bar]') < 0 &&
        convertedMeasurementRow.voltage.toString().indexOf('[V]') < 0)) {
      this._convertedMeasurementData.push(convertedMeasurementRow);
    }
  }

  private objectToIntermediateCoefficientsMap(o) {
    const m = new Map<number, IntermediateCoefficients>();
    for (const k of Object.keys(o)) {
      const coefficients: IntermediateCoefficients = o[k];
      m.set(Number.parseFloat(k), coefficients);
    }
    return m;
  }
}
function doNothing() {

}

