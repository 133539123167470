import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { select, Store } from '@ngrx/store';
import { Subscription, Subject, Observable, map, startWith, combineLatest } from 'rxjs';
import { nameSort } from 'src/app/shared/util';
import { environment } from 'src/environments/environment';
import { DisplayProductDataService } from '../display-product-data.service';
import { PictureCreateComponent } from '../picture-create/picture-create.component';
import { PictureViewComponent } from '../picture-view/picture-view.component';
import { setPumpUnitValue } from '../store/pump-system/pump-system.actions';
import { setPumpUnitPicture } from '../store/pump-unit/pump-unit.actions';
import { PumpUnitState } from '../store/pump-unit/pump-unit.reducer';
import { selectPumpUnit } from '../store/pump-unit/pump-unit.seletor';

@Component({
  selector: 'app-picture-store',
  templateUrl: './picture-store.component.html',
  styleUrls: ['./picture-store.component.scss']
})
export class PictureStoreComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  pictureList = [];
  pictureStore = '';
  pictureNamesList = [];
  dropdownList: string[] = [];

  partspictureControl = new UntypedFormControl('', [
    this.optionNotFound.bind(this),
  ]);

  filteredOptions: Set<string>;

  public readonly partspictureChanges: Subject<string> = new Subject<string>();
  public partspictureSuggestions: Observable<string[]>;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit(): void {
    const getPictureAndDrawingList = combineLatest([
      this.displayProductData.getPictureAndDrawingList(),
      this.store.pipe(
        select(selectPumpUnit),
        map((data: PumpUnitState) => data.picture)
      )
    ]).subscribe(([result, value]) => {
      const pictureNames = nameSort(result);
      this.pictureList = pictureNames;
      this.pictureList.forEach(item => this.pictureNamesList.push(item.name));

      this.dropdownList = this.pictureList.map(item => item.name);
      this.dropdownList.unshift('Not Available');
      this.dropdownList.unshift('');
      this.dropdownList.push('Add new picture');
      this.partspictureSuggestions = this.partspictureChanges.pipe(
        startWith(''),
        map((val: string) => this.filterResults(val))
      );

      if (value !== '') {
        const item = this.pictureList.find(item => item.name === value);
        if (item) {
          this.partspictureControl.setValue(`${value}`);
        }
      };
    });
    this.subscriptions.push(getPictureAndDrawingList);
  }

  private filterResults(val: string) {
    if (val !== '') {
      return this.dropdownList.filter(item => item.includes(val));
    } else {
      return this.dropdownList;
    }
  }

  optionNotFound(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value) {
      this.filteredOptions = new Set(
        this.dropdownList.filter((option) => option.toLowerCase().indexOf(value.toLowerCase()) > -1)
      );
    }
    if (value && !this.filteredOptions.size) {
      return {
        noOption: true
      };
    }
    return null;
  }

  onFocus(value: string) {
    if (value === '') {
      this.partspictureChanges.next('');
    };
    this.optionNotFound(this.partspictureControl);
  }

  updatePartspicture(value: string) {
    if (value === '') {
      this.partspictureControl.reset('');
    } else {

      const item = this.pictureList.find(item => item.name === value);
      if (item) {
        this.partspictureControl.setValue(value);
      } else {
        this.partspictureControl.reset('');
      }
    };
  }

  setPartspictureChangeAction(event: MatAutocompleteSelectedEvent) {
    if (event.option.value === 'create') {
      const dialogRef = this.dialog.open(PictureCreateComponent, {
        width: '1000px',
        maxWidth: '96vw',
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.pictureList.unshift(result);
          this.dropdownList = this.pictureList.map(item => item.name);
          this.dropdownList.unshift('Not Available');
          this.dropdownList.unshift('');
          this.dropdownList.push('Add new picture');
          this.partspictureControl.setValue(result.name);
        } else {
          this.partspictureControl.setValue(this.pictureStore);
        }
      });
    } else if (event.option.value === '') {
      this.pictureStore = '';
      this.partspictureControl.reset('');
    } else {
      this.pictureStore = event.option.value;
    }

    this.store.dispatch(setPumpUnitPicture({ data: event.option.value }));
    this.store.dispatch(setPumpUnitValue({ data: { picture: event.option.value } }));
  }

  openViewDialog() {

    const picture = this.partspictureControl.value;
    if (picture === 'notAvailable') {
      this.snackBar.open('Please choose picture first', '', {
        duration: 1500,
        panelClass: 'hintMsg'
      });
    } else if (picture) {
      this.pictureList.filter(item => {
        if (item.name === picture) {
          const extension = item.type === 'picture' ? 'jpg' : 'gif';
          const typePath = item.type === 'picture' ? 'Pictures' : 'Drawings';
          const imgUrl = `https://catalog-data-images-${environment.stage}.s3-eu-west-1.amazonaws.com/${typePath}/${item.uuid}/${item.name}.${extension}`;
          this.displayProductData.getImage(imgUrl).subscribe(
            () => {
              this.dialog.open(PictureViewComponent, {
                width: '1000px',
                maxWidth: '90vw',
                minHeight: '60vh',
                maxHeight: '90vh',
                data: {
                  url: imgUrl,
                  title: item.name
                },
              });
            },
            error => {
              this.snackBar.open('This picture is not available, please notice it.', '', {
                duration: 1500,
                panelClass: 'hintMsg'
              });
            },
            () => { }
          );
        }
      });
    } else {
      this.snackBar.open('Please choose picture first', '', {
        duration: 1500,
        panelClass: 'hintMsg'
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
