import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoginService } from './login.service';

/**
 * Guard thats allows navigation to a url when a user is authenticated.
 */
@Injectable({ providedIn: 'root', })
export class AuthenticatedGuard  {

  constructor(private router: Router, private loginService: LoginService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.loginService.isLoggedIn$.pipe(
      tap((isLoggedIn) => {

        if (isLoggedIn) {
          return true;
        }
        this.loginService.redirectUrl = state.url;
        this.router.navigate(['/', 'login']);
        return false;
      })
    );
  }

  canActivateChild(child: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(child, state);
  }
}
