import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { combineLatest, first, map, mergeMap, Subscription, distinctUntilChanged } from 'rxjs';
import { objectToMap } from 'src/app/shared/util';
import { PumpSimulationService } from '../pump-simulation.service';
import { ShareDataService } from '../share-data.service';
import { setCoefficientMeasurementData, setDeletedMeasurementItem } from '../store/calculate-coefficient/calculate-coefficient.actions';
import { CalculateCoefficientState } from '../store/calculate-coefficient/calculate-coefficient.reducer';
import { selectCalculateCoefficient } from '../store/calculate-coefficient/calculate-coefficient.selector';
import { setFlowRateChartData } from '../store/charts/flow-rate-chart/flow-rate-chart.actions';
import { SimulationSettingState } from '../store/simulation-setting/simulation-setting.reducer';
import { selectSimulationSetting } from '../store/simulation-setting/simulation-setting.selector';
import { SystemFormState } from '../store/system-form/system-form.reducer';
import { selectSystemForm } from '../store/system-form/system-form.selector';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  @Input() dataSource = new MatTableDataSource();
  tableLoading = true;
  isEmptyData = false;
  editField: any;
  measurementData: any;
  deletedMeasurementData = [];
  displayedColumns: string[] = ['headPressure', 'current', 'voltage', 'volume', 'rotationalSpeed', 'measurementTime', 'power', 'flowRate', 'remove'];

  constructor(
    private store: Store,
    private elementRef: ElementRef,
    private shareService: ShareDataService,
    private pumpSimulationService: PumpSimulationService,
  ) { }

  ngOnInit() {
    const getData = this.store.pipe(
      select(selectCalculateCoefficient),
      map((data: CalculateCoefficientState) => data.convertMeasurementData),
      distinctUntilChanged()
    ).subscribe(data => {
      if (data) {
        this.tableLoading = false;
        this.isEmptyData = false;
        this.dataSource.data = data;
        this.measurementData = [...data];
        this.shareService.setConvertMeasurementData([...data]);
      }
    });
    this.subscriptions.push(getData);

    const handleAllData = this.shareService.getRevertAllData().subscribe(isRevertAllData => {
      if (isRevertAllData) {
        this.store.pipe(
          select(selectCalculateCoefficient),
          map((data: CalculateCoefficientState) => data.originalConvertMeasurementData),
          first()
        ).subscribe(data => {
          if (data) {
            this.dataSource.data = data;
            this.measurementData = [...data];
            this.deletedMeasurementData = [];
            this.handleTableData(this.measurementData);
            this.store.dispatch(setDeletedMeasurementItem({data: []}));
          }
        })
      }
    });
    this.subscriptions.push(handleAllData);

    const handleData = this.shareService.getRevertData().subscribe(isRevertData => {
      if (isRevertData) {
        const row = this.deletedMeasurementData[0];
        this.measurementData.push(row);
        this.measurementData.sort((a, b) => a.current - b.current);
        this.measurementData.sort((a, b) => a.headPressure - b.headPressure);
        this.deletedMeasurementData.splice(0, 1);
        this.dataSource.data = this.measurementData;
        this.handleTableData(this.measurementData);
        this.store.dispatch(setDeletedMeasurementItem({data: [...this.deletedMeasurementData]}));
      }
    });
    this.subscriptions.push(handleData);

    const hightlightTableRow = this.store.pipe(
      select(selectCalculateCoefficient),
      map((data: CalculateCoefficientState) => data.selectRowIndex)
    ).subscribe(rowIndex => {
      const target = this.elementRef.nativeElement.querySelectorAll('table tr');

      target.forEach((el: any) => {
        el.classList.remove('gold');
        el.classList.add('white');
      });
      if (rowIndex > 0) {
        target[+rowIndex].classList.add('gold');
      }
    });
    this.subscriptions.push(hightlightTableRow);
  }

  remove(id: number) {
    this.deletedMeasurementData.push(this.measurementData[id]);
    this.measurementData.splice(id, 1);
    this.dataSource.data = this.measurementData;
    this.handleTableData(this.measurementData);
    this.store.dispatch(setDeletedMeasurementItem({data: [...this.deletedMeasurementData]}));
  }

  updateList(id: number, property: string, event: any) {
    const editField = event.target.textContent;
    if (Number(editField) !== this.measurementData[id][property]) {
      const item = {...this.measurementData[id]};
      item[property] = editField;
      this.measurementData[id] = item;
      this.handleTableData(this.measurementData);
    }
  }

  changeValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
  }

  handleTableData(changedData: any) {
    this.shareService.setConvertMeasurementData(changedData);
    combineLatest([
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data)
      ),
      this.store.pipe(
        select(selectSimulationSetting),
        map((data: SimulationSettingState) => data)
      ),
      this.store.pipe(
        select(selectSystemForm),
        map((data: SystemFormState) => data)
      )
    ]).pipe(
      mergeMap(([coefficientData, simulationData, formData]) => {
        const parameter = {
          dataType: 'FLOW',
          measurementData: changedData,
          coefficients: coefficientData.allCoefficientsData,
          inputParameters: {
            pump: formData.pumpName,
            controller: formData.controller,
            type: formData.type,
            splitPower: simulationData.splitPower,
            minLift: simulationData.minLift,
            maxLift: simulationData.maxLift,
            excelBehaviour: simulationData.excelBehaviour,
          }
        };
        return this.pumpSimulationService.getDataFor('getMeasuredDataFor', parameter);
      }),
      first()
    ).subscribe(data => {
      const result = objectToMap(data);
      this.store.dispatch(setFlowRateChartData({ data: result }));
    });
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
