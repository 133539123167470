import { Action, createReducer, on } from "@ngrx/store";
import { MaterialItem } from "../../controllers/controller.model";
import * as MaterialActions from './material.actions';

export const materialFeatureKey = 'material';

export interface MaterialState {
  data: MaterialItem[];
  loading: Boolean;
}

export const initialState: MaterialState = {
  data: [],
  loading: true
}

const reducer = createReducer(
  initialState,
  on(MaterialActions.loadMaterials, (state, action) => {
    return {
      ...state,
      loading: true
    }
  }),
  on(MaterialActions.loadMaterialsSuccess, (state, action) => {
    return {
      ...state,
      data: action.data,
      loading: false
    }
  }),
  on(MaterialActions.loadMaterialsFailure, (state, action) => {
    return {
      ...state,
      loading: false
    }
  }),
  on(MaterialActions.createMaterial, (state, action) => {
    return {
      ...state,
      data: [{...action.data}, ...state.data]
    }
  })
);

export function materialReducer (
  state: MaterialState,
  action: Action
): MaterialState {
  return reducer(state, action);
}
