<mat-form-field class="form-element" appearance="fill">
  <mat-label>drawing</mat-label>
  <mat-select placeholder="drawing" [formControl]="selectListControl" (selectionChange)="drawingChangeAction($event)">
    <mat-option value="empty"></mat-option>
    <mat-option *ngIf="showNotAvailable" value="notAvailable"> Not Available </mat-option>
    <mat-option *ngFor="let drawing of drawingList" [value]="drawing.name">{{ drawing.name }}</mat-option>
    <mat-option value="create">Add new drawing</mat-option>
  </mat-select>
</mat-form-field>
<button mat-raised-button color="primary" type="button" (click)="openViewDialog()">VIEW</button>
