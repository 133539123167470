import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { delay } from 'rxjs/operators';
import { v4 } from 'uuid';
import { DisplayProductDataService } from '../display-product-data.service';
import { checkEnclosureClassName } from '../names.validator';

export class ImmediateErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-enclosure-class-create',
  templateUrl: './enclosure-class-create.component.html',
  styleUrls: ['./enclosure-class-create.component.scss']
})
export class EnclosureClassCreateComponent implements OnInit {

  encClassForm: UntypedFormGroup;
  matcher = new ImmediateErrorStateMatcher();
  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private displayProductData: DisplayProductDataService,
    private dialogRef: MatDialogRef<EnclosureClassCreateComponent>
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.encClassForm = this.fb.group({
      name: ['', [Validators.required, checkEnclosureClassName.bind(this)]],
    });
  }

  onSubmit(result) {
    result.uuid = v4();

    this.displayProductData.createNewEncclass(result).subscribe(item => {
      this.snackBar
      .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
      .afterDismissed()
      .pipe(delay(0))
      .subscribe(() => this.dialogRef.close(result));
    });
  }

}
