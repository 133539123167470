import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { LoginService } from '../login/login.service';
import { setCoefficientConvertMeasurementData, setCoefficientsData, setOriginalConvertMeasurementData, setSelectedCoefficientsData } from '../pump-simulation/store/calculate-coefficient/calculate-coefficient.actions';
import { setCurrentMeasuredData, setCurrentCalculatedData } from '../pump-simulation/store/charts/current-chart/current-chart.actions';
import { setFlowRateMeasuredData, setFlowRateCalculatedData } from '../pump-simulation/store/charts/flow-rate-calculate/flow-rate-calculate.actions';
import { setIntermediateData, setIntermediateLoading } from '../pump-simulation/store/charts/intermediate-chart/intermediate-chart.actions';
import { setNPSHMeasuredData, setNPSHCalculatedData } from '../pump-simulation/store/charts/npsh-chart/npsh-chart.actions';
import { setRotationalMeasuredData, setRotationalCalculatedData } from '../pump-simulation/store/charts/rotational-speed-chart/rotational-speed-chart.actions';
import { setDataSource, setSourceUploadFile } from '../pump-simulation/store/data-source/data-source.actions';
import { setSimulationExcelBehaviour, setSimulationMinLift, setSimulationMaxLift, setSimulationSplitPower } from '../pump-simulation/store/simulation-setting/simulation-setting.actions';
import { setSystemFormType, setLoadSystemFormNameAppendix, setLoadVersionNumber, setSystemFormNameAppendix, setSystemFormPumpEnd, setSystemFormController } from '../pump-simulation/store/system-form/system-form.actions';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  isAdmin$: Observable<boolean>;
  isAuthenticated$: Observable<boolean>;
  isNotAuthenticated$: Observable<boolean>;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private store: Store
  ) { }

  ngOnInit() {
    this.isAdmin$ = this.loginService.isAdmin$;
    this.isAuthenticated$ = this.loginService.isLoggedIn$;
    this.isNotAuthenticated$ = this.loginService.isLoggedIn$.pipe(
      map((loggedIn) => !loggedIn)
    );
  }


  cleanData() {
    this.store.dispatch(setFlowRateMeasuredData({ data: null }));
    this.store.dispatch(setFlowRateCalculatedData({ data: null }));
    this.store.dispatch(setCurrentMeasuredData({ data: null }));
    this.store.dispatch(setCurrentCalculatedData({ data: null }));
    this.store.dispatch(setRotationalMeasuredData({ data: null }));
    this.store.dispatch(setRotationalCalculatedData({ data: null }));
    this.store.dispatch(setIntermediateData({ data: null }));
    this.store.dispatch(setIntermediateLoading({ data: true }));
    this.store.dispatch(setNPSHMeasuredData({ data: null }));
    this.store.dispatch(setNPSHCalculatedData({ data: null }));
    this.store.dispatch(setCoefficientsData({ data: null }));
    this.store.dispatch(setSelectedCoefficientsData({ data: null }));
    this.store.dispatch(setLoadSystemFormNameAppendix({ data: null }));
    this.store.dispatch(setLoadVersionNumber({ data: null }));
    this.store.dispatch(setSimulationExcelBehaviour({ data: false }));
    this.store.dispatch(setSimulationMinLift({ data: null }));
    this.store.dispatch(setSimulationMaxLift({ data: null }));
    this.store.dispatch(setSimulationSplitPower({ data: null }));
    this.store.dispatch(setCoefficientConvertMeasurementData({ data: null }));
    this.store.dispatch(setOriginalConvertMeasurementData({ data: null }));
    this.store.dispatch(setSourceUploadFile({ data: null }));
    this.store.dispatch(setCoefficientConvertMeasurementData({ data: null }));
    this.store.dispatch(setDataSource({ data: '' }));
    this.store.dispatch(setSystemFormNameAppendix({ data: '' }));
    this.store.dispatch(setSystemFormType({ data: '' }));
    this.store.dispatch(setSystemFormController({ data: '' }));
    this.store.dispatch(setSystemFormPumpEnd({ data: '' }));

  }


  logout() {
    this.loginService.logout();
    this.router.navigate(['/login']);
  }
}
