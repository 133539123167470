import { Action, createReducer, on } from "@ngrx/store";
import * as SizingAppActions from "./sizing-app.actions";


export const sizingAppFeatureKey = 'sizingApp';

export interface SizingAppState {
  dataSourceName: string;
  uploadedData: [any];
  file: string;
  version: string

}

export const initialState: SizingAppState = {
  dataSourceName: '',
  uploadedData: [null],
  file: '',
  version: ''

}

const reducer = createReducer(
  initialState,
  on(SizingAppActions.setSizingAppDataSource, (state, action) => {
    return {
      ...state,
      dataSourceName: action.data,

    }
  }),
  on(SizingAppActions.setSizingAppUploadData, (state, action) => {
    return {
      ...state,
      uploadedData: action.data,
      file: action.file,
      version: action.version
    }
  })
);

export function sizingAppReducer(
  state: SizingAppState,
  action: Action
): SizingAppState {
  return reducer(state, action);
}
