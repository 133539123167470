import { Input } from '@angular/core';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CountryItem } from "./country.model";
import { CountryNewComponent } from '../country-new/country-new.component';
import { DisplayProductDataService } from '../display-product-data.service';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss']
})
export class CountriesComponent implements OnInit, AfterViewInit {

  countryForm: UntypedFormGroup;
  displayedColumns: string[] = ['id', 'name', 'capital', 'water_temp'];
  tableLoading = true;
  isFirstInit = true;
  tableData = [];

  @Input() dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {
    this.displayProductData.getCountryList().subscribe((allCountries: CountryItem[]) => {
      this.tableLoading = false;
      this.tableData = allCountries.sort((a, b) => Number(a.id) - Number(b.id));
      this.dataSource.data = this.tableData;
      this.isFirstInit = false;
    });
  }

  createNewCountry() {
    const dialogRef = this.dialog.open(CountryNewComponent, {
      width: '1000px',
      maxWidth: '96vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tableData.unshift(result);
        this.dataSource.data = this.tableData;
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.firstPage());
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
