import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UploadService } from '../service/upload.service';
import { Subscription } from 'rxjs';
import * as fileSaver from 'file-saver';
import { Store } from '@ngrx/store';
import { setPS2UploadDownloadData, setPumpSystemUploadDownloadData } from '../store/upload-download/upload-download.actions';

@Component({
  selector: 'app-upload-download-pumpsystems',
  templateUrl: './upload-download-pumpsystems.component.html',
  styleUrls: ['./upload-download-pumpsystems.component.scss']
})
export class UploadDownloadPumpsystemsComponent implements OnInit {

  displayedColumns: string[] = [];
  @Input() dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  profileForm: UntypedFormGroup;
  uploadedFile: any = [];
  file: File;

  uploadFileContent = '';
  uploadFileVersion = '';
  fileTableHeader = [];
  version: any;
  showFileContentLoading = false;
  showFileContentTable = true;
  subscriptions: Subscription[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private uploadService: UploadService,
    private store: Store

  ) { }

  ngOnInit() {
    this.initProfileForm();


  }

  async profileFormSubmitPS() {
    const downloadPumpSystemFileSubscription =
      (await (this.uploadService.downloadPumpSystemFile('live', 'json'))).subscribe(item => {
        this.extractFileToTable(item);
      });
    this.subscriptions.push(downloadPumpSystemFileSubscription);
  }


  async downloadPumpSystems(state: string) {
    (await (this.uploadService.downloadPumpSystemFile(state, 'csv'))).subscribe(response => {
      let blob: any = new Blob([response], { type: 'text/csv; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, 'pumps.csv');
    }), error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  }


  private extractFileToTable(item: any, preview?: boolean) {
    this.showFileContentLoading = false;
    this.fileTableHeader = [];
    if (item && item.length !== 0) {
      this.uploadedFile = item;
      const uploadFileContent = JSON.stringify(this.uploadedFile);
      const uploadFileVersion = preview ? this.uploadedFile.version : this.uploadedFile.version.version;
      const data = preview ? JSON.parse(this.uploadedFile.file) : this.uploadedFile.entries;
      data.forEach((dataItem: {}) => {
        Object.keys(dataItem).map(value => this.fileTableHeader.push(value));
        this.fileTableHeader = [...new Set(this.fileTableHeader)];
      });
      this.showFileContentTable = false;
      this.displayedColumns = this.fileTableHeader;
      this.dataSource.data = data;
      this.store.dispatch(setPumpSystemUploadDownloadData({data, file: uploadFileContent, version: uploadFileVersion}));
    }
  }

  initProfileForm() {
    this.profileForm = this.fb.group({

    });
  }


  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

}


