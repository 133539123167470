import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { select, Store } from '@ngrx/store';
import { combineLatest, map, Observable, startWith, Subject, Subscription } from 'rxjs';
import { ControllerItem } from 'src/app/display-product-data/controllers/controller.model';
import { DisplayProductDataService } from 'src/app/display-product-data/display-product-data.service';
import { CONTROLLER_ITEMS } from 'src/app/display-product-data/pump-data.model';
import { setControlllerName } from 'src/app/display-product-data/store/controller/controller.actions';
import { setControllerSelect } from 'src/app/display-product-data/store/pump-system/pump-system.actions';
import { PumpSystemState } from 'src/app/display-product-data/store/pump-system/pump-system.reducer';
import { selectPumpSystem } from 'src/app/display-product-data/store/pump-system/pump-system.seletor';
import { nameSort } from '../../util';

@Component({
  selector: 'app-controller-select',
  templateUrl: './controller-select.component.html',
  styleUrls: ['./controller-select.component.scss']
})
export class ControllerSelectComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  controllerForm: UntypedFormGroup;
  controller: Observable<ControllerItem>;
  showControllerForm = false;
  controllerName = 'Please select controller';
  controllerUuid = '';
  controllerFilteredOptions: Set<string>;
  controllerDropdownList: string[] = [];
  controllerList: ControllerItem[] = [];
  controllerControl = new UntypedFormControl('', [
    Validators.required,
    this.controllerOptionNotFound.bind(this),
  ]);

  public readonly controllerChanges: Subject<string> = new Subject<string>();
  public controllerSuggestions: Observable<string[]>;

  constructor(
    private store: Store,
    private fb: UntypedFormBuilder,
    private displayProductData: DisplayProductDataService,
  ) { }

  ngOnInit() {

    const controllerUuid = combineLatest([
      this.displayProductData.getControllerList(),
      this.store.pipe(
        select(selectPumpSystem),
        map((data: PumpSystemState) => data.controllerUuid)
      )
    ]).subscribe(([data, controllerUuid]) => {

      this.controllerList = nameSort(data);
      this.controllerDropdownList = this.controllerList.map(item => item.name);

      this.controllerSuggestions = this.controllerChanges.pipe(
        startWith(''),
        map((val: string) => this.controllerFilterResults(val))
      );

      if (controllerUuid !== '') {
        const controller = this.controllerList.find(item => item.uuid === controllerUuid);
        if (controller) {
          this.controllerName = controller.name;
          this.controllerControl.setValue(controller.name);
          this.store.dispatch(setControlllerName({ data: controller.name }));
        }
        const getControllerDetail = this.displayProductData.getControllerItem(`${controllerUuid}`).subscribe(controllerData => {
          this.showControllerForm = true;
          const controllerFormValue = {};
          CONTROLLER_ITEMS.forEach(name => {
            const value = controllerData[`${name}`];
            controllerFormValue[`${name}`] = [{ value, disabled: true }, Validators.required];
          });
          this.controllerForm = this.fb.group(controllerFormValue);
        });
        this.subscriptions.push(getControllerDetail);
        this.controller = this.displayProductData.getControllerItem(`${controllerUuid}`);
      }
    });
    this.subscriptions.push(controllerUuid);
  }

  private controllerFilterResults(val: string) {
    if (val !== '') {
      return this.controllerDropdownList.filter(item => item.toLowerCase().includes(val.toLowerCase()));
    } else {
      return this.controllerDropdownList;
    }
  }

  controllerOptionNotFound(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value) {
      this.controllerFilteredOptions = new Set(
        this.controllerDropdownList.filter((option) => option.toLowerCase() === value.toLowerCase())
      );
    }
    if (value && !this.controllerFilteredOptions.size) {
      return {
        noOption: true
      };
    }
    return null;
  }

  onControllerInputFocus() {
    this.controllerChanges.next('');
  }

  updateController(value: string) {
    if (value === '') {
      this.controllerOptionNotFound(this.controllerControl);
      this.controllerName = '';
      this.showControllerForm = false;
    } else {
      const item = this.controllerList.find(item => item.name === value);
      if (item) {
        this.controllerControl.setValue(value);
      } else {
        this.controllerOptionNotFound(this.controllerControl);
        this.controllerName = '';
        this.showControllerForm = false;
      }
    };
  }

  setControllerChangeAction(event: MatAutocompleteSelectedEvent) {
    this.controllerName = event.option.value;
    this.showControllerForm = true;
    const controller = this.controllerList.find(item => item.name === event.option.value);
    const getControllerDetail = this.displayProductData.getControllerItem(controller.uuid).subscribe(controllerData => {
      const controllerFormValue = {};
      CONTROLLER_ITEMS.forEach(name => {
        const value = controllerData[`${name}`];
        controllerFormValue[`${name}`] = [{ value, disabled: true }, Validators.required];
      });
      this.controllerForm = this.fb.group(controllerFormValue);
    });
    this.subscriptions.push(getControllerDetail);
    this.store.dispatch(setControlllerName({ data: event.option.value }));
    this.store.dispatch(setControllerSelect({ data: controller.uuid }));
    this.controller = this.displayProductData.getControllerItem(controller.uuid);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
