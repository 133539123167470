import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-coefficient-data-create',
  templateUrl: './coefficient-data-create.component.html',
  styleUrls: ['./coefficient-data-create.component.scss']
})
export class CoefficientDataCreateComponent implements OnInit {

  measurementForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CoefficientDataCreateComponent>
  ) { }

  ngOnInit() {
    this.measurementForm = this.fb.group({
      headPressure: [null, Validators.required],
      current: [null, Validators.required],
      voltage: [null, Validators.required],
      volume: [null, Validators.required],
      rotationalSpeed: [null, Validators.required],
      measurementTime: [null, Validators.required],
      power: [null, Validators.required],
      flowRate: [null, Validators.required]
    });
  }

  handleMeasurementFormData() {
    this.dialogRef.close(this.measurementForm.value);
  }

}
