import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, Subject } from 'rxjs';
import { expand, map, reduce, repeatWhen, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ControllerItem, EncClassItem, MaterialItem, PackagingItem, PictureItem, VaporPressureItem } from './controllers/controller.model';
import { MotorItem } from './motors/motor.model';
import { PumpEndsItem } from './pump-ends/pump-end.model';
import { PumpSystemItem } from './pump-systems/pump-system.model';
import { CityItem } from './cities/city.model';
import { PartsListItem } from "./parts-list/partsList.model";
import { CountryItem } from "./countries/country.model";
import { PagedCityQueryResult } from "./cities/PagedCityQueryResult";
import { PagedCoordinateQueryResult } from "./location/PagedCoordinateQueryResult";
import { PagedDataQuery } from "./PagedDataQuery";
import { CoordinateItem } from "./location/coordinate.model";
import {
  Accessory, AccessoryDetail, ConnectedProduct, ConnectedProductDetail, Module,
  ModuleDetail, PipePart, PipePartDetail, PoleMount, PoleMountDetail, PumpSystem, PumpSystemDetail
} from './pump-data.model';

const HOST = environment.pumpDataUrl;
const GEO_HOST = environment.pumpGeoUrl;

@Injectable({
  providedIn: 'root'
})
export class DisplayProductDataService {

  controllerData$: Observable<ControllerItem[]>;
  motorData$: Observable<MotorItem[]>;
  pumpEndsData$: Observable<PumpEndsItem[]>;
  pumpSystemData$: Observable<PumpSystemItem[]>;
  packagingData$: Observable<PackagingItem[]>;
  materialData$: Observable<MaterialItem[]>;
  coordinateData$: Observable<CoordinateItem[]>;
  partsListsData$: Observable<PartsListItem[]>;
  pipePartData$: Observable<PipePart[]>;
  accessoriesData$: Observable<Accessory[]>;
  poleMountData$: Observable<PoleMount[]>;
  modulesData$: Observable<Module[]>;
  vaporPressureData$: Observable<VaporPressureItem[]>;
  connprodsData$: Observable<ConnectedProduct[]>;
  citiesData$: Observable<CityItem[]>;
  countriesData$: Observable<CountryItem[]>;
  picturesData$: Observable<PictureItem[]>;
  pagedCitiesData$: Observable<PagedCityQueryResult>;


  private reloadConnprodDataTrigger = new Subject<boolean>();
  private reloadModuleDataTrigger = new Subject<boolean>();
  private reloadCountryDataTrigger = new Subject<boolean>();
  private reloadPumpSystemDataTrigger = new Subject<boolean>();
  private reloadMotorDataTrigger = new Subject<boolean>();
  private reloadControllerDataTrigger = new Subject<boolean>();
  private reloadPumpEndDataTrigger = new Subject<boolean>();
  private reloadAccessoryDataTrigger = new Subject<boolean>();
  private reloadPartListDataTrigger = new Subject<boolean>();
  private reloadPipePartDataTrigger = new Subject<boolean>();
  private reloadPackagingTrigger = new Subject<boolean>();

  reloadModuleData() {
    this.reloadModuleDataTrigger.next(true);
  }

  reloadConnprodData() {
    this.reloadConnprodDataTrigger.next(true);
  }

  reloadCountryData() {
    this.reloadCountryDataTrigger.next(true);
  }

  reloadPumpSystemData() {
    this.reloadPumpSystemDataTrigger.next(true);
  }

  reloadMotorData() {
    this.reloadMotorDataTrigger.next(true);
  }

  reloadControllerData() {
    this.reloadControllerDataTrigger.next(true);
  }

  reloadPumpEndData() {
    this.reloadPumpEndDataTrigger.next(true);
  }

  reloadAccessoryData() {
    this.reloadAccessoryDataTrigger.next(true);
  }

  reloadPartListData() {
    this.reloadPartListDataTrigger.next(true);
  }

  reloadPipePartData() {
    this.reloadPipePartDataTrigger.next(true);
  }

  reloadPackaging() {
    this.reloadPackagingTrigger.next(true);
  }

  constructor(
    private http: HttpClient
  ) {
    this.controllerData$ = this.http.get<ControllerItem[]>(HOST + '/controllers').pipe(
      repeatWhen(() => this.reloadControllerDataTrigger),
      shareReplay(1)
    );

    this.motorData$ = this.http.get<MotorItem[]>(HOST + '/motors').pipe(
      repeatWhen(() => this.reloadMotorDataTrigger),
      shareReplay(1)
    );

    this.pumpEndsData$ = this.http.get<PumpEndsItem[]>(HOST + '/pumpends').pipe(
      repeatWhen(() => this.reloadPumpEndDataTrigger),
      shareReplay(1)
    );

    this.pumpSystemData$ = this.http.get<PumpSystemItem[]>(HOST + '/pumpsystems?ignoreStatuses=STATE_4').pipe(
      repeatWhen(() => this.reloadPumpSystemDataTrigger),
      shareReplay(1)
    );

    this.packagingData$ = this.http.get<PackagingItem[]>(HOST + '/packagings').pipe(
      repeatWhen(() => this.reloadPackagingTrigger),
      shareReplay(1)
    );

    this.materialData$ = this.http.get<MaterialItem[]>(HOST + '/materials').pipe(shareReplay(1));

    this.coordinateData$ = this.loadAllCoordinateData({uuid: null, lastKey: null, limit: 1000}).pipe(
      shareReplay(1)
    );

    this.partsListsData$ = this.http.get<PartsListItem[]>(HOST + '/parts').pipe(
      repeatWhen(() => this.reloadPartListDataTrigger),
      shareReplay(1)
    );

    this.pipePartData$ = this.http.get<PipePart[]>(HOST + '/pipeparts').pipe(
      repeatWhen(() => this.reloadPipePartDataTrigger),
      shareReplay(1)
    );

    this.accessoriesData$ = this.http.get<Accessory[]>(HOST + '/accessories').pipe(
      repeatWhen(() => this.reloadAccessoryDataTrigger),
      shareReplay(1)
    );

    this.poleMountData$ = this.http.get<PoleMount[]>(HOST + '/polemounts').pipe(shareReplay(1));

    this.modulesData$ = this.http.get<Module[]>(HOST + '/modules').pipe(
      repeatWhen(() => this.reloadModuleDataTrigger),
      shareReplay(1)
    );

    this.vaporPressureData$ = this.http.get<VaporPressureItem[]>(GEO_HOST + '/vaporpressures').pipe(shareReplay(1));

    this.connprodsData$ = this.http.get<ConnectedProduct[]>(HOST + '/connprods').pipe(
      repeatWhen(() => this.reloadConnprodDataTrigger),
      shareReplay(1),
    );

    this.citiesData$ = this.loadAllCityData({uuid: null, lastKey: null, limit: 10000}).pipe(
      shareReplay(1)
    );

    this.pagedCitiesData$ = this.loadCityData({uuid: null, lastKey: null, limit: 1000}).pipe(
      shareReplay(1)
    );


    this.countriesData$ = this.http.get<CountryItem[]>(GEO_HOST + '/countries').pipe(
      repeatWhen(() => this.reloadCountryDataTrigger),
      shareReplay(1)
    );

    this.picturesData$ = this.http.get<PictureItem[]>(HOST + '/pictures').pipe(shareReplay(1));
  }

  /**
   * list of city data records.
   *
   * The result of the server maybe paged. If the CityDataList#lastKey is not empty there are possibly more
   * result items that can be fetched from the server by providing the PumpDataList#lastKey#ts as lastTs
   * parameter in subsequent calls to this function.
   *
   * @param uuid - the id of the pump
   * @param limit - the number of items to return, if not defined returns all items
   * @returns - a list of city data records, maybe a partial result when paged
   */
  loadCityData(query: PagedDataQuery): Observable<PagedCityQueryResult> {

    let params = new HttpParams();

    if (query.lastKey) {
      params = params.set('lastKey', query.lastKey);
    }
    if (query.limit) {
      params = params.set('limit', query.limit.toString());
    }

    return this.http.get<PagedCityQueryResult>(GEO_HOST + '/cities', { params }).pipe(
      shareReplay(1)
    );
  }


  /**
   * loads the pump data, resolving all pages.
   *
   * the #loadPumpData method return 1 result that could be paged.
   * loadAllPumpData resolves all pages, emitting one result with all data.
   *
   */
  loadAllCityData(query: PagedDataQuery): Observable<CityItem[]> {
    return this.loadCityData(query).pipe(
      expand((pl: PagedCityQueryResult) => {
        if (!pl.lastEvaluatedKey) {
          return EMPTY;
        }
        const nextPageQuery = {
          ...query,
          lastKey: pl.lastEvaluatedKey
        };
        return this.loadCityData(nextPageQuery);
      }),
      map(result => result.data),
      reduce((acc: CityItem[], val: CityItem[]) => {
        acc.push(...val);
        return acc;
      }, []),
    );
  }

  /**
   * list of city data records.
   *
   * The result of the server maybe paged. If the CityDataList#lastKey is not empty there are possibly more
   * result items that can be fetched from the server by providing the PumpDataList#lastKey#ts as lastTs
   * parameter in subsequent calls to this function.
   *
   * @param uuid - the id of the pump
   * @param limit - the number of items to return, if not defined returns all items
   * @returns - a list of city data records, maybe a partial result when paged
   */
   loadCoordinateData(query: PagedDataQuery): Observable<PagedCoordinateQueryResult> {

    let params = new HttpParams();

    if (query.lastKey) {
      params = params.set('lastKey', query.lastKey);
    }
    if (query.limit) {
      params = params.set('limit', query.limit.toString());
    }

    return this.http.get<PagedCoordinateQueryResult>(GEO_HOST + '/coordinates', { params }).pipe(
      shareReplay(1)
    );
  }


  /**
   * loads the pump data, resolving all pages.
   *
   * the #loadPumpData method return 1 result that could be paged.
   * loadAllPumpData resolves all pages, emitting one result with all data.
   *
   */
  loadAllCoordinateData(query: PagedDataQuery): Observable<CoordinateItem[]> {
    return this.loadCoordinateData(query).pipe(
      expand((pl: PagedCoordinateQueryResult) => {
        if (!pl.lastEvaluatedKey) {
          return EMPTY;
        }
        const nextPageQuery = {
          ...query,
          lastKey: pl.lastEvaluatedKey
        };
        return this.loadCoordinateData(nextPageQuery);
      }),
      map(result => result.data),
      reduce((acc: CoordinateItem[], val: CoordinateItem[]) => {
        acc.push(...val);
        return acc;
      }, []),
    );
  }

  getControllerList(): Observable<ControllerItem[]> {
    return this.controllerData$;
  }

  getMotorList(): Observable<MotorItem[]> {
    return this.motorData$;
  }

  getPumpEndsList(): Observable<PumpEndsItem[]> {
    return this.pumpEndsData$;
  }

  getPumpSystemList(): Observable<PumpSystem[]> {
    return this.pumpSystemData$;
  }

  getPackagingList(): Observable<PackagingItem[]> {
    return this.packagingData$;
  }

  getCityList(): Observable<CityItem[]> {
    return this.citiesData$;
  }

  getCountryList(): Observable<CountryItem[]> {
    return this.countriesData$;
  }

  getCoordinateList(): Observable<CoordinateItem[]> {
    return this.coordinateData$;
  }

  getPartsList(): Observable<PartsListItem[]> {
    return this.partsListsData$;
  }

  getMaterialList(): Observable<MaterialItem[]> {
    return this.materialData$;
  }

  getAccessoryList(): Observable<Accessory[]> {
    return this.accessoriesData$;
  }

  getPoleMountList(): Observable<PoleMount[]> {
    return this.poleMountData$;
  }

  getPipePartList(): Observable<PipePart[]> {
    return this.pipePartData$;
  }

  getPictureAndDrawingList(): Observable<PictureItem[]> {
    return this.picturesData$;
  }

  getImage(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { responseType: 'blob' });
  }

  getConnectedProductList(): Observable<ConnectedProduct[]> {
    return this.connprodsData$;
  }

  getEncClassList(): Observable<EncClassItem[]> {
    return this.http.get<EncClassItem[]>(HOST + '/encclasses').pipe();
  }

  getModuleList(): Observable<Module[]> {
    return this.modulesData$;
  }

  getModuleItem(moduleUuid: string): Observable<ModuleDetail> {
    return this.http.get<ModuleDetail>(HOST + `/module/${moduleUuid}`);
  }

  updateModule(data: ModuleDetail): Observable<any> {
    return this.http.put(HOST + `/module`, data, { responseType: 'text' as 'json' }).pipe();
  }

  setModuleState(url: string, data: any): Observable<any> {
    return this.http.put(HOST + `/module/${url}`, data, { responseType: 'text' as 'json' }).pipe();
  }

  createNewModule(data: ModuleDetail): Observable<any> {
    return this.http.post(HOST + `/module`, data, { responseType: 'text' as 'json' }).pipe();
  }

  getVaporPressureList(): Observable<VaporPressureItem[]> {
    return this.vaporPressureData$;
  }

  getControllerItem(controllerName: string): Observable<ControllerItem> {
    return this.http.get<ControllerItem>(HOST + `/controller/${controllerName}`);
  }

  getMotorItem(motorUuid: string): Observable<MotorItem> {
    return this.http.get<MotorItem>(HOST + `/motor/${motorUuid}`);
  }

  getPumpEndItem(pumpEndUuid: string): Observable<PumpEndsItem> {
    return this.http.get<PumpEndsItem>(HOST + `/pumpend/${pumpEndUuid}`);
  }

  getPumpSystemItem(pumpSystemName: string): Observable<any> {
    return this.http.get<PumpSystemDetail>(HOST + `/ps/${pumpSystemName}`);
  }

  getPumpSystemItemByPumpNo(pumpNo: string): Observable<any> {
    return this.http.get<PumpSystemDetail>(HOST + `/ps/pumpNo/${pumpNo}`);
  }

  getPipePartItem(pipepartUuid: string): Observable<PipePartDetail> {
    return this.http.get<PipePartDetail>(HOST + `/pipepart/${pipepartUuid}`);
  }

  updatePipePart(data: any): Observable<any> {
    return this.http.put(HOST + `/pipepart`, data, { responseType: 'text' as 'json' }).pipe();
  }

  setPipePartState(url: string, data: any): Observable<any> {
    return this.http.put(HOST + `/pipepart/${url}`, data, { responseType: 'text' as 'json' }).pipe();
  }

  createPipePartItem(data: PipePartDetail): Observable<any> {
    return this.http.post(HOST + `/pipepart`, data, { responseType: 'text' as 'json' }).pipe();
  }

  getAccessoryItem(accessoryUuid: string): Observable<AccessoryDetail> {
    return this.http.get<AccessoryDetail>(HOST + `/accessory/${accessoryUuid}`);
  }

  updateAccessory(data: any): Observable<any> {
    return this.http.put(HOST + `/accessory`, data, { responseType: 'text' as 'json' }).pipe();
  }

  setAccessoryState(url: string, data: any): Observable<any> {
    return this.http.put(HOST + `/accessory/${url}`, data, { responseType: 'text' as 'json' }).pipe();
  }

  createAccessoryItem(data: AccessoryDetail): Observable<any> {
    return this.http.post(HOST + `/accessory`, data, { responseType: 'text' as 'json' }).pipe();
  }

  getPoleMountItem(poleMountUuid: string): Observable<PoleMountDetail> {
    return this.http.get<PoleMountDetail>(HOST + `/polemount/${poleMountUuid}`);
  }

  updatePoleMount(data: PoleMountDetail): Observable<any> {
    return this.http.patch(HOST + `/polemount`, data, {responseType: 'text' as 'json'}).pipe();
  }

  setPoleMountState(url: string, data: any): Observable<any> {
    return this.http.put(HOST + `/polemount/${url}`, data, { responseType: 'text' as 'json' }).pipe();
  }

  createPoleMountItem(data: PoleMountDetail): Observable<any> {
    return this.http.post(HOST + `/polemount`, data, { responseType: 'text' as 'json' }).pipe();
  }

  getConnectedProductItem(connectedProductUuid: string): Observable<ConnectedProductDetail> {
    return this.http.get<ConnectedProductDetail>(HOST + `/connprod/${connectedProductUuid}`);
  }

  createConnectedProductItem(data: ConnectedProduct): Observable<any> {
    return this.http.post(HOST + `/connprod`, data, { responseType: 'text' as 'json' }).pipe();
  }

  setConnectedProductState(url: string, data: any): Observable<any> {
    return this.http.put(HOST + `/connprod/${url}`, data, { responseType: 'text' as 'json' }).pipe();
  }

  updateConnectedProductItem(data: ConnectedProduct): Observable<any> {
    return this.http.put(HOST + `/connprod`, data, { responseType: 'text' as 'json' }).pipe();
  }

  createNewController(data: ControllerItem): Observable<any> {
    return this.http.post(HOST + `/controller`, data, { responseType: 'text' as 'json' }).pipe();
  }

  updateController(data: ControllerItem): Observable<any> {
    return this.http.patch(HOST + `/controller`, data, { responseType: 'text' as 'json' }).pipe();
  }

  createNewMotor(data: MotorItem): Observable<any> {
    return this.http.post(HOST + `/motor`, data, { responseType: 'text' as 'json' }).pipe();
  }

  updateMotor(uuid: string, data: MotorItem): Observable<any> {
    return this.http.patch(HOST + `/motor/${uuid}`, data, { responseType: 'text' as 'json' }).pipe();
  }

  createNewPumpEnd(data: PumpEndsItem): Observable<any> {
    return this.http.post(HOST + `/pumpend`, data, { responseType: 'text' as 'json' }).pipe();
  }

  updatePumpEnd(data: PumpEndsItem): Observable<any> {
    return this.http.patch(HOST + `/pumpend/${data.uuid}`, data, { responseType: 'text' as 'json' }).pipe();
  }

  createNewPumpSystem(data: PumpSystemItem): Observable<any> {
    return this.http.post(HOST + `/ps`, data, { responseType: 'text' as 'json' }).pipe();
  }

  updatePumpSystem(data: PumpSystemItem): Observable<any> {
    return this.http.put(HOST + `/ps`, data, { responseType: 'text' as 'json' }).pipe();
  }

  setPumpSystemState(url: string, data: any): Observable<any> {
    return this.http.put(HOST + `/ps/${url}`, data, { responseType: 'text' as 'json' }).pipe();
  }

  createNewPackaging(data: PackagingItem): Observable<any> {
    return this.http.post(HOST + `/packaging`, data, { responseType: 'text' as 'json' }).pipe();
  }

  createNewMaterial(data: MaterialItem): Observable<any> {
    return this.http.post(HOST + `/material`, data, { responseType: 'text' as 'json' }).pipe();
  }

  createNewVaporPressure(data: VaporPressureItem): Observable<any> {
    return this.http.post(GEO_HOST + `/vaporpressure`, data, { responseType: 'text' as 'json' }).pipe();
  }

  createNewEncclass(data: EncClassItem): Observable<any> {
    return this.http.post(HOST + `/encclass`, data, { responseType: 'text' as 'json' }).pipe();
  }

  // Three params needed, which is uploadpicture/type/uuid/name
  createNewPicture(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': `image/${data.imageType}` });
    return this.http.post(HOST + `/uploadpicture/${data.type}/${data.uuid}/${data.name}`,
      data.file, { headers, responseType: 'text' as 'json' }).pipe();
  }

  createNewPartlist(data: any): Observable<any> {
    return this.http.post(HOST + `/part`, data, { responseType: 'text' as 'json' }).pipe();
  }

  updatePartlist(uuid: string, data: any): Observable<any> {
    return this.http.put(HOST + `/part/${uuid}`, data, { responseType: 'text' as 'json' }).pipe();
  }

  createNewCity(data: CityItem): Observable<any> {
    return this.http.post(GEO_HOST + `/city`, data, { responseType: 'text' as 'json' }).pipe();
  }

  createNewCountry(data: CountryItem): Observable<any> {
    return this.http.post(GEO_HOST + `/country`, data, { responseType: 'text' as 'json' }).pipe();
  }

}


