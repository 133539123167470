<h1 mat-dialog-title> {{ type }} Parts List </h1>
<mat-dialog-content>
  
  <p *ngIf="type === 'Delete'">Are you sure to delete this parts list element?</p>

  <p *ngIf="type === 'Update'">Are you sure to update this parts list element?</p>
  
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button *ngIf="type === 'Delete' || type === 'Update'" mat-raised-button color="primary" (click)="onConfirmClick()">Confirm</button>
</mat-dialog-actions>
