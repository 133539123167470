<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">

    <mat-card-header>
      <mat-card-title>
        Create New Parts List
      </mat-card-title>
    </mat-card-header>

    <div class="form-element copy-part">
      <button mat-raised-button color="primary" type="button" (click)="openCopyDialog('parts-list')">COPY</button>

      <span *ngIf="copiedItem">{{copiedItem}} copied</span>
    </div>

    <div class="form-element upload-part">
      <div class="upload-wrapper">
        <button mat-raised-button color="primary">
          <mat-icon>add</mat-icon> Choose file
        </button>
        <input id="uploadPartsListNodes" type="file" name="profile" (change)="onFileSelect($event)"
          accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
      </div>
      <div class="upload-info" *ngIf="uploadFileName">
        <span> {{ uploadFileName }} </span>
      </div>
    </div>

    <mat-card-content *ngIf="showPartslistTree">

      <div class="treeLoading" *ngIf="treeLoading">
        <mat-progress-spinner mode="indeterminate" [diameter]=20></mat-progress-spinner>
      </div>

      <form class="partlistForm" [formGroup]="partlistForm" (ngSubmit)="onCreateNewPartList(partlistForm.value)">
        <mat-form-field>
          <mat-label> Parts List Name </mat-label>
          <input matInput placeholder="Parts List Name" formControlName="name">
          <mat-error *ngIf="partlistForm.get('name').hasError('required')">The field name is required</mat-error>
          <mat-error *ngIf="partlistForm.get('name').hasError('duplicateNames')">This name is already used</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>type</mat-label>
          <mat-select formControlName="type" placeholder="type">
            <mat-option value="pump_end">pump end</mat-option>
            <mat-option value="motor">motor</mat-option>
          </mat-select>
        </mat-form-field>
      </form>

      <mat-divider></mat-divider>

      <button mat-raised-button class="createBtn" color="primary" type="button" (click)="addPartListTreeNode()"> Create
        new item </button>

      <mat-tree #tree class="parts-tree" [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding
          [ngClass]="{'drop-above': dragNodeExpandOverArea === 'above' && dragNodeExpandOverNode === node,
                                      'drop-below': dragNodeExpandOverArea === 'below' && dragNodeExpandOverNode === node,
                                      'drop-center': dragNodeExpandOverArea === 'center' && dragNodeExpandOverNode === node}" (change)="checklistSelection.toggle(node);" draggable="true"
          (dragstart)="handleDragStart($event, node);" (dragover)="handleDragOver($event, node);"
          (drop)="handleDrop($event, node);" (dragend)="handleDragEnd();">

          <button mat-icon-button disabled></button>

          {{node.name}} ({{node.id}})

          <span *ngIf="node.material" class="parts-material"> {{ node.material }} </span>

          <div class="editWrapper">
            <button mat-icon-button color="primary" (click)="editNode(node)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="deleteNode(node)">
              <mat-icon class="deleteIcon">add_circle</mat-icon>
            </button>
          </div>

        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
          [ngClass]="{'drop-above': dragNodeExpandOverArea === 'above' && dragNodeExpandOverNode === node,
                                    'drop-below': dragNodeExpandOverArea === 'below' && dragNodeExpandOverNode === node,
                                    'drop-center': dragNodeExpandOverArea === 'center' && dragNodeExpandOverNode === node}" draggable="true" (dragstart)="handleDragStart($event, node);"
          (dragover)="handleDragOver($event, node);" (drop)="handleDrop($event, node);" (dragend)="handleDragEnd();">

          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          {{node.name}} ({{node.id}})
          <span *ngIf="node.material" class="parts-material"> {{ node.material }} </span>
          <div class="editWrapper">
            <button mat-icon-button color="primary" (click)="editNode(node)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="deleteNode(node)">
              <mat-icon class="deleteIcon">add_circle</mat-icon>
            </button>
          </div>
        </mat-tree-node>
      </mat-tree>

      <mat-divider></mat-divider>

      <div class="button-element marginBtn">
        <button mat-raised-button color="primary" [disabled]="!partlistForm.valid" type="button"
          (click)="onCreateNewPartList(partlistForm.value)"> Create new parts list </button>
      </div>

    </mat-card-content>
  </mat-card>
</section>

<span #emptyItem></span>