import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectUploadDownloadData } from '../store/upload-download/upload-download.selector';


@Component({
  selector: 'app-upload-download-data',
  templateUrl: './upload-download-data.component.html',
  styleUrls: ['./upload-download-data.component.scss']
})
export class UploadDownloadDataComponent implements OnInit, OnDestroy, AfterViewInit {

  displayedColumns: string[] = [];
  @Input() dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  uploadFileContent = '';
  uploadFileVersion = '';
  fileTableHeader = [];
  showFileContentLoading = false;
  showFileContentTable = true;
  subscriptions: Subscription[] = [];

  constructor(
    private store: Store
  ) { }

  ngOnInit() {

    this.store.pipe(
      select(selectUploadDownloadData),

    ).subscribe({
      next: (data) => {
        if (data && data.uploadedData != null) {
          this.buildTable(data.uploadedData);
          this.uploadFileContent = data.file;
          this.uploadFileVersion = data.version;
        }
      }
    });
  }

  private buildTable(data: any) {
    this.showFileContentLoading = false;
    this.fileTableHeader = [];

    data.forEach((dataItem: {}) => {
      Object.keys(dataItem).map(value => this.fileTableHeader.push(value));
      this.fileTableHeader = [...new Set(this.fileTableHeader)];
    });
    this.showFileContentTable = false;
    this.displayedColumns = this.fileTableHeader;
    this.dataSource.data = data;

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // If the user changes the sort order, reset back to the first page.
    // this.sort.sortChange.subscribe(() => this.paginator.firstPage());
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

}


