import { Component, EventEmitter, forwardRef, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-insulation-class-select',
  templateUrl: './insulation-class-select.component.html',
  styleUrls: ['./insulation-class-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InsulationClassSelectComponent),
      multi: true
    }
  ]
})
export class InsulationClassSelectComponent implements OnDestroy, ControlValueAccessor  {

  types = ['A', 'B', 'F', 'H', 'unknown'];

  typeStore = '';
  private subscriptions: Subscription[] = [];

  @Output() selectionChange = new EventEmitter<MatSelectChange>();

  selectListControl = new UntypedFormControl('');

  typeChangeAction(event) {
    this.typeStore = event.value;
    this.selectionChange.emit(event);
  }

  // model --> view
  writeValue(value: string): void {
    if (value) {
      this.typeStore = value;
      this.selectListControl.setValue(value, { emitEvent: false });
    } else {
      this.selectListControl.reset('');
    }
  }

  // view --> model
  registerOnChange(fn: (value: string) => void) {
    const onChangeSubscription = this.selectListControl.valueChanges.subscribe(fn);
    this.subscriptions.push(onChangeSubscription);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  onTouched: () => void = () => {};

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
