import { createAction, props } from "@ngrx/store";
import { DataSource, ProfileFormParameters, ProfileFormStepper } from "../../../pump-simulation/coefficient.model";

export const PROFILE_FORM_STEPPER = '[Profile Form] Set Profile form Stepper Part';
export const PROFILE_FORM_PARAMETERS = '[Profile Form] Set Profile form Parameters Part';
export const PROFILE_FORM_DATA_SOURCE = '[Profile Form] Update Coefficient Data Source';
export const PROFILE_FORM_UPDATE_PARAMETERS = '[Profile Form] Update Profile form Parameters Part';

export const setStepper = createAction(
  PROFILE_FORM_STEPPER,
  props<{ stepper: Partial<ProfileFormStepper> }>()
);

export const setParameters = createAction(
  PROFILE_FORM_PARAMETERS,
  props<{ parameters: Partial<ProfileFormParameters> }>()
);

export const updateParameters = createAction(
  PROFILE_FORM_UPDATE_PARAMETERS,
  props<{ isUpdateParameters: boolean }>()
);

export const setDataSource = createAction(
  PROFILE_FORM_DATA_SOURCE,
  props<{ dataSource: Partial<DataSource> }>()
);
