import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { first, map, Observable, Subscription } from 'rxjs';
import { ChartConfiguration } from 'src/app/pump-simulation/chartConfiguration';
import { createConfiguration, plotData } from 'src/app/shared/util';
import { setFlowRateCalculatedData, setFlowRateMeasuredData } from '../../store/charts/flow-rate-calculate/flow-rate-calculate.actions';
import { FlowRateCalculateState } from '../../store/charts/flow-rate-calculate/flow-rate-calculate.reducer';
import { selectFlowRateCalculate } from '../../store/charts/flow-rate-calculate/flow-rate-calculate.selector';

declare const google: any;

const FLOW_RATE_CALCULATE_ID = 'flowRateCalculate';

@Component({
  selector: 'app-flow-rate-chart-calculate',
  templateUrl: './flow-rate-chart-calculate.component.html',
  styleUrls: ['./flow-rate-chart-calculate.component.scss']
})
export class FlowRateChartCalculateComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  xAxisToYaxisChart = null;
  showPlaceholder$: Observable<Boolean>;
  @ViewChild(FLOW_RATE_CALCULATE_ID, { static: true }) chartElement: ElementRef;

  constructor(
    private store: Store,
  ) { }

  ngOnInit() {
    this.showPlaceholder$ = this.store.pipe(
      select(selectFlowRateCalculate),
      map((data: FlowRateCalculateState) => data.loading)
    );

    const flowRate = this.store.pipe(
      select(selectFlowRateCalculate),
      map((data: FlowRateCalculateState) => data)
    ).subscribe(data => {
      if (data.flowMeasuredData && data.flowCalculatedData) {
        this.createFlowRateGraph();
      }
    });
    this.subscriptions.push(flowRate);
  }

  createFlowRateGraph() {

    const flowChartConfiguration = createConfiguration({
      xAxis: 'electrical power [W]',
      yAxis: 'flow [m³/h]',
      height: 500
    });

    const flowRateGraph = this.store.pipe(
      select(selectFlowRateCalculate),
      map((data: FlowRateCalculateState) => data),
      first()
    ).subscribe(data => {
      if (data.flowMeasuredData && data.flowCalculatedData) {
        google.charts.load('current', { packages: ['corechart'] });
        google.charts.setOnLoadCallback(this.drawChart(flowChartConfiguration, this.chartElement.nativeElement, data.flowCalculatedData, data.flowMeasuredData));
      }
    });
    this.subscriptions.push(flowRateGraph);
  }

  drawChart(
    chartConfiguration: ChartConfiguration,
    chart: ElementRef,
    calculation: Map<number, Map<number, number>>,
    measurements: Map<number, Map<number, number>>
  ) {
    return () => {
      let columnIndex = 1;
      let rowIndex = 0;
      const colors = chartConfiguration.colours;
      const data = new google.visualization.DataTable();
      data.addColumn('number', chartConfiguration.xAxisTitle);
      const options = chartConfiguration.options;

      let head;
      ({ head, rowIndex, columnIndex } = plotData(
        calculation, data, rowIndex, chartConfiguration,
        columnIndex, options, colors, 'calculated'));
      ({ head, rowIndex, columnIndex } = plotData(
        measurements, data, rowIndex, chartConfiguration,
        columnIndex, options, colors, 'measured'));

      const xAxisToYaxisChart = new google.visualization.LineChart(chart);
      google.visualization.events.addListener(xAxisToYaxisChart, 'ready', () => {
        // remove loading
      });
      xAxisToYaxisChart.draw(data, options);
    };
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    if(this.xAxisToYaxisChart) {
      this.xAxisToYaxisChart.clearChart();
    }
    this.store.dispatch(setFlowRateMeasuredData({ data: null }));
    this.store.dispatch(setFlowRateCalculatedData({ data: null }));
  }
}
