import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { select, Store } from '@ngrx/store';
import { combineLatest, map, Observable, startWith, Subject, Subscription } from 'rxjs';
import { DisplayProductDataService } from 'src/app/display-product-data/display-product-data.service';
import { MotorItem } from 'src/app/display-product-data/motors/motor.model';
import { MOTOR_ITEMS } from 'src/app/display-product-data/pump-data.model';
import { setControlllerName, setMotorName } from 'src/app/display-product-data/store/controller/controller.actions';
import { setMotorSelect } from 'src/app/display-product-data/store/pump-system/pump-system.actions';
import { PumpSystemState } from 'src/app/display-product-data/store/pump-system/pump-system.reducer';
import { selectPumpSystem } from 'src/app/display-product-data/store/pump-system/pump-system.seletor';
import { nameSort } from '../../util';

@Component({
  selector: 'app-motor-select',
  templateUrl: './motor-select.component.html',
  styleUrls: ['./motor-select.component.scss']
})
export class MotorSelectComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  motorForm: UntypedFormGroup;
  motor: Observable<MotorItem>;
  showMotorForm = false;
  motorName = 'Please select motor';
  motorFilteredOptions: Set<string>;
  motorDropdownList: string[] = [];
  motorList: MotorItem[] = [];
  motorControl = new UntypedFormControl('', [
    Validators.required,
    this.motorOptionNotFound.bind(this),
  ]);

  public readonly motorChanges: Subject<string> = new Subject<string>();
  public motorSuggestions: Observable<string[]>;

  constructor(
    private store: Store,
    private fb: UntypedFormBuilder,
    private displayProductData: DisplayProductDataService,
  ) { }

  ngOnInit(): void {
    const motorUuid = combineLatest([
      this.displayProductData.getMotorList(),
      this.store.pipe(
        select(selectPumpSystem),
        map((data: PumpSystemState) => data.motorUuid)
      )
    ]).subscribe(([data, motorUuid]) => {

      this.motorList = nameSort(data);
      this.motorDropdownList = this.motorList.map(item => item.name);

      this.motorSuggestions = this.motorChanges.pipe(
        startWith(''),
        map((val: string) => this.motorFilterResults(val))
      );

      if (motorUuid !== '') {
        const motor = this.motorList.find(item => item.uuid === motorUuid);
        if (motor) {
          this.motorName = motor.name;
          this.motorControl.setValue(motor.name);
          this.store.dispatch(setMotorName({ data: motor.name }));
        }
        const getMotorDetail = this.displayProductData.getMotorItem(`${motorUuid}`).subscribe(motorData => {
          this.motorName = motorData.name;
          this.showMotorForm = true;
          const motorFormValue = {};
          MOTOR_ITEMS.forEach(name => {
            const value = motorData[`${name}`];
            motorFormValue[`${name}`] = [{ value, disabled: true }, Validators.required];
          });
          this.motorForm = this.fb.group(motorFormValue);
        });
        this.subscriptions.push(getMotorDetail);
        this.motor = this.displayProductData.getMotorItem(`${motorUuid}`);
      }
    });
    this.subscriptions.push(motorUuid);
  }

  private motorFilterResults(val: string) {
    if (val !== '') {
      return this.motorDropdownList.filter(item => item.toLowerCase().includes(val.toLowerCase()));
    } else {
      return this.motorDropdownList;
    }
  }

  motorOptionNotFound(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value) {
      this.motorFilteredOptions = new Set(
        this.motorDropdownList.filter((option) => option.toLowerCase() === value.toLowerCase())
      );
    }
    if (value && !this.motorFilteredOptions.size) {
      return {
        noOption: true
      };
    }
    return null;
  }

  onMotorFocus() {
    this.motorChanges.next('');
  }

  updateMotor(value: string) {
    if (value === '') {
      this.motorOptionNotFound(this.motorControl);
      this.motorName = '';
      this.showMotorForm = false;
    } else {

      const item = this.motorList.find(item => item.name === value);
      if (item) {
        this.motorControl.setValue(value);
      } else {
        this.motorOptionNotFound(this.motorControl);
        this.motorName = '';
        this.showMotorForm = false;
      }
    };
  }

  setMotorChangeAction(event: MatAutocompleteSelectedEvent) {
    this.motorName = event.option.value;
    this.showMotorForm = true;
    const motor = this.motorList.find(item => item.name === event.option.value);
    const getMotorDetail = this.displayProductData.getMotorItem(motor.uuid).subscribe(motorData => {
      this.motorName = motorData.name;
      const motorFormValue = {};
      MOTOR_ITEMS.forEach(name => {
        const value = motorData[`${name}`];
        motorFormValue[`${name}`] = [{ value, disabled: true }, Validators.required];
      });
      this.motorForm = this.fb.group(motorFormValue);
    });
    this.subscriptions.push(getMotorDetail);
    this.store.dispatch(setControlllerName({ data: event.option.value }));
    this.store.dispatch(setMotorSelect({ data: motor.uuid }));
    this.motor = this.displayProductData.getMotorItem(motor.uuid);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
