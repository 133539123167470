import { ElementRef } from '@angular/core';
import { ChartConfiguration } from '../pump-simulation/chartConfiguration';
import { IntermediateCoefficients } from '../pump-simulation/coefficient.model';

export function createConfiguration(
  {
    xAxis = 'head [m]',
    yAxis,
    height = 200,
    width = '100%',
    colours = ['black', 'blue', 'red', 'green', 'gray', 'cyan', 'magenta', 'yellow'],
    format = 'decimal',
    pointSize
  }: {
    xAxis?: string,
    yAxis: string,
    height?: number,
    width?: string,
    colours?: string[],
    format?: string,
    pointSize?: number
  }) {
  return {
    colours,
    xAxisTitle: xAxis,
    yAxisTitle: yAxis,
    options: {
      pointSize,
      height,
      width,
      series: {},
      hAxis: { title: xAxis, titleTextStyle: { italic: false } },
      vAxis: { title: yAxis, titleTextStyle: { italic: false }, format, minValue: 0 },
      crosshair: { trigger: 'both' },
      animation: {
        startup: true,
        duration: 1000,
        easing: 'out',
      }
    }
  };
}

export function plotData(
  calculation: Map<number, Map<number, number>>,
  data: any,
  rowIndex: number,
  chartConfiguration: ChartConfiguration,
  columnIndex: number,
  options: {
    height: string | number;
    width: string | number;
    series: {};
    hAxis: { title: string; titleTextStyle: { italic: boolean; }; };
    vAxis: { title: string; titleTextStyle: { italic: boolean; };
    format?: string; };
    crosshair: { trigger: string; };
  },
  colors: string[],
  type: string
) {
  const head = new Array();
  for (const key of calculation.keys()) {
    head.push(key);
  }
  for (const key of head) {
    data.addColumn('number', key + ' m');
  }

  for (const key of head) {

    const xAxisToYaxis = calculation.get(key);

    for (const entry of xAxisToYaxis.entries()) {
      data.addRow();
      data.setCell(rowIndex, data.getColumnIndex(chartConfiguration.xAxisTitle), entry[0]);
      data.setCell(rowIndex, columnIndex, entry[1]);
      rowIndex++;
    }

    if ( type === 'measured' ) {
      options.series[columnIndex - 1] = { type: 'scatter', color: colors[columnIndex - 1 - head.length], visibleInLegend: false };
    } else {
      options.series[columnIndex - 1] = { color: colors[columnIndex - 1] };
      if (key === -1) {
        options.series[columnIndex - 1].visibleInLegend = false;
      }
    }
    columnIndex++;
  }
  return { head, rowIndex, columnIndex };
}

export function removeRadioBtnFocus(element: ElementRef, target: number) {
  const tableElement = element.nativeElement.querySelectorAll(`table tr`);
  const tadioButton = tableElement[target+1].querySelector(`.mat-radio-${target}`);
  tadioButton.classList.remove('mat-radio-checked');
  tadioButton.classList.remove('cdk-focused');
  tadioButton.classList.remove('cdk-program-focused');
}

export function nameSort(data: any[]) {
  return [...data].sort((a, b) => {
    if(a.name < b.name) { return -1; }
    if(a.name > b.name) { return 1; }
    return 0;
  })
}

export function filenameSort(data: any[]) {
  return [...data].sort((a, b) => {
    if(a.displayName < b.displayName) { return -1; }
    if(a.displayName > b.displayName) { return 1; }
    return 0;
  })
}

export function fileSort(data: any[]) {
  return [...data].sort((a, b) => {
    if(a.file < b.file) { return -1; }
    if(a.file > b.file) { return 1; }
    return 0;
  })
}

export function compareArray(a: string[], b: string[]) {
  let difference = [];
  if (a.length > b.length) {
    difference = a.filter(x => !b.includes(x));
  } else if (a.length < b.length) {
    difference = b.filter(x => !a.includes(x));
  } else {
    difference = b.filter(x => !a.includes(x));
  }
  return difference;
}

export function pumpsystemNameSort(data: any[]) {
  const allData = nameSort(data);
  return allData.map(item => {

    if (item.name.indexOf(`${item.controller}-`) > -1) {
      item['diaplayName'] = `${item.controller} ${item.name.replace(`${item.controller}-`, '')}`;
    } else {
      item['diaplayName'] = item.name;
    }
    return item;
  });
}

export function objectToIntermediateCoefficientsMap(o) {
  const m = new Map<number, IntermediateCoefficients>();
  for (const k of Object.keys(o)) {
    const coefficients: IntermediateCoefficients = o[k];
    m.set(Number.parseFloat(k), coefficients);
  }
  return m;
}

export function objectToNumberMap(o) {
  const m = new Map<number, number>();
  const keys = Object.keys(o);
  const sortedKeys: number[] = [];
  sortKeysNumericallyAscending(keys, sortedKeys);
  for (const k of sortedKeys) {
    const value: number = o[k.toString()];
    m.set(k, value);
  }
  return m;
}

export function sortKeysNumericallyAscending(keys: string[], sortedKeys: number[]) {
  for (const key of keys) {
    sortedKeys.push(parseFloat(key));
  }
  sortedKeys.sort(function numberSort(a: number, b: number) {
    if (a < b) {
      return -1;
    }
    if (b < a) {
      return 1;
    }
    return 0;
  });
}

export function objectToMap(o) {
  const m = new Map<number, Map<number, number>>();
  const keys = Object.keys(o);
  const sortedKeys: number[] = [];
  sortKeysNumericallyAscending(keys, sortedKeys);
  for (const k of sortedKeys) {
    m.set(k, objectToNumberMap(o[k]));
  }
  return m;
}

export function mapToObjectRec(m) {
  const lo = {};
  for (const [k, v] of m) {
    if (v instanceof Map) {
      lo[k] = this.mapToObjectRec(v);
    }
    else {
      lo[k] = v;
    }
  }
  return lo;
}

export function objectToIntermediateCoefficentMap(o) {
  const m = new Map<number, IntermediateCoefficients>();
  const keys = Object.keys(o);
  const sortedKeys: number[] = [];
  sortKeysNumericallyAscending(keys, sortedKeys);

  for (const k of sortedKeys) {
    const coefficients: IntermediateCoefficients = o[k.toString()];
    m.set(k, coefficients);
  }
  return m;
}

export function isEmpty(obj: Object): boolean {
  return !obj || !Object.keys(obj).some(x => obj[x] !== void 0);
}
