import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { first, map, Observable, Subscription } from 'rxjs';
import { ChartConfiguration } from 'src/app/pump-simulation/chartConfiguration';
import { createConfiguration, plotData } from 'src/app/shared/util';
import { setRotationalMeasuredData, setRotationalCalculatedData } from '../../store/charts/rotational-speed-chart/rotational-speed-chart.actions';
import { RotationalSpeedState } from '../../store/charts/rotational-speed-chart/rotational-speed-chart.reducer';
import { selectRotationalSpeed } from '../../store/charts/rotational-speed-chart/rotational-speed-chart.selector';


declare const google: any;

const ROTATIONAL_SPEED_CHART_ID = 'rotationalSpeedChart';

@Component({
  selector: 'app-rotational-speed-chart',
  templateUrl: './rotational-speed-chart.component.html',
  styleUrls: ['./rotational-speed-chart.component.scss']
})
export class RotationalSpeedChartComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  xAxisToYaxisChart = null;
  showPlaceholder$: Observable<Boolean>;
  @ViewChild(ROTATIONAL_SPEED_CHART_ID, { static: true }) chartElement: ElementRef;

  constructor(
    private store: Store,
  ) { }

  ngOnInit() {
    this.showPlaceholder$ = this.store.pipe(
      select(selectRotationalSpeed),
      map((data: RotationalSpeedState) => data.loading)
    );

    const rotational = this.store.pipe(
      select(selectRotationalSpeed),
      map((data: RotationalSpeedState) => data)
    ).subscribe(data => {
      if (data.rotationalMeasuredData && data.rotationalCalculatedData) {
        this.createRotationalSpeedGraph();
      }
    });
    this.subscriptions.push(rotational);
  }

  createRotationalSpeedGraph() {

    const flowChartConfiguration = createConfiguration({
      xAxis: 'flow [m³/h]',
      yAxis: 'rotational speed [1/min]',
      height: 300
    });

    const rotationalSpeed = this.store.pipe(
      select(selectRotationalSpeed),
      map((data: RotationalSpeedState) => data),
      first()
    ).subscribe(data => {
      if (data.rotationalMeasuredData && data.rotationalCalculatedData) {
        google.charts.load('current', { packages: ['corechart'] });
        google.charts.setOnLoadCallback(this.drawChart(flowChartConfiguration, this.chartElement.nativeElement, data.rotationalCalculatedData, data.rotationalMeasuredData));
      }
    });
    this.subscriptions.push(rotationalSpeed);
  }

  drawChart(
    chartConfiguration: ChartConfiguration,
    chart: ElementRef,
    calculation: Map<number, Map<number, number>>,
    measurements: Map<number, Map<number, number>>
  ) {
    return () => {
      let columnIndex = 1;
      let rowIndex = 0;
      const colors = chartConfiguration.colours;
      const data = new google.visualization.DataTable();
      data.addColumn('number', chartConfiguration.xAxisTitle);
      const options = chartConfiguration.options;

      let head;
      ({ head, rowIndex, columnIndex } = plotData(
        calculation, data, rowIndex, chartConfiguration,
        columnIndex, options, colors, 'calculated'));
      ({ head, rowIndex, columnIndex } = plotData(
        measurements, data, rowIndex, chartConfiguration,
        columnIndex, options, colors, 'measured'));

      this.xAxisToYaxisChart = new google.visualization.LineChart(chart);
      google.visualization.events.addListener(this.xAxisToYaxisChart, 'ready', () => {
        // remove loading
      });
      this.xAxisToYaxisChart.draw(data, options);
    };
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    if(this.xAxisToYaxisChart) {
      this.xAxisToYaxisChart.clearChart();
    }
    this.store.dispatch(setRotationalMeasuredData({ data: null }));
    this.store.dispatch(setRotationalCalculatedData({ data: null }));
  }
}
