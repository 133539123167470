import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CopyButtonComponent } from '../copy-button/copy-button.component';
import { DisplayProductDataService } from '../display-product-data.service';
import { PumpEndsItem } from '../pump-ends/pump-end.model';
import { v4 } from 'uuid';
import { PUMPENDS_DIAMS_ITEMS, PUMPENDS_ITEMS } from '../pump-data.model';
import { checkPumpEndsName } from '../names.validator';
import { PartsListService } from '../parts-list.service';
import { select, Store } from '@ngrx/store';
import { combineLatest, delay, map, Subscription } from 'rxjs';
import { PackagingItem } from '../controllers/controller.model';
import { PackagingState } from '../store/packaging/packaging.reducer';
import { selectPackaging } from '../store/packaging/packaging.selector';
import { ErrorButtonComponent } from '../error-button/error-button.component';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { setPartsData, setPartsName, setPartsPicture, setPartsType } from '../store/parts-list/parts-list.actions';
import { setPartsListName } from '../store/controller/controller.actions';
import { ControllerState } from '../store/controller/controller.reducer';
import { selectController } from '../store/controller/controller.seletor';
import { PartsListState } from '../store/parts-list/parts-list.reducer';
import { selectPartsList } from '../store/parts-list/parts-list.seletor';

@Component({
  selector: 'app-pump-end-new',
  templateUrl: './pump-end-new.component.html',
  styleUrls: ['./pump-end-new.component.scss']
})
export class PumpEndNewComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  pumpEndForm: UntypedFormGroup;
  diamsGroup: UntypedFormGroup;
  showLoading = false;
  copiedItem: string;
  packagingList = [];
  showPartslistTree = false;
  partslistName = 'Please select parts list';
  partsListData: any;
  pumpEndPartsValue: any;

  constructor(
    private store: Store,
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private database: PartsListService,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {

    this.createForm();
    this.store.dispatch(setPartsType({ data: 'pump_end' }));
    const getPackagingList = combineLatest([
      this.store.pipe(
        select(selectPackaging),
        map((data: PackagingState) => data.data)
      ),
      this.store.pipe(
        select(selectController),
        map((data: ControllerState) => data.partsList)
      ),
      this.store.pipe(
        select(selectPartsList),
        map((data: PartsListState) => data.partsData)
      )
    ]).subscribe(([data, partsName, partsData ]) => {
      this.packagingList = [...data].sort((a, b) => Number(a.subType.L) - Number(b.subType.L));

      if (partsName !== '') {
        this.partslistName = `${partsName}`;
      }

      if (
        partsData // null and undefined check
        && Object.keys(partsData).length === 0
        && Object.getPrototypeOf(partsData) === Object.prototype
      ) {
        this.showPartslistTree = false;
      } else {
        this.showPartslistTree = true;
      }
    });
    this.subscriptions.push(getPackagingList);
  }

  openCopyDialog(type: string) {
    const dialogRef = this.dialog.open(CopyButtonComponent, {
      width: '1000px',
      maxWidth: '96vw',
      data: type,
      disableClose: true,
      minHeight: '60vh',
      maxHeight: '60vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.showLoading = true;
      this.copyItem(result);
    });
  }

  copyItem(pumpEndName: string) {
    const getPumpEnd = this.displayProductData.getPumpEndItem(pumpEndName).subscribe((pumpEnd: PumpEndsItem) => {

      this.showLoading = false;
      this.copiedItem = pumpEnd.name;

      const diamsData: any = {};
      PUMPENDS_DIAMS_ITEMS.forEach(name => {
        diamsData[`${name}`] = pumpEnd.diams[`${name}`];
      });
      this.diamsGroup.patchValue(diamsData);

      const data: any = {};
      PUMPENDS_ITEMS.forEach(name => {
        data[`${name}`] = pumpEnd[`${name}`];
      });
      data.name = '';
      this.pumpEndForm.patchValue(data);

      if (pumpEnd.parts) {
        this.partslistName = pumpEnd.parts.name;
        this.showPartslistTree = true;
        this.database.setPartsListUuid(pumpEnd.partsUuid);
        this.store.dispatch(setPartsName({ data: pumpEnd.parts.name }));
        this.store.dispatch(setPartsListName({ data: pumpEnd.parts.name }));
        this.store.dispatch(setPartsData({ data: pumpEnd.parts }));
      }

      if ( pumpEnd.parts_picture && pumpEnd.parts_picture !== '0' ) {
        this.store.dispatch(setPartsPicture({ data: pumpEnd.parts_picture }));
      }
    });
    this.subscriptions.push(getPumpEnd);
  }

  createForm() {
    const diamsData: any = {};
    PUMPENDS_DIAMS_ITEMS.forEach(name => {
      diamsData[`${name}`] = new UntypedFormControl('');
    });
    this.diamsGroup = this.fb.group(diamsData);

    const data: any = {};
    PUMPENDS_ITEMS.forEach(name => {
      data[`${name}`] = null;
    });
    data.name = ['', [Validators.required, checkPumpEndsName.bind(this)]];
    data.diams = this.diamsGroup;
    this.pumpEndForm = this.fb.group(data);
  }

  createNewPumpEnd(data) {
    data.uuid = v4();
    data.parts = this.pumpEndPartsValue;

    if ( this.pumpEndForm.value.packaging.subType ) {
      const packagingValue: any = {};
      packagingValue.name = data.packaging.uuid;
      packagingValue.w = data.packaging.subType.W;
      packagingValue.h = data.packaging.subType.H;
      packagingValue.l = data.packaging.subType.L;
      packagingValue.pack_weight = data.packaging.weight;
      data.packaging = packagingValue;
    } else {
      const result = this.packagingList.filter(item => item.name.includes(data.packaging));
      const packaging: any = {};
      packaging.name = result[0].uuid;
      packaging.w = `${result[0].subType.W}`;
      packaging.h = `${result[0].subType.H}`;
      packaging.l = `${result[0].subType.L}`;
      packaging.pack_weight = `${result[0].weight}`;
      data.packaging = packaging;
    }

    this.displayProductData.createNewPumpEnd(data).subscribe(
      item => {
        this.snackBar
        .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
        .afterDismissed()
        .pipe(delay(0))
        .subscribe(() => {
          this.displayProductData.reloadPumpEndData();
          this.router.navigate(['/data/pump-end', data.uuid]);
        });
      },
      error => {
        this.dialog.open(ErrorButtonComponent, {
          width: '600px',
          data: JSON.parse(error.error),
          minHeight: '20vh',
        });
      },
      () => {}
    );
  }

  updatePartslistTree(event) {
    this.pumpEndPartsValue = event.partsValue;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
