<section>
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

    <!-- Index Column -->
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let event">
        <mat-checkbox color="primary" [checked]="false" (change)='addItem(event)'></mat-checkbox>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let event" (click)="expandedEvent = expandedEvent === event ? null : event; openItem()">
        {{event.file}} </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let event" [attr.colspan]="displayedColumns.length">
        <div class="element-detail" [@detailExpand]="event === expandedEvent ? 'expanded' : 'collapsed'">
          <div class="element-description">
            <mat-form-field class="form-element">
              <mat-label>type</mat-label>
              <input matInput disabled placeholder="type" value={{event.type}}>
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>status</mat-label>
              <input matInput disabled placeholder="status" value={{event.status}}>
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>explanation</mat-label>
              <input matInput disabled placeholder="explanation" value={{event.explanation}}>
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>item_no_1</mat-label>
              <input matInput disabled placeholder="item_no_1" value={{event.item_no_1}}>
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>item_no_2</mat-label>
              <input matInput disabled placeholder="item_no_2" value={{event.item_no_2}}>
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>item_no_3</mat-label>
              <input matInput disabled placeholder="item_no_3" value={{event.item_no_3}}>
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>item_no_4</mat-label>
              <input matInput disabled placeholder="item_no_4" value={{event.item_no_4}}>
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>item_no_5</mat-label>
              <input matInput disabled placeholder="item_no_5" value={{event.item_no_5}}>
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>product_name_1</mat-label>
              <input matInput disabled placeholder="product_name_1" value={{event.product_name_1}}>
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>product_name_2</mat-label>
              <input matInput disabled placeholder="product_name_2" value={{event.product_name_2}}>
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>product_name_3</mat-label>
              <input matInput disabled placeholder="product_name_3" value={{event.product_name_3}}>
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>product_name_4</mat-label>
              <input matInput disabled placeholder="product_name_4" value={{event.product_name_4}}>
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>product_name_5</mat-label>
              <input matInput disabled placeholder="product_name_5" value={{event.product_name_5}}>
            </mat-form-field>

            <app-packaging-disabled></app-packaging-disabled>
          </div>
        </div>
      </td>
    </ng-container>

    <tr [ngClass]="showTableHeader ? '' : 'hideContent'" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let event; columns: displayedColumns;" class="element-row"
      [class.expanded-row]="expandedEvent === event">
    </tr>
    <tr mat-row *matRowDef="let event; columns: ['expandedDetail']" class="detail-row"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</section>