import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { DisplayProductDataService } from "../../display-product-data.service";
import { PumpSystem } from "../../pump-data.model";
import * as PumpSystemActions from "./pump-system.actions";


@Injectable()

export class PumpSystemEffects {

  // Get all Accessory Names
  loadPumpSystems$ = createEffect(() => this.actions$.pipe(
    ofType(PumpSystemActions.loadPumpSystems),
    mergeMap(() => this.displayProductData.getPumpSystemList()
    .pipe(
      map((data: PumpSystem[]) => PumpSystemActions.loadPumpSystemsSuccess({ data })),
      catchError(error => of(PumpSystemActions.loadPumpSystemsFailure({ error })))
    ))
  ));

  constructor(
    private actions$: Actions,
    private displayProductData: DisplayProductDataService
  ){}
}


