import { trigger, state, style, transition, animate } from '@angular/animations';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Store, select } from '@ngrx/store';
import { Subscription, map, Observable } from 'rxjs';
import { fileSort } from 'src/app/shared/util';
import { DisplayProductDataService } from '../display-product-data.service';
import { AccessoryDetail, AccessoryEvent, ACCESSORY_ITEMS } from '../pump-data.model';
import { removeAccessoryItem, setAccessoryExpandedEvent } from '../store/accessory/accessory.actions';
import { AccessoryState } from '../store/accessory/accessory.reducer';
import { selectAccessory } from '../store/accessory/accessory.seletor';

@Component({
  selector: 'app-pump-accessory',
  templateUrl: './pump-accessory.component.html',
  styleUrls: ['./pump-accessory.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PumpAccessoryComponent implements OnInit, AfterViewInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  accessoryForm: UntypedFormGroup;
  displayedColumns: string[] = ['index', 'name'];
  @Input() dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  accessory: Observable<AccessoryDetail>;
  expandedEvent: AccessoryEvent | null;
  showTableHeader = false;
  showAccessoryForm = false;

  constructor(
    private store: Store,
    private fb: UntypedFormBuilder,
    private displayProductData: DisplayProductDataService,
  ) { }

  ngOnInit() {

    const tableData = this.store.pipe(
      select(selectAccessory),
      map((data: AccessoryState) => data.prepareList)
    ).subscribe((data: Partial<AccessoryDetail>[]) => {
      if (data.length !== 0) {
        this.showTableHeader = true;
      } else {
        this.showTableHeader = false;
      }
      this.dataSource.data = fileSort([...data]);
    });
    this.subscriptions.push(tableData);

    const getExpandedEvent = this.store.pipe(
      select(selectAccessory),
      map((data: AccessoryState) => data.expandedEvent)
    ).subscribe((data) => this.expandedEvent = data);
    this.subscriptions.push(getExpandedEvent);
  }

  addItem(event) {
    this.store.dispatch(removeAccessoryItem({ data: event }));
  }

  toggleExpansion(event: AccessoryEvent): void {
    this.store.dispatch(setAccessoryExpandedEvent({ data: this.expandedEvent }));
    if (event === this.expandedEvent) {
      this.expandedEvent = null;
      this.showAccessoryForm = false;
    } else {
      this.expandedEvent = event;
      const getAccessoryItem = this.displayProductData.getAccessoryItem(event.uuid).subscribe(data => {
        const accessoryFormValue = {};
        ACCESSORY_ITEMS.forEach(name => {
          const value = data.versions[0][`${name}`];
          accessoryFormValue[`${name}`] = [{ value, disabled: true }, Validators.required];
        });
        this.accessoryForm = this.fb.group(accessoryFormValue);
      });
      this.subscriptions.push(getAccessoryItem);
      this.accessory = this.displayProductData.getAccessoryItem(event.uuid);
      this.showAccessoryForm = true;
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

}
