import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, Observable, startWith, map, delay } from 'rxjs';
import { CoefficientNameItem } from 'src/app/pump-simulation/coefficient.model';
import { DisplayPumpSimulationService } from 'src/app/pump-simulation/display-pump-simulation.service';
import { filenameSort } from 'src/app/shared/util';
import { PumpSimulationHelperService } from '../pump-simulation-helper.service';
import { setCoefficientsData, setSelectedCoefficientsData } from '../store/calculate-coefficient/calculate-coefficient.actions';
import { setChartSettingsValue, setLoadSimulationStepper, setSimulationProcess } from '../store/charts-setting/charts-setting.actions';
import { setCurrentMeasuredData, setCurrentCalculatedData } from '../store/charts/current-chart/current-chart.actions';
import { setFlowRateMeasuredData, setFlowRateCalculatedData } from '../store/charts/flow-rate-calculate/flow-rate-calculate.actions';
import { setIntermediateData, setIntermediateLoading } from '../store/charts/intermediate-chart/intermediate-chart.actions';
import { setNPSHMeasuredData, setNPSHCalculatedData } from '../store/charts/npsh-chart/npsh-chart.actions';
import { setRotationalMeasuredData, setRotationalCalculatedData } from '../store/charts/rotational-speed-chart/rotational-speed-chart.actions';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  @ViewChild(MatSidenav) private sidenav!: MatSidenav;
  private subscriptions: Subscription[] = [];
  nameControl = new UntypedFormControl();
  filteredOptions: Observable<CoefficientNameItem[]>;
  options: CoefficientNameItem[] = [];
  coefficientSetNameList: Observable<CoefficientNameItem[]>;
  navTitle = '';

  constructor(
    private store: Store,
    private router: Router,
    private displayPumpSimulationService: DisplayPumpSimulationService,
    private pumpSimulationHelperService: PumpSimulationHelperService
  ) { }

  ngOnInit() {
    this.coefficientSetNameList = this.displayPumpSimulationService.getAllMeasurementFileVersions().pipe(
      map(data => {
        let cut: CoefficientNameItem[] = [];
        data.forEach(item => {
          const coefficientName = {
            'filename': item.filename, 'displayName': !this.pumpSimulationHelperService.isEmpty(item.displayName) ?
              item.displayName : item.filename
          }
          if (!cut.find(element => element.filename === coefficientName.filename)) {
            cut.push(coefficientName)
          }
        });
        filenameSort(cut);
        this.options = cut;
        this.filteredOptions = this.nameControl.valueChanges.pipe(
          startWith(''),
          delay(0),
          map(value => this._filter(value))
        );
        if (this.router.url === '/simulations') {
          this.navTitle = '';
        } else if (this.router.url === '/simulations/simulation/new') {
          this.navTitle = 'Create Pump Simulation';
          this.store.dispatch(setSimulationProcess({ data: 'create' }));
        } else {
          const name = this.router.url.split('/')
          const value = name[name.length - 1];

          let name_string: string = value;
          this.options.forEach(item => {
            if (item.filename === value) {
              name_string = item.displayName;
            }
          })

          this.navTitle = `Version overview: ${name_string}`;
          this.store.dispatch(setSimulationProcess({ data: 'load' }));
        }

        return cut
      })

    );

    this.store.dispatch(setFlowRateMeasuredData({ data: null }));
    this.store.dispatch(setFlowRateCalculatedData({ data: null }));
    this.store.dispatch(setCurrentMeasuredData({ data: null }));
    this.store.dispatch(setCurrentCalculatedData({ data: null }));
    this.store.dispatch(setRotationalMeasuredData({ data: null }));
    this.store.dispatch(setRotationalCalculatedData({ data: null }));
    this.store.dispatch(setIntermediateData({ data: null }));
    this.store.dispatch(setIntermediateLoading({ data: true }));
    this.store.dispatch(setNPSHMeasuredData({ data: null }));
    this.store.dispatch(setNPSHCalculatedData({ data: null }));
    this.store.dispatch(setCoefficientsData({ data: null }));
    this.store.dispatch(setSelectedCoefficientsData({ data: null }));
    this.store.dispatch(setChartSettingsValue({ data: null }));
  }

  onSelectionChange(event) {
    let id: string = null;
    this.options.forEach(item => {
      if (item.displayName === event.option.value) {
        id = item.filename;
      }
    })
    this.sidenav.toggle();
    this.nameControl.setValue('');
    this.router.navigate([`/simulations/simulation/${id}`]);
    this.store.dispatch(setLoadSimulationStepper({ data: false }));
  }

  private _filter(value: string): CoefficientNameItem[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.filename.toLowerCase().includes(filterValue));
  }

  handleTitle(value: string) {
    this.navTitle = '';
    if (value === 'create') {
      this.navTitle = 'Create Pump Simulation';
      this.store.dispatch(setSimulationProcess({ data: 'create' }));
    } else {
      let name: string = value;
      this.options.forEach(item => {
        if (item.filename === value) {
          name = item.displayName;
        }
      })
      this.navTitle = `Version overview: ${name}`;
      this.store.dispatch(setSimulationProcess({ data: 'load' }));
    }
    this.sidenav.toggle();
    this.store.dispatch(setLoadSimulationStepper({ data: false }));
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

}
