import { AbstractControl } from '@angular/forms';
import { CountryItem } from "./countries/country.model";
import { MaterialItem, VaporPressureItem } from './controllers/controller.model';
import { Controller, Motor, PumpEnd, PumpSystem, PartsList, ConnectedProduct, Module, PoleMount, Accessory } from './pump-data.model';
import { pumpsystemNameSort } from '../shared/util';

export function checkEnclosureClassName(control: AbstractControl) {

  const existNames = [];

  this.displayProductData.getEncClassList().subscribe((allEncClasss: MaterialItem[]) => {
    allEncClasss.forEach(encClass => existNames.push(encClass.name.toLowerCase()));
  });

  const isNameAlreadyUse = existNames.indexOf(control.value.toLowerCase()) > -1;

  return isNameAlreadyUse
    ? { duplicateNames: true }
    : null;
}

export function checkMaterialName(control: AbstractControl) {

  const existNames = [];

  this.displayProductData.getMaterialList().subscribe((allMaterialLists: MaterialItem[]) => {
    allMaterialLists.forEach(material => existNames.push(material.name.toLowerCase()));
  });

  const isNameAlreadyUse = existNames.indexOf(control.value.toLowerCase()) > -1;

  return isNameAlreadyUse
    ? { duplicateNames: true }
    : null;
}

export function checkPartListName(control: AbstractControl) {

  const existNames = [];

  this.displayProductData.getPartsList().subscribe((allPartsLists: PartsList[]) => {
    allPartsLists.forEach(partsList => existNames.push(partsList.name.toLowerCase()));
  });

  const isNameAlreadyUse = existNames.indexOf(control.value.toLowerCase()) > -1;

  return isNameAlreadyUse
    ? { duplicateNames: true }
    : null;
}

export function checkTemperature(control: AbstractControl) {
  const temperatures = [];
  this.displayProductData.getVaporPressureList().subscribe((allVaporPressures: VaporPressureItem[]) => {
    allVaporPressures.forEach(item => temperatures.push(Number(item.temperature)));
  });
  const isTemperatureAlreadyUse = temperatures.includes(control.value);
  return isTemperatureAlreadyUse
    ? { duplicateNames: true }
    : null;
}

export function checkPressure(control: AbstractControl) {
  const pressures = [];
  this.displayProductData.getVaporPressureList().subscribe((allVaporPressures: VaporPressureItem[]) => {
    allVaporPressures.forEach(item => pressures.push(Number(item.pressure)));
  });
  const isPressureAlreadyUse = pressures.includes(control.value);
  return isPressureAlreadyUse
    ? { duplicateNames: true }
    : null;
}

export function checkConnectedProductName(control: AbstractControl) {

  const existNames = [];

  this.displayProductData.getConnectedProductList().subscribe((allConnectedProducts: ConnectedProduct[]) => {
    allConnectedProducts.forEach(connectedProduct => existNames.push(connectedProduct.name.toLowerCase()));
  });

  const isNameAlreadyUse = existNames.indexOf(control.value.toLowerCase()) > -1;

  return isNameAlreadyUse
    ? { duplicateNames: true }
    : null;
}

export function checkCountryName(control: AbstractControl) {

  const existNames = [];

  this.displayProductData.getCountryList().subscribe((allCountries: CountryItem[]) => {
    allCountries.forEach(country => existNames.push(country.name.toLowerCase()));
  });
  const isNameAlreadyUse = existNames.indexOf(control.value.toLowerCase()) > -1;

  return isNameAlreadyUse
    ? { duplicateNames: true }
    : null;
}

export function checkCapitalName(control: AbstractControl) {

  const existNames = [];

  this.displayProductData.getCountryList().subscribe((allCapitales: CountryItem[]) => {
    allCapitales.forEach(capital => existNames.push(capital.capital.toLowerCase()));
  });

  const isNameAlreadyUse = existNames.indexOf(control.value.toLowerCase()) > -1;

  return isNameAlreadyUse
    ? { duplicateNames: true }
    : null;
}

export function checkModuleName(control: AbstractControl) {

  const existNames = [];

  this.displayProductData.getModuleList().subscribe((allModules: Module[]) => {
    allModules.forEach(module => existNames.push(module.name.toLowerCase()));
  });

  const isNameAlreadyUse = existNames.indexOf(control.value.toLowerCase()) > -1;

  return isNameAlreadyUse
    ? { duplicateNames: true }
    : null;
}

export function checkControllerName(control: AbstractControl) {

  const existNames = [];

  this.displayProductData.getControllerList().subscribe((allController: Controller[]) => {
    allController.forEach(controller => existNames.push(controller.name.toLowerCase()));
  });

  const isNameAlreadyUse = existNames.indexOf(control.value.toLowerCase()) > -1;

  return isNameAlreadyUse
    ? { duplicateNames: true }
    : null;
}

export function checkMotorName(control: AbstractControl) {

  const existNames = [];

  this.displayProductData.getMotorList().subscribe((allMotors: Motor[]) => {
    allMotors.forEach(motor => existNames.push(motor.name.toLowerCase()));
  });

  const isNameAlreadyUse = existNames.indexOf(control.value.toLowerCase()) > -1;

  return isNameAlreadyUse
    ? { duplicateNames: true }
    : null;
}

export function checkPumpEndsName(control: AbstractControl) {

  const existNames = [];

  this.displayProductData.getPumpEndsList().subscribe((allPumpEnds: PumpEnd[]) => {
    allPumpEnds.forEach(pumpEnd => existNames.push(pumpEnd.name.toLowerCase()));
  });

  const isNameAlreadyUse = existNames.indexOf(control.value.toLowerCase()) > -1;

  return isNameAlreadyUse
    ? { duplicateNames: true }
    : null;
}

export function checkPumpSystemName(control: AbstractControl) {

  const existNames = [];

  this.displayProductData.getPumpSystemList().subscribe((allPumpSystems: PumpSystem[]) => {
    const result = pumpsystemNameSort(allPumpSystems);
    result.forEach(pumpSystem => existNames.push(pumpSystem.diaplayName.toLowerCase()));
  });

  const isNameAlreadyUse = existNames.indexOf(control.value.toLowerCase()) > -1;

  return isNameAlreadyUse
    ? { duplicateNames: true }
    : null;
}

export function checkPoleMountName(control: AbstractControl) {

  const existNames = [];

  this.displayProductData.getPoleMountList().subscribe((allPoleMounts: PoleMount[]) => {
    allPoleMounts.forEach(poleMount => existNames.push(poleMount.name.toLowerCase()));
  });

  const isNameAlreadyUse = existNames.indexOf(control.value.toLowerCase()) > -1;

  return isNameAlreadyUse
    ? { duplicateNames: true }
    : null;
}

export function checkAccessoryName(control: AbstractControl) {

  const existNames = [];

  this.displayProductData.getAccessoryList().subscribe((allAccessories: Accessory[]) => {
    allAccessories.forEach(accessory => existNames.push(accessory.name.toLowerCase()));
  });

  const isNameAlreadyUse = existNames.indexOf(control.value.toLowerCase()) > -1;

  return isNameAlreadyUse
    ? { duplicateNames: true }
    : null;
}
