import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { first, map, Observable, Subscription } from 'rxjs';
import { ChartConfiguration } from 'src/app/pump-simulation/chartConfiguration';
import { createConfiguration, plotData } from 'src/app/shared/util';
import { setTableHightlightRow } from '../../store/calculate-coefficient/calculate-coefficient.actions';
import { FlowRateState } from '../../store/charts/flow-rate-chart/flow-rate-chart.reducer';
import { selectFlowRate } from '../../store/charts/flow-rate-chart/flow-rate-chart.selector';

declare const google: any;

const FLOW_RATE_CHART_ID = 'flowRateChart';

@Component({
  selector: 'app-flow-rate-chart-new',
  templateUrl: './flow-rate-chart-new.component.html',
  styleUrls: ['./flow-rate-chart-new.component.scss']
})
export class FlowRateChartNewComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  xAxisToYaxisChart = null;
  showPlaceholder$: Observable<Boolean>;
  @ViewChild(FLOW_RATE_CHART_ID, { static: true }) chartElement: ElementRef;

  constructor(
    private store: Store,
  ) { }

  ngOnInit() {
    this.showPlaceholder$ = this.store.pipe(
      select(selectFlowRate),
      map((data: FlowRateState) => data.loading)
    );

    const flowRate = this.store.pipe(
      select(selectFlowRate),
      map((data: FlowRateState) => data.flowRateData)
    ).subscribe(data => {
      if (data) {
        this.buildChart();
      }
    });
    this.subscriptions.push(flowRate);
  }

  buildChart() {
    google.charts.load('current', { packages: ['corechart'] });
    google.charts.setOnLoadCallback(this.createFlowRateGraph.bind(this));
  }

  createFlowRateGraph() {
    const xAxis = 'electrical power [W]';
    const yAxis = 'flow [m³/h]';

    const flowChartConfiguration = createConfiguration({
      xAxis,
      yAxis,
      height: 600,
      pointSize: 6
    });

    const flowRateGraph = this.store.pipe(
      select(selectFlowRate),
      map((data: FlowRateState) => data.flowRateData),
      first()
    ).subscribe(data => {
      if (data) {
        google.charts.load('current', { packages: ['corechart'] });
        google.charts.setOnLoadCallback(this.drawChart(flowChartConfiguration, this.chartElement.nativeElement,
          data));
      }
    });
    this.subscriptions.push(flowRateGraph);
  }

  drawChart(
    chartConfiguration: ChartConfiguration,
    chart: ElementRef,
    measurements: Map<number, Map<number, number>>
  ) {

    let columnIndex = 1;
    let rowIndex = 0;
    const colors = chartConfiguration.colours;

    const data = new google.visualization.DataTable();
    data.addColumn('number', chartConfiguration.xAxisTitle);

    const options = chartConfiguration.options;

    let head: any;
    ({ head, rowIndex, columnIndex } = plotData(measurements, data, rowIndex, chartConfiguration,
      columnIndex, options, colors, 'calculated'));

    this.xAxisToYaxisChart = new google.visualization.LineChart(chart);
    google.visualization.events.addListener(this.xAxisToYaxisChart, 'ready', () => {});

    google.visualization.events.addListener(this.xAxisToYaxisChart, 'select', selectHandler.bind(this));
    this.xAxisToYaxisChart.draw(data, options);

    function selectHandler() {
      const selection = this.xAxisToYaxisChart.getSelection();
      let message = '';
      let trIndex = 0;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < selection.length; i++) {
        const item = selection[i];
        if (item.row != null && item.column != null) {
          const str = data.getFormattedValue(item.row, item.column);
          trIndex = item.row;
          message += '{row:' + item.row + ',column:' + item.column + '} = ' + str + '\n';
        }
      }
      if (message !== '') {
        this.store.dispatch(setTableHightlightRow({ data: trIndex + 1 }));
      } else {
        this.store.dispatch(setTableHightlightRow({ data: -1 }));
      }
    }

  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    if(this.xAxisToYaxisChart) {
      this.xAxisToYaxisChart.clearChart();
    }
  }
}
