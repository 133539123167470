import { Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { Subscription, Subject, Observable, startWith, map } from 'rxjs';
import { DisplayProductDataService } from 'src/app/display-product-data/display-product-data.service';
import { PumpEndsItem } from 'src/app/display-product-data/pump-ends/pump-end.model';
import { nameSort } from '../../util';

@Component({
  selector: 'app-coefficient-pump-end-select',
  templateUrl: './coefficient-pump-end-select.component.html',
  styleUrls: ['./coefficient-pump-end-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoefficientPumpEndSelectComponent),
      multi: true
    }
  ]
})
export class CoefficientPumpEndSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

  private subscriptions: Subscription[] = [];
  dropdownList: string[] = [];
  pumpendList: PumpEndsItem[] = [];
  pumpendControl = new UntypedFormControl('', [
    Validators.required,
    this.optionNotFound.bind(this),
  ]);
  filteredOptions: Set<string>;
  public readonly pumpendChanges: Subject<string> = new Subject<string>();
  public pumpendSuggestions: Observable<string[]>;

  @Output() autocompleteChange = new EventEmitter<MatAutocompleteSelectedEvent>();
  @Output() inputChange = new EventEmitter<string>();

  constructor(
    private router: Router,
    private displayProductData: DisplayProductDataService,
  ) { }

  ngOnInit() {

    const getPumpEndsList = this.displayProductData.getPumpEndsList().subscribe(data => {
      this.pumpendList = nameSort(data);
      this.dropdownList = this.pumpendList.map(item => item.name);
      this.dropdownList.unshift('Add new pump end');
      this.pumpendSuggestions = this.pumpendChanges.pipe(
        startWith(''),
        map((val: string) => this.filterResults(val))
      );
    })
    this.subscriptions.push(getPumpEndsList);

  }

  private filterResults(val: string) {
    if (val !== '') {
      return this.dropdownList.filter(item => item.toLowerCase().includes(val.toLowerCase()));
    } else {
      return this.dropdownList;
    }
  }

  onFocus() {
    this.pumpendChanges.next('');
  }

  updatePumpend(value: string) {
    if (value === '') {
      this.optionNotFound(this.pumpendControl);
    } else {
      
      const item = this.dropdownList.find(item => item === value);
      if (item) {
        this.inputChange.emit(value);
      } else {
        this.optionNotFound(this.pumpendControl);
      }
    };
  }

  setPumpendChangeAction(event: MatAutocompleteSelectedEvent) {
    if ( event.option.value === 'Add new pump end' ) {
      this.router.navigate(['/data/pump-end/new']);
    } else {
      this.autocompleteChange.emit(event);
    }
  }

  optionNotFound(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    this.filteredOptions = new Set(
      this.dropdownList.filter((option) => option.toLowerCase() === value.toLowerCase())
    );
    if (value && !this.filteredOptions.size) {
      return {
        noOption: true
      };
    }
    return null;
  }

  navigateTo(value: string) {
    const item = this.pumpendList.find(item => item.name === value);
    this.router.navigate([`/data/pump-end/${item.uuid}`]);
  }

  // model --> view
  writeValue(value): void {
    if (value) {
      this.pumpendControl.setValue(value, { emitEvent: false });
    } else {
      this.pumpendControl.reset('');
    }
  }
  
  // view --> model
  registerOnChange(fn: (value: string) => void) {
    const onChangeSubscription = this.pumpendControl.valueChanges.subscribe(fn);
    this.subscriptions.push(onChangeSubscription);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  onTouched: () => void = () => {};

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

}
