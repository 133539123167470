<mat-form-field class="form-element" appearance="fill">
  <mat-label>parts picture</mat-label>
  <mat-select placeholder="parts picture" [formControl]="selectListControl" (selectionChange)="pictureChangeAction($event)">
    <mat-option value="empty"></mat-option>
    <mat-option *ngIf="showNotAvailable" value="notAvailable"> Not Available </mat-option>
    <mat-option *ngFor="let picture of pictureList" [value]="picture.name" >{{ picture.name }}</mat-option>
    <mat-option value="create">Add new picture</mat-option>
  </mat-select>
</mat-form-field>
<button mat-raised-button color="primary" type="button" (click)="openViewDialog()">VIEW</button>
