<section class="content">
  <mat-form-field>
    <mat-label>Select uploaded file</mat-label>
    <input type="text" placeholder="Search Uploaded file name" matInput [formControl]="uploadedFileControl"
      [matAutocomplete]="auto" (focus)="onFocus()" (blur)="updateUploadedFile($event.target.value)"
      (input)="uploadedFileChanges.next($event.target.value)" />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setUploadedFileChangeAction($event)">
      <mat-option *ngFor="let option of uploadedFileSuggestions | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="uploadedFileControl.hasError('noOption')">
      Uploaded file not Found
    </mat-error>
    <mat-error *ngIf="uploadedFileControl.hasError('required')">
      Uploaded file is required
    </mat-error>
  </mat-form-field>
</section>