import { createAction, props } from "@ngrx/store";
import { Accessory, AccessoryDetail, AccessoryEvent } from "../../pump-data.model";


export const loadAccessories = createAction('[Product Data] Load Accessories');

export const loadAccessoriesSuccess = createAction(
  '[Product Data] Load Accessories Success',
  props<{ data: Accessory[] }>()
);

export const loadAccessoriesFailure = createAction(
  '[Product Data] Load Accessories Failure',
  props<{ error: any }>()
);

export const loadAllAccessory = createAction('[Product Data] Load All Accessory Item Data');

export const loadAllAccessorySuccess = createAction(
  '[Product Data] Load All Accessory Item Data Success',
  props<{ data: AccessoryDetail[] }>()
);

export const loadAllAccessoryFailure = createAction(
  '[Product Data] Load All Accessory Item Data Failure',
  props<{ error: any }>()
);

export const updateAccessory = createAction(
  '[Product Data] Update Accessory',
  props<{ data: Accessory }>()
);

export const createAccessory = createAction(
  '[Product Data] Create New Accessory',
  props<{ data: Accessory }>()
);

export const setAccessoryPrepareList = createAction(
  '[Product Data] Set Accessory Item Prepare Array',
  props<{ data: Partial<AccessoryDetail>[] }>()
);

export const setAccessorySelectedList = createAction(
  '[Product Data] Set Accessory Item Selected Array',
  props<{ data: Partial<AccessoryDetail>[] }>()
);

export const addAccessoryItem = createAction(
  '[Product Data] Accessory Table Add Item',
  props<{ data: AccessoryDetail }>()
);

export const removeAccessoryItem = createAction(
  '[Product Data] Accessory Table Remove Item',
  props<{ data: AccessoryDetail }>()
);

export const setAccessoryExpandedEvent = createAction(
  '[Product Data] Accessory Table Handle expanded and collapsed',
  props<{ data: AccessoryEvent | null }>()
);

export const setAccessoryCopyEvent = createAction(
  '[Product Data] Accessory Table Handle copied item',
  props<{ isCopied: Boolean }>()
);

export const setOriginalAccessoryArray = createAction(
  '[Product Data] Set Accessory Table Original Data Array',
  props<{ data: string[] }>()
);

export const setOriginalAccessoryArrayBeforeCopied = createAction(
  '[Product Data] Set Accessory Table Original Data Array Before Copied',
  props<{ data: string[] }>()
);
