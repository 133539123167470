<mat-form-field class="form-element" appearance="fill">
  <mat-label>packaging</mat-label>
  <input type="text" placeholder="Search packaging" matInput [formControl]="packagingControl" [matAutocomplete]="auto"
    (focus)="onFocus($event.target.value)" (blur)="updatePackaging($event.target.value)"
    (input)="packagingChanges.next($event.target.value)" />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setPackagingChangeAction($event)">
    <mat-option *ngFor="let option of packagingSuggestions | async" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="packagingControl.hasError('noOption')">
    Packaging not Found
  </mat-error>
</mat-form-field>

<div class="form-wrapper__packaging">
  <div class="form-wrapper__packaging-inner"></div>
  <mat-form-field class="form-element">
    <mat-label> Packaging Width </mat-label>
    <input [value]="packagingWidth" matInput disabled placeholder="Packaging Width">
  </mat-form-field>

  <mat-form-field class="form-element">
    <mat-label> Packaging Height </mat-label>
    <input [value]="packagingHeight" matInput disabled placeholder="Packaging Height">
  </mat-form-field>

  <mat-form-field class="form-element">
    <mat-label> Packaging Length </mat-label>
    <input [value]="packagingLength" matInput disabled placeholder="Packaging Length">
  </mat-form-field>

  <mat-form-field class="form-element">
    <mat-label> Packaging Weight </mat-label>
    <input [value]="packagingWeight" matInput disabled placeholder="Packaging Weight">
  </mat-form-field>
</div>