<mat-form-field class="form-element" appearance="fill">
  <mat-label>parts list name</mat-label>
  <input type="text" placeholder="Search parts list" matInput [formControl]="pumppartsControl" [matAutocomplete]="auto"
    (focus)="onFocus($event.target.value)" (blur)="updatePumpparts($event.target.value)"
    (input)="pumppartsChanges.next($event.target.value)" />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setPumppartsChangeAction($event)">
    <mat-option *ngFor="let option of pumppartsSuggestions | async" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="pumppartsControl.hasError('noOption')">
    Parts list not Found
  </mat-error>
</mat-form-field>