import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { CopyButtonComponent } from '../copy-button/copy-button.component';
import { DisplayProductDataService } from '../display-product-data.service';
import { PartsListDialogComponent } from '../parts-list-dialog/parts-list-dialog.component';
import * as XLSX from 'xlsx';
import { PartsListService } from '../parts-list.service';
import { PartsListFlatNode, PartsListNode } from '../pump-data.model';
import { v4 } from 'uuid';
import { MatSnackBar } from '@angular/material/snack-bar';
import { delay } from 'rxjs/operators';
import { checkPartListName } from '../names.validator';
import { Router } from '@angular/router';
import { ErrorButtonComponent } from '../error-button/error-button.component';
import { PartsListAddNodeComponent } from '../parts-list-add-node/parts-list-add-node.component';
import { PartsListEditNodeComponent } from '../parts-list-edit-node/parts-list-edit-node.component';
import { nameSort } from 'src/app/shared/util';


const POSITION_ABOVE = 'above';
const POSITION_BELOW = 'below';
const POSITION_CENTER = 'center';
const POSITION_PERCENTAGE_ABOVE = 0.25;
const POSITION_PERCENTAGE_BELOW = 0.75;

@Component({
  selector: 'app-parts-list-new',
  templateUrl: './parts-list-new.component.html',
  styleUrls: ['./parts-list-new.component.scss'],
})
export class PartsListNewComponent implements OnInit {

  partListNodeForm: UntypedFormGroup;
  partlistForm: UntypedFormGroup;
  treeLoading = true;
  copiedItem = '';
  showPartslistTree = false;
  uploadFileName = '';
  uploadedFile = [];
  file: File;
  arrayBuffer: any;
  fileList: any;



  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<PartsListFlatNode, PartsListNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<PartsListNode, PartsListFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: PartsListFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<PartsListFlatNode>;

  treeFlattener: MatTreeFlattener<PartsListNode, PartsListFlatNode>;

  dataSource: MatTreeFlatDataSource<PartsListNode, PartsListFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<PartsListFlatNode>(true /* multiple */);

  /* Drag and drop */
  dragNode: any;
  dragNodeExpandOverWaitTimeMs = 300;
  dragNodeExpandOverNode: any;
  dragNodeExpandOverTime: number;
  dragNodeExpandOverArea: string;
  @ViewChild('emptyItem') emptyItem: ElementRef;

  pumppartsList = [];

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private database: PartsListService,
    private displayProductData: DisplayProductDataService
  ) { }

  getLevel = (node: PartsListFlatNode) => node.level;

  isExpandable = (node: PartsListFlatNode) => node.expandable;

  getChildren = (node: PartsListNode): PartsListNode[] => node.children;

  // tslint:disable-next-line: variable-name
  hasChild = (_: number, _nodeData: PartsListFlatNode) => _nodeData.expandable;

  // tslint:disable-next-line: variable-name
  hasNoContent = (_: number, _nodeData: PartsListFlatNode) => _nodeData.name === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: PartsListNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.name
      ? existingNode
      : new PartsListFlatNode();
    flatNode.name = node.name;
    flatNode.material = node.material || '';
    flatNode.children = node.children;
    flatNode.id = node.id;
    flatNode.level = level;
    flatNode.expandable = (node.children && node.children.length > 0);
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  ngOnInit() {
    this.partListNodeForm = this.fb.group({
      name: '',
      material: ''
    });

    this.partlistForm = this.fb.group({
      name: ['', [Validators.required, checkPartListName.bind(this)]],
      type: ['', Validators.required],
    });

    this.displayProductData.getPartsList().subscribe(allPumpparts => this.pumppartsList = nameSort(allPumpparts));
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<PartsListFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.database.dataChange.subscribe(data => {
      this.dataSource.data = [];
      this.dataSource.data = data;
      if (data.length !== 0) {
        this.treeLoading = false;
      }
    });
  }

  openCopyDialog(type: string) {
    const dialogRef = this.dialog.open(CopyButtonComponent, {
      width: '1000px',
      maxWidth: '96vw',
      data: type,
      disableClose: true,
      minHeight: '60vh',
      maxHeight: '60vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.showPartslistTree = true;
      this.treeLoading = true;
      this.dataSource.data = [];
      this.database.updatePartsListTree(result);
      this.pumppartsList.forEach(item => {
        if (item.uuid === result) {
          this.copiedItem = item.name;
        }
      });
    });
  }

  onCreateNewPartListNode(value) {
    if (value.name) {
      this.database.addPartsListNode(value);
      this.partListNodeForm.reset();
    }
  }

  parseFile(event) {
    this.file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = async () => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const arraylist = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: true });
      this.uploadPartslistData(arraylist);
    };
  }

  uploadPartslistData(data) {
    const uploadData = [];
    data.forEach(item => {
      const newNode = {
        name: item[0],
        material: ''
      } as PartsListNode;
      uploadData.push(newNode);
    });
    this.showPartslistTree = true;
    this.treeLoading = true;
    this.dataSource.data = [];
    this.database.uploadPartsListTree(uploadData);
    (document.getElementById('uploadPartsListNodes') as HTMLInputElement).value = '';
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.uploadFileName = event.target.files[0].name;
      this.parseFile(event);
    }
  }

  addPartListTreeNode() {
    const dialogRef = this.dialog.open(PartsListAddNodeComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.treeControl.expand(this.treeControl.dataNodes[0]);
      }
    });
  }

  onCreateNewPartList(value) {

    const namesArray = [];
    this.database.data.forEach(item => namesArray.push(item.name));

    const newParts = [this.database.data[0]]
      .concat(this.database.data[0].children)
      .concat(
        this.database.data[0].children
        .map(pl => pl.children)
        .reduce((accumulator, currentValue) => accumulator.concat(currentValue), [])
      );

    const postData = {
      uuid: v4(),
      parts: newParts,
      name: value.name,
      type: value.type
    };

    this.displayProductData.createNewPartlist(postData).subscribe(
      result => {
        this.snackBar
          .open(result, '', { duration: 1500, panelClass: 'hintMsg' })
          .afterDismissed()
          .pipe(delay(0))
          .subscribe(() => {
            this.displayProductData.reloadPartListData();
            this.router.navigate(['/data/part-list', postData.uuid]);
          });
      },
      error => {
        this.dialog.open(ErrorButtonComponent, {
          width: '600px',
          data: JSON.parse(error.error),
          minHeight: '20vh',
        });
      },
      () => {}
    );

  }

  /** Delete the node when ceate */
  deleteNode(node: PartsListFlatNode) {
    const dialogRef = this.dialog.open(PartsListDialogComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
      data: {
        type: 'Delete',
        node
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.database.deletePartsListNode(this.flatNodeMap.get(node));
      }
    });
  }

  editNode(node: PartsListFlatNode) {
    const dialogRef = this.dialog.open(PartsListEditNodeComponent, {
      width: '1000px',
      maxWidth: '96vw',
      disableClose: true,
      data: node,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const nestedNode = this.flatNodeMap.get(node);
        this.database.updatePartsListNode(nestedNode, result.name, result.id, result.material);
        this.treeControl.expand(node);
      }
    });
  }

  handleDragStart(event, node) {
    // Required by Firefox (https://stackoverflow.com/questions/19055264/why-doesnt-html5-drag-and-drop-work-in-firefox)
    event.dataTransfer.setData('foo', 'bar');
    event.dataTransfer.setDragImage(this.emptyItem.nativeElement, 0, 0);
    this.dragNode = node;
    this.treeControl.collapse(node);
  }

  handleDragOver(event, node) {
    event.preventDefault();

    // Handle node expand
    if (node === this.dragNodeExpandOverNode) {
      if (this.dragNode !== node && !this.treeControl.isExpanded(node)) {
        if ((new Date().getTime() - this.dragNodeExpandOverTime) > this.dragNodeExpandOverWaitTimeMs) {
          this.treeControl.expand(node);
        }
      }
    } else {
      this.dragNodeExpandOverNode = node;
      this.dragNodeExpandOverTime = new Date().getTime();
    }

    // Handle drag area
    const percentageY = event.offsetY / event.target.clientHeight;
    if (percentageY < POSITION_PERCENTAGE_ABOVE) {
      this.dragNodeExpandOverArea = POSITION_ABOVE;
    } else if (percentageY > POSITION_PERCENTAGE_BELOW) {
      this.dragNodeExpandOverArea = POSITION_BELOW;
    } else {
      this.dragNodeExpandOverArea = POSITION_CENTER;
    }
  }

  handleDrop(event, node) {
    event.preventDefault();
    if (node !== this.dragNode) {
      let newItem: PartsListNode;
      if (this.dragNodeExpandOverArea === POSITION_ABOVE) {
        newItem = this.database.copyPastePartsListNodeAbove(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
      } else if (this.dragNodeExpandOverArea === POSITION_BELOW) {
        newItem = this.database.copyPastePartsListNodeBelow(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
      } else {
        newItem = this.database.copyPastePartsListNode(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
      }
      this.database.deletePartsListNode(this.flatNodeMap.get(this.dragNode));
      this.treeControl.expandDescendants(this.nestedNodeMap.get(newItem));
    }
    this.dragNode = null;
    this.dragNodeExpandOverNode = null;
    this.dragNodeExpandOverTime = 0;
  }

  handleDragEnd() {
    this.dragNode = null;
    this.dragNodeExpandOverNode = null;
    this.dragNodeExpandOverTime = 0;
  }

  get checkListGet() {
    return this.checklistSelection.selected.map(x => x.name);
  }
}
