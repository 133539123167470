<h2 mat-dialog-title> Create New Pipe Part </h2>
<mat-dialog-content>
  <form [formGroup]="pipePartForm" (ngSubmit)="onSubmit(pipePartForm.value)" class="form">

    <mat-form-field class="form-element">
      <mat-label>diameter</mat-label>
      <input matInput placeholder="diameter" formControlName="diameter">
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label>elbow_90</mat-label>
      <input matInput placeholder="elbow_90" formControlName="elbow_90">
    </mat-form-field>
    
    <mat-form-field class="form-element">
      <mat-label>check_valve_spring</mat-label>
      <input matInput placeholder="check_valve_spring" formControlName="check_valve_spring">
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label>comment</mat-label>
      <input matInput placeholder="comment" formControlName="comment">
    </mat-form-field>

  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="greyBtn" mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" type="submit" class="button" data-testid="submit" [disabled]="!pipePartForm.valid" (click)="onSubmit(pipePartForm.value)"> Create </button>
</mat-dialog-actions>
