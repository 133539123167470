import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CopyButtonComponent } from 'src/app/display-product-data/copy-button/copy-button.component';
import { DisplayProductDataService } from 'src/app/display-product-data/display-product-data.service';
import { AccessoryDetail, AccessoryEvent, ACCESSORY_ITEMS } from 'src/app/display-product-data/pump-data.model';
import { addAccessoryItem, setAccessoryCopyEvent, setAccessoryExpandedEvent, setAccessoryPrepareList, setAccessorySelectedList } from 'src/app/display-product-data/store/accessory/accessory.actions';
import { AccessoryState } from 'src/app/display-product-data/store/accessory/accessory.reducer';
import { selectAccessory } from 'src/app/display-product-data/store/accessory/accessory.seletor';
import { fileSort } from '../../util';

@Component({
  selector: 'app-accessory-select',
  templateUrl: './accessory-select.component.html',
  styleUrls: ['./accessory-select.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class AccessorySelectComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  @Input() dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['index', 'name'];
  accessoryForm: UntypedFormGroup;
  accessory: Observable<AccessoryDetail>;
  showTableHeader = false;
  showAccessoryLoading = false;
  copiedAccessoryName = '';
  defaultAccessoryList = [];
  expandedEvent: AccessoryEvent | null;

  constructor(
    private store: Store,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private displayProductData: DisplayProductDataService,
  ) { }

  ngOnInit() {

    const getAccessoryData = this.displayProductData.getAccessoryList().subscribe(result => this.defaultAccessoryList = result);
    this.subscriptions.push(getAccessoryData);

    const selectedTableData = this.store.pipe(
      select(selectAccessory),
      map((data: AccessoryState) => data.selectedList)
    ).subscribe((data: Partial<AccessoryDetail>[]) => {
      if (data.length !== 0) {
        this.showTableHeader = true;
      } else {
        this.showTableHeader = false;
      }
      this.dataSource.data = fileSort([...data]);
    });
    this.subscriptions.push(selectedTableData);

    const getExpandedEvent = this.store.pipe(
      select(selectAccessory),
      map((data: AccessoryState) => data.expandedEvent)
    ).subscribe((data) => this.expandedEvent = data);
    this.subscriptions.push(getExpandedEvent);
  }

  removeItem(event) {
    this.store.dispatch(addAccessoryItem({ data: event }));
  }

  toggleExpansion(event: AccessoryEvent): void {
    this.store.dispatch(setAccessoryExpandedEvent({ data: this.expandedEvent }));
    if (event === this.expandedEvent) {
      this.expandedEvent = null;
    } else {
      this.expandedEvent = event;
      const getAccessoryItem = this.displayProductData.getAccessoryItem(event.uuid).subscribe(data => {
        const accessoryFormValue = {};
        ACCESSORY_ITEMS.forEach(name => {
          const value = data.versions[0][`${name}`];
          accessoryFormValue[`${name}`] = [{ value, disabled: true }, Validators.required];
        });
        this.accessoryForm = this.fb.group(accessoryFormValue);
      });
      this.subscriptions.push(getAccessoryItem);
      this.accessory = this.displayProductData.getAccessoryItem(event.uuid);
    }
  }

  openCopyDialog(type: string) {
    this.copiedAccessoryName = '';
    const dialogRef = this.dialog.open(CopyButtonComponent, {
      width: '1000px',
      maxWidth: '96vw',
      data: type,
      minHeight: '60vh',
      maxHeight: '60vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.showAccessoryLoading = true;
      this.copyAccessroy(result);
    });
  }

  copyAccessroy(pumpSystemUuid: string) {
    this.copiedAccessoryName = '';
    this.store.dispatch(setAccessoryPrepareList({ data: [] }));
    this.store.dispatch(setAccessorySelectedList({ data: [] }));
    this.store.dispatch(setAccessoryCopyEvent({ isCopied: true }));
    const getPumpSystemItem = this.displayProductData.getPumpSystemItem(pumpSystemUuid)
      .subscribe((pumpSystemData: any) => {
        const pumpSystem = JSON.parse(pumpSystemData['message']);
        this.showAccessoryLoading = false;
        this.copiedAccessoryName = pumpSystem.name;
        const allData = pumpSystem.versions[0];
        if (allData.accessories) {
          this.store.dispatch(setAccessorySelectedList({ data: allData.accessories }));
          const prepareList = [];
          this.defaultAccessoryList.forEach(item => {
            const target = allData.accessories.map(x => x.uuid);
            if(!target.includes(item.uuid)) {
              prepareList.push(item);
            }
          });
          this.store.dispatch(setAccessoryPrepareList({ data: prepareList }));
        } else {
          this.store.dispatch(setAccessorySelectedList({ data: [] }));
          this.store.dispatch(setAccessoryPrepareList({ data: this.defaultAccessoryList }));
        };
      });

    this.subscriptions.push(getPumpSystemItem);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
