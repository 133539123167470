import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { DisplayPumpSimulationService } from '../../../pump-simulation/display-pump-simulation.service';
import { LoadEventService } from '../../../pump-simulation/load-event.service';
import { setDataSource } from '../../../upload-download/store/coefficient-form/coefficient-form.actions';

@Component({
  selector: 'app-upload-file-select',
  templateUrl: './upload-file-select.component.html',
  styleUrls: ['./upload-file-select.component.scss']
})
export class UploadFileSelectComponent implements OnInit {

  private subscriptions: Subscription[] = [];

  uploadedFile$: Observable<string[]>;
  // file upload error message
  theSelectedFileIsEmpty = false;
  selectUploadFile = '';
  uploadFileName = '';

  constructor(
    private store: Store,
    private loadService: LoadEventService,
    private pumpSimulationService: DisplayPumpSimulationService,
  ) { }

  ngOnInit(): void {
    this.uploadedFile$ = this.pumpSimulationService.getUploadedFileList();
  }

  selectedUploadedFile(event: MatSelectChange) {
    this.selectUploadFile = event.value;
    this.theSelectedFileIsEmpty = false;
    this.loadService.setSelectUploadFileName(event.value);
    this.store.dispatch(setDataSource({ dataSource: { uploadFileName: event.value }}));
  }
}
