<label id="data-source">Please choose data source: {{ dataSource }}</label>
<mat-radio-group aria-labelledby="data-source" [(ngModel)]="dataSource" (ngModelChange)="modelChangeFn($event)">
  <div class="option-item">
    <mat-radio-button color="primary" value="Upload file">Upload file</mat-radio-button>
    <app-source-upload-file></app-source-upload-file>
  </div>
  <div class="option-item">
    <mat-radio-button color="primary" value="Use uploaded file">Use uploaded file</mat-radio-button>
    <app-source-use-uploaded-file></app-source-use-uploaded-file>
  </div>
  <div class="option-item">
    <mat-radio-button color="primary" value="Manual data input">Manual data input</mat-radio-button>
  </div>
</mat-radio-group>