import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShareDataService } from '../../share-data.service';
import { setDataSource } from '../../store/data-source/data-source.actions';

@Component({
  selector: 'app-source-overview',
  templateUrl: './source-overview.component.html',
  styleUrls: ['./source-overview.component.scss']
})
export class SourceOverviewComponent {

  dataSource = '';

  constructor(
    private store: Store,
    private shareService: ShareDataService,
  ) { }

  modelChangeFn(value: string) {
    this.store.dispatch(setDataSource({ data: value }));

    if (value !== 'Upload file') {
      this.shareService.setUploadFileDataEmpty(false);
    }
  }
}
