import { createAction, props } from "@ngrx/store";

export const setSimulationProcess = createAction(
  '[Simulation Charts Setting] Set Simulation Process',
  props<{ data: String }>()
);

export const setLoadSimulationStepper = createAction(
  '[Simulation Charts Setting] Set Load Simulation Process Stepper',
  props<{ data: Boolean }>()
);

export const setValueChangedItem = createAction(
  '[Simulation Charts Setting] Set Value Changed Item',
  props<{ data: String[] }>()
);

export const setChartSettingsValue = createAction(
  '[Simulation Charts Setting] Set Chart Settings Value',
  props<{ data: any }>()
);