<h2>Pump systems download</h2>

          <form [formGroup]="profileForm" class="form" (ngSubmit)="profileFormSubmitPS()">
            <ng-template matStepLabel>System components</ng-template>

            <div class="form-element">
              <button mat-raised-button color="primary" type="submit">View pump systems file</button>
            </div>
          </form>

          <form [formGroup]="profileForm" class="form" (ngSubmit)="downloadPumpSystems('live')">
            <ng-template matStepLabel>System components</ng-template>

            <div class="form-element">
              <button mat-raised-button color="primary" type="submit">Download live (original) pump systems file</button>
            </div>
          </form>

          <form [formGroup]="profileForm" class="form" (ngSubmit)="downloadPumpSystems('preview')">
            <ng-template matStepLabel>System components</ng-template>

            <div class="form-element">
              <button mat-raised-button color="primary" type="submit">Download relational pump systems file</button>
            </div>
          </form>


