<h1 mat-dialog-title> Edit Parts List Node </h1>
<mat-dialog-content>

  <form [formGroup]="newPartsListNodeForm">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput placeholder="name" formControlName="name">
      <mat-error *ngIf="newPartsListNodeForm.get('name').hasError('required')">The field name is required</mat-error>
    </mat-form-field>

    <app-material-select formControlName="material"></app-material-select>

    <mat-form-field>
      <mat-label>Id</mat-label>
      <input matInput type="number" min="0" placeholder="id" formControlName="id">
    </mat-form-field>

  </form>

</mat-dialog-content>

<mat-dialog-actions>
  <button class="greyBtn" mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" type="submit" class="button" (click)="onSubmit(newPartsListNodeForm.value)">
    Confirm </button>
</mat-dialog-actions>