import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { concatAll, delay, map, mergeMap, take, toArray } from 'rxjs/operators';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { v4 } from 'uuid';
import { CopyButtonComponent } from '../copy-button/copy-button.component';
import { DisplayProductDataService } from '../display-product-data.service';
import { checkPumpSystemName } from '../names.validator';
import { PUMPSYSYTEM_ITEMS_CREATE, PUMP_SYSTEM_STATUS, PUMP_SYSTEM_TYPES, ConnectedProductDetail, AccessoryDetail, CalculationDetail, CALCULATIONS_ITEMS, SelectedCalculationDetail } from '../pump-data.model';
import { setAccessorySelectedList, setAccessoryPrepareList } from '../store/accessory/accessory.actions';
import { AccessoryState } from '../store/accessory/accessory.reducer';
import { selectAccessory } from '../store/accessory/accessory.seletor';
import { setCalculationValue, setCoefficientSetValueName, setPumpSystemCopied } from '../store/coefficient-set/coefficient-set.actions';
import { CoefficientSetState } from '../store/coefficient-set/coefficient-set.reducer';
import { selectCoefficientSet } from '../store/coefficient-set/coefficient-set.selector';
import { setConnectedProductPrepareList, setConnectedProductSelectedList, setPumpSystemUuid } from '../store/connected-product/connected-product.actions';
import { ConnectedProductState } from '../store/connected-product/connected-product.reducer';
import { selectConnectedProduct } from '../store/connected-product/connected-product.seletor';
import { ControllerState } from '../store/controller/controller.reducer';
import { setControllerSelect, setMotorSelect, setPumpEndSelect, setPumpSytemCoefficientSetName, setPumpUnitValue } from '../store/pump-system/pump-system.actions';
import { selectController } from '../store/controller/controller.seletor';
import { PumpSystemState } from '../store/pump-system/pump-system.reducer';
import { selectPumpSystem } from '../store/pump-system/pump-system.seletor';
import { setPumpUnitItem, setPumpUnitMaxTemp, setPumpUnitPackaging, setPumpUnitPicture, setPumpUnitSuctionHead } from '../store/pump-unit/pump-unit.actions';
import { PumpUnitState } from '../store/pump-unit/pump-unit.reducer';
import { selectPumpUnit } from '../store/pump-unit/pump-unit.seletor';
import { PackagingState } from '../store/packaging/packaging.reducer';
import { selectPackaging } from '../store/packaging/packaging.selector';
import { isEmpty } from 'src/app/shared/util';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-pump-system-new',
  templateUrl: './pump-system-new.component.html',
  styleUrls: ['./pump-system-new.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PumpSystemNewComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  matcher = new MyErrorStateMatcher();
  userLocalStorage = new UserLocalStorage();

  pumpSystemForm: UntypedFormGroup;
  copiedItem: string;
  createdPumpSystemName = '';
  showLoading = false;
  showSubmitLoading = false;
  showPumpSystemTable = false;
  statusList = PUMP_SYSTEM_STATUS;
  typeSelect$: Observable<boolean>;
  calculationsValue: Partial<CalculationDetail> | Partial<SelectedCalculationDetail>;
  defaultAccessoryList = [];
  defaultConnectedProductList = [];
  accessoriesList: AccessoryDetail[] = [];
  connectedProductsList: ConnectedProductDetail[] = [];
  controllerName = '';
  motorName = '';
  pumpEndName = '';

  constructor(
    private store: Store,
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private displayProductData: DisplayProductDataService,
  ) { }

  ngOnInit() {

    const connectedProductTableData = this.store.pipe(
      select(selectConnectedProduct),
      map((data: ConnectedProductState) => data.selectedList)
    ).subscribe((data: ConnectedProductDetail[]) => {
      if (data.length !== 0) {
        this.connectedProductsList = data;
      }
    });
    this.subscriptions.push(connectedProductTableData);

    const accessoryTableData = this.store.pipe(
      select(selectAccessory),
      map((data: AccessoryState) => data.selectedList)
    ).subscribe((data: AccessoryDetail[]) => {
      if (data.length !== 0) {
        this.accessoriesList = data;
      }
    });
    this.subscriptions.push(accessoryTableData);

    const getCalculation = combineLatest([
      this.store.pipe(
        select(selectCoefficientSet),
        map((data: CoefficientSetState) => data.calculation)
      ),
      this.store.pipe(
        select(selectCoefficientSet),
        map((data: CoefficientSetState) => data.name)
      ),
      this.store.pipe(
        select(selectPumpSystem),
        map((data: PumpSystemState) => data.coefficientSetValue)
      ),
    ]).subscribe(([data, name, fileName]) => {
      this.calculationsValue = {...data};
      this.calculationsValue.displayName = `${name}`;
      this.calculationsValue.filename = `${fileName}`;

    });
    this.subscriptions.push(getCalculation);

    this.initPumpSystemForm();

    this.typeSelect$ = this.pumpSystemForm.valueChanges.pipe(
      map(value => PUMP_SYSTEM_TYPES.includes(value.type))
    );

    const pumpSystemName = combineLatest([
      this.store.pipe(
        select(selectController),
        map((data: ControllerState) => data.controller)
      ),
      this.store.pipe(
        select(selectController),
        map((data: ControllerState) => data.motor)
      ),
      this.store.pipe(
        select(selectController),
        map((data: ControllerState) => data.pumpEnd)
      )
    ]).subscribe(([controllerName, motorName, pumpEndName]) => {
      if (controllerName === '' && pumpEndName === '') {
        this.createdPumpSystemName = '';
      } else {
        this.createdPumpSystemName = `${controllerName} ${pumpEndName}appendix`;
      };
      this.controllerName = `${controllerName}`;
      this.pumpEndName = `${pumpEndName}`;
      this.motorName = `${motorName}`;
    });
    this.subscriptions.push(pumpSystemName);

    const getConnectedProductList = this.displayProductData.getConnectedProductList()
    .pipe(
      take(1),
      concatAll(),
      mergeMap(item => this.displayProductData.getConnectedProductItem(item.uuid)),
      toArray()
    )
    .subscribe(result => {
      const tableData = [];
      for (const item of result) {
        const tartget = item.versions[0];
        tartget.file = item.name;
        tartget.uuid = item.uuid;
        tableData.push(tartget);
        this.defaultConnectedProductList.push(tartget);
      }
      this.store.dispatch(setConnectedProductSelectedList({ data: [] }));
      this.store.dispatch(setConnectedProductPrepareList({ data: tableData }));
    });
    this.subscriptions.push(getConnectedProductList);

    const accessorySubscription = this.displayProductData.getAccessoryList().subscribe(result => {
      const tableData = [];
      for (const item of result) {
        const tartget: any = {...item};
        tartget.file = item.name;
        tableData.push(tartget);
        this.defaultAccessoryList.push(tartget);
      }
      this.store.dispatch(setAccessorySelectedList({ data: [] }));
      this.store.dispatch(setAccessoryPrepareList({ data: tableData }));
    });
    this.subscriptions.push(accessorySubscription);
    this.store.dispatch(setControllerSelect({ data: '' }));
    this.store.dispatch(setMotorSelect({ data: '' }));
    this.store.dispatch(setPumpEndSelect({ data: '' }));

    const pumpUnitValue: any = {
      item: '',
      packaging: '',
      maxTemp: '',
      suctionHead: '',
      picture: '',
    };
    this.store.dispatch(setPumpUnitItem({ data: pumpUnitValue.item }));
    this.store.dispatch(setPumpUnitMaxTemp({ data: pumpUnitValue.pu_max_temp }));
    this.store.dispatch(setPumpUnitSuctionHead({ data: pumpUnitValue.suction_head }));
    this.store.dispatch(setPumpUnitPicture({ data: pumpUnitValue.picture }));
    this.store.dispatch(setPumpUnitPackaging({ data: pumpUnitValue.packaging }));
    this.store.dispatch(setPumpUnitValue({ data: pumpUnitValue }));
  }

  onPumpSystemNameInputFocus(value: string) {
    this.pumpSystemForm.get('name').patchValue(value);
  }

  handleInput(value: string) {
    const fixedText = this.createdPumpSystemName.slice(0, -8);
    if (value.slice(0, fixedText.length) !== fixedText) {
      this.pumpSystemForm.get('name').patchValue(fixedText);
    }
  }

  openCopyDialog(type: string) {
    const dialogRef = this.dialog.open(CopyButtonComponent, {
      width: '1000px',
      maxWidth: '96vw',
      data: type,
      minHeight: '60vh',
      maxHeight: '60vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showLoading = true;
        this.copyItem(result);
      }
    });
  }

  copyItem(pumpSystemUuid: string) {
    this.store.dispatch(setPumpSystemUuid({pumpSystemUuid}));
    this.displayProductData.getPumpSystemItem(pumpSystemUuid)
      .subscribe((pumpSystemValue: any) => {
        const pumpSystem = JSON.parse(pumpSystemValue['message']);
        if (pumpSystem.versions && pumpSystem.versions.length > 1) {

          const allData = pumpSystem.versions[0];
          this.showLoading = false;
          this.showPumpSystemTable = true;
          this.copiedItem = `${allData.controller.name} ${allData.pump_end.name}`;

          const systemData: any = {};
          PUMPSYSYTEM_ITEMS_CREATE.forEach(name => systemData[name] = allData[name]);
          this.createdPumpSystemName = `${this.copiedItem}appendix`;
          this.pumpSystemForm.patchValue(systemData);

          const pumpUnit = allData.pu_unit;
          if (pumpUnit) {
            this.store.dispatch(setPumpUnitItem({ data: pumpUnit.item }));
            this.store.dispatch(setPumpUnitMaxTemp({ data: pumpUnit.pu_max_temp }));
            this.store.dispatch(setPumpUnitSuctionHead({ data: pumpUnit.suction_head }));
            this.store.dispatch(setPumpUnitPicture({ data: pumpUnit.picture }));
            const target = pumpUnit.packaging;
            if (target) {
              this.store.dispatch(setPumpUnitPackaging({ data: `${target.l} (${target.h}x${target.w})-${target.pack_weight}` }));
            }
          }

          if (allData.controller) {
            this.store.dispatch(setControllerSelect({ data: allData.controller.uuid }));
          }
          if (allData.motor) {
            this.store.dispatch(setMotorSelect({ data: allData.motor.uuid }));
          }
          if (allData.pump_end) {
            this.store.dispatch(setPumpEndSelect({ data: allData.pump_end.uuid }));
          }

          if (allData.calculations) {
            this.store.dispatch(setCalculationValue({ data: allData.calculations }));
            this.store.dispatch(setCoefficientSetValueName({ data: allData.calculations.displayName }));
            this.store.dispatch(setPumpSytemCoefficientSetName({ data: allData.calculations.filename }));
            this.store.dispatch(setPumpSystemCopied({ data: true }));
          }
          this.initAccessory(allData);
          this.initConnectedProduct(allData);
        }
      });
  }

  initAccessory(allData) {
    if (allData.accessories) {
      this.store.dispatch(setAccessorySelectedList({ data: allData.accessories }));
      const prepareList = [];
      this.defaultAccessoryList.forEach(item => {
        const target = allData.accessories.map(x => x.uuid);
        if(!target.includes(item.uuid)) {
          prepareList.push(item);
        }
      });
      this.store.dispatch(setAccessoryPrepareList({ data: prepareList }));
    } else {
      this.store.dispatch(setAccessorySelectedList({ data: [] }));
      this.store.dispatch(setAccessoryPrepareList({ data: this.defaultAccessoryList }));
    };
  }

  initConnectedProduct(allData) {
    if (allData.connectedProducts) {
      this.store.dispatch(setConnectedProductSelectedList({ data: allData.connectedProducts }));
      const prepareList = [];
      this.defaultConnectedProductList.forEach(item => {
        const target = allData.connectedProducts.map(x => x.uuid);
        if(!target.includes(item.uuid)) {
          prepareList.push(item);
        }
      });
      this.store.dispatch(setConnectedProductPrepareList({ data: prepareList }));
    } else {
      this.store.dispatch(setConnectedProductSelectedList({ data: [] }));
      this.store.dispatch(setConnectedProductPrepareList({ data: this.defaultConnectedProductList }));
    };
  }

  initPumpSystemForm() {
    const pumpSystemFormValue: any = {};
    PUMPSYSYTEM_ITEMS_CREATE.forEach(name => {
      pumpSystemFormValue[name] = null;
    });
    pumpSystemFormValue.name = ['', [Validators.required, checkPumpSystemName.bind(this)]];
    pumpSystemFormValue.controller_uuid = null;
    pumpSystemFormValue.motor_uuid = null;
    pumpSystemFormValue.pump_end_uuid = null;
    this.pumpSystemForm = this.fb.group(pumpSystemFormValue);
  }

  onSubmit(data: any) {
    this.showSubmitLoading = true;
    const user = this.userLocalStorage.loadUser();
    data.author = user.user.username;
    data.uuid = v4();
    data.controller = this.controllerName;
    data.motor_name = this.motorName;
    data.pump_end_name = this.pumpEndName;

    const calculationData: any = {};
    CALCULATIONS_ITEMS.forEach(name => {
      if (this.calculationsValue[name]) {
        calculationData[name] = this.calculationsValue[name];
      } else {
        calculationData[name] = '';
      }
    });
    data.calculations = calculationData;

    if (!isEmpty(this.connectedProductsList)) {
      const connectedProducts = [];
      this.connectedProductsList.forEach((item: any) => {
        const subItem: any = {};
        subItem.name = item.file;
        subItem.version = item.version_number;
        connectedProducts.push(subItem);
      });
      data.connectedProducts = connectedProducts;
    }

    const uuids = combineLatest([
      this.store.pipe(
        select(selectPumpSystem),
        map((data: PumpSystemState) => data.controllerUuid)
      ),
      this.store.pipe(
        select(selectPumpSystem),
        map((data: PumpSystemState) => data.motorUuid)
      ),
      this.store.pipe(
        select(selectPumpSystem),
        map((data: PumpSystemState) => data.pumpEndUuid)
      ),
      this.store.pipe(
        select(selectPumpUnit),
        map((data: PumpUnitState) => data.item)
      ),
      this.store.pipe(
        select(selectPumpUnit),
        map((data: PumpUnitState) => data.maxTemp)
      ),
      this.store.pipe(
        select(selectPumpUnit),
        map((data: PumpUnitState) => data.suctionHead)
      ),
      this.store.pipe(
        select(selectPumpUnit),
        map((data: PumpUnitState) => data.picture)
      ),
      this.store.pipe(
        select(selectPackaging),
        map((data: PackagingState) => data.data)
      ),
      this.store.pipe(
        select(selectPumpUnit),
        map((data: PumpUnitState) => data.packaging)
      )
    ]).subscribe(([controllerUuid, motorUuid, pumpEndUuid, itemValue, maxTempValue, suctionHeadValue, pictureValue, packagingData, packagingValue]) => {
      data.controller_uuid = controllerUuid;
      data.motor_uuid = motorUuid;
      data.pump_end_uuid = pumpEndUuid;
      data.pu_item = itemValue;
      data.pu_max_temp = maxTempValue;
      data.pu_suction_head = suctionHeadValue;
      data.pu_picture = pictureValue;
      const item = [...packagingData].find(item => item.name === packagingValue);
      if (item) {
        data.pu_packaging = item;
      } else {
        data.pu_packaging = {
          uuid: '',
          name: '',
          weight: '',
          subType: {
            H: '',
            L: '',
            W: ''
          }
        }
      }
      const pumpUnit: any = {
        item: data.pu_item,
        pu_max_temp: data.pu_max_temp,
        parts: data.pu_parts,
        picture: data.pu_picture,
        suction_head: data.pu_suction_head,
        packaging: data.pu_packaging
      };
      data.pu_unit = pumpUnit;
      this.checkPumpSystemWithData(data);
    });
    this.subscriptions.push(uuids);
  }

  checkPumpSystemWithData(data: any) {
    const accessories = [];
    if (this.accessoriesList && !isEmpty(this.accessoriesList)) {
      const accessoryData = of(this.accessoriesList).pipe(
        concatAll(),
        mergeMap(item => this.displayProductData.getAccessoryItem(item.uuid)),
        toArray()
      )
      .subscribe(result => {
        for (const item of result) {
          const tartget = {...item.versions[0]};
          tartget.name = item.name;
          tartget.version = item.versions[0].version_number;
          accessories.push(tartget);
        }
        data.accessories = accessories;
        this.createPumpSystemWithData(data);
      });
      this.subscriptions.push(accessoryData);
    } else {
      // this.accessoryList value is undefined
      this.createPumpSystemWithData(data);
    }
  }

  createPumpSystemWithData(data: any) {
    this.displayProductData.getPumpSystemList()
      .pipe(
        take(1),
        mergeMap(allPumpSystems => {
          data.id = allPumpSystems.length+1;
          return this.displayProductData.createNewPumpSystem(data);
        })
      ).subscribe(
        item => {
          this.snackBar
            .open(JSON.parse(item)['message'], '', { duration: 1500, panelClass: 'hintMsg' })
            .afterDismissed()
            .pipe(delay(0))
            .subscribe(() => {
              this.displayProductData.reloadPumpSystemData();
              this.router.navigate(['/data/pump-system', data.uuid]);
              this.store.dispatch(setAccessorySelectedList({ data: [] }));
              this.store.dispatch(setAccessoryPrepareList({ data: [] }));
              this.store.dispatch(setConnectedProductSelectedList({ data: [] }));
              this.store.dispatch(setConnectedProductPrepareList({ data: [] }));
            });
        },
        err => {
          this.snackBar
            .open(JSON.parse(err.error)['message'], '', { duration: 1500, panelClass: 'hintMsg' })
            .afterDismissed()
            .pipe(delay(0))
            .subscribe(() => this.showSubmitLoading = false);
        }
      );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
