import { Component, OnDestroy, OnInit } from '@angular/core';
import { first, map, Observable, Subscription } from 'rxjs';
import * as XLSX from 'xlsx';
import { select, Store } from '@ngrx/store';
import { setSourceUploadFile } from '../../store/data-source/data-source.actions';
import { ShareDataService } from '../../share-data.service';
import { setCoefficientConvertMeasurementData, setCoefficientConvertNPSHData, setCoefficientMeasurementData, setCoefficientsData, setFilename, setOriginalConvertMeasurementData } from '../../store/calculate-coefficient/calculate-coefficient.actions';
import { setSimulationMaxLift, setSimulationMinLift } from '../../store/simulation-setting/simulation-setting.actions';
import { PumpSimulationService } from '../../pump-simulation.service';
import { SystemFormState } from '../../store/system-form/system-form.reducer';
import { selectSystemForm } from '../../store/system-form/system-form.selector';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { now } from 'moment-timezone';
import { v4 } from 'uuid';

@Component({
  selector: 'app-source-upload-file',
  templateUrl: './source-upload-file.component.html',
  styleUrls: ['./source-upload-file.component.scss']
})
export class SourceUploadFileComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  file: File;
  arrayBuffer: any;
  fileList: any;
  uploadFileName = '';
  showUploadFileName = false;
  isUploadFileDataEmpty$: Observable<Boolean>;
  theHeadersAreIncorrect = false;
  pressureIsNotAscending = false;
  convertedNPSHData = [];
  convertedMeasurementData = [];
  userLocalStorage = new UserLocalStorage();

  constructor(
    private store: Store,
    private shareService: ShareDataService,
    private pumpSimulationService: PumpSimulationService,
  ) { }

  ngOnInit() {
    this.isUploadFileDataEmpty$ = this.shareService.getUploadFileDataEmpty();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.theHeadersAreIncorrect = false;
      this.pressureIsNotAscending = false;
      this.uploadFileName = event.target.files[0].name;
      this.showUploadFileName = true;
      this.parseFile(event);
      this.shareService.setUploadFileDataEmpty(false);
    }
  }

  parseFile(event) {
    this.file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = async (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.fileList = arraylist;
      if (this.checkImport()) {
        // this place is save upload file data
        this.store.dispatch(setSourceUploadFile({ data: this.fileList }));
        this.convertExcelData(this.fileList);
        this.handleUploadData(this.fileList);
        this.store.dispatch(setCoefficientMeasurementData({ data: this.fileList }));
      }
    };
  }

  handleUploadData(result: any) {
    this.store.pipe(
      select(selectSystemForm),
      map((data: SystemFormState) => data),
      first()
    ).subscribe(data => {
      const parameter = {
        pumpSystem: data.pumpName,
        file: JSON.stringify(this.fileList)
      };
      this.pumpSimulationService.uploadAndSaveData(parameter).subscribe(() => {
        this.createInitialVersion(result);
      });
    });
  }

  createInitialVersion(result: any) {
    const user = this.userLocalStorage.loadUser();
    this.store.pipe(
      select(selectSystemForm),
      map((data: SystemFormState) => data),
      first()
    ).subscribe(data => {
      const parameters = {
        filename: v4(),
        version: 0,
        username: user.user.username,
        notes: 'initial upload',
        changes: result,
        inputParams: {
          pump: data.pumpName,
          controller: data.controller,
          type: data.type,
        },
        displayName: data.pumpName,
        status: 'INITIAL',
        approver: user.user.username,
        timestamp: now()
      };
      const coefficients = {};
      coefficients['filename'] = parameters.filename;
      this.store.dispatch(setFilename({ data: parameters.filename }));
      this.pumpSimulationService.createInitialVersion(parameters).subscribe(data => {

      });
    });
  }

  checkImport() {
    let firstLine = true;
    let pressure = 0;
    for (const line of this.fileList) {
      if (firstLine) {
        if (line.I1 && line.U1 && line.V && line.n && line.t && line.Δp) {
          firstLine = false;
          continue;
        } else {
          this.theHeadersAreIncorrect = true;
          return false;
        }
      }
      else if (!(line.I1 && line.U1 && line.n && line.Δp)) {
        continue;
      }
      line.Δp = (Math.round(line.Δp * 100)) / 100;
      if (pressure === 0) {
        pressure = line.Δp;
        this.store.dispatch(setSimulationMinLift({ data: pressure * 10 }));
        continue;
      }
      if (!(pressure <= line.Δp)) {
        this.pressureIsNotAscending = true;
        return false;
      }
      pressure = line.Δp;
      this.store.dispatch(setSimulationMaxLift({ data: pressure * 10 }));
    }
    return true;
  }

  convertExcelData(data: any[]) {
    let i = 0;
    for (const row of data) {
      i++;
      this.convertMeasurementData(row, i);
      this.convertNPSHData(row);
    }
    this.store.dispatch(setCoefficientConvertNPSHData({ data: this.convertedNPSHData }));
    this.store.dispatch(setCoefficientConvertMeasurementData({ data: this.convertedMeasurementData }));
    this.store.dispatch(setOriginalConvertMeasurementData({ data: this.convertedMeasurementData }));
  }

  convertNPSHData(row: any) {
    const convertedNPSHRow = {
      flowRate: row.Q,
      suctionHead: row.m
    };
    if ((convertedNPSHRow.suctionHead) &&
      (convertedNPSHRow.flowRate.toString().indexOf('[Q]') < 0 && convertedNPSHRow.suctionHead.toString().indexOf('[m]') < 0)) {
        this.convertedNPSHData.push(convertedNPSHRow);
    }
  }

  convertMeasurementData(row: any, _id: number) {
    const convertedMeasurementRow = {
      id: _id,
      current: row.I1,
      voltage: row.U1,
      volume: row.V,
      rotationalSpeed: row.n,
      measurementTime: row.t,
      headPressure: row.Δp * 10,
      power: row.U1 * row.I1,
      flowRate: row.V / row.t * 3600 / 1000	// [m^3/h]
    };

    if (!isFinite(convertedMeasurementRow.flowRate)) { convertedMeasurementRow.flowRate = 0; }

    if ((convertedMeasurementRow.current &&
      convertedMeasurementRow.headPressure &&
      convertedMeasurementRow.rotationalSpeed &&
      convertedMeasurementRow.voltage)
      && (convertedMeasurementRow.current.toString().indexOf('[A]') < 0 &&
        convertedMeasurementRow.headPressure.toString().indexOf('[bar]') < 0 &&
        convertedMeasurementRow.voltage.toString().indexOf('[V]') < 0)) {
          this.convertedMeasurementData.push(convertedMeasurementRow);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
