import { Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-type-select-new',
  templateUrl: './type-select-new.component.html',
  styleUrls: ['./type-select-new.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TypeSelectNewComponent),
      multi: true
    }
  ]
})
export class TypeSelectNewComponent implements OnDestroy, ControlValueAccessor {

  types = ['Default', 'Custom', 'GRUNDFOS', 'Lowara', 'C', 'CS', 'BOOST', 'HR', 'AHR', 'HRE', 'SHRE', 'AHRE'];

  typeStore = '';
  private subscriptions: Subscription[] = [];

  @Output() selectionChange = new EventEmitter<MatSelectChange>();

  selectListControl = new UntypedFormControl('');

  typeChangeAction(event) {
    if ( event.value === 'empty' ) {
      this.typeStore = '';
      this.selectListControl.reset('');
    } else {
      this.typeStore = event.value;
    }

    this.selectionChange.emit(event);
  }

  // model --> view
  writeValue(value: string): void {
    if (value) { // value must not be null or undefined
      if (!this.types.includes(value)) {
        this.types.unshift(value);
      }
      this.typeStore = value;
      this.selectListControl.setValue(value, { emitEvent: false });
    } else {
      this.selectListControl.reset('');
    }
  }

  // view --> model
  registerOnChange(fn: (value: string) => void) {
    const onChangeSubscription = this.selectListControl.valueChanges.subscribe(fn);
    this.subscriptions.push(onChangeSubscription);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  onTouched: () => void = () => {};

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

}
