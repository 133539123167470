import { Action, createReducer, on } from "@ngrx/store";
import { MeasurementRow, SelectedCoefficientResultRow } from "src/app/pump-simulation/coefficient.model";
import * as CalculateCoefficientActions from "./calculate-coefficient.actions";

export const calculateCoefficientFeatureKey = 'calculateCoefficient';

export interface CalculateCoefficientState {
  filename: string;
  measurementData: any;
  deletedMeasurementData: MeasurementRow[],
  selectRowIndex: Number,
  showRevertDataBtn: Boolean,
  showRevertAllDataBtn: Boolean,
  convertNPSHData: any;
  convertMeasurementData: any;
  originalConvertMeasurementData: any;
  allCoefficientsData: any;
  selectedCoefficientsData: SelectedCoefficientResultRow;
}

export const initialState: CalculateCoefficientState = {
  filename: null,
  measurementData: null,
  deletedMeasurementData: [],
  selectRowIndex: -1,
  showRevertDataBtn: false,
  showRevertAllDataBtn: false,
  convertNPSHData: null,
  convertMeasurementData: null,
  originalConvertMeasurementData: null,
  allCoefficientsData: null,
  selectedCoefficientsData: null,
}

const reducer = createReducer(
  initialState,
  on(CalculateCoefficientActions.setCoefficientMeasurementData, (state, action) => {
    return {
      ...state,
      measurementData: action.data
    }
  }),
  on(CalculateCoefficientActions.setCoefficientConvertNPSHData, (state, action) => {
    return {
      ...state,
      convertNPSHData: action.data
    }
  }),
  on(CalculateCoefficientActions.setCoefficientConvertMeasurementData, (state, action) => {
    return {
      ...state,
      convertMeasurementData: action.data
    }
  }),
  on(CalculateCoefficientActions.setOriginalConvertMeasurementData, (state, action) => {
    return {
      ...state,
      originalConvertMeasurementData: action.data
    }
  }),
  on(CalculateCoefficientActions.setDeletedMeasurementItem, (state, action) => {
    return {
      ...state,
      deletedMeasurementData: action.data,
      showRevertDataBtn: action.data.length > 0 ? true : false,
      showRevertAllDataBtn: action.data.length > 1 ? true : false,
    }
  }),
  on(CalculateCoefficientActions.setCoefficientsData, (state, action) => {
    return {
      ...state,
      allCoefficientsData: action.data
    }
  }),
  on(CalculateCoefficientActions.setTableHightlightRow, (state, action) => {
    return {
      ...state,
      selectRowIndex: action.data
    }
  }),
  on(CalculateCoefficientActions.setSelectedCoefficientsData, (state, action) => {
    return {
      ...state,
      selectedCoefficientsData: action.data
    }
  }),
  on(CalculateCoefficientActions.setFilename, (state, action) => {
    return {
      ...state,
      filename: action.data
    }
  })
);



export function calculateCoefficientReducer(
  state: CalculateCoefficientState,
  action: Action
): CalculateCoefficientState {
  return reducer(state, action);
}
