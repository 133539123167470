import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { catchError, concatAll, map, mergeMap, of, take, toArray } from "rxjs";
import { DisplayProductDataService } from "../../display-product-data.service";
import { ConnectedProduct, ConnectedProductDetail } from "../../pump-data.model";
import * as ConnectedProductActions from "./connected-product.actions";
import { ConnectedProductState } from "./connected-product.reducer";
import { selectConnectedProduct } from "./connected-product.seletor";


@Injectable()

export class ConnectedProductEffects {

  // Get all Accessory Names
  loadConnectedProducts$ = createEffect(() => this.actions$.pipe(
    ofType(ConnectedProductActions.loadConnectedProducts),
    mergeMap(() => this.displayProductData.getConnectedProductList()
    .pipe(
      map((data: ConnectedProduct[]) => ConnectedProductActions.loadConnectedProductsSuccess({ data })),
      catchError(error => of(ConnectedProductActions.loadConnectedProductsFailure({ error })))
    ))
  ));

  // Get each Accessory item data and put them together
  loadAllConnectedProductData$ = createEffect(() => this.actions$.pipe(
    ofType(ConnectedProductActions.loadAllConnectedProduct),
    mergeMap(() => this.store.pipe(
      select(selectConnectedProduct),
      map((data: ConnectedProductState) => data.connectedProducts),
      take(1),
      concatAll(),
      mergeMap(item => this.displayProductData.getConnectedProductItem(item.uuid)),
      toArray(),
      map((result: ConnectedProductDetail[]) => {
        const data = result.map(item => {
          return {
            ...item.versions[0],
            file: item.name,
            uuid: item.uuid
          }
        });
        return ConnectedProductActions.loadAllConnectedProductSuccess({ data });
      }),
      catchError(error => of(ConnectedProductActions.loadAllConnectedProductFailure({ error })))
    ))
  ));

  constructor(
    private store: Store,
    private actions$: Actions,
    private displayProductData: DisplayProductDataService
  ){}
}


