import { Input } from '@angular/core';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CoordinateItem } from "./coordinate.model";
import { DisplayProductDataService } from '../display-product-data.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = [
    'id', 'lat', 'lon',
    'ins_0', 'ins_1', 'ins_2', 'ins_3', 'ins_4', 'ins_5',
    'ins_6', 'ins_7', 'ins_8', 'ins_9', 'ins_10', 'ins_11', 'ins_12',
    'rain_0', 'rain_1', 'rain_2', 'rain_3', 'rain_4', 'rain_5',
    'rain_6', 'rain_7', 'rain_8', 'rain_9', 'rain_10', 'rain_11',
    'rain_12', 'temp_0', 'temp_1', 'temp_2', 'temp_3', 'temp_4',
    'temp_5', 'temp_6', 'temp_7', 'temp_8', 'temp_9', 'temp_10', 'temp_11', 'temp_12'];


  tableLoading = true;
  isFirstInit = true;
  @Input() dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {
    this.displayProductData.getCoordinateList().subscribe((allLocations: CoordinateItem[]) => {

      allLocations.map(item => {
        for ( const subItem of item.ins ) { item[`ins_${subItem.position}`] = subItem.value; }
        for ( const subItem of item.rain ) { item[`rain_${subItem.position}`] = subItem.value; }
        for ( const subItem of item.temp ) { item[`temp_${subItem.position}`] = subItem.value; }
        return item;
      });
      this.tableLoading = false;
      this.dataSource.data = allLocations.sort((a, b) => Number(a.id) - Number(b.id));
      this.isFirstInit = false;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.firstPage());
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

