<ng-container *ngFor="let item of data; let i=index">
  <div class={{item.class}}>
    <div class="name">{{item.title}}</div>
    <div class="radios">
      <mat-radio-group>
        <mat-radio-button color="primary" *ngFor="let type of item.radios" checked={{type.checked}} [value]="type.name"
          (change)="radioChange($event, item)">{{type.name}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="value">
      <input matInput type="number" *ngFor="let subItem of item.values" disabled={{subItem.disabled}}
        [value]="subItem.value" (blur)="onUpdate($event, item)" />
    </div>
  </div>
</ng-container>