<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">

    <div class="top-wrapper">
      <button class="form-wrapper__copy-btn" mat-raised-button color="primary" type="button"
        (click)="openCopyDialog('pole-mount')">COPY</button>

      <span class="form-wrapper__copy-hint" *ngIf="copiedItem">{{copiedItem}} copied</span>
    </div>
    <mat-card-header>
      <mat-card-title>
        Pole Mount
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>

      <div class="form-wrapper__loading" *ngIf="showLoading">
        <mat-progress-spinner mode="indeterminate" [diameter]=20></mat-progress-spinner>
      </div>

      <div class="container" novalidate>
        <form [formGroup]="poleMountForm" (ngSubmit)="onSubmit(poleMountForm.value)" class="form">

          <mat-form-field class="form-element">
            <mat-label>Pole Mount</mat-label>
            <input matInput placeholder="Pole Mount" data-testid="pole_mount" formControlName="pole_mount">
            <mat-error *ngIf="poleMountForm.get('pole_mount').hasError('required')">The field name is required
            </mat-error>
            <mat-error *ngIf="poleMountForm.get('pole_mount').hasError('duplicateNames')">This name is already used
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>Item No</mat-label>
            <input matInput placeholder="Item No" data-testid="item_no" formControlName="item_no">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>status</mat-label>
            <input matInput placeholder="status" data-testid="status" formControlName="status">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>comment</mat-label>
            <input matInput placeholder="comment" data-testid="comment" formControlName="comment">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>Price</mat-label>
            <input matInput placeholder="Price" data-testid="price" formControlName="price">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>a_mm</mat-label>
            <input matInput placeholder="a_mm" data-testid="a_mm" formControlName="a_mm">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>a_ft</mat-label>
            <input matInput placeholder="a_ft" data-testid="a_ft" formControlName="a_ft">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>b_mm</mat-label>
            <input matInput placeholder="b_mm" data-testid="b_mm" formControlName="b_mm">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>b_inch</mat-label>
            <input matInput placeholder="b_inch" data-testid="b_inch" formControlName="b_inch">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>c_mm</mat-label>
            <input matInput placeholder="c_mm" data-testid="c_mm" formControlName="c_mm">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>c_ft</mat-label>
            <input matInput placeholder="c_ft" data-testid="c_ft" formControlName="c_ft">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>modules_count</mat-label>
            <input matInput placeholder="modules_count" data-testid="modules_count" formControlName="modules_count">
          </mat-form-field>

          <div class="form-element">
            <button mat-raised-button color="primary" type="submit" data-testid="submit" class="button"
              [disabled]="!poleMountForm.valid">Submit Form</button>
          </div>

        </form>
      </div>
    </mat-card-content>
  </mat-card>
</section>
