import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { v4 } from 'uuid';
import { DisplayProductDataService } from '../display-product-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CopyButtonComponent } from '../copy-button/copy-button.component';
import { MotorItem } from '../motors/motor.model';
import { MOTOR_ITEMS_CREATE } from '../pump-data.model';
import { checkMotorName } from '../names.validator';
import { delay, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorButtonComponent } from '../error-button/error-button.component';
import { combineLatest, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { PackagingState } from '../store/packaging/packaging.reducer';
import { selectPackaging } from '../store/packaging/packaging.selector';
import { setPartsData, setPartsName, setPartsPicture, setPartsType } from '../store/parts-list/parts-list.actions';
import { setPartsListName } from '../store/controller/controller.actions';
import { ControllerState } from '../store/controller/controller.reducer';
import { selectController } from '../store/controller/controller.seletor';
import { PartsListState } from '../store/parts-list/parts-list.reducer';
import { selectPartsList } from '../store/parts-list/parts-list.seletor';
import { PartsListService } from '../parts-list.service';

@Component({
  selector: 'app-motor-new',
  templateUrl: './motor-new.component.html',
  styleUrls: ['./motor-new.component.scss']
})
export class MotorNewComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  motorForm: UntypedFormGroup;
  copiedItem: string;
  showLoading = false;
  showPartslistTree = false;
  partslistName = 'Please select parts list';
  packagingList = [];
  motorPartsValue: any;

  constructor(
    private store: Store,
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private database: PartsListService,
    private displayProductData: DisplayProductDataService
  ) { }


  ngOnInit() {

    this.createForm();
    this.store.dispatch(setPartsType({ data: 'motor' }));
    const getData = combineLatest([
      this.store.pipe(
        select(selectPackaging),
        map((data: PackagingState) => data.data)
      ),
      this.store.pipe(
        select(selectController),
        map((data: ControllerState) => data.partsList)
      ),
      this.store.pipe(
        select(selectPartsList),
        map((data: PartsListState) => data.partsData)
      )
    ]).subscribe(([data, partsName, partsData]) => {
      this.packagingList = [...data].sort((a, b) => Number(a.subType.L) - Number(b.subType.L));
      
      if (partsName !== '') {
        this.partslistName = `${partsName}`;
      }

      if (
        partsData // null and undefined check
        && Object.keys(partsData).length === 0
        && Object.getPrototypeOf(partsData) === Object.prototype
      ) {
        this.showPartslistTree = false;
      } else {
        this.showPartslistTree = true;
      }
      
    });
    this.subscriptions.push(getData);
  }

  openCopyDialog(type: string) {
    const dialogRef = this.dialog.open(CopyButtonComponent, {
      width: '1000px',
      maxWidth: '96vw',
      data: type,
      disableClose: true,
      minHeight: '60vh',
      maxHeight: '60vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.showLoading = true;
      this.copyItem(result);
    });
  }

  copyItem(motorUuid: string) {
    this.displayProductData.getMotorItem(motorUuid)
    .subscribe((motor: MotorItem) => {
      this.showLoading = false;
      this.copiedItem = motor.name;
      const motorFormValue: any = {};
      MOTOR_ITEMS_CREATE.forEach(name => {
        motorFormValue[`${name}`] = motor[`${name}`];
      });
      motorFormValue.name = '';
      this.motorForm.patchValue(motorFormValue);
      if ( motor.parts ) {
        this.partslistName = motor.parts.name;
        this.showPartslistTree = true;
        this.database.setPartsListUuid(motor.partsUuid);
        this.store.dispatch(setPartsName({ data: motor.parts.name }));
        this.store.dispatch(setPartsListName({ data: motor.parts.name }));
        this.store.dispatch(setPartsData({ data: motor.parts }));
      }

      if ( motor.parts_picture && motor.parts_picture !== '0' ) {
        this.store.dispatch(setPartsPicture({ data: motor.parts_picture }));
      }

    });
  }

  createForm() {
    const motorFormValue: any = {};
    MOTOR_ITEMS_CREATE.forEach(name => {
      motorFormValue[`${name}`] = [null, Validators.required];
    });
    motorFormValue.name = ['', [Validators.required, checkMotorName.bind(this)]];
    this.motorForm = this.fb.group(motorFormValue);
  }

  createNewMotor(data: any) {
    data.uuid = v4();
    data.parts = this.motorPartsValue;

    if ( this.motorForm.value.packaging.subType ) {
      const packagingValue: any = {};
      packagingValue.name = data.packaging.uuid;
      packagingValue.w = data.packaging.subType.W;
      packagingValue.h = data.packaging.subType.H;
      packagingValue.l = data.packaging.subType.L;
      packagingValue.pack_weight = data.packaging.weight;
      data.packaging = packagingValue;
    } else {
      const result = this.packagingList.filter(item => item.name.includes(data.packaging));
      const packaging: any = {};
      if (result.length > 0) {
        packaging.name = result[0].uuid;
        packaging.w = `${result[0].subType.W}`;
        packaging.h = `${result[0].subType.H}`;
        packaging.l = `${result[0].subType.L}`;
        packaging.pack_weight = `${result[0].weight}`;
      }
      data.packaging = packaging;
    }

    this.displayProductData.createNewMotor(data).subscribe(
      item => {
        this.snackBar
        .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
        .afterDismissed()
        .pipe(delay(0))
        .subscribe(() => {
          this.displayProductData.reloadMotorData();
          this.router.navigate(['/data/motor', data.uuid]);
        });
      },
      error => {
        const dialogRef = this.dialog.open(ErrorButtonComponent, {
          width: '600px',
          data: JSON.parse(error.error),
          minHeight: '20vh',
        });
        dialogRef.afterClosed().subscribe(() => {});
      },
      () => {}
    );
  }

  updatePartslistTree(event) {
    this.motorPartsValue = event.partsValue;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
