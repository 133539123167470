<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">
    <mat-card-header>
      <mat-card-title>
        Packaging Overview
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field class="form-wrapper__searchPart">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" #input>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="createNewPackaging()">
        <mat-icon>add</mat-icon> Add New Packaging
      </button>
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pack name</th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
          </ng-container>

          <!-- Item Height Column -->
          <ng-container matColumnDef="itemH">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pack height</th>
            <td mat-cell *matCellDef="let row"> {{row.subType.H}} </td>
          </ng-container>

          <!-- Item Width Column -->
          <ng-container matColumnDef="itemW">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pack width</th>
            <td mat-cell *matCellDef="let row"> {{row.subType.W}} </td>
          </ng-container>

          <!-- Item Length Column -->
          <ng-container matColumnDef="itemL">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pack length</th>
            <td mat-cell *matCellDef="let row"> {{row.subType.L}} </td>
          </ng-container>

          <!-- Item weight Column -->
          <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pack weight</th>
            <td mat-cell *matCellDef="let row"> {{row.weight}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr [ngClass]="(tableLoading$ | async) ? 'removeContent' : ''" class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5">No data matching the filter "{{input.value}}"</td>
          </tr>
        </table>
        <div class="form-wrapper__table-loading" *ngIf="tableLoading$ | async">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <mat-paginator [pageSizeOptions]="[12, 25, 100]"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</section>