import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ControllerItem } from '../controllers/controller.model';
import { DisplayProductDataService } from '../display-product-data.service';
import { MotorItem } from '../motors/motor.model';
import { PumpEndsItem } from '../pump-ends/pump-end.model';
import { Accessory, ConnectedProduct, Module, PipePart, PoleMount, PumpSystem } from '../pump-data.model';
import { PartsListItem } from "../parts-list/partsList.model";
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { selectAccessory } from '../store/accessory/accessory.seletor';
import { AccessoryState } from '../store/accessory/accessory.reducer';
import { loadAccessories } from '../store/accessory/accessory.actions';
import { loadPackagings } from '../store/packaging/packaging.actions';
import { filenameSort, nameSort, pumpsystemNameSort } from 'src/app/shared/util';
import { loadMaterials } from '../store/material/material.actions';
import { loadConnectedProducts } from '../store/connected-product/connected-product.actions';
import { loadCoefficientSets } from '../store/coefficient-set/coefficient-set.actions';
import { PumpSimulationService } from '../../pump-simulation/pump-simulation.service';
import { CoefficientNameItem } from '../../pump-simulation/coefficient.model';

@Component({
  selector: 'app-data-side-nav',
  templateUrl: './data-side-nav.component.html',
  styleUrls: ['./data-side-nav.component.scss']
})
export class DataSideNavComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  searchControl = new UntypedFormControl();

  selectedPanel = '';

  allExpandPanels: string[] = ['expandPanelPumpSystem', 'expandPanelController', 'expandPanelMotor', 'expandPanelPumpEnd', 'expandPanelPartsList', 'expandPanelAccessory', 'expandPanelModule', 'expandPanelPoleMount', 'expandPanelCity', 'expandPanelCountry', 'expandPanelLocation', 'expandPanelPipePart', 'expandPanelMaterial', 'expandPanelPackaging', 'expandPanelConnected', 'expandPanelVaporPressure'];

  expandPanelPumpSystem = false;
  expandPanelController = false;
  expandPanelMotor = false;
  expandPanelPumpEnd = false;
  expandPanelPartsList = false;
  expandPanelAccessory = false;
  expandPanelModule = false;
  expandPanelPoleMount = false;
  expandPanelCity = false;
  expandPanelCountry = false;
  expandPanelLocation = false;
  expandPanelPipePart = false;
  expandPanelMaterial = false;
  expandPanelPackaging = false;
  expandPanelConnected = false;
  expandPanelVaporPressure = false;

  controllerData$: Observable<ControllerItem[]>;
  motorData$: Observable<MotorItem[]>;
  pumpEndsData$: Observable<PumpEndsItem[]>;
  pumpSystemData$: Observable<PumpSystem[]>;
  accessoryData$: Observable<Accessory[]>;
  partsData$: Observable<PartsListItem[]>;
  poleMountData$: Observable<PoleMount[]>;
  moduleData$: Observable<Module[]>;
  pipePartData$: Observable<PipePart[]>;

  accessoryLoading: Observable<Boolean>;

  controllerList: Observable<ControllerItem[]>;
  motorList: Observable<MotorItem[]>;
  pumpEndsList: Observable<PumpEndsItem[]>;
  pumpSystemList: Observable<PumpSystem[]>;
  partsList: Observable<PartsListItem[]>;
  poleMountList: Observable<PoleMount[]>;
  moduleList: Observable<Module[]>;
  pipePartList: Observable<PipePart[]>;

  coefficientSetNameList: Observable<CoefficientNameItem[]>;
  connectedProductList: Observable<ConnectedProduct[]>;
  customCollapsedHeight = '48px';
  customExpandedHeight = '48px';

  constructor(
    private store: Store,
    private router: Router,
    private pumpSimulationService: PumpSimulationService,
    private displayProductData: DisplayProductDataService
  ) {
    this.initExpandPanel();
  }

  ngOnInit() {

    this.store.dispatch(loadAccessories());
    this.store.dispatch(loadPackagings());
    this.store.dispatch(loadMaterials());
    this.store.dispatch(loadConnectedProducts());
    this.store.dispatch(loadCoefficientSets());

    this.pumpSystemList = this.displayProductData.getPumpSystemList().pipe(
      map(data => pumpsystemNameSort([...data]))
    );

    this.controllerList = this.displayProductData.getControllerList().pipe(
      map(data => nameSort(data))
    );

    this.motorList = this.displayProductData.getMotorList().pipe(
      map(data => nameSort(data))
    );

    this.pumpEndsList = this.displayProductData.getPumpEndsList().pipe(
      map(data => nameSort(data))
    );

    this.accessoryData$ = this.store.pipe(
      select(selectAccessory),
      map((data: AccessoryState) => nameSort([...data.accessories]))
    );

    this.accessoryLoading = this.store.pipe(
      select(selectAccessory),
      map((data: AccessoryState) => data.loading)
    );

    this.partsList = this.displayProductData.getPartsList().pipe(
      map(data => nameSort(data))
    );

    this.moduleList = this.displayProductData.getModuleList().pipe(
      map(data => nameSort(data))
    );

    this.poleMountList = this.displayProductData.getPoleMountList().pipe(
      map(data => nameSort(data))
    );
    this.pipePartList = this.displayProductData.getPipePartList().pipe(
      tap(data => data.sort((a, b) => Number(b.id) - Number(a.id)))
    );

    this.connectedProductList = this.displayProductData.getConnectedProductList().pipe(
      map(data => nameSort([...data]))
    );

    this.coefficientSetNameList = this.pumpSimulationService.getAllMeasurementFileNames().pipe(
      map(data => filenameSort(data))
    );

    this.initSearch();

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.searchControl.setValue('');
    });
  }

  filterSort(data: any[], value: string) {
    const result = data.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
    return nameSort(result);
  }

  setExpandPanel(target: string) {
    this.selectedPanel = target;
    this.allExpandPanels.forEach(item => {
      if ( target === item ) {
        this[`${item}`] = !this[`${target}`];
      } else {
        this[`${item}`] = false;
      }
    });
  }

  /**
   * Filter search result according to the expanded panel.
   */
  private initSearch() {
    const searchSubscription = this.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(searchValue => {

      const value = searchValue.trim().toLowerCase();
      if ( this.expandPanelPumpSystem ) {
        this.pumpSystemListSearch(value);
      } else if ( this.expandPanelController ) {
        this.controllerListSearch(value);
      } else if ( this.expandPanelMotor ) {
        this.motorListSearch(value);
      } else if ( this.expandPanelPumpEnd ) {
        this.pumpEndsListSearch(value);
      } else if ( this.expandPanelPartsList ) {
        this.partsListSearch(value);
      } else if ( this.expandPanelAccessory ) {
        this.accessoryListSearch(value);
      } else if ( this.expandPanelModule ) {
        this.moduleListSearch(value);
      } else if ( this.expandPanelPoleMount ) {
        this.poleMountListSearch(value);
      } else if ( this.expandPanelConnected ) {
        this.connectedProductListSearch(value);
      } else {
        this.expandPanelPumpSystem = true;
        this.pumpSystemListSearch(value);
      }
    });

    this.subscriptions.push(searchSubscription);
  }

  /**
   * Sets the target expandPanel in the side nav bar depending on the selected route.
   */
  private initExpandPanel() {

    const navEndSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((nav) => {
        const url = (nav as NavigationEnd).url;
        if ( url && url.includes('pump-system') ) {
          this.expandPanelPumpSystem = true;
        } else if ( url && url.includes('controller') ) {
          this.expandPanelController = true;
        } else if ( url && url.includes('motor') ) {
          this.expandPanelMotor = true;
        } else if ( url && url.includes('pump-end') ) {
          this.expandPanelPumpEnd = true;
        } else if ( url && url.includes('part-list') ) {
          this.expandPanelPartsList = true;
        } else if ( url && url.includes('accessory') ) {
          this.expandPanelAccessory = true;
        } else if ( url && url.includes('module') ) {
          this.expandPanelModule = true;
        } else if ( url && url.includes('pole-mount') ) {
          this.expandPanelPoleMount = true;
        } else if ( url && url.includes('connected-item') ) {
          this.expandPanelConnected = true;
        } else if ( url && url.includes('city') ) {
          this.expandPanelCity = true;
        } else if ( url && url.includes('country') ) {
          this.expandPanelCountry = true;
        } else if ( url && url.includes('location') ) {
          this.expandPanelLocation = true;
        } else if ( url && url.includes('pipe-part') ) {
          this.expandPanelPipePart = true;
        } else if ( url && url.includes('material') ) {
          this.expandPanelMaterial = true;
        } else if ( url && url.includes('packaging') ) {
          this.expandPanelPackaging = true;
        } else if ( url && url.includes('vapor-pressure') ) {
          this.expandPanelVaporPressure = true;
        } else {
          this.allExpandPanels.forEach(item => this[`${item}`] = false);
        }
      });
    this.subscriptions.push(navEndSubscription);
  }

  pumpSystemListSearch(value: string) {
    if (value.length > 2) {
      this.pumpSystemList = this.displayProductData.getPumpSystemList().pipe(
        map(items => {
          const result = items.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
          return pumpsystemNameSort(result)
        })
      );
    } else {
      this.pumpSystemList = this.displayProductData.getPumpSystemList().pipe(
        map(data => pumpsystemNameSort(data))
      );
    }
  }

  controllerListSearch(value: string) {
    if (value.length > 2) {
      this.controllerList = this.displayProductData.getControllerList().pipe(
        map(items => this.filterSort(items, value))
      );
    } else {
      this.controllerList = this.displayProductData.getControllerList().pipe(
        map(data => nameSort(data))
      );
    }
  }

  motorListSearch(value: string) {
    if (value.length > 2) {
      this.motorList = this.displayProductData.getMotorList().pipe(
        map(items => this.filterSort(items, value))
      );
    } else {
      this.motorList = this.displayProductData.getMotorList().pipe(
        map(data => nameSort(data))
      );
    }
  }

  pumpEndsListSearch(value: string) {
    if (value.length > 2) {
      this.pumpEndsList = this.displayProductData.getPumpEndsList().pipe(
        map(items => this.filterSort(items, value))
      );
    } else {
      this.pumpEndsList = this.displayProductData.getPumpEndsList().pipe(
        map(data => nameSort(data))
      );
    }
  }

  partsListSearch(value: string) {
    if (value.length > 2) {
      this.partsList = this.displayProductData.getPartsList().pipe(
        map(items => this.filterSort(items, value))
      );
    } else {
      this.partsList = this.displayProductData.getPartsList().pipe(
        map(data => nameSort(data))
      );
    }
  }

  accessoryListSearch(value: string) {
    if (value.length > 2) {
      this.accessoryData$ = this.store.pipe(
        select(selectAccessory),
        map((data: AccessoryState) => this.filterSort([...data.accessories], value))
      );
    } else {
      this.accessoryData$ = this.store.pipe(
        select(selectAccessory),
        map((data: AccessoryState) => nameSort([...data.accessories]))
      );
    }
  }

  moduleListSearch(value: string) {
    if (value.length > 2) {
      this.moduleList = this.displayProductData.getModuleList().pipe(
        map(items => this.filterSort(items, value))
      );
    } else {
      this.moduleList = this.displayProductData.getModuleList().pipe(
        map(data => nameSort(data))
      );
    }
  }

  poleMountListSearch(value: string) {
    if (value.length > 2) {
      this.poleMountList = this.displayProductData.getPoleMountList().pipe(
        map(items => this.filterSort(items, value))
      );
    } else {
      this.poleMountList = this.displayProductData.getPoleMountList().pipe(
        map(data => nameSort(data))
      );
    }
  }

  connectedProductListSearch(value: string) {
    if (value.length > 2) {
      this.connectedProductList = this.displayProductData.getConnectedProductList().pipe(
        map(items => this.filterSort(items, value))
      );
    } else {
      this.connectedProductList = this.displayProductData.getConnectedProductList().pipe(
        map(data => nameSort(data))
      );
    }
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

}
