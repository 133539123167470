import { Action, createReducer, on } from "@ngrx/store";
import * as SystemFormActions from "./system-form.actions";

export const systemFormFeatureKey = 'systemForm';

export interface SystemFormState {
  controller: String;
  type: String;
  pumpEnd: String;
  nameAppendix: String;
  pumpName: String;
  enableNextStep: Boolean;
  loadName: String;
  versionNumber: Number;
}

export const initialState: SystemFormState = {
  controller: '',
  type: '',
  pumpEnd: '',
  nameAppendix: '',
  pumpName: '',
  enableNextStep: false,
  loadName: '',
  versionNumber: -1,
}

const reducer = createReducer(
  initialState,
  on(SystemFormActions.setSystemFormController, (state, action) => {
    let setName = '';
    if (state.nameAppendix === '') {
      if (action.data === '' && state.pumpEnd !== '') {
        setName = `${state.pumpEnd}_coeff`;
        setName = setName.replace(/"/g,"inch");
      } else {
        setName = `${action.data}_${state.pumpEnd}_coeff`;
        setName = setName.replace(/"/g,"inch");
      }
    } else {
      const appendix = state.nameAppendix.replace(/ /g,"_");
      if (action.data === '' && state.pumpEnd !== '') {
        setName = `${state.pumpEnd}${'_coeff_' + appendix}`;
        setName = setName.replace(/"/g,"inch");
      } else {
        setName = `${action.data}_${state.pumpEnd}${'_coeff_' + appendix}`;
        setName = setName.replace(/"/g,"inch");
      }
    }

    if (action.data === '' && state.type === '' && state.pumpEnd === '') {
      setName = '';
    }

    return {
      ...state,
      controller: action.data,
      pumpName: setName,
      enableNextStep: action.data !== '' && state.type !== '' && state.pumpEnd !== ''
    }
  }),
  on(SystemFormActions.setSystemFormType, (state, action) => {
    return {
      ...state,
      type: action.data,
      enableNextStep: state.controller !== '' && action.data !== '' && state.pumpEnd !== ''
    }
  }),
  on(SystemFormActions.setSystemFormPumpEnd, (state, action) => {
    let setName = '';
    if (state.nameAppendix === '') {
      if (action.data === '' && state.controller !== '') {
        setName = `${state.controller}_coeff`;
        setName = setName.replace(/"/g,"inch");
      } else {
        setName = `${state.controller}_${action.data}_coeff`;
      }
    } else {
      const appendix = state.nameAppendix.replace(/ /g,"_");
      if (action.data === '' && state.controller !== '') {
        setName = `${state.controller}${'_coeff_' + appendix}`;
        setName = setName.replace(/"/g,"inch");
      } else {
        setName = `${state.controller}_${action.data}${'_coeff_' + appendix}`;
        setName = setName.replace(/"/g,"inch");
      }
    }

    if (state.controller == '' && state.type == '' && action.data == '') {
      setName = '';
    }

    return {
      ...state,
      pumpEnd: action.data,
      pumpName: setName,
      enableNextStep: state.controller !== '' && state.type !== '' && action.data !== ''
    }
  }),
  on(SystemFormActions.setSystemFormNameAppendix, (state, action) => {
    let setName = '';
    if (action.data === '') {
      setName = `${state.controller}_${action.data}_coeff`;
      setName = setName.replace(/"/g,"inch");
    } else {
      const appendix = action.data.replace(/ /g,"_");
      setName = `${state.controller}_${state.pumpEnd}${'_coeff_' + appendix}`;
      setName = setName.replace(/"/g,"inch");
    }
    return {
      ...state,
      nameAppendix: action.data,
      pumpName: setName
    }
  }),
  on(SystemFormActions.setLoadSystemFormNameAppendix, (state, action) => {
    return {
      ...state,
      loadName: action.data
    }
  }),
  on(SystemFormActions.setLoadVersionNumber, (state, action) => {
    return {
      ...state,
      versionNumber: action.data
    }
  })

);

export function systemFormReducer(
  state: SystemFormState,
  action: Action
): SystemFormState {
  return reducer(state, action);
}
