<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">
    <mat-card-header>
      <mat-card-title>
        Cities overview
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <mat-form-field class="form-wrapper__searchPart">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="City Name" #input>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="createNewCity()"> <mat-icon>add</mat-icon> Add New City </button>
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
            <td mat-cell *matCellDef="let row"> {{row.id}} </td>
          </ng-container>

          <!-- Country Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
          </ng-container>

          <!-- Country Id Column -->
          <ng-container matColumnDef="country_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Country Id</th>
            <td mat-cell *matCellDef="let row"> {{row.country_id}} </td>
          </ng-container>

          <!-- Latitude Column -->
          <ng-container matColumnDef="lat">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Latitude</th>
            <td mat-cell *matCellDef="let row"> {{row.lat}} </td>
          </ng-container>

          <!-- Longitude Column -->
          <ng-container matColumnDef="lon">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Longitude</th>
            <td mat-cell *matCellDef="let row"> {{row.lon}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr [ngClass]="isFirstInit ? 'firstInit' : ''" class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5">No data matching the filter "{{input.value}}"</td>
          </tr>
        </table>
        <div class="form-wrapper__table-loading" *ngIf="tableLoading">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
  </mat-paginator>

      </div>
    </mat-card-content>
  </mat-card>
</section>
