// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: 'test',
  stage: 'test',
  calculationUrl: 'https://test.api.ldd.a.aws.lorentz.zone/calculation/',
  importUrl: 'https://test.api.ldd.a.aws.lorentz.zone/impex/',
  importApiKey: '9HyOWwth657yirn4ZPdOD3fefHMgOo5t3EquUXoP',
  versioningUrl: 'https://test.api.ldd.a.aws.lorentz.zone/auditing/',
  userServiceUrl: 'https://test.api.ldd.a.aws.lorentz.zone/user/',
  pumpDataUrl: 'https://test.api.ldd.a.aws.lorentz.zone/pumpdata/',
  pumpGeoUrl: 'https://test.api.ldd.a.aws.lorentz.zone/geo/',
  adminUsers: ['christopher.burt', 'andrew.rossiter', 'aleksandrs.berzins'],
  lorentzAssistName: 'LORENTZ_ASSIST',
  loglevel: 'info',
  sEntryLogging: false,
  consoleLogging: true,
  sEntryDSN: 'https://e784a19190be4badbbba41404c94066f@o188299.ingest.sentry.io/5692693',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
