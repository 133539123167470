import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, Subject, Observable, startWith, map } from 'rxjs';
import { ControllerItem } from 'src/app/display-product-data/controllers/controller.model';
import { DisplayProductDataService } from 'src/app/display-product-data/display-product-data.service';
import { setSystemFormController } from 'src/app/pump-simulation/store/system-form/system-form.actions';
import { nameSort } from '../../util';

@Component({
  selector: 'app-simulation-controller',
  templateUrl: './simulation-controller.component.html',
  styleUrls: ['./simulation-controller.component.scss']
})
export class SimulationControllerComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  dropdownList: string[] = [];
  controllerList: ControllerItem[] = [];
  controllerControl = new UntypedFormControl('', [
    Validators.required,
    this.optionNotFound.bind(this),
  ]);
  filteredOptions: Set<string>;
  public readonly controllerChanges: Subject<string> = new Subject<string>();
  public controllerSuggestions: Observable<string[]>;

  constructor(
    private store: Store,
    private router: Router,
    private displayProductData: DisplayProductDataService,
  ) { }

  ngOnInit() {

    const getControllerList = this.displayProductData.getControllerList().subscribe(data => {
      this.controllerList = nameSort(data);
      this.dropdownList = this.controllerList.map(item => item.name);
      this.dropdownList.unshift('Add new controller');
      this.controllerSuggestions = this.controllerChanges.pipe(
        startWith(''),
        map((val: string) => this.filterResults(val))
      );
    })
    this.subscriptions.push(getControllerList);

  }

  private filterResults(val: string) {
    if (val !== '') {
      return this.dropdownList.filter(item => item.toLowerCase().includes(val.toLowerCase()));
    } else {
      return this.dropdownList;
    }
  }

  onFocus() {
    this.controllerChanges.next('');
  }

  updateController(value: string) {
    if (value === '') {
      this.optionNotFound(this.controllerControl);
      this.store.dispatch(setSystemFormController({ data: '' }));
    } else {
      
      const item = this.dropdownList.find(item => item === value);
      if (item) {
        this.controllerControl.setValue(value);
      } else {
        this.optionNotFound(this.controllerControl);
      }
    };
  }

  setControllerChangeAction(event: MatAutocompleteSelectedEvent) {
    if ( event.option.value === 'Add new controller' ) {
      this.router.navigate(['/data/controller/new']);
    } else {
      this.store.dispatch(setSystemFormController({ data: event.option.value }));
    }
  }

  optionNotFound(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    this.filteredOptions = new Set(
      this.dropdownList.filter((option) => option.toLowerCase() === value.toLowerCase())
    );
    if (value && !this.filteredOptions.size) {
      this.store.dispatch(setSystemFormController({ data: '' }));
      return {
        noOption: true
      };
    }
    return null;
  }

  navigateTo(value: string) {
    const item = this.controllerList.find(item => item.name === value);
    this.router.navigate([`/data/controller/${item.uuid}`]);
  }


  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
