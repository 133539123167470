<section>
  <mat-horizontal-stepper #createStepper>

    <mat-step>
      <div class="form">
        <ng-template matStepLabel>System components</ng-template>
        <app-system-form></app-system-form>
        <div class="actions">
          <button mat-raised-button matStepperNext [disabled]="(enableFirstStepNextBtn$ | async)"
            color="primary">Next</button>
        </div>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Data source</ng-template>
      <div class="form-source">
        <app-source-overview></app-source-overview>
        <div class="stepperLoading" *ngIf="stepperLoading">
          <mat-progress-spinner [diameter]="40" color="primary" mode="indeterminate">
          </mat-progress-spinner>
        </div>
        <div class="actions">
          <button mat-raised-button matStepperPrevious color="primary">Back</button>
          <button mat-raised-button (click)="sourceStepper()" [disabled]="(enableSecondStepNextBtn$ | async)"
            color="primary">Next</button>
        </div>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Data review</ng-template>
      <app-flow-rate-chart-new></app-flow-rate-chart-new>
      <div class="actions">
        <button mat-raised-button color="primary" (click)="addNewCreateItemData()">Add new item</button>
        <button mat-raised-button *ngIf="(showRevertAllDataBtn$ | async)" (click)="revertAllData()"
          color="primary">Recover original
          data</button>
        <button mat-raised-button *ngIf="(showRevertDataBtn$ | async)" (click)="revertData()" color="primary">Revert
          removed
          item</button>
        <button mat-raised-button matStepperPrevious color="primary">Back</button>
        <button mat-raised-button (click)="createReviewStepper()" color="primary">Next</button>
      </div>
      <app-data-table></app-data-table>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Coefficients</ng-template>
      <app-simulation-result *ngIf="showResult"></app-simulation-result>
    </mat-step>

  </mat-horizontal-stepper>
</section>