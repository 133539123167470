import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { MaterialItem } from "../../controllers/controller.model";
import { DisplayProductDataService } from "../../display-product-data.service";
import * as MaterialActions from './material.actions';


@Injectable()

export class MaterialEffects {

  // Get all Accessory Names
  loadMaterials$ = createEffect(() => this.actions$.pipe(
    ofType(MaterialActions.loadMaterials),
    mergeMap(() => this.displayProductData.getMaterialList()
    .pipe(
      map((data: MaterialItem[]) => MaterialActions.loadMaterialsSuccess({ data })),
      catchError((error) => of(MaterialActions.loadMaterialsFailure({ error })))
    ))
  ));

  constructor(
    private actions$: Actions,
    private displayProductData: DisplayProductDataService
  ){}
}
