import { Action, createReducer, on } from "@ngrx/store";
import * as NPSHActions from "./npsh-chart.actions";

export const NPSHFeatureKey = 'NPSHChart';

export interface NPSHState {
  loading: Boolean;
  npshCalculatedData: any;
  npshMeasuredData: any
}

export const initialState: NPSHState = {
  loading: true,
  npshCalculatedData: null,
  npshMeasuredData: null
}

const reducer = createReducer(
  initialState,
  on(NPSHActions.setNPSHLoading, (state, action) => {
    return {
      ...state,
      loading: action.data
    }
  }),
  on(NPSHActions.setNPSHMeasuredData, (state, action) => {
    return {
      ...state,
      loading: !state.npshCalculatedData,
      npshMeasuredData: action.data
    }
  }),
  on(NPSHActions.setNPSHCalculatedData, (state, action) => {
    return {
      ...state,
      loading: !state.npshMeasuredData,
      npshCalculatedData: action.data
    }
  })
);

export function npshReducer(
  state: NPSHState,
  action: Action
): NPSHState {
  return reducer(state, action);
}
