<div class="title">
  <p>Name</p>
  <p>Amount</p>
</div>
<mat-tree #tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
    <button mat-icon-button disabled></button>
    {{node.name}} ({{node.id}})
    <div class="editWrapper">
      <input type="number" matInput min=1 [value]="stringToNumber(node.partsNumbers)"
        (input)="updateNumberOfPartsList(node, $event.target.value)">
    </div>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    {{node.name}} ({{node.id}})
    <div class="editWrapper">
      <input type="number" matInput min=1 [value]="stringToNumber(node.partsNumbers)"
        (input)="updateNumberOfPartsList(node, $event.target.value)">
    </div>
  </mat-tree-node>
</mat-tree>