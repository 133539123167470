import { createAction, props } from "@ngrx/store";

export const setSimulationSplitPower = createAction(
  '[Simulation Setting Form] Set Simulation Split Power',
  props<{ data: Number }>()
);

export const setSimulationMinLift = createAction(
  '[Simulation Setting Form] Set Simulation Min Lift',
  props<{ data: Number }>()
);

export const setSimulationMaxLift = createAction(
  '[Simulation Setting Form] Set Simulation Max Lift',
  props<{ data: Number }>()
);

export const setSimulationExcelBehaviour = createAction(
  '[Simulation Setting Form] Set Simulation Split ExcelBehaviour',
  props<{ data: Boolean }>()
);