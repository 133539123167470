import { environment } from '../../environments/environment';
import * as Sentry from '@sentry/browser';
import { Injectable, ErrorHandler } from '@angular/core';
import { release } from './logger_releasespecification';

const dsn = environment.sEntryDSN || '';

interface FakeSentryErrorType {
  originalError?: any;
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  logLevel: string;
  sEntryLogging: boolean;
  consoleLogging: boolean;
  isInternetExplorer: boolean;

  constructor() {
    this.logLevel = environment.loglevel || 'info';
    this.sEntryLogging = environment.sEntryLogging || false;
    this.consoleLogging = environment.consoleLogging || false;
    this.isInternetExplorer =
      navigator.userAgent.indexOf('MSIE') > -1   // IE up to 10
      // || navigator.userAgent.indexOf('Edge/') > -1,
      || navigator.userAgent.indexOf('Trident/') > -1;  // IE 11

    // no logging for MSIE
    if (this.sEntryLogging && !this.isInternetExplorer) {
      Sentry.init({
        dsn,
        environment: environment.stage,
        release,
        beforeSend: (event, hint) => {
          if (!event || !hint || !hint.originalException ||
            !event.exception || !event.exception.values || event.exception.values.length === 0 ||
            !(event.exception.values[0].value)) {
            return null;
          }

          const ignoredErrors = [
            'Non-Error exception captured',
            'Uncaught (in promise): ChunkLoadError: Loading chunk',
            'Uncaught (in promise): Event: {"isTrusted":',
            'NS_ERROR_NOT_INITIALIZED',
            'NS_ERROR_FILE_NO_DEVICE_SPACE'
          ];

          for (const errEntry of ignoredErrors) {
            if (event.exception.values[0].value.startsWith(errEntry) ||
              hint.originalException['message'].startsWith(errEntry)) {
              return null;
            }
          }

          return event;
        }
      });
    }
  }

  handleError(error: FakeSentryErrorType): void {
    if (this.sEntryLogging === true) {
      Sentry.captureException(error.originalError || error);
    }
    if (this.consoleLogging === true) {
      console.log('Error', error.originalError || error);
    }
    throw error;
  }
}

export class Logger {

  static error(msg: string, ...optionalParams: any[]): void {
    if (environment.consoleLogging === true) {
      console.log('Error', msg, optionalParams);
    }
    if (environment.sEntryLogging === true) {
      let paramString = '';
      if (optionalParams) {
        paramString = '\n Params: ' + optionalParams.toString();
      }
      Sentry.captureMessage('Error: ' + msg + paramString);
    }
  }

  static info(msg: string, ...optionalParams: any[]): void {
    if (environment.consoleLogging === true) {
      console.log('Info', msg, optionalParams);
    }
    if (environment.sEntryLogging === true) {
      let paramString = '';
      if (optionalParams) {
        paramString = '\n Params: ' + optionalParams.toString();
      }
      Sentry.captureMessage('Info: ' + msg + paramString);
    }
  }

  static warn(msg: string, ...optionalParams: any[]): void {
    if (environment.consoleLogging === true) {
      console.log('Warn', msg, optionalParams);
    }
    if (environment.sEntryLogging === true) {
      let paramString = '';
      if (optionalParams) {
        paramString = '\n Params: ' + optionalParams.toString();
      }
      Sentry.captureMessage('Warning: ' + msg + paramString);
    }
  }
}
