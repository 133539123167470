<div class="part-left">
  <div class="form-element">

    <h2>Data upload/download SizingApp</h2>


     <mat-form-field class="example-full-width">
        <input matInput placeholder="Filename" aria-label="filename" [matAutocomplete]="auto" [(ngModel)]="filename" [ngModelOptions]="{standalone: true}">
        <mat-autocomplete #auto="matAutocomplete" >
          <mat-option *ngFor="let version of versions" [value]="version.filename">
            <span>{{ version.filename }} - {{version.version}}</span>
          </mat-option>
        </mat-autocomplete>
        </mat-form-field>

    </div>
    <div class="form-element">
      <div class="upload-wrapper">
      <button mat-raised-button color="primary"><mat-icon>add</mat-icon> Upload file
      <input
        type="file"
        name="profile"
        (change)="onFileSelect($event, filename)"
        accept=".csv,.json,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      ></button>
    </div>
    <div class="upload-info" *ngIf="showUploadFileName">
      <span> {{ uploadFileName }}  </span>
    </div>
  </div>


    <ng-template matStepLabel>System components</ng-template>

    <div class="form-element">
      <button mat-raised-button color="primary" type="button" (click)="displayApps()">View Sizing Apps</button>
    </div>
    <div class="form-wrapper__table-loading" *ngIf="showFileContentLoading">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div class="mat-elevation-z8" [ngClass]="showFileContentTable ? 'showContent' : ''">
      <div class="form-wrapper__table-wapper">
        <table mat-table *ngIf="dataSource" [dataSource]="dataSource">
          <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
            <th mat-header-cell *matHeaderCellDef> {{column}} </th>
            <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
    <div *ngIf="uploadFileVersion" class="upload-version"> File version: {{ uploadFileVersion }} </div>
    <div *ngIf="uploadFileContent" class="upload-content"> {{ uploadFileContent }} </div>
</div>
