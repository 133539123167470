import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, distinctUntilChanged, first, map, Observable, of, Subscription } from 'rxjs';
import { ChartConfiguration } from 'src/app/pump-simulation/chartConfiguration';
import { createConfiguration, plotData } from 'src/app/shared/util';
import { ShareDataService } from '../../share-data.service';
import { CalculateCoefficientState } from '../../store/calculate-coefficient/calculate-coefficient.reducer';
import { selectCalculateCoefficient } from '../../store/calculate-coefficient/calculate-coefficient.selector';
import { IntermediateState } from '../../store/charts/intermediate-chart/intermediate-chart.reducer';
import { selectIntermediate } from '../../store/charts/intermediate-chart/intermediate-chart.selector';

declare const google: any;

const AQ_CHART_ID = 'aqChart';

@Component({
  selector: 'app-aq-chart',
  templateUrl: './aq-chart.component.html',
  styleUrls: ['./aq-chart.component.scss']
})
export class AqChartComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  xAxisToYaxisChart = null;
  showPlaceholder$: Observable<Boolean>;
  @ViewChild(AQ_CHART_ID, { static: true }) chartElement: ElementRef;

  constructor(
    private store: Store,
    private shareService: ShareDataService,
  ) { }

  ngOnInit() {
    const chartLoading = this.shareService.getChartItem().subscribe(data => {
      if (data.includes('maq') || data.includes('naq')) {
        this.showPlaceholder$ = of(true);
      }
    });
    this.subscriptions.push(chartLoading);

    const aqChart = this.store.pipe(
      select(selectIntermediate),
      map((data: IntermediateState) => data.intermediateData),
      distinctUntilChanged()
    ).subscribe(data => {
      if (data) {
        this.handleAQGraph();
      }
    });
    this.subscriptions.push(aqChart);

  }

  handleAQGraph() {
    const data =
      this.store.pipe(
        select(selectIntermediate),
        map((data: IntermediateState) => data.chartItems),
        first()
      )
    .subscribe( creationData => {
      if (creationData.includes('aqChart')) {
        this.showPlaceholder$ = of(true);
        this.createAQGraph();
      }
    });
    this.subscriptions.push(data);
  }

  createAQGraph() {
    const aqChartConfiguration = createConfiguration({ yAxis: 'AQ [m³/(hW²)]' });
    const aqGraph = combineLatest([
      this.store.pipe(
        select(selectCalculateCoefficient),
        map((data: CalculateCoefficientState) => data)
      ),
      this.store.pipe(
        select(selectIntermediate),
        map((data: IntermediateState) => data),
      ),
    ]).pipe(first()).subscribe(([measuredData, calculatedData]) => {
      const aqMeasuredData = new Map<number, number>();
      const aqCalculatedData = new Map<number, number>();
      const aqMeasuredDataMap = new Map();
      const aqCalculatedDataMap = new Map();
      for (const entry of measuredData.allCoefficientsData.intermediateCoefficents.entries()) {
        aqMeasuredData.set(entry[0], entry[1].aq);
        aqMeasuredDataMap.set(1, aqMeasuredData);
      }
      if (calculatedData.intermediateData) {
        for (const entry of calculatedData.intermediateData.entries()) {
          aqCalculatedData.set(entry[0], entry[1].aq);
          aqCalculatedDataMap.set(-1, aqCalculatedData);
        }
        this.showPlaceholder$ = of(false);
        google.charts.load('current', { packages: ['corechart'] });
        google.charts.setOnLoadCallback(this.drawChart(aqChartConfiguration, this.chartElement.nativeElement, aqCalculatedDataMap, aqMeasuredDataMap));
      }
    });
    this.subscriptions.push(aqGraph);
  }

  drawChart(
    chartConfiguration: ChartConfiguration,
    chart: ElementRef,
    calculation: Map<number, Map<number, number>>,
    measurements: Map<number, Map<number, number>>
  ) {
    return () => {
      let columnIndex = 1;
      let rowIndex = 0;
      const colors = chartConfiguration.colours;
      const data = new google.visualization.DataTable();
      data.addColumn('number', chartConfiguration.xAxisTitle);
      const options = chartConfiguration.options;

      let head;
      ({ head, rowIndex, columnIndex } = plotData(
        calculation, data, rowIndex, chartConfiguration,
        columnIndex, options, colors, 'calculated'));
      ({ head, rowIndex, columnIndex } = plotData(
        measurements, data, rowIndex, chartConfiguration,
        columnIndex, options, colors, 'measured'));

      this.xAxisToYaxisChart = new google.visualization.LineChart(chart);
      google.visualization.events.addListener(this.xAxisToYaxisChart, 'ready', () => {
        // remove loading
      });
      this.xAxisToYaxisChart.draw(data, options);
    };
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    if(this.xAxisToYaxisChart) {
      this.xAxisToYaxisChart.clearChart();
    }
    // this.store.dispatch(setIntermediateData({ data: null }));
  }
}
