<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">
    <div class="top-wrapper">
      <button class="form-wrapper__copy-btn" mat-raised-button color="primary" type="button"
        (click)="openCopyDialog('connected-item')">COPY</button>

      <span class="form-wrapper__copy-hint" *ngIf="copiedItem">{{copiedItem}} copied</span>
    </div>
    <mat-card-header>
      <mat-card-title>
        Connected Items
      </mat-card-title>
    </mat-card-header>


    <mat-card-content>

      <div class="form-wrapper__loading" *ngIf="showLoading">
        <mat-progress-spinner mode="indeterminate" [diameter]=20></mat-progress-spinner>
      </div>

      <div class="container" novalidate>
        <form [formGroup]="connectedForm" (ngSubmit)="onSubmit(connectedForm.value)" class="form">

          <mat-form-field class="form-element">
            <mat-label>type</mat-label>
            <input matInput placeholder="type" formControlName="type">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>status</mat-label>
            <input matInput placeholder="status" formControlName="status">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>explanation</mat-label>
            <input matInput placeholder="explanation" formControlName="explanation">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>comment</mat-label>
            <input matInput placeholder="comment" formControlName="comment">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_1</mat-label>
            <input matInput placeholder="item_no_1" formControlName="item_no_1">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_2</mat-label>
            <input matInput placeholder="item_no_2" formControlName="item_no_2">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_3</mat-label>
            <input matInput placeholder="item_no_3" formControlName="item_no_3">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_4</mat-label>
            <input matInput placeholder="item_no_4" formControlName="item_no_4">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_5</mat-label>
            <input matInput placeholder="item_no_5" formControlName="item_no_5">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_1</mat-label>
            <input matInput placeholder="product_name_1" formControlName="product_name_1">
            <mat-error *ngIf="connectedForm.get('product_name_1').hasError('required')">The field name is required
            </mat-error>
            <mat-error *ngIf="connectedForm.get('product_name_1').hasError('duplicateNames')">This name is already used
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_2</mat-label>
            <input matInput placeholder="product_name_2" formControlName="product_name_2">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_3</mat-label>
            <input matInput placeholder="product_name_3" formControlName="product_name_3">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_4</mat-label>
            <input matInput placeholder="product_name_4" formControlName="product_name_4">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_5</mat-label>
            <input matInput placeholder="product_name_5" formControlName="product_name_5">
          </mat-form-field>

          <app-packaging-select formControlName="packaging"></app-packaging-select>

          <div class="form-element">
            <button mat-raised-button color="primary" type="submit" class="button"
              [disabled]="!connectedForm.valid">Submit Form</button>
          </div>

        </form>
      </div>
    </mat-card-content>
  </mat-card>
</section>
