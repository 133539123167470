import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { map, Subscription } from 'rxjs';
import { PackagingItem } from '../controllers/controller.model';
import { ConnectedProductState } from '../store/connected-product/connected-product.reducer';
import { selectConnectedProduct } from '../store/connected-product/connected-product.seletor';
import { PackagingState } from '../store/packaging/packaging.reducer';
import { selectPackaging } from '../store/packaging/packaging.selector';

@Component({
  selector: 'app-packaging-disabled',
  templateUrl: './packaging-disabled.component.html',
  styleUrls: ['./packaging-disabled.component.scss']
})
export class PackagingDisabledComponent implements OnInit, OnDestroy {

  packagingControl = new UntypedFormControl({value: '', disabled: true});
  private subscriptions: Subscription[] = [];

  packagingWidth = '';
  packagingHeight = '';
  packagingLength = '';
  packagingWeight = '';
  packagingList = [];

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {

    const getPackagingList = this.store.pipe(
      select(selectPackaging),
      map((data: PackagingState) => data.data)
    ).subscribe((data: PackagingItem[]) => {
      this.packagingList = [...data].sort((a, b) => Number(a.subType.L) - Number(b.subType.L));
    });

    this.subscriptions.push(getPackagingList);

    const packagingSubscription = this.store.pipe(
      select(selectConnectedProduct),
      map((data: ConnectedProductState) => data.packaging)
    ).subscribe(data => {
      const target = this.packagingList.find(item => item.uuid === data);
      if (target) {
        this.packagingWidth = target.subType.W;
        this.packagingHeight = target.subType.H;
        this.packagingLength = target.subType.L;
        this.packagingWeight = target.weight;
        this.packagingControl.setValue(target.name);
      }
    });
    this.subscriptions.push(packagingSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
