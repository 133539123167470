import { Action, createReducer, on } from "@ngrx/store";
import * as FlowRateActions from "./flow-rate-chart.actions";

export const flowRateFeatureKey = 'flowRateChart';

export interface FlowRateState {
  loading: Boolean;
  flowRateData: any
}

export const initialState: FlowRateState = {
  loading: false,
  flowRateData: null
}

const reducer = createReducer(
  initialState,
  on(FlowRateActions.setFlowRateChartLoading, (state, action) => {
    return {
      ...state,
      loading: action.data
    }
  }),
  on(FlowRateActions.setFlowRateChartData, (state, action) => {
    return {
      ...state,
      loading: false,
      flowRateData: action.data
    }
  })
);

export function flowRateReducer(
  state: FlowRateState,
  action: Action
): FlowRateState {
  return reducer(state, action);
}
