import { createAction, props } from "@ngrx/store";

export const setCurrentLoading = createAction(
  '[Charts Part] Load Current Chart',
  props<{ data: Boolean }>()
);

export const setCurrentCalculatedData = createAction(
  '[Charts Part] Set Current Chart Calculated Data',
  props<{ data: any }>()
);

export const setCurrentMeasuredData = createAction(
  '[Charts Part] Set Current Chart Measured Data',
  props<{ data: any }>()
);
