import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { delay, mergeMap } from 'rxjs/operators';
import { v4 } from 'uuid';
import { DisplayProductDataService } from '../display-product-data.service';
import { checkTemperature } from '../names.validator';

@Component({
  selector: 'app-vapor-pressure-new',
  templateUrl: './vapor-pressure-new.component.html',
  styleUrls: ['./vapor-pressure-new.component.scss']
})
export class VaporPressureNewComponent implements OnInit {

  vaporPressureForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private displayProductData: DisplayProductDataService,
    private dialogRef: MatDialogRef<VaporPressureNewComponent>
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.vaporPressureForm = this.fb.group({
      temperature: [null, [Validators.required, checkTemperature.bind(this)]],
      pressure: [null, Validators.required]
    });
  }

  onSubmit(result) {
    result.uuid = v4();
    this.displayProductData.getVaporPressureList()
    .pipe(
      mergeMap(allVaporPressures => {
        result.id = allVaporPressures.length;
        return this.displayProductData.createNewVaporPressure(result);
      })
    ).subscribe(item => {
      this.snackBar
      .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
      .afterDismissed()
      .pipe(delay(0))
      .subscribe(() => this.dialogRef.close(result));
    });
  }

}
