import { Component, OnInit } from '@angular/core';
import { UploadService } from '../service/upload.service';
import * as fileSaver from 'file-saver';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-upload-download-android',
  templateUrl: './upload-download-android.component.html',
  styleUrls: ['./upload-download-android.component.scss']
})
export class UploadDownloadAndroidComponent implements OnInit {

  profileForm: UntypedFormGroup;

  constructor( private uploadService: UploadService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.profileForm = this.fb.group({

    });
  }

  async downloadAndroidPumpSystems() {
    (await (this.uploadService.downloadAndroidFileFromDB())).subscribe((response: any) => {
      console.log(response);
      let blob: any = new Blob([response], { type: 'text/csv; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, 'android.csv');
    }), error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  }

}
