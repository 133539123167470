import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DisplayProductDataService } from './display-product-data.service';
import { PartsListNode } from './pump-data.model';

@Injectable({
  providedIn: 'root'
})
export class PartsListService {

  private partsListUuid: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private currentPartsList: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  dataChange = new BehaviorSubject<PartsListNode[]>([]);

  get data(): PartsListNode[] { return this.dataChange.value; }

  constructor(
    private displayProductData: DisplayProductDataService,
  ) { }

  setPartsListUuid(data: string) {
    this.partsListUuid.next(data);
  }

  getPartsListUuid(): Observable<string> {
    return this.partsListUuid.asObservable();
  }

  setCurrentPartsList(data: any) {
    this.currentPartsList.next(data);
  }

  getCurrentPartsList(): Observable<any> {
    return this.currentPartsList.asObservable();
  }

  transferRowData(data) {
    if (data === undefined) {
      return;
    }
    const mapParentToChildren = new Map(data.map(i => [JSON.stringify({ name: i.name, id: i.id }), i]));

    const parent = [...new Set(data[0].children)];
    const newPartsListData = [{
      name: data[0].name,
      id: data[0].id,
      partsNumbers: data[0].partsNumbers,
      material: data[0].material || '',
      children: []
    }];
    // tslint:disable-next-line: prefer-for-of
    for ( let i = 0; i < parent.length; i++ ) {
      const child: any = {};
      child.name = parent[i]['name'];
      child.id = parent[i]['id'];
      child.material = parent[i]['material'];
      if (mapParentToChildren.get(JSON.stringify({name: parent[i]['name'], id: parent[i]['id']}))) {
        child.partsNumbers = mapParentToChildren.get(JSON.stringify({name: parent[i]['name'], id: parent[i]['id']}))['partsNumbers'];
      }
      // tslint:disable-next-line: prefer-for-of
      for ( let j = 0; j < data.length; j++ ) {
        if ( parent[i]['name'] === data[j].name && parent[i]['id'] === data[j].id) {

          let elemntLength = data[j].children.length;
          if ( elemntLength === 0 ) {
            elemntLength = 1;
            child.children = [];
            child.partsNumbers = data[j].partsNumbers;
            child.id = data[j].id;
            child.material = data[j].material || '';
          } else {
            child.id = data[j].id;
            child.material = data[j].material;
            // tslint:disable-next-line: max-line-length
            child.children = mapParentToChildren.get(JSON.stringify({name: child.name, id: child.id}))['children'].map(c => mapParentToChildren.get(JSON.stringify({name: c['name'], id: c['id']})));
          }
        }
      }
      newPartsListData[0].children.push(child);
    }
    return newPartsListData;
  }

  updatePartsListTreeWithNumber(allData: any) {
    if ( allData ) {
      const result = this.transferRowData(allData.parts);
      this.dataChange.next(this.buildPartsListTree(result, 0));
    }
  }

  /**
   * Update the Parts list structure tree.
   */
  updatePartsListTree(uuid: string) {
    this.displayProductData.getPartsList().subscribe(result => {
      result.forEach(item => {
        if ( item.uuid === uuid ) {
          const data = this.transferRowData(item.parts);
          this.dataChange.next(this.buildPartsListTree(data, 0));
        }
      });
    });
  }

  /**
   * Upload the Parts list structure tree.
   */
  uploadPartsListTree(uploadData: PartsListNode[]) {
    if ( this.data ) {
      this.dataChange.next(this.buildPartsListTree(uploadData, 0));
      // uploadData.forEach(item => this.addPartsListNode(item));
    } else {
      this.dataChange.next(this.buildPartsListTree(uploadData, 0));
    }
  }

  /**
   * Build the Parts list structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `PartsListNode`.
   */
  buildPartsListTree(obj: object, level: number): PartsListNode[] {
    return Object.values(obj).map((value) => {

      const node = new PartsListNode();
      node.name = value.name;
      node.material = value.material || '';
      node.partsNumbers = value.partsNumbers || '1';
      node.id = value.id;
      if ( typeof value === 'object' && value.children && value.children.length !== 0 && value.children.every((x) => x !== undefined)) {
        node.children = this.buildPartsListTree(value.children, level + 1);
      } else {
        node.name = value.name;
        node.id = value.id;
        node.material = value.material || '';
        node.partsNumbers = value.partsNumbers || '1';
        node.children = [];
      }

      return node;
    });
  }

  /** Insert a node to parts list tree */
  insertPartsListNode({ parent, name, id, material, partsNumbers, children }: {
  parent: PartsListNode; name: string; id: string; material: string; partsNumbers: string; children: PartsListNode[];
}): PartsListNode {
    if (!parent.children) {
      parent.children = [];
    }
    const newItem = { name, id, material, children, partsNumbers } as PartsListNode;
    parent.children.push(newItem);
    this.dataChange.next(this.data);
    return newItem;
  }

  /** add a node to parts list tree */
  addPartsListNode(data) {
    const availableControlNode = {
      name: data.name,
      id: data.id,
      material: data.material || '',
      children: []
    } as PartsListNode;
    this.data[0]['children'].push(availableControlNode);
    this.dataChange.next(this.data);
  }

  /** When drag a node and insert current code above */
  // tslint:disable-next-line: variable-name
  // tslint:disable-next-line: max-line-length
  insertPartsListNodeAbove({ node, name, id, material, partsNumbers, children }: { node: PartsListNode; name: string; id: string; material: string; partsNumbers?: string; children: PartsListNode[]; }): PartsListNode {
    const parentNode = this.getParentFromNodes(node);
    const newItem = { name, id, material, partsNumbers, children } as PartsListNode;
    if (parentNode != null) {
      parentNode.children.splice(parentNode.children.indexOf(node), 0, newItem);
    } else {
      this.data.splice(this.data.indexOf(node), 0, newItem);
    }
    this.dataChange.next(this.data);
    return newItem;
  }

  /** When drag a node and insert current code below */
  // tslint:disable-next-line: variable-name
  insertPartsListNodeBelow({ node, name, id, material, partsNumbers, children }:
    { node: PartsListNode; name: string; id: string; material: string;
    partsNumbers?: string; children: PartsListNode[]; }): PartsListNode {
    const parentNode = this.getParentFromNodes(node);
    const newItem = { name, id, material, partsNumbers, children } as PartsListNode;
    if (parentNode != null) {
      parentNode.children.splice(parentNode.children.indexOf(node) + 1, 0, newItem);
    } else {
      this.data.splice(this.data.indexOf(node) + 1, 0, newItem);
    }
    this.dataChange.next(this.data);
    return newItem;
  }

  getParentFromNodes(node: PartsListNode): PartsListNode {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.data.length; ++i) {
      const currentRoot = this.data[i];
      const parent = this.getParent(currentRoot, node);
      if (parent != null) {
        return parent;
      }
    }
    return null;
  }

  getParent(currentRoot: PartsListNode, node: PartsListNode): PartsListNode {
    if (currentRoot.children && currentRoot.children.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < currentRoot.children.length; ++i) {
        const child = currentRoot.children[i];
        if (child === node) {
          return currentRoot;
        } else if (child.children && child.children.length > 0) {
          const parent = this.getParent(child, node);
          if (parent != null) {
            return parent;
          }
        }
      }
    }
    return null;
  }

  /** Update node in parts list tree */
  updatePartsListNode(node: PartsListNode, name: string, id: string, material: any) {
    node.name = name;
    node.id = id + '';
    node.material = material || '';
    this.dataChange.next(this.data);
  }

  /** Update number of parts list in the tree */
  updateNumberWithPartsListNode(node: PartsListNode, value: number) {
    node.partsNumbers = `${value}`;
    this.dataChange.next(this.data);
  }

  /** Delete node in parts list tree */
  deletePartsListNode(node: PartsListNode) {
    this.deleteNode(this.data, node);
    this.dataChange.next(this.data);
  }

  copyPastePartsListNode(from: PartsListNode, to: PartsListNode): PartsListNode {
    const newItem = this.insertPartsListNode({ parent: to, name: from.name, id: from.id, material: from.material,
      partsNumbers: from.partsNumbers, children: from.children });
    if (from.children) {
      from.children.forEach(child => {
        this.copyPastePartsListNode(child, newItem);
      });
    }
    return newItem;
  }

  copyPastePartsListNodeAbove(from: PartsListNode, to: PartsListNode): PartsListNode {
    const newItem = this.insertPartsListNodeAbove({
      node: to,
      name: from.name,
      id: from.id,
      material: from.material,
      partsNumbers: from.partsNumbers,
      children: from.children
    });
    if (from.children) {
      from.children.forEach(child => {
        this.copyPastePartsListNode(child, newItem);
      });
    }
    return newItem;
  }

  copyPastePartsListNodeBelow(from: PartsListNode, to: PartsListNode): PartsListNode {
    const newItem = this.insertPartsListNodeBelow({
      node: to,
      name: from.name,
      id: from.id,
      material: from.material,
      partsNumbers: from.partsNumbers,
      children: from.children
    });
    if (from.children) {
      from.children.forEach(child => {
        this.copyPastePartsListNode(child, newItem);
      });
    }
    return newItem;
  }

  deleteNode(nodes: PartsListNode[], nodeToDelete: PartsListNode) {
    const index = nodes.indexOf(nodeToDelete, 0);
    if (index > -1) {
      nodes.splice(index, 1);
    } else {
      nodes.forEach(node => {
        if (node.children && node.children.length > 0) {
          this.deleteNode(node.children, nodeToDelete);
        }
      });
    }
  }
}
