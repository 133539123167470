import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-parts-list-dialog',
  templateUrl: './parts-list-dialog.component.html',
  styleUrls: ['./parts-list-dialog.component.scss']
})
export class PartsListDialogComponent implements OnInit {

  type = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<PartsListDialogComponent>,
  ) { }

  ngOnInit() {
    this.type = this.data.type;
  }

  onConfirmClick() {
    this.dialogRef.close(true);
  }

}
