import { Action, createReducer, on } from "@ngrx/store";
import { ChartTableData, VersionTableData } from "../../../pump-simulation/coefficient.model";
import * as fromCoefficientDataActions from "./coefficient-data.actions";

export const dataFeatureKey = 'coefficienData';

export interface DataState {
  chartTable: ChartTableData,
  versionTable: VersionTableData
}

export const initialDataState: DataState = {
  chartTable: {
    isRevertDeletedData: false,
    isRevertAllDeletedData: false,
    deletedMeasurementData: [],
    measurementData: [],
    selectRowIndex: -1,
    isRedrawFlowRateChart: false
  },
  versionTable: {
    name: '',
    versionKey: 1
  }
}

const reducer = createReducer(
  initialDataState,
  on(fromCoefficientDataActions.setChartTable, (state, action) => {
    return {
      ...state,
      chartTable: {...state.chartTable, ...action.chartTable}
    }
  }),
  on(fromCoefficientDataActions.setVersionTable, (state, action) => {
    return {
      ...state,
      versionTable: {...state.versionTable, ...action.versionTable}
    }
  })
);

export function dataReducer (
  state: DataState,
  action: Action
): DataState {
  return reducer(state, action);
}
