import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { PackagingItem } from "../../controllers/controller.model";
import { DisplayProductDataService } from "../../display-product-data.service";
import * as PackagingActions from './packaging.actions';


@Injectable()

export class PackagingEffects {

  // Get all Accessory Names
  loadPackagings$ = createEffect(() => this.actions$.pipe(
    ofType(PackagingActions.loadPackagings),
    mergeMap(() => this.displayProductData.getPackagingList()
    .pipe(
      map((data: PackagingItem[]) => {
        data.map(item => item.name = `${item.subType.L} (${item.subType.H}x${item.subType.W})-${item.weight}`)
        return PackagingActions.loadPackagingsSuccess({ data });
      }),
      catchError((error) => of(PackagingActions.loadPackagingsFailure({ error })))
    ))
  ));

  constructor(
    private actions$: Actions,
    private displayProductData: DisplayProductDataService
  ){}
}
