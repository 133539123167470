import { Action, createReducer, on } from "@ngrx/store";
import * as CurrentActions from "./current-chart.actions";

export const currentFeatureKey = 'current';

export interface CurrentState {
  loading: Boolean;
  currentCalculatedData: any;
  currentMeasuredData: any
}

export const initialState: CurrentState = {
  loading: true,
  currentCalculatedData: null,
  currentMeasuredData: null
}

const reducer = createReducer(
  initialState,
  on(CurrentActions.setCurrentLoading, (state, action) => {
    return {
      ...state,
      loading: action.data
    }
  }),
  on(CurrentActions.setCurrentMeasuredData, (state, action) => {
    return {
      ...state,
      loading: !state.currentCalculatedData,
      currentMeasuredData: action.data
    }
  }),
  on(CurrentActions.setCurrentCalculatedData, (state, action) => {
    return {
      ...state,
      loading: !state.currentMeasuredData,
      currentCalculatedData: action.data
    }
  })
);

export function currentReducer(
  state: CurrentState,
  action: Action
): CurrentState {
  return reducer(state, action);
}
