<section class="form">

  <app-simulation-controller></app-simulation-controller>

  <mat-form-field class="form-item">
    <mat-label>Type</mat-label>
    <mat-select [formControl]="typeControl" (selectionChange)="setTypeChangeAction($event.value)">
      <mat-option *ngFor="let type of types" [value]="type"> {{ type }} </mat-option>
    </mat-select>
    <mat-error *ngIf="typeControl.hasError('required')">Type is Required</mat-error>
  </mat-form-field>

  <app-simulation-pump-end></app-simulation-pump-end>

  <mat-form-field class="form-item">
    <mat-label>Name appendix</mat-label>
    <input matInput placeholder="Name appendix" value={{appendix}} (input)="onInput($event.target.value)">
  </mat-form-field>

  <mat-form-field class="form-item">
    <mat-label>Coefficient set name</mat-label>
    <input disabled matInput placeholder="Coefficient set name" [value]="(createSetName$ | async)">
  </mat-form-field>
</section>