<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">
    <mat-card-header>
      <mat-card-title>
        Pole Mount: {{ polemountName }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <mat-progress-spinner *ngIf="showVersionTable" mode="indeterminate" [diameter]=20></mat-progress-spinner>

      <app-version-table *ngIf="!showVersionTable" [versionData]="versionData()"
        (showSelectedVersionData)="showSelectedVersionData($event)"
        (showSelectedVersionRadioBtn)="showSelectedVersionRadioBtn($event)"></app-version-table>

      <div class="container mat-elevation-z8" *ngIf="showPolemountForm">
        <form [formGroup]="polemountForm" class="form" (ngSubmit)="onSave()">

          <mat-form-field class="form-element">
            <mat-label>pole_mount</mat-label>
            <input matInput placeholder="pole_mount" formControlName="pole_mount">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no</mat-label>
            <input matInput placeholder="item_no" formControlName="item_no">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>price</mat-label>
            <input matInput placeholder="price" formControlName="price">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>a_mm</mat-label>
            <input matInput placeholder="a_mm" formControlName="a_mm">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>a_ft</mat-label>
            <input matInput placeholder="a_ft" formControlName="a_ft">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>b_mm</mat-label>
            <input matInput placeholder="b_mm" formControlName="b_mm">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>b_inch</mat-label>
            <input matInput placeholder="b_inch" formControlName="b_inch">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>c_mm</mat-label>
            <input matInput placeholder="c_mm" formControlName="c_mm">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>c_ft</mat-label>
            <input matInput placeholder="c_ft" formControlName="c_ft">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>modules_count</mat-label>
            <input matInput placeholder="modules_count" formControlName="modules_count">
          </mat-form-field>

          <div class="form-element">
            <button mat-raised-button color="primary" [disabled]="!isDirty" type="submit" data-testid="submit"
              class="button">Save Form</button>
          </div>

        </form>
      </div>
    </mat-card-content>
  </mat-card>
</section>