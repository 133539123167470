<mat-form-field class="form-item">
  <mat-label>Controller</mat-label>
  <input type="text" placeholder="Search controller" matInput [formControl]="controllerControl"
    [matAutocomplete]="autoController" (focus)="onFocus()" (blur)="updateController($event.target.value)"
    (input)="controllerChanges.next($event.target.value)" />
  <mat-autocomplete #autoController="matAutocomplete" (optionSelected)="setControllerChangeAction($event)">
    <mat-option *ngFor="let controller of controllerSuggestions | async; let i = index" [value]="controller">
      {{ controller }}
      <button *ngIf="i !== 0" class="navigateBtn" (click)="navigateTo(controller)" mat-stroked-button>Go to</button>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="controllerControl.hasError('required')">
    Controller is Required
  </mat-error>
  <mat-error *ngIf="controllerControl.hasError('noOption')">
    Controller not Found
  </mat-error>
</mat-form-field>