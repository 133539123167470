import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { delay, mergeMap, take } from 'rxjs/operators';
import { v4 } from 'uuid';
import { CopyButtonComponent } from '../copy-button/copy-button.component';
import { DisplayProductDataService } from '../display-product-data.service';
import { checkPoleMountName } from '../names.validator';
import { PoleMountDetail, POLE_MOUNT_ITEMS } from '../pump-data.model';

@Component({
  selector: 'app-pole-mounts-new',
  templateUrl: './pole-mounts-new.component.html',
  styleUrls: ['./pole-mounts-new.component.scss']
})
export class PoleMountsNewComponent implements OnInit {

  displayedColumns: string[] = ['version', 'status', 'live', 'preview', 'author', 'comment', 'action'];

  poleMountForm: UntypedFormGroup;
  showLoading = false;
  copiedItem = '';
  selectedRowIndex = -1;

  @Input() dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  openCopyDialog(type: string) {
    const dialogRef = this.dialog.open(CopyButtonComponent, {
      width: '1000px',
      maxWidth: '96vw',
      data: type,
      disableClose: true,
      minHeight: '60vh',
      maxHeight: '60vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.showLoading = true;
      this.copyItem(result);
    });
  }

  copyItem(poleMountUuid: string) {
    this.displayProductData.getPoleMountItem(poleMountUuid).subscribe((poleMount: PoleMountDetail) => {
      this.copiedItem = poleMount.name;
      const allData = poleMount.versions[0];
      const poleMountFormValue = {};
      POLE_MOUNT_ITEMS.forEach(name => poleMountFormValue[`${name}`] = allData[`${name}`]);
      this.poleMountForm.patchValue(poleMountFormValue);
      this.showLoading = false;
    });
  }

  createForm() {
    const poleMountFormValue: any = {};
    POLE_MOUNT_ITEMS.forEach(name => {
      poleMountFormValue[`${name}`] = [null, Validators.required];
    });
    poleMountFormValue.pole_mount = ['', [Validators.required, checkPoleMountName.bind(this)]];
    this.poleMountForm = this.fb.group(poleMountFormValue);
  }

  onSubmit(data) {
    data.uuid = v4();
    data.file = this.poleMountForm.value.pole_mount;
    data.preview = true;
    this.displayProductData.getPoleMountList()
      .pipe(
        take(1),
        mergeMap(allPoleMounts => {
          data.id = allPoleMounts.length;
          return this.displayProductData.createPoleMountItem(data);
        })
      ).subscribe(item => {
        this.snackBar
          .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
          .afterDismissed()
          .pipe(delay(0))
          .subscribe(() => {
            const url = `${data.uuid}/1/preview`;
            this.displayProductData.reloadMotorData();
            this.displayProductData.setPoleMountState(url, data).subscribe(item => {
              this.router.navigate(['/data/pole-mount', data.uuid]);
            });
          });
      });
  }

}
