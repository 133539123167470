import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { delay, mergeMap, take } from 'rxjs/operators';
import { v4 } from 'uuid';
import { CityNewComponent } from '../city-new/city-new.component';
import { DisplayProductDataService } from '../display-product-data.service';
import { checkCountryName, checkCapitalName } from '../names.validator';

@Component({
  selector: 'app-country-new',
  templateUrl: './country-new.component.html',
  styleUrls: ['./country-new.component.scss']
})
export class CountryNewComponent implements OnInit {

  countryForm: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private displayProductData: DisplayProductDataService,
    private dialogRef: MatDialogRef<CityNewComponent>
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.countryForm = this.fb.group({
      name: ['', [Validators.required, checkCountryName.bind(this)]],
      capital: ['', [Validators.required, checkCapitalName.bind(this)]],
      water_temp: [null, Validators.required],
    });
  }

  onSubmit(result) {
    result.uuid = v4();
    this.displayProductData.getCountryList()
    .pipe(
      take(1),
      mergeMap(allCountries => {
        result.id = allCountries.length;
        return this.displayProductData.createNewCountry(result);
      })
    ).subscribe(item => {
      this.snackBar
      .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
      .afterDismissed()
      .pipe(delay(0))
      .subscribe(() => {
        this.displayProductData.reloadCountryData();
        this.dialogRef.close(result);
      });
    });
  }

}
