<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">
    <mat-card-header>
      <mat-card-title>
        Accessory: {{ accessoryName }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <mat-progress-spinner *ngIf="showVersionTable" mode="indeterminate" [diameter]=20></mat-progress-spinner>

      <app-version-table *ngIf="!showVersionTable" [versionData]="versionData()"
        (showSelectedVersionData)="showSelectedVersionData($event)"
        (showSelectedVersionRadioBtn)="showSelectedVersionRadioBtn($event)"></app-version-table>

      <div class="container mat-elevation-z8" novalidate *ngIf="showAccessoryForm">
        <form [formGroup]="accessoryForm" class="form" (ngSubmit)="onSave()">

          <mat-form-field class="form-element">
            <mat-label>type</mat-label>
            <input matInput placeholder="type" formControlName="type">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>status</mat-label>
            <input matInput placeholder="status" formControlName="status">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>comment</mat-label>
            <input matInput placeholder="comment" formControlName="comment">
          </mat-form-field>

          <app-packaging-select formControlName="packaging"></app-packaging-select>

          <mat-form-field class="form-element">
            <mat-label>item_no_1</mat-label>
            <input matInput placeholder="item_no_1" formControlName="item_no_1">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_2</mat-label>
            <input matInput placeholder="item_no_2" formControlName="item_no_2">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_3</mat-label>
            <input matInput placeholder="item_no_3" formControlName="item_no_3">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_4</mat-label>
            <input matInput placeholder="item_no_4" formControlName="item_no_4">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_5</mat-label>
            <input matInput placeholder="item_no_5" formControlName="item_no_5">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_6</mat-label>
            <input matInput placeholder="item_no_6" formControlName="item_no_6">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_7</mat-label>
            <input matInput placeholder="item_no_7" formControlName="item_no_7">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_8</mat-label>
            <input matInput placeholder="item_no_8" formControlName="item_no_8">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_9</mat-label>
            <input matInput placeholder="item_no_9" formControlName="item_no_9">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_10</mat-label>
            <input matInput placeholder="item_no_10" formControlName="item_no_10">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_1</mat-label>
            <input matInput placeholder="product_name_1" formControlName="product_name_1">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_2</mat-label>
            <input matInput placeholder="product_name_2" formControlName="product_name_2">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_3</mat-label>
            <input matInput placeholder="product_name_3" formControlName="product_name_3">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_4</mat-label>
            <input matInput placeholder="product_name_4" formControlName="product_name_4">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_5</mat-label>
            <input matInput placeholder="product_name_5" formControlName="product_name_5">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_6</mat-label>
            <input matInput placeholder="product_name_6" formControlName="product_name_6">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_7</mat-label>
            <input matInput placeholder="product_name_7" formControlName="product_name_7">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_8</mat-label>
            <input matInput placeholder="product_name_8" formControlName="product_name_8">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_9</mat-label>
            <input matInput placeholder="product_name_9" formControlName="product_name_9">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_10</mat-label>
            <input matInput placeholder="product_name_10" formControlName="product_name_10">
          </mat-form-field>

          <div class="form-element">
            <button mat-raised-button color="primary" type="submit" class="button" data-testid="submit"
              [disabled]="!isDirty">Save Form</button>
          </div>

        </form>
      </div>
    </mat-card-content>
  </mat-card>
</section>