import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, map, Subscription } from 'rxjs';
import { setPumpUnitValue } from 'src/app/display-product-data/store/pump-system/pump-system.actions';
import { setPumpUnitItem, setPumpUnitMaxTemp, setPumpUnitSuctionHead } from 'src/app/display-product-data/store/pump-unit/pump-unit.actions';
import { PumpUnitState } from 'src/app/display-product-data/store/pump-unit/pump-unit.reducer';
import { selectPumpUnit } from 'src/app/display-product-data/store/pump-unit/pump-unit.seletor';

@Component({
  selector: 'app-pump-unit-select',
  templateUrl: './pump-unit-select.component.html',
  styleUrls: ['./pump-unit-select.component.scss']
})
export class PumpUnitSelectComponent implements OnInit, OnDestroy {

  private pumpUnitChange: Subscription;
  item = '';
  maxTemp = '';
  suctionHead = '';

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.pumpUnitChange = combineLatest([
      this.store.pipe(
        select(selectPumpUnit),
        map((data: PumpUnitState) => data.item)
      ),
      this.store.pipe(
        select(selectPumpUnit),
        map((data: PumpUnitState) => data.maxTemp)
      ),
      this.store.pipe(
        select(selectPumpUnit),
        map((data: PumpUnitState) => data.suctionHead)
      )
    ]).subscribe((data) => {
      this.item = `${data[0]}`;
      this.maxTemp = `${data[1]}`;
      this.suctionHead = `${data[2]}`;
    });
  }

  onInput(value: string, type: string) {
    switch(type) {
      case 'item':
        this.store.dispatch(setPumpUnitItem({ data: value }));
        this.store.dispatch(setPumpUnitValue({ data: { item: value } }));
        break;
      case 'maxTemp':
        this.store.dispatch(setPumpUnitMaxTemp({ data: value }));
        this.store.dispatch(setPumpUnitValue({ data: { maxTemp: value } }));
        break;
      default:
        this.store.dispatch(setPumpUnitSuctionHead({ data: value }));
        this.store.dispatch(setPumpUnitValue({ data: { suctionHead: value } }));
    }
  }

  ngOnDestroy() {
    if (this.pumpUnitChange) {
      this.pumpUnitChange.unsubscribe();
    }
  }
}
