<section class="main">

  <mat-card appearance="outlined">

    <mat-card-content>


      <div class="main-wrapper">
        <!-- commented out to prevent unwanted accessory uploads, leave in if necessary for later uploads
            <app-upload-download-accessories></app-upload-download-accessories>
          -->
        <div class="part-left">
          <app-upload-download-ps2></app-upload-download-ps2>
          <app-upload-download-psk3></app-upload-download-psk3>
          <app-upload-download-xylem-psk3></app-upload-download-xylem-psk3>

        </div>


      <div class="part-right">

        <app-upload-download-pumpsystems></app-upload-download-pumpsystems>

        <app-upload-download-android></app-upload-download-android>

      </div>
    </div>


      <div class="form-wrapper__table-loading" *ngIf="showFileContentLoading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>

      <div class="mat-elevation-z8" [ngClass]="showFileContentTable ? 'showContent' : ''">
        <div class="form-wrapper__table-wapper">
          <table mat-table *ngIf="dataSource" [dataSource]="dataSource">
            <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
              <th mat-header-cell *matHeaderCellDef> {{column}} </th>
              <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
      </div>
      <div *ngIf="uploadFileVersion" class="upload-version"> File version: {{ uploadFileVersion }} </div>
      <div *ngIf="uploadFileContent" class="upload-content"> {{ uploadFileContent }} </div>
    </mat-card-content>
  </mat-card>






</section>
