<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">
    <div class="top-wrapper">
    <button class="form-wrapper__copy-btn" mat-raised-button color="primary" type="button"
      (click)="openCopyDialog('accessory')">COPY</button>

    <span class="form-wrapper__copy-hint" *ngIf="copiedItem">{{copiedItem}} copied</span>
    </div>
    <mat-card-header>
      <mat-card-title>
        Accessory
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="form-wrapper__loading" *ngIf="showLoading">
        <mat-progress-spinner mode="indeterminate" [diameter]=20></mat-progress-spinner>
      </div>

      <div class="container" novalidate>
        <form [formGroup]="accessoryForm" (ngSubmit)="onSubmit(accessoryForm.value)" class="form">

          <mat-form-field class="form-element">
            <mat-label>name</mat-label>
            <input matInput placeholder="name" data-testid="name" formControlName="name">
            <mat-error *ngIf="accessoryForm.get('name').hasError('required')">The field name is required</mat-error>
            <mat-error *ngIf="accessoryForm.get('name').hasError('duplicateNames')">This name is already used
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>type</mat-label>
            <input matInput placeholder="type" data-testid="type" formControlName="type">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>status</mat-label>
            <input matInput placeholder="status" data-testid="status" formControlName="status">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>comment</mat-label>
            <input matInput placeholder="comment" data-testid="comment" formControlName="comment">
          </mat-form-field>

          <app-packaging-select data-testid="packaging" formControlName="packaging"></app-packaging-select>

          <mat-form-field class="form-element">
            <mat-label>item_no_1</mat-label>
            <input matInput placeholder="item_no_1" data-testid="item_no_1" formControlName="item_no_1">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_2</mat-label>
            <input matInput placeholder="item_no_2" data-testid="item_no_2" formControlName="item_no_2">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_3</mat-label>
            <input matInput placeholder="item_no_3" data-testid="item_no_3" formControlName="item_no_3">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_4</mat-label>
            <input matInput placeholder="item_no_4" data-testid="item_no_4" formControlName="item_no_4">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_5</mat-label>
            <input matInput placeholder="item_no_5" data-testid="item_no_5" formControlName="item_no_5">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_6</mat-label>
            <input matInput placeholder="item_no_6" data-testid="item_no_6" formControlName="item_no_6">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_7</mat-label>
            <input matInput placeholder="item_no_7" data-testid="item_no_7" formControlName="item_no_7">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_8</mat-label>
            <input matInput placeholder="item_no_8" data-testid="item_no_8" formControlName="item_no_8">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_9</mat-label>
            <input matInput placeholder="item_no_9" data-testid="item_no_9" formControlName="item_no_9">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>item_no_10</mat-label>
            <input matInput placeholder="item_no_10" data-testid="item_no_10" formControlName="item_no_10">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_1</mat-label>
            <input matInput placeholder="product_name_1" data-testid="product_name_1" formControlName="product_name_1">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_2</mat-label>
            <input matInput placeholder="product_name_2" data-testid="product_name_2" formControlName="product_name_2">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_3</mat-label>
            <input matInput placeholder="product_name_3" data-testid="product_name_3" formControlName="product_name_3">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_4</mat-label>
            <input matInput placeholder="product_name_4" data-testid="product_name_4" formControlName="product_name_4">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_5</mat-label>
            <input matInput placeholder="product_name_5" data-testid="product_name_5" formControlName="product_name_5">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_6</mat-label>
            <input matInput placeholder="product_name_6" data-testid="product_name_6" formControlName="product_name_6">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_7</mat-label>
            <input matInput placeholder="product_name_7" data-testid="product_name_7" formControlName="product_name_7">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_8</mat-label>
            <input matInput placeholder="product_name_8" data-testid="product_name_8" formControlName="product_name_8">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_9</mat-label>
            <input matInput placeholder="product_name_9" data-testid="product_name_9" formControlName="product_name_9">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>product_name_10</mat-label>
            <input matInput placeholder="product_name_10" data-testid="product_name_10"
              formControlName="product_name_10">
          </mat-form-field>

          <div class="form-element">
            <button mat-raised-button color="primary" type="submit" class="button" data-testid="submit"
              [disabled]="!accessoryForm.valid">Submit Form</button>
          </div>

        </form>
      </div>

    </mat-card-content>
  </mat-card>
</section>
