<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">
    <div class="top-wrapper">
      <button class="form-wrapper__copy-btn" mat-raised-button color="primary" type="button"
        (click)="openCopyDialog('pump-end')">COPY</button>

      <span class="form-wrapper__copy-hint" *ngIf="copiedItem">{{copiedItem}} copied</span>
    </div>
    <mat-card-header>
      <mat-card-title i18n="@@pumpEndrAttributes">
        Pump ends attributes
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <div class="form-wrapper__loading" *ngIf="showLoading">
        <mat-progress-spinner mode="indeterminate" [diameter]=20></mat-progress-spinner>
      </div>

      <form [formGroup]="pumpEndForm" class="form">
        <mat-form-field class="form-element">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Name" data-testid="name" formControlName="name">
          <mat-error *ngIf="pumpEndForm.get('name').hasError('required')">The field name is required</mat-error>
          <mat-error *ngIf="pumpEndForm.get('name').hasError('duplicateNames')">This name is already used</mat-error>
        </mat-form-field>

        <mat-form-field class="form-element">
          <mat-label>Item</mat-label>
          <input matInput placeholder="Item" data-testid="item" formControlName="item">
        </mat-form-field>

        <app-material-select data-testid="material" formControlName="material"></app-material-select>

        <app-packaging-select data-testid="packaging" formControlName="packaging"></app-packaging-select>

        <mat-form-field class="form-element">
          <mat-label>Net Weight</mat-label>
          <input matInput type="number" placeholder="Net Weight" data-testid="net_weight" formControlName="net_weight">
          <span matSuffix>kg</span>
        </mat-form-field>

        <mat-form-field class="form-element">
          <mat-label>length</mat-label>
          <input matInput type="number" placeholder="length" data-testid="length" formControlName="length">
          <span matSuffix>mm</span>
        </mat-form-field>

        <mat-form-field class="form-element">
          <mat-label>diameter</mat-label>
          <input matInput type="number" min="0" placeholder="diameter" data-testid="diameter"
            formControlName="diameter">
          <span matSuffix>mm</span>
        </mat-form-field>

        <div formGroupName="diams">
          <mat-form-field class="form-element">
            <mat-label>dim D</mat-label>
            <input matInput type="number" min="0" placeholder="diam D" data-testid="diamD" formControlName="diamD">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim E</mat-label>
            <input matInput type="number" min="0" placeholder="diam E" data-testid="diamE" formControlName="diamE">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim F</mat-label>
            <input matInput type="number" min="0" placeholder="diam F" data-testid="diamF" formControlName="diamF">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim G</mat-label>
            <input matInput type="number" min="0" placeholder="diam G" data-testid="diamG" formControlName="diamG">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim H</mat-label>
            <input matInput type="number" min="0" placeholder="diam H" data-testid="diamH" formControlName="diamH">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim I</mat-label>
            <input matInput type="number" min="0" placeholder="diam I" data-testid="diamI" formControlName="diamI">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim J</mat-label>
            <input matInput type="number" min="0" placeholder="diam J" data-testid="diamJ" formControlName="diamJ">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim K</mat-label>
            <input matInput type="number" min="0" placeholder="diam K" data-testid="diamK" formControlName="diamK">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim L</mat-label>
            <input matInput type="number" min="0" placeholder="diam L" data-testid="diamL" formControlName="diamL">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim M</mat-label>
            <input matInput type="number" min="0" placeholder="diam M" data-testid="diamM" formControlName="diamM">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim N</mat-label>
            <input matInput type="number" min="0" placeholder="diam N" data-testid="diamN" formControlName="diamN">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim O</mat-label>
            <input matInput type="number" min="0" placeholder="diam O" data-testid="diamO" formControlName="diamO">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim P</mat-label>
            <input matInput type="number" min="0" placeholder="diam P" data-testid="diamP" formControlName="diamP">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim Q</mat-label>
            <input matInput type="number" min="0" placeholder="diam Q" data-testid="diamQ" formControlName="diamQ">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim R</mat-label>
            <input matInput type="number" min="0" placeholder="diam R" data-testid="diamR" formControlName="diamR">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim S</mat-label>
            <input matInput type="number" min="0" placeholder="diam S" data-testid="diamS" formControlName="diamS">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim T</mat-label>
            <input matInput type="number" min="0" placeholder="diam T" data-testid="diamT" formControlName="diamT">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim U</mat-label>
            <input matInput type="number" min="0" placeholder="diam U" data-testid="diamU" formControlName="diamU">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim V</mat-label>
            <input matInput type="number" min="0" placeholder="diam V" data-testid="diamV" formControlName="diamV">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim W</mat-label>
            <input matInput type="number" min="0" placeholder="diam W" data-testid="diamW" formControlName="diamW">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dim X</mat-label>
            <input matInput type="number" min="0" placeholder="diam X" data-testid="diamX" formControlName="diamX">
            <span matSuffix>mm</span>
          </mat-form-field>
        </div>

      </form>

      <mat-expansion-panel class="form">
        <mat-expansion-panel-header>
          <mat-panel-title>
            parts list
          </mat-panel-title>
          <mat-panel-description>
            {{ partslistName }}
          </mat-panel-description>
        </mat-expansion-panel-header>

        <app-parts-list-name></app-parts-list-name>
        <app-parts-list-picture></app-parts-list-picture>
        <div *ngIf="showPartslistTree">
          <app-parts-list-tree (updatePartslistTree)="updatePartslistTree($event)"></app-parts-list-tree>
        </div>
      </mat-expansion-panel>

      <div class="form">
        <button mat-raised-button color="primary" data-testid="submit" (click)="createNewPumpEnd(pumpEndForm.value)"
          class="button" [disabled]="!pumpEndForm.valid">Submit Form</button>
      </div>

    </mat-card-content>
  </mat-card>
</section>
