import { createFeatureSelector, createSelector } from "@ngrx/store";
import { uploadDownloadFeatureKey } from "./upload-download.reducer";

export const selectUploadDownloadState = createFeatureSelector(uploadDownloadFeatureKey);

export const selectUploadDownloadData = createSelector(
  selectUploadDownloadState,
  (state: any) => state
);

