import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';
import { map, mergeMap } from 'rxjs/operators';


/**
 * AuthInterceptor adds the accessToken to http requests.
 *
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.includes('/login') || req.url.includes('//catalog-data-images')) {
      // no authentication header on login requests
      return next.handle(req);
    }

    return this.loginService.accessToken().pipe(
      map(accessToken => req.clone({ setHeaders: { 'Authorization': 'Bearer ' + accessToken } })),
      mergeMap(authReq => next.handle(authReq))
    );
  }
}
