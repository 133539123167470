import { Component, OnInit, Input, OnDestroy, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { delay, filter, map, mergeMap } from 'rxjs/operators';
import { CalculateDialogComponent } from 'src/app/pump-simulation/calculate-dialog/calculate-dialog.component';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { removeRadioBtnFocus } from 'src/app/shared/util';
import { PackagingItem } from '../controllers/controller.model';
import { DirtyComponent } from '../dirty-component';
import { DisplayProductDataService } from '../display-product-data.service';
import { ErrorButtonComponent } from '../error-button/error-button.component';
import { CONNECTED_ITEMS } from '../pump-data.model';
import { SaveButtonComponent } from '../save-button/save-button.component';
import { PackagingState } from '../store/packaging/packaging.reducer';
import { selectPackaging } from '../store/packaging/packaging.selector';
import { UpdateButtonComponent } from '../update-button/update-button.component';

@Component({
  selector: 'app-connected-items',
  templateUrl: './connected-items.component.html',
  styleUrls: ['./connected-items.component.scss']
})
export class ConnectedItemsComponent implements OnInit, DirtyComponent, OnDestroy {

  private subscriptions: Subscription[] = [];
  private formValueChange: Subscription;
  @Input() dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['approveForUse', 'version', 'status', 'published', 'author', 'comment', 'action'];
  userLocalStorage = new UserLocalStorage();
  selectedRowIndex = -1;
  connectedName = '';
  showConnectedForm = false;
  isDirty = false;
  showVersionTable = true;
  tableLoading = true;
  onLoading = false;

  originalConnectedFormValue: any;
  connectedProductUuid = '';
  connectedData = [];
  connectedForm: UntypedFormGroup;
  lastVersion: number;

  packagingList = [];

  constructor(
    private store: Store,
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private displayProductData: DisplayProductDataService
  ) { }


  ngOnInit() {

    const getPackagingList = this.store.pipe(
      select(selectPackaging),
      map((data: PackagingState) => data.data)
    ).subscribe((data: PackagingItem[]) => this.packagingList = [...data].sort((a, b) => Number(a.subType.L) - Number(b.subType.L)));

    this.subscriptions.push(getPackagingList);

    const urlSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.showVersionTable = true;
      this.connectedName = '';
      this.showConnectedForm = false;
      this.selectedRowIndex = -1;
      this.isDirty = false;
    });
    this.subscriptions.push(urlSubscription);


    const dataSubscription = this.route.paramMap.pipe(
      map(params => params.get('uuid')),
      mergeMap(uuid => {
        this.connectedProductUuid = uuid;
        return this.displayProductData.getConnectedProductItem(uuid);
      })
    ).subscribe((connectedData) => {
      this.connectedName = connectedData.name;
      this.dataSource.data = connectedData.versions;
      this.connectedData = connectedData.versions;
      this.showVersionTable = false;
      this.lastVersion = this.connectedData.length;
    });

    this.subscriptions.push(dataSubscription);

    this.initConnectedForm();
  }

  versionData() {
    return this.connectedData;
  }

  initConnectedForm() {
    const data: any = {};
    CONNECTED_ITEMS.forEach(name => {
      data[`${name}`] = [null, Validators.required];
    });
    this.connectedForm = this.fb.group(data);
  }

  showSelectedVersionData(version: string) {
    if (this.formValueChange) {
      this.formValueChange.unsubscribe();
    }

    if (!this.isDirty) {
      // the form value not changed
      this.connectedFormValueChange(version);
    } else {
      const dialogRef = this.dialog.open(UpdateButtonComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isDirty = false;
          this.connectedFormValueChange(version);
        }
      });
    }

  }

  connectedFormValueChange(version) {
    this.showConnectedForm = true;
    this.selectedRowIndex = Number(version);

    for (const item of this.connectedData) {
      if (item.version_number === Number(version)) {
        const data = {};
        CONNECTED_ITEMS.forEach(name => data[`${name}`] = item[`${name}`]);
        this.connectedForm.patchValue(data);
        this.originalConnectedFormValue = this.connectedForm.value;
        const packagingValue = this.packagingList.find(item => item.uuid === this.connectedForm.value.packaging.uuid);
        this.originalConnectedFormValue.packaging = packagingValue.name;
      }
    }

    this.formValueChange = this.connectedForm.valueChanges.subscribe((value) => {

      if ( this.connectedForm.get('packaging').value.uuid ) {
        const result = this.packagingList.filter(item => item.uuid === this.connectedForm.get('packaging').value.uuid);
        if ( result.length > 0 ) {
          value.packaging = result[0].name;
        } else {
          value.packaging = '';
        }
      } else {
        const result = this.packagingList.filter(item => item.name.includes(this.connectedForm.get('packaging').value));
        if (result.length !== 0) {
          value.packaging = result[0].name;
        }
      }

      this.isDirty = JSON.stringify(this.originalConnectedFormValue) !== JSON.stringify(value);
    });

    this.subscriptions.push(this.formValueChange);
  }

  showSelectedVersionRadioBtn(version: number) {
    const versionNumber = version + 1;
    if (this.showConnectedForm && (versionNumber === this.selectedRowIndex)) {
      const dialogRef = this.dialog.open(CalculateDialogComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
        data: {
          message: 'Are you sure you want to publish this version?',
          type: 'confirm'
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const data = this.connectedData[version];
          data.version = this.connectedData[version].version_number;
          const url = `${this.connectedProductUuid}/${versionNumber}/live`;
          this.displayProductData.setConnectedProductState(url, data).subscribe(item => {
            this.snackBar
              .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
              .afterDismissed()
              .pipe(delay(0))
              .subscribe(() => {
                this.ngOnInit();
                this.showVersionTable = true;
                this.connectedName = '';
                this.showConnectedForm = false;
                this.selectedRowIndex = -1;
                this.isDirty = false;
              });
          });
        } else {
          removeRadioBtnFocus(this.elementRef, version);
        }
      });
    } else {
      const dialogRef = this.dialog.open(CalculateDialogComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
        data: {
          message: 'You have to load a version before publishing it.',
          type: 'hint'
        }
      });

      dialogRef.afterClosed().subscribe(() => removeRadioBtnFocus(this.elementRef, version));
    }

  }

  onSave() {
    const dialogRef = this.dialog.open(SaveButtonComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onLoading = true;
        this.updateConnectedProductItem();
      }
    });
  }

  updateConnectedProductItem() {
    const user = this.userLocalStorage.loadUser();
    const data = this.connectedForm.value;
    data.author = user.user.username;
    data.uuid = this.connectedProductUuid;
    data.file = this.connectedName || this.connectedForm.value.product_name_1;

    if (this.connectedForm.value.packaging.subType) {
      const packagingValue: any = {};
      packagingValue.name = data.packaging.uuid;
      packagingValue.w = data.packaging.subType.W;
      packagingValue.h = data.packaging.subType.H;
      packagingValue.l = data.packaging.subType.L;
      packagingValue.pack_weight = data.packaging.weight;
      data.packaging = packagingValue;
    } else {
      const result = this.packagingList.filter(item => item.name.includes(data.packaging));
      const packaging: any = {};
      packaging.name = result[0].uuid;
      packaging.w = `${result[0].subType.W}`;
      packaging.h = `${result[0].subType.H}`;
      packaging.l = `${result[0].subType.L}`;
      packaging.pack_weight = `${result[0].weight}`;
      data.packaging = packaging;
    }

    this.displayProductData.updateConnectedProductItem(data).subscribe(() => {
      const url = `${data.uuid}/${this.lastVersion}/preview`;
      this.displayProductData.setConnectedProductState(url, data).subscribe(
        item => {
          this.snackBar
            .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
            .afterDismissed()
            .pipe(delay(0))
            .subscribe(() => {
              this.ngOnInit();
              this.showVersionTable = true;
              this.connectedName = '';
              this.showConnectedForm = false;
              this.selectedRowIndex = -1;
              this.isDirty = false;
            });
        },
        error => {
          this.dialog.open(ErrorButtonComponent, {
            width: '600px',
            data: JSON.parse(error.error),
            minHeight: '20vh',
          });
        },
        () => {}
      );
    });
  }

  canDeactivate() {
    return this.isDirty;
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

}

