import { Action, createReducer, on } from "@ngrx/store";
import * as SimulationSettingActions from "./simulation-setting.actions";

export const simulationSettingFeatureKey = 'simulationSetting';

export interface SimulationSettingState {
  splitPower: Number;
  minLift: Number;
  maxLift: Number;
  excelBehaviour: Boolean;
}

export const initialState: SimulationSettingState = {
  splitPower: 0,
  minLift: 0,
  maxLift: 0,
  excelBehaviour: false
}

const reducer = createReducer(
  initialState,
  on(SimulationSettingActions.setSimulationSplitPower, (state, action) => {
    return {
      ...state,
      splitPower: action.data
    }
  }),
  on(SimulationSettingActions.setSimulationMinLift, (state, action) => {
    return {
      ...state,
      minLift: action.data
    }
  }),
  on(SimulationSettingActions.setSimulationMaxLift, (state, action) => {
    return {
      ...state,
      maxLift: action.data
    }
  }),
  on(SimulationSettingActions.setSimulationExcelBehaviour, (state, action) => {
    return {
      ...state,
      excelBehaviour: action.data
    }
  })
);

export function simulationSettingReducer(
  state: SimulationSettingState,
  action: Action
): SimulationSettingState {
  return reducer(state, action);
}
