<mat-dialog-content>
	<p *ngIf="message"> {{message}} </p>

	<form *ngIf="type !== 'hint'" [formGroup]="eventForm" class="form">

  </form>
</mat-dialog-content>
<mat-dialog-actions>
	<button *ngIf="type !== 'hint'" mat-raised-button mat-dialog-close> Cancel </button>
  <button *ngIf="type === 'switch' || type === 'hint'" mat-raised-button (click)="onConfirmClick()"> Confirm </button>
  <button *ngIf="type === 'confirm'" mat-raised-button color="primary" [mat-dialog-close]="eventForm.getRawValue()"> Confirm </button>
</mat-dialog-actions>

