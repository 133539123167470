<section class="content">
  <mat-form-field>
    <mat-label>Select Coefficients Set</mat-label>
    <input type="text" placeholder="Search Coefficients Set name" matInput
      [formControl]="coefficientsSetControl" [matAutocomplete]="autoCoefficients" (focus)="onFocus()"
      (blur)="updateCoefficients($event.target.value)" (input)="coefficientsSetChanges.next($event.target.value)" />
    <mat-autocomplete #autoCoefficients="matAutocomplete" (optionSelected)="setCoefficientsChangeAction($event)">
      <mat-option *ngFor="let option of coefficientsSetSuggestions | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="coefficientsSetControl.hasError('noOption')">
      Coefficients Set not Found
    </mat-error>
    <mat-error *ngIf="coefficientsSetControl.hasError('required')">
      Coefficients Set is required
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Coefficient set name</mat-label>
    <input disabled matInput placeholder="Coefficient set name" [value]="(coefficientSetName$ | async)">
  </mat-form-field>
</section>