<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Controller
    </mat-panel-title>
    <mat-panel-description>
      {{ controllerName }}
    </mat-panel-description>
  </mat-expansion-panel-header>

  <mat-form-field class="form-element" appearance="fill">
    <mat-label>Select controller</mat-label>
    <input type="text" placeholder="Search controller" matInput [formControl]="controllerControl"
      [matAutocomplete]="controllerAuto" (focus)="onControllerInputFocus()"
      (blur)="updateController($event.target.value)" (input)="controllerChanges.next($event.target.value)" />
    <mat-autocomplete #controllerAuto="matAutocomplete" (optionSelected)="setControllerChangeAction($event)">
      <mat-option *ngFor="let option of controllerSuggestions | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="controllerControl.hasError('required')">The field name is required</mat-error>
    <mat-error *ngIf="controllerControl.hasError('noOption')">Controller not Found</mat-error>
  </mat-form-field>

  <div class="container inner-item" *ngIf="showControllerForm" novalidate>
    <form *ngIf="controller | async; else controllerLoading" [formGroup]="controllerForm">
      <mat-form-field class="form-element">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="name">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>Item</mat-label>
        <input matInput placeholder="Item" formControlName="item">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>Net Weight</mat-label>
        <input matInput placeholder="Net Weight" formControlName="net_weight">
        <span matSuffix>kg</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>h</mat-label>
        <input matInput placeholder="h" formControlName="h">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>h1</mat-label>
        <input matInput placeholder="h1" formControlName="h1">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>h2</mat-label>
        <input matInput placeholder="h2" formControlName="h2">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>w</mat-label>
        <input matInput placeholder="w" formControlName="w">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>w1</mat-label>
        <input matInput placeholder="w1" formControlName="w1">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>w2</mat-label>
        <input matInput placeholder="w2" formControlName="w2">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>w3</mat-label>
        <input matInput placeholder="w3" formControlName="w3">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>d</mat-label>
        <input matInput placeholder="d" formControlName="d">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>d1</mat-label>
        <input matInput placeholder="d1" formControlName="d1">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>d2</mat-label>
        <input matInput placeholder="d2" formControlName="d2">
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>drawing</mat-label>
        <input matInput placeholder="drawing" formControlName="picture">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>economical_value_for_sort_order</mat-label>
        <input matInput placeholder="economical_value_for_sort_order" formControlName="economical_value_for_sort_order">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>min temp</mat-label>
        <input matInput placeholder="min temp" formControlName="min_temp">
        <span matSuffix>°C</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>max temp</mat-label>
        <input matInput placeholder="max temp" formControlName="max_temp">
        <span matSuffix>°C</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>enclosure class</mat-label>
        <input matInput placeholder="enclosure class" formControlName="ingress_protection_code">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>efficiency</mat-label>
        <input matInput placeholder="efficiency" formControlName="efficiency">
        <span matSuffix>%</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>Max Voc</mat-label>
        <input matInput placeholder="Max Voc" formControlName="max_voc">
        <span matSuffix>V</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>Max I</mat-label>
        <input matInput placeholder="Max I" formControlName="max_i">
        <span matSuffix>A</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>Min Cable Size</mat-label>
        <input matInput placeholder="Min Cable Size" formControlName="min_cable_size">
        <span matSuffix>m&#x33A1;</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>Max P</mat-label>
        <input matInput placeholder="Max P" formControlName="max_p">
        <span matSuffix>W</span>
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>U Factor</mat-label>
        <input matInput placeholder="U Factor" formControlName="u_factor">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>I Factor</mat-label>
        <input matInput placeholder="I Factor" formControlName="i_factor">
      </mat-form-field>

      <mat-form-field class="form-element">
        <mat-label>I Offset</mat-label>
        <input matInput placeholder="I Offset" formControlName="i_offset">
      </mat-form-field>
    </form>
    <ng-template #controllerLoading>
      <mat-progress-spinner mode="indeterminate" [diameter]=20></mat-progress-spinner>
    </ng-template>
  </div>
</mat-expansion-panel>