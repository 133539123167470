import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { delay, mergeMap, take } from 'rxjs/operators';
import { UserLocalStorage } from 'src/app/login/user-local-store';
import { v4 } from 'uuid';
import { CopyButtonComponent } from '../copy-button/copy-button.component';
import { DisplayProductDataService } from '../display-product-data.service';
import { checkModuleName } from '../names.validator';
import { ModuleDetail, MODULE_ITEMS_CREATE } from '../pump-data.model';

@Component({
  selector: 'app-module-new',
  templateUrl: './module-new.component.html',
  styleUrls: ['./module-new.component.scss']
})
export class ModuleNewComponent implements OnInit {

  moduleForm: UntypedFormGroup;

  showLoading = false;
  copiedItem = '';

  userLocalStorage = new UserLocalStorage();

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  openCopyDialog(type: string) {
    const dialogRef = this.dialog.open(CopyButtonComponent, {
      width: '1000px',
      maxWidth: '96vw',
      data: type,
      minHeight: '60vh',
      maxHeight: '60vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showLoading = true;
        this.copyItem(result);
      }
    });
  }

  copyItem(moduleUuid: string) {

    this.displayProductData.getModuleItem(moduleUuid).subscribe((module: ModuleDetail) => {
      this.showLoading = false;
      this.copiedItem = module.name;
      if (module.versions && module.versions.length > 1) {
        const moduleData = module.versions[0];
        const data = {};
        MODULE_ITEMS_CREATE.forEach(name => data[`${name}`] = moduleData[`${name}`]);
        this.moduleForm.patchValue(data);
      }
    });
  }

  createForm() {

    const data: any = {};
    MODULE_ITEMS_CREATE.forEach(name => {
      data[`${name}`] = [null, Validators.required];
    });
    data.name = ['', [Validators.required, checkModuleName.bind(this)]];

    this.moduleForm = this.fb.group(data);
  }

  onSubmit(data) {
    const user = this.userLocalStorage.loadUser();
    data.author = user.user.username;
    data.file = data.name;
    data.uuid = v4();
    data.preview = true;
    this.displayProductData.getModuleList()
      .pipe(
        take(1),
        mergeMap(allModules => {
          data.id = allModules.length;
          return this.displayProductData.createNewModule(data);
        })
      ).subscribe(item => {
        this.snackBar
          .open(item, '', { duration: 1500, panelClass: 'hintMsg' })
          .afterDismissed()
          .pipe(delay(0))
          .subscribe(() => {
            const url = `${data.uuid}/1/preview`;
            this.displayProductData.reloadModuleData();
            this.displayProductData.setModuleState(url, data).subscribe(item => {
              this.router.navigate(['/data/module', data.uuid]);
            });
          });
      });
  }
}
