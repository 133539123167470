import { createAction, props } from "@ngrx/store";

export const setCoefficientMeasurementData = createAction(
  '[Calculate Coefficient] Set Calculate Coefficient Measurement Data',
  props<{ data: any }>()
);

export const setCoefficientConvertNPSHData = createAction(
  '[Calculate Coefficient] Set Calculate Coefficient Convert NPSH Data',
  props<{ data: any }>()
);

export const setCoefficientConvertMeasurementData = createAction(
  '[Calculate Coefficient] Set Calculate Coefficient Converted Measurement Data',
  props<{ data: any }>()
);

export const setOriginalConvertMeasurementData = createAction(
  '[Calculate Coefficient] Set Calculate Original Coefficient Converted Measurement Data',
  props<{ data: any }>()
);

export const setDeletedMeasurementItem = createAction(
  '[Calculate Coefficient] Set Calculate Table Deleted Measurement Item',
  props<{ data: any }>()
);

export const setTableHightlightRow = createAction(
  '[Calculate Coefficient] Set Calculate Table Hightligh tRow',
  props<{ data: Number }>()
);

export const setCoefficientsData = createAction(
  '[Calculate Coefficient] Set Calculate Coefficients Data',
  props<{ data: any }>()
);

export const setFilename = createAction(
  '[Calculate Coefficient] Set Filename',
  props<{ data: any }>()
);

export const setSelectedCoefficientsData = createAction(
  '[Calculate Coefficient] Set Selected Coefficients Data',
  props<{ data: any }>()
);
