import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, Subject, Observable, startWith, map } from 'rxjs';
import { DisplayProductDataService } from 'src/app/display-product-data/display-product-data.service';
import { PumpEndsItem } from 'src/app/display-product-data/pump-ends/pump-end.model';
import { setSystemFormPumpEnd } from 'src/app/pump-simulation/store/system-form/system-form.actions';
import { nameSort } from '../../util';

@Component({
  selector: 'app-simulation-pump-end',
  templateUrl: './simulation-pump-end.component.html',
  styleUrls: ['./simulation-pump-end.component.scss']
})
export class SimulationPumpEndComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  dropdownList: string[] = [];
  pumpendList: PumpEndsItem[] = [];
  pumpendControl = new UntypedFormControl('', [
    Validators.required,
    this.optionNotFound.bind(this),
  ]);
  filteredOptions: Set<string>;
  public readonly pumpendChanges: Subject<string> = new Subject<string>();
  public pumpendSuggestions: Observable<string[]>;

  constructor(
    private store: Store,
    private router: Router,
    private displayProductData: DisplayProductDataService,
  ) { }

  ngOnInit() {

    const getPumpEndsList = this.displayProductData.getPumpEndsList().subscribe(data => {
      this.pumpendList = nameSort(data);
      this.dropdownList = this.pumpendList.map(item => item.name);
      this.dropdownList.unshift('Add new pump end');
      this.pumpendSuggestions = this.pumpendChanges.pipe(
        startWith(''),
        map((val: string) => this.filterResults(val))
      );
    })
    this.subscriptions.push(getPumpEndsList);

  }

  private filterResults(val: string) {
    if (val !== '') {
      return this.dropdownList.filter(item => item.toLowerCase().includes(val.toLowerCase()));
    } else {
      return this.dropdownList;
    }
  }

  onFocus() {
    this.pumpendChanges.next('');
  }

  updatePumpend(value: string) {
    if (value === '') {
      this.optionNotFound(this.pumpendControl);
      this.store.dispatch(setSystemFormPumpEnd({ data: '' }));
    } else {
      
      const item = this.dropdownList.find(item => item === value);
      if (item) {
        this.pumpendControl.setValue(value);
      } else {
        this.optionNotFound(this.pumpendControl);
      }
    };
  }

  setPumpendChangeAction(event: MatAutocompleteSelectedEvent) {
    if ( event.option.value === 'Add new pump end' ) {
      this.router.navigate(['/data/pump-end/new']);
    } else {
      this.store.dispatch(setSystemFormPumpEnd({ data: event.option.value }));
    }
  }

  optionNotFound(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    this.filteredOptions = new Set(
      this.dropdownList.filter((option) => option.toLowerCase() === value.toLowerCase())
    );
    if (value && !this.filteredOptions.size) {
      this.store.dispatch(setSystemFormPumpEnd({ data: '' }));
      return {
        noOption: true
      };
    }
    return null;
  }

  navigateTo(value: string) {
    const item = this.pumpendList.find(item => item.name === value);
    this.router.navigate([`/data/pump-end/${item.uuid}`]);
  }
  
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
