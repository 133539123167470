import { Action, createReducer, on } from "@ngrx/store";
import { CalculationDetail, CoefficientSetItemDetail, SelectedCalculationDetail } from "../../pump-data.model";
import * as CoefficientSetActions from './coefficient-set.actions';

export const coefficientSetFeatureKey = 'coefficientSet';

export const emptyCalculationValue = {
  corr_fact: '',
  mai: '',
  maq: '',
  mar_: '',
  mbi: '',
  mbq: '',
  mbr: '',
  mci: '',
  mcq: '',
  mcr: '',
  mdq_: '',
  nai: '',
  naq: '',
  nar_: '',
  nbi: '',
  nbq: '',
  nbr: '',
  nci: '',
  ncq: '',
  ncr: '',
  ndq_: '',
  npsh_a: '',
  npsh_c: '',
  npsh_exp: '',
  split_fact: ''
};

export interface CoefficientSetState {
  data: CoefficientSetItemDetail[];
  calculation: Partial<CalculationDetail> | Partial<SelectedCalculationDetail>;
  loading: Boolean;
  showCurrentOption: Boolean;
  isCopied: Boolean;
  name: String;
}

export const initialState: CoefficientSetState = {
  data: [],
  calculation: emptyCalculationValue,
  loading: true,
  showCurrentOption: false,
  isCopied: false,
  name: ''
}

const reducer = createReducer(
  initialState,
  on(CoefficientSetActions.loadCoefficientSets, (state, action) => {
    return {
      ...state,
      loading: true
    }
  }),
  on(CoefficientSetActions.loadCoefficientSetsSuccess, (state, action) => {
    return {
      ...state,
      data: action.data,
      loading: false
    }
  }),
  on(CoefficientSetActions.loadCoefficientSetsFailure, (state, action) => {
    return {
      ...state,
      loading: false
    }
  }),
  on(CoefficientSetActions.setCalculationValue, (state, action) => {
    return {
      ...state,
      calculation: action.data
    }
  }),
  on(CoefficientSetActions.setPumpSystemCopied, (state, action) => {
    return {
      ...state,
      isCopied: action.data
    }
  }),
  on(CoefficientSetActions.setCoefficientSetValueName, (state, action) => {
    return {
      ...state,
      name: action.data
    }
  })
);

export function coefficientSetReducer (
  state: CoefficientSetState,
  action: Action
): CoefficientSetState {
  return reducer(state, action);
}
