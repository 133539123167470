import { createAction, props } from "@ngrx/store";
import { PackagingItem } from "../../controllers/controller.model";
import { ConnectedProduct, ConnectedProductDetail, ConnectedProductEvent } from "../../pump-data.model";

export const loadConnectedProducts = createAction('[Product Data] Load Connected Product');

export const loadConnectedProductsSuccess = createAction(
  '[Product Data] Load Connected Product Success',
  props<{ data: ConnectedProduct[] }>()
);

export const loadConnectedProductsFailure = createAction(
  '[Product Data] Load Connected Product Failure',
  props<{ error: any }>()
);

export const loadAllConnectedProduct = createAction('[Product Data] Load All Connected Product Item Data');

export const loadAllConnectedProductSuccess = createAction(
  '[Product Data] Load All Connected Product Item Data Success',
  props<{ data: ConnectedProductDetail[] }>()
);

export const loadAllConnectedProductFailure = createAction(
  '[Product Data] Load All Connected Product Item Data Failure',
  props<{ error: any }>()
);

export const updateConnectedProduct = createAction(
  '[Product Data] Update Connected Product Item',
  props<{ data: ConnectedProduct }>()
);

export const createConnectedProduct = createAction(
  '[Product Data] Create New Connected Product Item',
  props<{ data: ConnectedProduct }>()
);

export const setPumpSystemUuid = createAction(
  '[Product Data] Set Pump System Uuid',
  props<{ pumpSystemUuid: String }>()
);

export const setConnectedProductPackaing = createAction(
  '[Product Data] Set Connected Product Item Packaging',
  props<{ packaging: String }>()
);

export const setConnectedProductPrepareList = createAction(
  '[Product Data] Set Connected Product Item',
  props<{ data: Partial<ConnectedProductDetail>[] }>()
);

export const setConnectedProductSelectedList = createAction(
  '[Product Data] Set Connected Product Item Selected',
  props<{ data: Partial<ConnectedProductDetail>[] }>()
);

export const addConnectedProductItem = createAction(
  '[Product Data] Connected Product Table Add Item',
  props<{ data: Partial<ConnectedProductDetail> }>()
);

export const removeConnectedProductItem = createAction(
  '[Product Data] Connected Product Table Remove Item',
  props<{ data: Partial<ConnectedProductDetail> }>()
);

export const setConnectedProductExpandedEvent = createAction(
  '[Product Data] Connected Product Table Handle expanded and collapsed',
  props<{ data: ConnectedProductEvent | null }>()
);

export const setConnectedProductCopyEvent = createAction(
  '[Product Data] Connected Product Table Handle copied item',
  props<{ isCopied: Boolean }>()
);
