import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { setSystemFormNameAppendix, setSystemFormType } from '../store/system-form/system-form.actions';
import { SystemFormState } from '../store/system-form/system-form.reducer';
import { selectSystemForm } from '../store/system-form/system-form.selector';

@Component({
  selector: 'app-system-form',
  templateUrl: './system-form.component.html',
  styleUrls: ['./system-form.component.scss']
})
export class SystemFormComponent implements OnInit {

  appendix = '';
  types = ['C', 'CS', 'BOOST', 'HR'];
  typeControl = new UntypedFormControl('', [Validators.required]);
  nameAppendixControl = new UntypedFormControl('');
  createSetName$: Observable<String>;

  constructor(
    private store: Store,
  ) { }

  ngOnInit() {
    this.createSetName$ = this.store.pipe(
      select(selectSystemForm),
      map((data: SystemFormState) => data.pumpName)
    )
  }

  setTypeChangeAction(value: string) {
    this.store.dispatch(setSystemFormType({ data: value }));
  }

  onInput(value: string) {
    this.store.dispatch(setSystemFormNameAppendix({ data: value }));
  }
}
