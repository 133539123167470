<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Pump Unit</mat-panel-title>
    <mat-panel-description>Pump unit fields</mat-panel-description>
  </mat-expansion-panel-header>

  <mat-form-field class="form-element">
    <mat-label>pu_item</mat-label>
    <input matInput placeholder="pu_item" value={{item}} (input)="onInput($event.target.value, 'item')">
  </mat-form-field>

  <app-packaging-store></app-packaging-store>

  <mat-form-field class="form-element">
    <mat-label>pu_max_temp</mat-label>
    <input matInput placeholder="pu_max_temp" value={{maxTemp}} (input)="onInput($event.target.value, 'maxTemp')">
  </mat-form-field>

  <mat-form-field class="form-element">
    <mat-label>pu_suction_head</mat-label>
    <input matInput placeholder="pu_suction_head" value={{suctionHead}}
      (input)="onInput($event.target.value, 'suctionHead')">
  </mat-form-field>

  <app-picture-store class="form-wrapper__picture-wrapper"></app-picture-store>

</mat-expansion-panel>