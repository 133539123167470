import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DirtyComponent } from '../display-product-data/dirty-component';
import { UpdateButtonComponent } from '../display-product-data/update-button/update-button.component';

@Injectable({
  providedIn: 'root'
})

export class DirtyCheckGuard  {

  constructor(
    private dialog: MatDialog,
  ) {}

  canDeactivate(
    component: DirtyComponent,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.canDeactivate()) {

      const dialogRef = this.dialog.open(UpdateButtonComponent, {
        width: '600px',
        disableClose: true,
        minHeight: '20vh',
      });
      return dialogRef.afterClosed();
    } else {
      return of(true);
    }
  }
}
