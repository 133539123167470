
        <div class="part-left">
          <div class="form-element">

            <h2>Data upload/download PS3</h2>


              <!--<mat-form-field class="example-full-width">
                <input matInput placeholder="Filename" aria-label="filename" [matAutocomplete]="auto" [(ngModel)]="filename" [ngModelOptions]="{standalone: true}">
                <mat-autocomplete #auto="matAutocomplete" >
                  <mat-option *ngFor="let version of versions" [value]="version.filename">
                    <span>{{ version.filename }}</span>
                  </mat-option>
                </mat-autocomplete>
                </mat-form-field> -->

            </div>
            <div class="form-element">
              <div class="upload-wrapper">
              <button mat-raised-button color="primary"><mat-icon>add</mat-icon> Upload file
              <input
                type="file"
                name="profile"
                (change)="onFileSelect($event, 'PS3')"
                accept=".csv,.json,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              ></button>
            </div>
            <div class="upload-info" *ngIf="showUploadFileName">
              <span> {{ uploadFileName }}  </span>
            </div>
          </div>


            <ng-template matStepLabel>System components</ng-template>

            <div class="form-element">
              <button mat-raised-button color="primary" type="button" (click)="profileFormSubmit('PS3')">View Uploaded PS3 File</button>
            </div>

        </div>
