import { Action, createReducer, on } from "@ngrx/store";
import * as PumpUnitActions from "./pump-unit.actions";

export const pumpUnitFeatureKey = 'pumpUnit';

export interface PumpUnitState {
  item: String;
  packaging: String;
  maxTemp: String;
  suctionHead: String;
  picture: String;
}

export const initialState: PumpUnitState = {
  item: '',
  packaging: '',
  maxTemp: '',
  suctionHead: '',
  picture: '',
}

const reducer = createReducer(
  initialState,
  on(PumpUnitActions.setPumpUnitItem, (state, action) => {
    return {
      ...state,
      item: action.data
    }
  }),
  on(PumpUnitActions.setPumpUnitPackaging, (state, action) => {
    return {
      ...state,
      packaging: action.data
    }
  }),
  on(PumpUnitActions.setPumpUnitMaxTemp, (state, action) => {
    return {
      ...state,
      maxTemp: action.data
    }
  }),
  on(PumpUnitActions.setPumpUnitSuctionHead, (state, action) => {
    return {
      ...state,
      suctionHead: action.data
    }
  }),
  on(PumpUnitActions.setPumpUnitPicture, (state, action) => {
    return {
      ...state,
      picture: action.data
    }
  })
);

export function pumpUnitReducer(
  state: PumpUnitState,
  action: Action
): PumpUnitState {
  return reducer(state, action);
}
