import { BehaviorSubject, Observable } from 'rxjs';

export interface LoadEvent {
  name: string;
  versionKey: number;
}

export interface StepperEvent {
  stepIndexNow: number;
  stepIndexNext: number;
}

export interface RedrawChart {
  type: any;
  valueChangedData: string[];
}

export interface UpdateChart {
  type: any;
  itemName: string;
  value?: string;
}

export class LoadEventService {

  data = {
    name: '',
    versionKey: 1
  };

  stepperIndex = {
    stepIndexNow: -1,
    stepIndexNext: -1
  };

  redraw = {
    type: '',
    valueChangedData: []
  };

  update = {
    type: '',
    itemName: ''
  };

  private childClickedEvent = new BehaviorSubject<LoadEvent>(this.data);

  private stepperMoveEvent = new BehaviorSubject<StepperEvent>(this.stepperIndex);

  private redrawChartEvent = new BehaviorSubject<RedrawChart>(this.redraw);

  private updateChartEvent = new BehaviorSubject<UpdateChart>(this.update);

  private updateCoefficientInputParameters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private isAllManulSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private coefficientTableData: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private uploadFileName: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private allRadioBtnType: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private updateSettingsForm: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  emitChildEvent(msg: LoadEvent) {
    this.childClickedEvent.next(msg);
  }

  childEventListner() {
    return this.childClickedEvent.asObservable();
  }

  emitRedrawChartEvent(data: RedrawChart) {
    this.redrawChartEvent.next(data);
  }

  redrawChartEventListner() {
    return this.redrawChartEvent.asObservable();
  }

  emitUpdateChartEventEvent(data: UpdateChart) {
    this.updateChartEvent.next(data);
  }

  updateChartEventListner() {
    return this.updateChartEvent.asObservable();
  }

  setStepperIndex(value: StepperEvent) {
    this.stepperMoveEvent.next(value);
  }

  getStepperIndex(): Observable<StepperEvent> {
    return this.stepperMoveEvent.asObservable();
  }

  setTableData(value: string) {
    this.coefficientTableData.next(value);
  }

  getTableData(): Observable<string> {
    return this.coefficientTableData.asObservable();
  }

  setSelectUploadFileName(value: string) {
    this.uploadFileName.next(value);
  }

  getSelectUploadFileName(): Observable<string> {
    return this.uploadFileName.asObservable();
  }

  setFormBasedInputParams(value: boolean) {
    this.updateCoefficientInputParameters.next(value);
  }

  getFormBasedInputParams(): Observable<boolean> {
    return this.updateCoefficientInputParameters.asObservable();
  }

  setSwitchAllRadio(value: string) {
    this.allRadioBtnType.next(value);
  }

  getSwitchAllRadio(): Observable<string> {
    return this.allRadioBtnType.asObservable();
  }

  setAllManulSelected(value: boolean) {
    this.isAllManulSelected.next(value);
  }

  getAllManulSelected(): Observable<boolean> {
    return this.isAllManulSelected.asObservable();
  }

  setSettingsFormValue(value: any) {
    this.updateSettingsForm.next(value);
  }

  getSettingsFormValue(): Observable<any> {
    return this.updateSettingsForm.asObservable();
  }

}
