import { Injectable } from '@angular/core';
import { SelectedCoefficientResultRow, CoefficientResultRow, RecalculateFormItemNames } from '../pump-simulation/coefficient.model';


@Injectable({
  providedIn: 'root'
})
export class PumpSimulationHelperService {

  constructor() { }

  extractManualValues(selectedCoefficientsData: SelectedCoefficientResultRow, manualValue: SelectedCoefficientResultRow) {
    if (selectedCoefficientsData) {
      RecalculateFormItemNames.forEach(itemName => {
        const coefficent = selectedCoefficientsData[itemName];
        if (coefficent.man) {
          { manualValue[itemName] = coefficent; }
        }
      });
    }
    return manualValue;
  }

  isEmpty(obj: Object): boolean {
    return !obj || !Object.keys(obj).some(x => obj[x] !== void 0);
  }

  transformSelectedCoefficientsIntoCoefficients(selectedCoefficients: SelectedCoefficientResultRow): CoefficientResultRow {

    const coefficient: CoefficientResultRow = {
      corr_fact: selectedCoefficients.corr_fact.value,
      mai: selectedCoefficients.mai.value,
      maq: selectedCoefficients.maq.value,
      mar_: selectedCoefficients.mar_.value,
      mbi: selectedCoefficients.mbi.value,
      mbq: selectedCoefficients.mbq.value,
      mbr: selectedCoefficients.mbr.value,
      mci: selectedCoefficients.mci.value,
      mcq: selectedCoefficients.mcq.value,
      mcr: selectedCoefficients.mcr.value,
      mdq_: selectedCoefficients.mdq_.value,
      nai: selectedCoefficients.nai.value,
      naq: selectedCoefficients.naq.value,
      nar_: selectedCoefficients.nar_.value,
      nbi: selectedCoefficients.nbi.value,
      nbq: selectedCoefficients.nbq.value,
      nbr: selectedCoefficients.nbr.value,
      nci: selectedCoefficients.nci.value,
      ncq: selectedCoefficients.ncq.value,
      ncr: selectedCoefficients.ncr.value,
      ndq_: selectedCoefficients.ndq_.value,
      npsh_a: selectedCoefficients.npsh_a.value,
      npsh_c: selectedCoefficients.npsh_c.value,
      npsh_exp: selectedCoefficients.npsh_exp.value,
      intermediateCoefficentsJSON: selectedCoefficients.intermediateCoefficentsJSON,
      intermediateCoefficents: selectedCoefficients.intermediateCoefficents
    }
    return coefficient;
  }


  transformCoefficientsResultToSelectedCoefficients(coefficients: CoefficientResultRow): SelectedCoefficientResultRow {
    const selectedCoefficients: SelectedCoefficientResultRow = {
      maq: { value: coefficients.maq, man: false },
      naq: { value: coefficients.naq, man: false },
      mbq: { value: coefficients.mbq, man: false },
      nbq: { value: coefficients.nbq, man: false },
      mcq: { value: coefficients.mcq, man: false },
      ncq: { value: coefficients.ncq, man: false },
      mdq_: { value: coefficients.mdq_, man: false },
      ndq_: { value: coefficients.ndq_, man: false },
      mai: { value: coefficients.mai, man: false },
      nai: { value: coefficients.nai, man: false },
      mbi: { value: coefficients.mbi, man: false },
      nbi: { value: coefficients.nbi, man: false },
      mci: { value: coefficients.mci, man: false },
      nci: { value: coefficients.nci, man: false },
      mar_: { value: coefficients.mar_, man: false },
      nar_: { value: coefficients.nar_, man: false },
      mbr: { value: coefficients.mbr, man: false },
      nbr: { value: coefficients.nbr, man: false },
      mcr: { value: coefficients.mcr, man: false },
      ncr: { value: coefficients.ncr, man: false },
      corr_fact: { value: coefficients.corr_fact, man: false },
      npsh_a: { value: coefficients.npsh_a, man: false },
      npsh_exp: { value: coefficients.npsh_exp, man: false },
      npsh_c: { value: coefficients.npsh_c, man: false },
      intermediateCoefficentsJSON: coefficients.intermediateCoefficentsJSON,
      intermediateCoefficents: coefficients.intermediateCoefficents
    }
    return selectedCoefficients;
  }
}
