import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable, of, Subject, combineLatest } from 'rxjs';
import { catchError, map, mergeMap, tap, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LoginTokenResponse } from './global-user';
import { User } from './user';
import { UserLocalStorage } from './user-local-store';
import { Logger } from '../shared/logger';


const host = environment.userServiceUrl;
const LOGIN_URL = `${host}/login`;

/**
 * LoginService provides access to the logged in user and
 * manages the login process with the user-service.
 */
@Injectable({ providedIn: 'root', })
export class LoginService {

  private userLocalStorage = new UserLocalStorage();
  private logoutEvent$ = new Subject<void>();
  private userSubject = new BehaviorSubject<User | null>(null);

  user$: Observable<User | null> = this.userSubject.asObservable();

  // url to redirect after login.
  redirectUrl: string | null = null;

  constructor(private router: Router, private http: HttpClient) { }

  get isLoggedIn$(): Observable<boolean> {

    return this.user$.pipe(
      map((u: User | null) => u !== null)
    );
  }

  get isAdmin$(): Observable<boolean> {
    const adminUserAddress = environment.adminUsers.map((username: string) => username + '@lorentz.de');
    return this.user$.pipe(
      map((u: User | null) => u !== null && u.user !== null && adminUserAddress.includes(u.user.username) )
    );
  }

  /** load a user from the local storage */
  initUserFromLocalStorage() {
    const loggedInUser = this.userLocalStorage.loadUser();
    this.userSubject.next(loggedInUser);
  }

  /**
   * Get a valid access token.
   *
   * If the current access token in expired a new token will be fetched with the refresh token.
   */
  accessToken(): Observable<string> {

    return this.user$.pipe(
      take(1),
      mergeMap((user: User | null) => {
        if (user === null) {
          throw new Error('user must not be null');
        }
        if (user.isTokenValidInFutureSeconds(new Date(), 10)) {
          return of(user.accessToken);
        } else {
          return this.tokenLogin().pipe(
            map(principal => principal.accessToken),
          );
        }
      })
    );
  }

  /**
   * Get a new access token with a refresh token.
   */
  tokenLogin(): Observable<User> {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.user$.pipe(
      take(1),
      mergeMap((user: User) => {
        if (user === null) {
          throw new Error('must be logged in');
        }
        const credentials = {
          type: 'refresh',
          username: user.user.username,
          refreshToken: user.refreshToken
        };
        return combineLatest([of(user), this.http.post<LoginTokenResponse>(LOGIN_URL, credentials, options)]);
      }),
      map((response: [User, LoginTokenResponse]) => {
        const user = response[0];
        const tokenResponse = response[1];
        if (tokenResponse === null) {
          throw new Error();
        }
        user.accessToken = tokenResponse.access_token;
        user.refreshToken = tokenResponse.refresh_token;
        user.validUntil = moment().add(tokenResponse.expires_in, 's').toDate();
        user.refreshTokenValidUntil = moment.unix(tokenResponse.refresh_valid_until).toDate();
        return user;
      }),
      tap(user => this.userLocalStorage.saveUser(user)),
      catchError((error: any): Observable<User> => {
        Logger.error('Error on token login');
        Logger.error(error);
        this.userSubject.next(null);
        this.navigateToLogin();
        throw error;
      })
    );
  }

  /**
   * Authenticate the user with the provided username and password.
   */
  login(username: string, password: string): Observable<User> {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const credentials = {
      username,
      password
    };

    return this.http.post<LoginTokenResponse>(LOGIN_URL, credentials, options)
      .pipe(
        map(tokenResponse => {
          const user = new User(tokenResponse.access_token,
            moment().add(tokenResponse.expires_in, 's').toDate(),
            tokenResponse.refresh_token,
            moment.unix(tokenResponse.refresh_valid_until).toDate(),
            tokenResponse.user);
          return user;
        }),
        tap((user: User) => this.userLocalStorage.saveUser(user)),
        tap(user => this.userSubject.next(user)),
        tap(_ => this.navigateAfterLogin()),
        catchError((error, cought): Observable<User> => {
          console.error('Error on login');
          console.error(error);
          throw error;
        })
      );
  }

  logout() {
    this.userLocalStorage.deleteUser();
    this.userSubject.next(null);
    this.publishLogoutEvent();
  }

  get logoutEvent(): Observable<void> {
    return this.logoutEvent$ as Observable<void>;
  }

  private publishLogoutEvent() {
    this.logoutEvent$.next();
  }

  /**
   * Redirect to the url after login or to the default route.
   */
  navigateAfterLogin() {
    const redirect = this.redirectUrl !== null ? this.redirectUrl : '/';
    this.redirectUrl = null;
    this.router.navigate([redirect]);
  }

  navigateToLogin() {
    this.router.navigate(['/login']);

  }
}
