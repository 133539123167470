<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">
    <mat-card-header>
      <mat-card-title>
        Pump systems name: {{ pumpSystemName }}
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>

      <mat-progress-spinner *ngIf="showVersionTable" mode="indeterminate" [diameter]=20></mat-progress-spinner>

      <div class="mat-elevation-z8" *ngIf="!showVersionTable">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="approveForUse">
            <th mat-header-cell *matHeaderCellDef> Approve For Use </th>
            <td mat-cell *matCellDef="let row; let id = index">
              <mat-radio-button color="primary" [ngClass]="'mat-radio-' + id" (click)="selectVersionItem(id)">
              </mat-radio-button>
            </td>
          </ng-container>


          <!-- Version Column -->
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef> Version </th>
            <td mat-cell *matCellDef="let row"> {{row.version_number}} </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let row"> {{row.status}} </td>
          </ng-container>

          <!-- published Column -->
          <ng-container matColumnDef="published">
            <th mat-header-cell *matHeaderCellDef> Published? </th>
            <td mat-cell *matCellDef="let row">
              <div *ngIf="row.live; then thenBlock else elseBlock"></div>
              <ng-template #thenBlock>LIVE</ng-template>
              <ng-template #elseBlock>PREVIEW</ng-template>
            </td>
          </ng-container>

          <!-- Author Column -->
          <ng-container matColumnDef="author">
            <th mat-header-cell *matHeaderCellDef> Author </th>
            <td mat-cell *matCellDef="let row"> {{row.author}} </td>
          </ng-container>

          <!-- Comment Column -->
          <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef> Comment </th>
            <td mat-cell *matCellDef="let row" class="comment-cell"> {{row.comment}} </td>
          </ng-container>

          <!-- Action Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let row">
              <button mat-button color="primary" (click)="showVersionData(row.version_number)"> LOAD </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; let id = index; columns: displayedColumns;"
            [ngClass]="{'highlight': selectedRowIndex === row.version_number}"
            [ngClass]="id !== 0 ? '' : 'removeContent'"></tr>
        </table>
        <div class="form-wrapper__table-loading" *ngIf="tableLoading">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </div>

      <div class="container mat-elevation-z8" *ngIf="showPumpSystemForm" novalidate>

        <div class="inner-container">

          <form [formGroup]="pumpSystemForm" class="form" (ngSubmit)="onSave()">

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>Pump System Components</mat-panel-title>
              </mat-expansion-panel-header>

              <app-pump-unit-select></app-pump-unit-select>

              <app-controller-select></app-controller-select>

              <app-motor-select></app-motor-select>

              <app-pump-end-select></app-pump-end-select>

              <app-coefficient-set-select></app-coefficient-set-select>

              <app-accessory-select></app-accessory-select>

              <app-connected-products-select></app-connected-products-select>

            </mat-expansion-panel>

            <div class="form-wrapper__view-hint">
              <mat-form-field>
                <mat-label>No</mat-label>
                <input matInput placeholder="No" formControlName="no">
              </mat-form-field>
              <mat-icon #tooltip="matTooltip" (click)="tooltip.toggle()" matTooltip="Number used in Compass."
                [matTooltipPosition]="'right'">info
              </mat-icon>
            </div>

            <div class="form-wrapper__view-hint">
              <mat-form-field>
                <mat-label>Field No</mat-label>
                <input matInput placeholder="Field No" formControlName="field_no">
              </mat-form-field>

              <mat-icon #tooltip="matTooltip" (click)="tooltip.toggle()" matTooltip="Number used in Global and Android."
                [matTooltipPosition]="'right'">info
              </mat-icon>
            </div>

            <app-pump-system-type formControlName="type"></app-pump-system-type>

            <div [ngClass]="typeSelect ? 'sub-detail' : ''">
              <div class="type-wrapper sub-content">
                <mat-form-field class="form-element">
                  <mat-label>min_speed</mat-label>
                  <input matInput placeholder="m_min_speed" formControlName="m_min_speed">
                </mat-form-field>

                <mat-form-field class="form-element">
                  <mat-label>min_set_speed</mat-label>
                  <input matInput placeholder="m_min_set_speed" formControlName="m_min_set_speed">
                </mat-form-field>

                <mat-form-field class="form-element">
                  <mat-label>max_speed</mat-label>
                  <input matInput placeholder="m_max_speed" formControlName="m_max_speed">
                </mat-form-field>

                <mat-form-field class="form-element">
                  <mat-label>min_speed_Hz</mat-label>
                  <input matInput placeholder="m_min_speed_Hz" formControlName="m_min_speed_Hz">
                </mat-form-field>

                <mat-form-field class="form-element">
                  <mat-label>min_set_speed_Hz</mat-label>
                  <input matInput placeholder="m_min_set_speed_Hz" formControlName="m_min_set_speed_Hz">
                </mat-form-field>

                <mat-form-field class="form-element">
                  <mat-label>max_speed_Hz</mat-label>
                  <input matInput placeholder="m_max_speed_Hz" formControlName="m_max_speed_Hz">
                </mat-form-field>

                <mat-form-field class="form-element">
                  <mat-label>min_u</mat-label>
                  <input matInput placeholder="m_min_u" formControlName="m_min_u">
                </mat-form-field>

                <mat-form-field class="form-element">
                  <mat-label>max_u</mat-label>
                  <input matInput placeholder="m_max_u" formControlName="m_max_u">
                </mat-form-field>

                <mat-form-field class="form-element">
                  <mat-label>motor_parts</mat-label>
                  <input matInput placeholder="motor_parts" formControlName="motor_parts">
                </mat-form-field>

                <mat-form-field class="form-element">
                  <mat-label>motor_parts_list_name</mat-label>
                  <input matInput placeholder="motor_parts_list_name" formControlName="motor_parts_list_name">
                </mat-form-field>

                <mat-form-field class="form-element">
                  <mat-label>m_item</mat-label>
                  <input matInput placeholder="m_item" formControlName="m_item">
                </mat-form-field>

                <mat-form-field class="form-element">
                  <mat-label>m_weight</mat-label>
                  <input matInput placeholder="m_weight" formControlName="m_weight">
                </mat-form-field>

                <mat-form-field class="form-element">
                  <mat-label>m_material</mat-label>
                  <input matInput placeholder="m_material" formControlName="m_material">
                </mat-form-field>

                <mat-form-field class="form-element">
                  <mat-label>cos_phi</mat-label>
                  <input matInput placeholder="cos_phi" formControlName="cos_phi">
                </mat-form-field>

                <mat-form-field class="form-element">
                  <mat-label>ac_poles</mat-label>
                  <input matInput placeholder="ac_poles" formControlName="ac_poles">
                </mat-form-field>

              </div>
            </div>

            <mat-form-field class="form-element">
              <mat-label>pump</mat-label>
              <input matInput placeholder="pump" formControlName="pump">
            </mat-form-field>

            <mat-form-field class="form-element" appearance="fill">
              <mat-label>status</mat-label>
              <mat-select formControlName="status">
                <mat-option *ngFor="let status of statusList" [value]="status.id">{{ status.content }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>comments</mat-label>
              <input matInput placeholder="comments" formControlName="comments">
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>ps_item</mat-label>
              <input matInput placeholder="ps_item" formControlName="ps_item">
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>min_lift</mat-label>
              <input matInput placeholder="min_lift" formControlName="min_lift">
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>max_lift</mat-label>
              <input matInput placeholder="max_lift" formControlName="max_lift">
            </mat-form-field>

            <app-picture-select-new class="form-wrapper__picture-wrapper" formControlName="picture">
            </app-picture-select-new>

            <mat-form-field class="form-element">
              <mat-label>app_type</mat-label>
              <input matInput placeholder="app_type" formControlName="app_type">
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>prod_standard</mat-label>
              <input matInput placeholder="prod_standard" formControlName="prod_standard">
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>prod_ulcsa</mat-label>
              <input matInput placeholder="prod_ulcsa" formControlName="prod_ulcsa">
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>max_curves</mat-label>
              <input matInput placeholder="max_curves" formControlName="max_curves">
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>max_power</mat-label>
              <input matInput placeholder="max_power" formControlName="max_power">
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>min_borehole</mat-label>
              <input matInput placeholder="min_borehole" formControlName="min_borehole">
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>min_pv_pmax</mat-label>
              <input matInput placeholder="min_pv_pmax" formControlName="min_pv_pmax">
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>max_pv_pmax</mat-label>
              <input matInput placeholder="max_pv_pmax" formControlName="max_pv_pmax">
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>min_pv_vmp</mat-label>
              <input matInput placeholder="min_pv_vmp" formControlName="min_pv_vmp">
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>min_pv_vmp_exp</mat-label>
              <input matInput placeholder="min_pv_vmp_exp" formControlName="min_pv_vmp_exp">
            </mat-form-field>

            <mat-form-field class="form-element">
              <mat-label>opt_pv_vmp</mat-label>
              <input matInput placeholder="opt_pv_vmp" formControlName="opt_pv_vmp">
            </mat-form-field>

            <mat-form-field class="form-element hideAll">
              <mat-label>split_fact</mat-label>
              <input matInput placeholder="split_fact" formControlName="split_fact">
            </mat-form-field>

            <mat-form-field class="form-element hideAll">
              <mat-label>corr_fact</mat-label>
              <input matInput placeholder="corr_fact" formControlName="corr_fact">
            </mat-form-field>

            <div class="form">
              <button mat-raised-button color="primary" type="submit" class="button" [disabled]="!isDirty">Save
                Form</button>
            </div>

          </form>

          <div class="occupied"> place occupy </div>

          <div class="submit-spinner" *ngIf="showSubmitLoading">
            <mat-progress-spinner class="submitLoading" mode="indeterminate" [diameter]=20></mat-progress-spinner>
          </div>

        </div>
      </div>
    </mat-card-content>
  </mat-card>
</section>
