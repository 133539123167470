import { createAction, props } from "@ngrx/store";

export const setSystemFormController = createAction(
  '[Simulation System Form] Set System Form Controller',
  props<{ data: String }>()
);

export const setSystemFormType = createAction(
  '[Simulation System Form] Set System Form Type',
  props<{ data: String }>()
);

export const setSystemFormPumpEnd = createAction(
  '[Simulation System Form] Set System Form PumpEnd',
  props<{ data: String }>()
);

export const setSystemFormNameAppendix = createAction(
  '[Simulation System Form] Set System Form Name Appendix',
  props<{ data: String }>()
);

export const setLoadSystemFormNameAppendix = createAction(
  '[Simulation System Form] Set System Load Form Name Appendix',
  props<{ data: String }>()
);

export const setLoadVersionNumber= createAction(
  '[Simulation System Form] Set System Form Version Number Appendix',
  props<{ data: Number }>()
);
