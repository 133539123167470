import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CityNewComponent } from '../city-new/city-new.component';
import { DisplayProductDataService } from '../display-product-data.service';
import { CityItem } from './city.model';
import { CoordinateItem } from "../location/coordinate.model";

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss']
})
export class CitiesComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['id', 'name', 'country_id', 'lat', 'lon'];
  cityForm: UntypedFormGroup;
  tableLoading = true;
  isFirstInit = true;
  isFirstLoad = true;

  tableData = [];

  pageSizeOptions = [10, 25, 100];
  totalRows = 0;
  currentPage = 0;
  pageSize = 10;


  @Input() dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  allLocations: CoordinateItem[];
  lastKey: string;

  constructor(
    private dialog: MatDialog,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {

    this.displayProductData.loadCityData({ uuid: null, lastKey: null, limit: 1000 }).subscribe((allCities) => {

      this.tableLoading = false;
      this.lastKey = allCities.lastEvaluatedKey;
      this.tableData = allCities.data.sort((a, b) => Number(a.id) - Number(b.id));
      this.dataSource.data = this.tableData;
      this.totalRows = this.tableData.length;
      this.isFirstInit = false;
    });
    this.loadData();
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
  }
  
  loadData() {
    this.isFirstLoad = false;
    this.displayProductData.loadAllCityData({ uuid: null, lastKey: null, limit: 10000 }).subscribe((allCities) => {

      this.tableLoading = false;
      this.tableData = allCities.sort((a, b) => Number(a.id) - Number(b.id));
      this.dataSource.data = this.tableData;
      this.totalRows = this.tableData.length;


    });
  }

  createNewCity() {
    const dialogRef = this.dialog.open(CityNewComponent, {
      width: '1000px',
      maxWidth: '96vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tableData.unshift(result);
        this.dataSource.data = this.tableData;
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.firstPage());
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
