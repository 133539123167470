<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">
    <mat-card-header>
      <mat-card-title>
        Controller attributes
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="container" novalidate>
        <mat-progress-spinner *ngIf="!showControllerForm" mode="indeterminate" [diameter]=20></mat-progress-spinner>

        <form *ngIf="showControllerForm" [formGroup]="controllerForm" data-testid="form" (ngSubmit)="onSave()"
          class="form">

          <mat-form-field class="form-element">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" data-testid="name" formControlName="name">
            <mat-error *ngIf="controllerForm.get('name').hasError('required')">The field name is required</mat-error>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>Item</mat-label>
            <input matInput type="number" placeholder="Item" data-testid="item" formControlName="item">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>Net Weight</mat-label>
            <input matInput type="number" placeholder="Net Weight" data-testid="net_weight"
              formControlName="net_weight">
            <span matSuffix>kg</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>h</mat-label>
            <input matInput type="number" placeholder="h" data-testid="h" formControlName="h">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>h1</mat-label>
            <input matInput type="number" placeholder="h1" data-testid="h1" formControlName="h1">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>h2</mat-label>
            <input matInput type="number" placeholder="h2" data-testid="h2" formControlName="h2">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>w</mat-label>
            <input matInput type="number" placeholder="w" data-testid="w" formControlName="w">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>w1</mat-label>
            <input matInput type="number" placeholder="w1" data-testid="w1" formControlName="w1">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>w2</mat-label>
            <input matInput type="number" placeholder="w2" data-testid="w2" formControlName="w2">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>w3</mat-label>
            <input matInput type="number" placeholder="w3" data-testid="w3" formControlName="w3">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>d</mat-label>
            <input matInput type="number" placeholder="d" data-testid="d" formControlName="d">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>d1</mat-label>
            <input matInput type="number" placeholder="d1" data-testid="d1" formControlName="d1">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>d2</mat-label>
            <input matInput type="number" placeholder="d2" data-testid="d2" formControlName="d2">
            <span matSuffix>mm</span>
          </mat-form-field>

          <app-drawing-view class="form-wrapper__view-wrapper" data-testid="picture" formControlName="picture">
          </app-drawing-view>

          <mat-form-field class="form-element">
            <mat-label>economical_value_for_sort_order</mat-label>
            <input matInput type="number" placeholder="economical_value_for_sort_order"
              data-testid="economical_value_for_sort_order" formControlName="economical_value_for_sort_order">
          </mat-form-field>

          <app-packaging-select data-testid="packaging" formControlName="packaging"></app-packaging-select>

          <mat-form-field class="form-element">
            <mat-label>min temp</mat-label>
            <input matInput type="number" placeholder="min temp" data-testid="min_temp" formControlName="min_temp">
            <span matSuffix>°C</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>max temp</mat-label>
            <input matInput type="number" placeholder="max temp" data-testid="max_temp" formControlName="max_temp">
            <span matSuffix>°C</span>
          </mat-form-field>

          <app-enclosure-class-select-new data-testid="ingress_protection_code"
            formControlName="ingress_protection_code"></app-enclosure-class-select-new>

          <mat-form-field class="form-element">
            <mat-label>efficiency</mat-label>
            <input matInput type="number" placeholder="efficiency" data-testid="efficiency"
              formControlName="efficiency">
            <span matSuffix>%</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>Max Voc</mat-label>
            <input matInput type="number" placeholder="Max Voc" data-testid="max_voc" formControlName="max_voc">
            <span matSuffix>V</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>Max I</mat-label>
            <input matInput type="number" placeholder="Max I" data-testid="max_i" formControlName="max_i">
            <span matSuffix>A</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>Min Cable Size</mat-label>
            <input matInput type="number" placeholder="Min Cable Size" data-testid="min_cable_size"
              formControlName="min_cable_size">
            <span matSuffix>m&#x33A1;</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>Max P</mat-label>
            <input matInput type="number" placeholder="Max P" data-testid="max_p" formControlName="max_p">
            <span matSuffix>W</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>U Factor</mat-label>
            <input matInput type="number" placeholder="U Factor" data-testid="u_factor" formControlName="u_factor">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>I Factor</mat-label>
            <input matInput type="number" placeholder="I Factor" data-testid="i_factor" formControlName="i_factor">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>I Offset</mat-label>
            <input matInput type="number" placeholder="I Offset" data-testid="i_offset" formControlName="i_offset">
          </mat-form-field>

          <div class="form-element">
            <button mat-raised-button color="primary" [disabled]="!isDirty" data-testid="submit" type="submit"
              class="button">Save Form</button>
          </div>

        </form>

        <div class="occupied"> place occupy </div>

      </div>
    </mat-card-content>

  </mat-card>

</section>