<h2 mat-dialog-title> Create New Measurement Data </h2>
<mat-dialog-content class="dialog">
  <form [formGroup]="measurementForm">

    <mat-form-field class="form-element">
      <mat-label> Head Pressure </mat-label>
      <input matInput min="0" type="number" placeholder="Head Pressure" formControlName="headPressure">
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label> Current </mat-label>
      <input matInput min="0" type="number" placeholder="Current" formControlName="current">
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label> Voltage </mat-label>
      <input matInput min="0" type="number" placeholder="Voltage" formControlName="voltage">
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label> Volume </mat-label>
      <input matInput min="0" type="number" placeholder="Volume" formControlName="volume">
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label> Rotational Speed </mat-label>
      <input matInput min="0" type="number" placeholder="Rotational Speed" formControlName="rotationalSpeed">
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label> Measurement Time </mat-label>
      <input matInput min="0" type="number" placeholder="Measurement Time" formControlName="measurementTime">
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label> Power </mat-label>
      <input matInput min="0" type="number" placeholder="Power" formControlName="power">
    </mat-form-field>

    <mat-form-field class="form-element">
      <mat-label> Flow Rate </mat-label>
      <input matInput min="0" type="number" placeholder="Flow Rate" formControlName="flowRate">
    </mat-form-field>

  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="greyBtn" mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" type="submit" [disabled]="!measurementForm.valid"
    (click)="handleMeasurementFormData()"> Create </button>
</mat-dialog-actions>