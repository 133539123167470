<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">

    <mat-card-header>
      <mat-card-title>
        Material Overview
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field class="form-wrapper__searchPart">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Material name" #input>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="createNewMaterial()"> <mat-icon>add</mat-icon> Add New Material </button>

      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
      
          <!-- Material Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Material Name</th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row [mat-dialog-close]="row.uuid" *matRowDef="let row; columns: displayedColumns;"></tr>
      
          <!-- Row shown when there is no matching data. -->
          <tr [ngClass]="isFirstInit ? 'firstInit' : ''" class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
          </tr>
        </table>
        <div class="form-wrapper__table-loading" *ngIf="tableLoading">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</section>
