import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { delay, filter, map, mergeMap } from 'rxjs/operators';
import { DisplayProductDataService } from '../display-product-data.service';
import { PUMPENDS_DIAMS_ITEMS, PUMPENDS_ITEMS } from '../pump-data.model';
import { PumpEndsItem } from './pump-end.model';
import { MatDialog } from '@angular/material/dialog';
import { SaveButtonComponent } from '../save-button/save-button.component';
import { DirtyComponent } from '../dirty-component';
import { ErrorButtonComponent } from '../error-button/error-button.component';
import { select, Store } from '@ngrx/store';
import { PackagingItem } from '../controllers/controller.model';
import { PackagingState } from '../store/packaging/packaging.reducer';
import { selectPackaging } from '../store/packaging/packaging.selector';
import { initPartsData, setOriginalFormValue, setPartsData, setPartsName, setPartsPicture, setPartsType } from '../store/parts-list/parts-list.actions';
import { PartsListService } from '../parts-list.service';
import { setPartsListName } from '../store/controller/controller.actions';
import { PartsListState } from '../store/parts-list/parts-list.reducer';
import { selectPartsList } from '../store/parts-list/parts-list.seletor';
import { ControllerState } from '../store/controller/controller.reducer';
import { selectController } from '../store/controller/controller.seletor';

@Component({
  selector: 'app-pump-ends',
  templateUrl: './pump-ends.component.html',
  styleUrls: ['./pump-ends.component.scss']
})
export class PumpEndsComponent implements OnInit, OnDestroy, DirtyComponent {

  private subscriptions: Subscription[] = [];
  pumpEnds: Observable<PumpEndsItem>;
  packagingList = [];
  pumpEndForm: UntypedFormGroup;
  diamsGroup: UntypedFormGroup;
  showPumpendForm = false;
  showPartslistTree = false;
  partslistName = 'Please select parts list';
  partsPicture = '';
  isDirty = false;
  pumpEndPartsValue: any;
  originalPumpEndFormValue: any;
  originalPumpEndData: any;
  pumpEndUuid = '';

  constructor(
    private store: Store,
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private database: PartsListService,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {
    this.store.dispatch(setPartsType({ data: 'pump_end' }));
    const getData = combineLatest([
      this.store.pipe(
        select(selectPackaging),
        map((data: PackagingState) => data.data)
      ),
      this.store.pipe(
        select(selectPartsList),
        map((data: PartsListState) => data.partsPicture)
      ),
      this.store.pipe(
        select(selectController),
        map((data: ControllerState) => data.partsList)
      )
    ]).subscribe(([data, partsPicture, partsName]) => {

      this.packagingList = [...data].sort((a, b) => Number(a.subType.L) - Number(b.subType.L));

      if (partsPicture !== '') {
        this.partsPicture = `${partsPicture}`;
      };

      if (partsName !== '') {
        this.partslistName = `${partsName}`;
      }
    });
    this.subscriptions.push(getData);

    const urlData = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.isDirty = false;
      this.partslistName = '';
      this.showPumpendForm = false;
    });
    this.subscriptions.push(urlData);

    const pumpEndData = this.route.paramMap.pipe(
      map(params => params.get('uuid')),
      mergeMap(uuid => {
        this.pumpEndUuid = uuid;
        return this.displayProductData.getPumpEndItem(uuid);
      })
    ).subscribe((pumpEndData) => {
      this.showPumpendForm = true;
      this.originalPumpEndData = pumpEndData;
      const diamsData: any = {};
      PUMPENDS_DIAMS_ITEMS.forEach(name => {
        const value = pumpEndData.diams[`${name}`];
        diamsData[`${name}`] = Number(value);
      });
      this.diamsGroup = this.fb.group(diamsData);

      const pumpEndFormValue: any = {};
      PUMPENDS_ITEMS.forEach(name => {
        pumpEndFormValue[`${name}`] = pumpEndData[`${name}`];
      });
      pumpEndFormValue.diams = this.diamsGroup;
      this.pumpEndForm = this.fb.group(pumpEndFormValue);

      this.originalPumpEndFormValue = this.pumpEndForm.value;

      if (pumpEndData.packaging) {
        const item = this.packagingList.find(item => item.uuid === pumpEndData.packaging.uuid);
        if (item) {
          this.originalPumpEndFormValue.packaging = item.name;
        }
      }

      const pumpEndForm = this.pumpEndForm.valueChanges.subscribe((value) => {
        this.isDirty = JSON.stringify(this.originalPumpEndFormValue) !== JSON.stringify(value);
      });
      this.subscriptions.push(pumpEndForm);

      const partsListValue: any = {
        name: '',
        picture: ''
      };
      if (pumpEndData.parts) {
        this.partslistName = pumpEndData.parts.name;
        this.showPartslistTree = true;
        partsListValue.name = pumpEndData.parts.name;
        this.store.dispatch(setPartsName({ data: pumpEndData.parts.name }));
        this.store.dispatch(setPartsData({ data: pumpEndData.parts }));
        this.store.dispatch(initPartsData({ data: pumpEndData.parts }));
      } else {
        this.showPartslistTree = false;
        this.store.dispatch(setPartsListName({ data: 'Please select parts list' }));
        this.store.dispatch(setPartsName({ data: '' }));
        this.store.dispatch(setPartsData({ data: {} }));
        this.store.dispatch(initPartsData({ data: {} }));
      }

      if ( pumpEndData.parts_picture && pumpEndData.parts_picture !== '0' ) {
        partsListValue.picture = pumpEndData.parts_picture;
      }
      this.store.dispatch(setOriginalFormValue({ data: partsListValue }));
      this.store.dispatch(setPartsListName({ data: partsListValue.name }));
      this.store.dispatch(setPartsPicture({ data: partsListValue.picture }));
    });
    this.subscriptions.push(pumpEndData);
  }

  onSave() {
    const dialogRef = this.dialog.open(SaveButtonComponent, {
      width: '600px',
      disableClose: true,
      minHeight: '20vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updatePumpEnd();
      }
    });
  }

  updatePumpEnd() {
    const data = this.pumpEndForm.value;
    data.parts = this.pumpEndPartsValue;
    data.parts_picture = this.partsPicture;
    data.uuid = this.pumpEndUuid;

    if (this.pumpEndForm.value.packaging.subType) {
      const packagingValue: any = {};
      packagingValue.name = data.packaging.uuid;
      packagingValue.w = data.packaging.subType.W;
      packagingValue.h = data.packaging.subType.H;
      packagingValue.l = data.packaging.subType.L;
      packagingValue.pack_weight = data.packaging.weight;
      data.packaging = packagingValue;
    } else {
      const result = this.packagingList.filter(item => item.name.includes(data.packaging));
      const packaging: any = {};
      packaging.name = result[0].uuid;
      packaging.w = `${result[0].subType.W}`;
      packaging.h = `${result[0].subType.H}`;
      packaging.l = `${result[0].subType.L}`;
      packaging.pack_weight = `${result[0].weight}`;
      data.packaging = packaging;
    }

    this.displayProductData.updatePumpEnd(data).subscribe(
      item => {
        this.snackBar.open(item, '', { duration: 1500, panelClass: 'hintMsg' })
          .afterDismissed()
          .pipe(delay(0))
          .subscribe(() => {
            this.ngOnInit();
            this.isDirty = false;
            this.showPumpendForm = false;
          });
      },
      error => {
        this.dialog.open(ErrorButtonComponent, {
          width: '600px',
          data: JSON.parse(error.error),
          minHeight: '20vh',
        });
      },
      () => { }
    );
  }

  updatePartslistTree(event) {
    const isFormValueChanged = this.store.pipe(
      select(selectPartsList),
      map((data: PartsListState) => data.isFormValueChanged)
    ).subscribe((isFormValueChanged) => {
      if (isFormValueChanged || event.isPartsNumberChanged) {
        this.isDirty = true;
      } else {
        this.isDirty = false;
      }
    });
    this.subscriptions.push(isFormValueChanged);
    this.pumpEndPartsValue = event.partsValue;
  }

  canDeactivate() {
    return this.isDirty;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.store.dispatch(setOriginalFormValue({ data: {
      name: '',
      picture: ''
    }}));
    this.store.dispatch(setPartsListName({ data: '' }));
    this.store.dispatch(setPartsPicture({ data: '' }));
    this.store.dispatch(setPartsName({ data: '' }));
    this.store.dispatch(setPartsData({ data: {} }));
    this.store.dispatch(initPartsData({ data: {} }));
  }
}
