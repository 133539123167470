import { Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DisplayProductDataService } from '../display-product-data.service';
import { PictureCreateComponent } from '../picture-create/picture-create.component';
import { PictureViewComponent } from '../picture-view/picture-view.component';
import { environment } from '../../../environments/environment';
import { nameSort } from 'src/app/shared/util';

@Component({
  selector: 'app-picture-select-new',
  templateUrl: './picture-select-new.component.html',
  styleUrls: ['./picture-select-new.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PictureSelectNewComponent),
      multi: true
    }
  ]
})
export class PictureSelectNewComponent implements OnInit, OnDestroy, ControlValueAccessor {

  pictureList = [];
  pictureStore = '';
  pictureNamesList = [];
  private subscriptions: Subscription[] = [];

  showNotAvailable = false;

  @Output() selectionChange = new EventEmitter<MatSelectChange>();

  selectListControl = new UntypedFormControl('');

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private displayProductData: DisplayProductDataService
  ) { }

  ngOnInit() {
    this.displayProductData.getPictureAndDrawingList()
      .subscribe(result => {
        const pictureNames = nameSort(result);
        this.pictureList = pictureNames;

        this.pictureList.forEach(item => this.pictureNamesList.push(item.name));

        if ( this.pictureNamesList.includes(this.pictureStore) ) {
          this.showNotAvailable = false;
          this.selectListControl.setValue(this.pictureStore, { emitEvent: false });
        } else {
          this.showNotAvailable = true;
          this.selectListControl.setValue('notAvailable', { emitEvent: false });
        }
      });
  }

  pictureChangeAction(event) {
    if (event.value === 'create') {
      const dialogRef = this.dialog.open(PictureCreateComponent, {
        width: '1000px',
        maxWidth: '96vw',
        minHeight: '60vh',
        maxHeight: '60vh',
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.pictureList.unshift(result);
          this.selectListControl.setValue(result.name);
        } else {
          this.selectListControl.setValue(this.pictureStore);
        }
      });
    } else if (event.value === 'empty') {
      this.pictureStore = '';
      this.selectListControl.reset('');
    } else {
      this.pictureStore = event.value;
    }

    this.selectionChange.emit(event);
  }

  openViewDialog() {

    const picture = this.selectListControl.value;
    if (picture === 'notAvailable') {
      this.snackBar.open('Please choose picture first', '', {
        duration: 1500,
        panelClass: 'hintMsg'
      });
    } else if (picture) {
      this.pictureList.filter(item => {
        if (item.name === picture) {
          const extension = item.type === 'picture' ? 'jpg' : 'gif';
          const typePath = item.type === 'picture' ? 'Pictures' : 'Drawings';
          const imgUrl = `https://catalog-data-images-${environment.stage}.s3-eu-west-1.amazonaws.com/${typePath}/${item.uuid}/${item.name}.${extension}`;
          this.displayProductData.getImage(imgUrl).subscribe(
            () => {
              this.dialog.open(PictureViewComponent, {
                width: '1000px',
                maxWidth: '90vw',
                minHeight: '60vh',
                maxHeight: '90vh',
                data: {
                  url: imgUrl,
                  title: item.name
                },
              });
            },
            error => {
              this.snackBar.open('This picture is not available, please notice it.', '', {
                duration: 1500,
                panelClass: 'hintMsg'
              });
            },
            () => {}
          );
        }
      });
    } else {
      this.snackBar.open('Please choose picture first', '', {
        duration: 1500,
        panelClass: 'hintMsg'
      });
    }
  }

  // model --> view
  writeValue(value: string): void {
    if (value && value !== '') {
      this.pictureStore = value;
      this.selectListControl.setValue(value, { emitEvent: false });
    } else {
      this.selectListControl.reset('empty');
    }
  }

  // view --> model
  registerOnChange(fn: (value: string) => void) {
    const onChangeSubscription = this.selectListControl.valueChanges.subscribe(fn);
    this.subscriptions.push(onChangeSubscription);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  onTouched: () => void = () => { };

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

}
