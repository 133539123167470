import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { now } from 'moment-timezone';
import { CoefficientSetItemDetail } from '../display-product-data/pump-data.model';
import { CoefficientSetItem, CoefficientNameItem, VersionStatus, CoefficientResultRow, IntermediateCoefficients } from '../pump-simulation/coefficient.model';
import { UserLocalStorage } from '../login/user-local-store';

const HOST = environment.calculationUrl;
const VERSION = environment.versioningUrl;

@Injectable({
  providedIn: 'root'
})
export class PumpSimulationService {

  userLocalStorage = new UserLocalStorage();
  fileVersionData$: Observable<CoefficientSetItem[]>;
  fileNameData$: Observable<CoefficientNameItem[]>;

  constructor(
    private http: HttpClient
  ) {
    this.fileVersionData$ = this.http.get<CoefficientSetItem[]>(VERSION + 'getVersionedMeasurementFileKeys').pipe(shareReplay(1));

    this.fileNameData$ = this.http.get<CoefficientNameItem[]>(VERSION + 'getVersionedMeasurementFileNames').pipe(shareReplay(1));
  }

  getUploadedFileList(): Observable<string[]> {
    return this.http.get<string[]>(HOST + 'measurementFiles');
  }

  getUploadedFileItem(id: string): Observable<any> {
    return this.http.get<any>(HOST + `measurementFile/${id}`);
  }

  getAllMeasurementFileVersions(): Observable<CoefficientSetItem[]> {
    return this.fileVersionData$;
  }

  getAllMeasurementFileNames(): Observable<CoefficientNameItem[]> {
    return this.fileNameData$;
  }

  getMeasurementFileVersionByID(id: string, version: number): Observable<any> {
    return this.http.get<any>(VERSION + `versionedMeasurementFile/${id}/${version}`);
  }

  getMeasurementFileVersionsByID(id: string): Observable<any> {
    return this.http.get<any>(VERSION + `versionedMeasurementFiles/${id}`);
  }

  getLiveMeasurementFileVersionsByID(id: string): Observable<any> {
    return this.http.get<any>(VERSION + `liveVersionedMeasurementFiles/${id}`);
  }

  getPossibleLiveMeasurementFileVersions(id: string): Observable<any> {
    return this.http.get<any>(VERSION + `possibleLiveVersionedMeasurementFiles/${id}`);
  }

  getAllLiveMeasurementFileVersions(): Observable<CoefficientSetItemDetail[]> {
    return this.http.get<CoefficientSetItemDetail[]>(VERSION + `liveVersionedMeasurementFiles`);
  }

  deleteMeasurementFileVersionByID(id: string, version: number): Observable<any> {
    return this.http.delete<any>(VERSION + `deleteVersionedMeasurementFile/${id}/${version}`);
  }

  calculateCoefficient(parameter: any): Observable<CoefficientResultRow> {
    return this.http.post<CoefficientResultRow>(HOST + 'doCalculation', parameter).pipe();
  }

  getDataFor(source: string, parameter: any): Observable<Map<number, Map<number, number>>> {
    let result: Observable<Map<number, Map<number, number>>> = null;
    try {
      result =  this.http.post<Map<number, Map<number, number>>>(HOST + `${source}`, parameter);
    } catch (error) {
      console.log('error getting graph data, most likely reason is incorrectly inputted data', error);
    }
    return result;
  }

  getIntermediate(parameter: any): Observable<any> {
    return this.http.post<Map<number, IntermediateCoefficients>>(HOST + 'getIntermediateCoefficientsCalculatedData', parameter).pipe();
  }

  createVersion(parameter: any): Observable<any> {
    return this.http.post<any>(VERSION + 'versionMeasurementFile', parameter).pipe();
  }

  createInitialVersion(parameter: any): Observable<any> {
    return this.http.post<any>(VERSION + 'versionMeasurementFile', parameter);
  }

  uploadAndSaveData(parameter: any): Observable<any> {
    return this.http.post<any>(HOST + 'persist/measurementFile', parameter).pipe();
  }

  /**
   * Persists the content of an uploaded measurement excel file to an S3 bucket
   *
   * @param pumpSystem string - the name of the pumpSystem to which this measurement belongs
   * @param file string - the measurement file content formatted as a json string
   */
  uploadImportedData(pumpSystem: string, file: string) {
    const uploadURL = HOST + `persist/measurementFile`;
    const parameter = {
      pumpSystem, file
    };
    return this.http.post<any>(uploadURL, parameter);
  }

  /**
   * This creates an initial version no. 1 using the original file content
   * in JSON format. If this is restored it has to be converted into
   * usable measurement data.
   *
   * @param filename the name of the measurement file to be saved
   * @param file the file in json format
   */
  createInitialUploadVersion(filename: string, file: string) {

    const user = this.userLocalStorage.loadUser();
    // this.calculateCoefficientService.inputPartParameters
    const inputParameters = JSON.stringify('');

    const values = {
      filename,
      version: 0,
      username: user.user.username,
      notes: 'initial upload',
      changes: file,
      inputParams: inputParameters,
      status: VersionStatus.INITIAL,
      approver: user.user.username,
      timestamp: now()
    };
    const uploadURL = VERSION + `versionMeasurementFile`;

    return this.http.post<any>(uploadURL, values);
  }

  /**
   * Saves the current measurement, input and coefficient data as the next
   * version, while updating the current version to match the new one.
   *
   * @param status the status of the version - PROVISIONAL, PREVIEW and LIVE
   * @param notes any notes the user wishes to make on saving this version
   */
  public async createUploadVersion(status: string, notes: string) {

    const user = this.userLocalStorage.loadUser();
    const inputParameters = JSON.stringify('this.calculateCoefficientService.inputParameters');
    const measurementData = JSON.stringify('this.calculateCoefficientService.convertedMeasurementData');
    const coefficients = JSON.stringify('this.calculateCoefficientService.coefficientResults');
    const npshData = JSON.stringify('this.calculateCoefficientService.convertedNPSHData');
    const interCoefficients = 'this.calculateCoefficientService.coefficientResults.intermediateCoefficentsJSON';

    const values = {
      filename: 'this.calculateCoefficientService.inputParameters.pump',
      version: 0,
      username: user.user.username,
      notes,
      changes: measurementData,
      inputParams: inputParameters,
      coefficients,
      inter_coefficients: interCoefficients,
      npsh_data: npshData,
      status,
      approver: user.user.username,
      timestamp: now()
    };
    const uploadURL = VERSION + `versionMeasurementFile`;

    return this.http.post<any>(uploadURL, values);
  }
}
