<section class="form-wrapper">
  <mat-card appearance="outlined" class="form-wrapper__settings">
    <div class="top-wrapper">

      <button class="form-wrapper__copy-btn" mat-raised-button color="primary" type="button"
        (click)="openCopyDialog('motor')">COPY</button>

      <span class="form-wrapper__copy-hint" *ngIf="copiedItem">{{copiedItem}} copied</span>
    </div>
    <mat-card-header>
      <mat-card-title i18n="@@motorAttributes">
        Motor attributes
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <div class="container" novalidate>

        <div class="form-wrapper__loading" *ngIf="showLoading">
          <mat-progress-spinner mode="indeterminate" [diameter]=20></mat-progress-spinner>
        </div>

        <form [formGroup]="motorForm" class="form">

          <mat-form-field class="form-element">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" data-testid="name" formControlName="name">
            <mat-error *ngIf="motorForm.get('name').hasError('required')">The field name is required</mat-error>
            <mat-error *ngIf="motorForm.get('name').hasError('duplicateNames')">This name is already used</mat-error>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>Item</mat-label>
            <input matInput placeholder="Item" data-testid="item" formControlName="item">
          </mat-form-field>

          <app-material-select data-testid="material" formControlName="material"></app-material-select>

          <app-packaging-select data-testid="packaging" formControlName="packaging"></app-packaging-select>

          <mat-form-field class="form-element">
            <mat-label>Net Weight</mat-label>
            <input matInput type="number" placeholder="Net Weight" data-testid="net_weight"
              formControlName="net_weight">
            <span matSuffix>kg</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>length</mat-label>
            <input matInput type="number" placeholder="length" data-testid="length" formControlName="length">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>diameter</mat-label>
            <input matInput type="number" placeholder="diameter" data-testid="diameter" formControlName="diameter">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dimension A</mat-label>
            <input matInput type="number" placeholder="dimension A" data-testid="diameterA" formControlName="diameterA">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dimension B</mat-label>
            <input matInput type="number" placeholder="dimension B" data-testid="diameterB" formControlName="diameterB">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>dimension C</mat-label>
            <input matInput type="number" placeholder="dimension C" data-testid="diameterC" formControlName="diameterC">
            <span matSuffix>mm</span>
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>max subm.</mat-label>
            <input matInput type="number" placeholder="max subm." data-testid="max_subm" formControlName="max_subm">
          </mat-form-field>

          <app-insulation-class-select formControlName="ins_class"></app-insulation-class-select>

          <app-enclosure-class-select-new data-testid="ingress_protection_code"
            formControlName="ingress_protection_code"></app-enclosure-class-select-new>

          <mat-form-field class="form-element">
            <mat-label>ac poles</mat-label>
            <input matInput placeholder="ac poles" data-testid="ac_poles" formControlName="ac_poles">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>cos phi</mat-label>
            <input matInput placeholder="cos phi" data-testid="cos_phi" formControlName="cos_phi">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>rated power</mat-label>
            <input matInput placeholder="rated power" data-testid="rated_power" formControlName="rated_power">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>motor min u</mat-label>
            <input matInput placeholder="motor min u" data-testid="m_min_u" formControlName="m_min_u">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>motor max u</mat-label>
            <input matInput placeholder="motor max u" data-testid="m_max_u" formControlName="m_max_u">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>max speed</mat-label>
            <input matInput placeholder="max speed" data-testid="max_speed" formControlName="max_speed">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>max speed Hz</mat-label>
            <input matInput placeholder="max speed Hz" data-testid="max_speed_Hz" formControlName="max_speed_Hz">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>min set speed</mat-label>
            <input matInput placeholder="min set speed" data-testid="min_set_speed" formControlName="min_set_speed">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>min set speed Hz</mat-label>
            <input matInput placeholder="min set speed Hz" data-testid="min_set_speed_Hz"
              formControlName="min_set_speed_Hz">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>min speed</mat-label>
            <input matInput placeholder="min speed" data-testid="min_speed" formControlName="min_speed">
          </mat-form-field>

          <mat-form-field class="form-element">
            <mat-label>min speed Hz</mat-label>
            <input matInput placeholder="min speed Hz" data-testid="min_speed_Hz" formControlName="min_speed_Hz">
          </mat-form-field>

        </form>

        <mat-expansion-panel class="form">
          <mat-expansion-panel-header>
            <mat-panel-title>
              parts list
            </mat-panel-title>
            <mat-panel-description>
              {{ partslistName }}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <app-parts-list-name></app-parts-list-name>
          <app-parts-list-picture></app-parts-list-picture>
          <div *ngIf="showPartslistTree">
            <app-parts-list-tree (updatePartslistTree)="updatePartslistTree($event)"></app-parts-list-tree>
          </div>

        </mat-expansion-panel>

        <div class="form">
          <button mat-raised-button color="primary" data-testid="submit" (click)="createNewMotor(motorForm.value)"
            class="button" [disabled]="!motorForm.valid">Submit Form</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</section>
