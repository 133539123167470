import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { from, map, mergeMap, Subscription, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as XLSX from 'xlsx';
import { DisplayProductDataService } from '../../display-product-data/display-product-data.service';
import { Accessory, ACCESSORY_FILE_NAMES, PumpSystem, PumpSystemDetail } from '../../display-product-data/pump-data.model';
import { UserLocalStorage } from '../../login/user-local-store';

@Component({
  selector: 'app-upload-download-accessories',
  templateUrl: './upload-download-accessories.component.html',
  styleUrls: ['./upload-download-accessories.component.scss']
})
export class UploadDownloadAccessoriesComponent implements OnInit {
  accessoryList: Accessory[] = [];
  subscriptions: Subscription[] = [];
  pumpSystemList: PumpSystem[] = [];
  userLocalStorage = new UserLocalStorage();
  profileForm: FormGroup;
  uploadFileName: string;
  showUploadFileName = false;
  uploadedFile: any = [];
  file: File;
  arrayBuffer: any;
  fileList: any;
  FILENAME: any = environment.lorentzAssistName;



  constructor(
    private fb: FormBuilder,
    private displayProductDataService: DisplayProductDataService,
  ) { }

  ngOnInit(): void {
    this.profileForm = this.fb.group({
      filename: [this.FILENAME, Validators.required],
      version: ['0', Validators.required],
    });
    this.pumpSystemList = [];

    const pumpSystemDetailSubscription = this.displayProductDataService.getPumpSystemList().pipe(
      map(pumpSystemList => this.pumpSystemList = pumpSystemList)
    ).subscribe();
    this.subscriptions.push(pumpSystemDetailSubscription);

    this.accessoryList = [];
    const accessoryListSubscription = this.displayProductDataService.getAccessoryList().pipe(
      map(accessoryList => this.accessoryList = accessoryList)
    ).subscribe();
    this.subscriptions.push(accessoryListSubscription);
  }

  onFileSelectAccessory(event: { target: { files: string | any[]; }; }) {
    if (event.target.files.length > 0) {
      this.parseAccessoryFile(event);
    }
  }

  onPublishAllLastVersions() {
    this.updateAllLastPumpVersionsToLive();
  }

  parseAccessoryFile(event: any) {
    let importedAccessories: unknown[];

    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = () => {
      const arrayBuffer: any = fileReader.result;
      const data = new Uint8Array(arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      importedAccessories = arraylist;
      console.log('### imported Accessories', JSON.stringify(importedAccessories));
      this.updateAccessoryRefOnPumps(importedAccessories);

    }

  }

  private updateAllLastPumpVersionsToLive() {

    const publishePumpVersionsSubscription = from(this.pumpSystemList).pipe(
      mergeMap(pumpSystem => this.findAndPublishPumpVersion(pumpSystem), 4)

    ).subscribe(
      {
        error: (e) => console.error(e),
        complete: () => console.info('###### updateAllLastPumpVersionsToLive complete')
      }
    );
    this.subscriptions.push(publishePumpVersionsSubscription);
  }


  private findAndPublishPumpVersion(pumpSystem: PumpSystem) {
    return this.displayProductDataService.getPumpSystemItem(pumpSystem.uuid).pipe(
      map(pumpSystemDetailMessage => this.extractPumpVersionData(pumpSystemDetailMessage)
      ),
      mergeMap(v => this.updateVersionToLive(v.url, v.data), 4)
    )
  }

  private updateVersionToLive(url: string, data: { author: string; comment: string; }) {
    return this.displayProductDataService.setPumpSystemState(url, data).pipe(
      tap(() => console.log(...data.comment))
    )
  }

  private extractPumpVersionData(pumpSystemDetailMessage: any) {
    const pumpSystemDetail: PumpSystemDetail = JSON.parse(pumpSystemDetailMessage.message);
    const data = { 'author': '', 'comment': '' };
    const user = this.userLocalStorage.loadUser();
    data.author = user.user.username;
    data.comment = `${pumpSystemDetail.name} changed on accessory ref import`;
    const url = `${pumpSystemDetail.uuid}/${pumpSystemDetail.versions.length - 1}/live`;
    return { url, data };
  }

  private updateAccessoryRefOnPumps(importedAccessories: any[]) {

    let accessoryArray = [];
    accessoryArray = this.accessoryList.map(element => { return { uuid: element.uuid, name: element.name }; });
    const updatePumpsSubscription = from(importedAccessories).pipe(
      tap(importedAccessory => console.log('the imported accessory says: ', importedAccessory)),
      map(importedAccessory => this.findAssignedAccessoriesForEachPump(importedAccessory, accessoryArray)),
      mergeMap(assignedAccessories => this.assignAccessoriesToEachPump(assignedAccessories.no, assignedAccessories.assignedAccessories), 4)

    ).subscribe(
      {
        error: (e) => console.error(e),
        complete: () => console.info('##### updateAccessoryRefOnPumps complete')
      }
    );

    this.subscriptions.push(updatePumpsSubscription);
  }

  private assignAccessoriesToEachPump(importedAccessory: string, assignedAccessories: any[]) {
    return this.displayProductDataService.getPumpSystemItemByPumpNo(importedAccessory).pipe(
      tap(() => console.log('the imported accessory says: ', importedAccessory)),
      map(pumpSystemMessage => {
        console.log('pump system is ', JSON.stringify(pumpSystemMessage));
        const pumpSystem: any = JSON.parse(pumpSystemMessage.message);
        pumpSystem.accessories = assignedAccessories;

        return pumpSystem;
      }),
      mergeMap(pumpSystem => this.displayProductDataService.updatePumpSystem(pumpSystem), 4)
    );
  }

  private findAssignedAccessoriesForEachPump(importedAccessory: any, accessoryArray: any[]) {
    let assignedAccessories = [];
    ACCESSORY_FILE_NAMES.forEach(name => {
      if (importedAccessory[name] === 1) {

        accessoryArray.forEach(accessory => {
          if (accessory.name === name) { assignedAccessories.push({ 'uuid': accessory.uuid, 'name': accessory.name, 'version': '1' }); }
        });
      }

    });
    console.log('#### assigned Accessories are: ', JSON.stringify(assignedAccessories));
    return { 'no': importedAccessory.no, 'assignedAccessories': assignedAccessories };
  }


}
