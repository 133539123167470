<mat-sidenav-container class="dashboard">
  <mat-sidenav #sidenav="matSidenav" mode="side" class="nav-item">
    <mat-nav-list>
      <mat-list-item routerLink="/simulations/simulation/new" routerLinkActive="active" (click)="handleTitle('create')">
        <mat-icon>add</mat-icon> Add coefficients sets
      </mat-list-item>
      <mat-divider></mat-divider>

      <div class="nav-item__search">
        <mat-form-field>
          <mat-label>Search</mat-label>
          <input type="text" matInput [formControl]="nameControl" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChange($event)">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.filename">
              {{option.displayName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <p class="nav-item__title" mat-flat-button> List of all coefficients sets </p>
      <ng-template *ngIf="coefficientSetNameList | async; else loading"></ng-template>
      <mat-list-item *ngFor="let item of coefficientSetNameList | async"
        routerLink="/simulations/simulation/{{item.filename}}" routerLinkActive="active"
        (click)="handleTitle(item.filename)">
        {{ item.displayName  }} </mat-list-item>
      <ng-template #loading>
        <mat-progress-spinner mode="indeterminate" [diameter]=20></mat-progress-spinner>
      </ng-template>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="nav-content">
    <mat-card appearance="outlined">
      <mat-card-content>
        <mat-toolbar class="nav-toolbar">
          <button mat-mini-fab color="primary" (click)="sidenav.toggle()">
            <mat-icon *ngIf="!sidenav.opened">menu</mat-icon>
            <mat-icon *ngIf="sidenav.opened">close</mat-icon>
          </button>
          {{ navTitle }}
        </mat-toolbar>
        <router-outlet></router-outlet>
      </mat-card-content>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>
