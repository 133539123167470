import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { select, Store } from '@ngrx/store';
import { combineLatest, map, Subscription, tap } from 'rxjs';
import { DisplayProductDataService } from 'src/app/display-product-data/display-product-data.service';
import { PartsListService } from 'src/app/display-product-data/parts-list.service';
import { PartsListFlatNode, PartsListNode } from 'src/app/display-product-data/pump-data.model';
import { PartsListState } from 'src/app/display-product-data/store/parts-list/parts-list.reducer';
import { selectPartsList } from 'src/app/display-product-data/store/parts-list/parts-list.seletor';
import { isEmpty } from '../../util';

@Component({
  selector: 'app-parts-list-tree',
  templateUrl: './parts-list-tree.component.html',
  styleUrls: ['./parts-list-tree.component.scss']
})
export class PartsListTreeComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  @Output() updatePartslistTree = new EventEmitter();
  partsListName = '';
  originalTreeValue = '';
  flatNodeMap = new Map<PartsListFlatNode, PartsListNode>();
  nestedNodeMap = new Map<PartsListNode, PartsListFlatNode>();
  treeControl: FlatTreeControl<PartsListFlatNode>;
  treeFlattener: MatTreeFlattener<PartsListNode, PartsListFlatNode>;
  dataSource: MatTreeFlatDataSource<PartsListNode, PartsListFlatNode>;
  isValueUpdated = false;

  constructor(
    private store: Store,
    private database: PartsListService,
    private displayProductData: DisplayProductDataService,
  ) { }


  getLevel = (node: PartsListFlatNode) => node.level;

  isExpandable = (node: PartsListFlatNode) => node.expandable;

  getChildren = (node: PartsListNode): PartsListNode[] => node.children;

  // tslint:disable-next-line: variable-name
  hasChild = (_: number, _nodeData: PartsListFlatNode) => _nodeData.expandable;

  // tslint:disable-next-line: variable-name
  hasNoContent = (_: number, _nodeData: PartsListFlatNode) => _nodeData.name === '';

  transformer = (node: PartsListNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.name
      ? existingNode
      : new PartsListFlatNode();
    flatNode.name = node.name;
    flatNode.id = node.id;
    flatNode.material = node.material;
    flatNode.partsNumbers = node.partsNumbers;
    flatNode.level = level;
    flatNode.expandable = (node.children && node.children.length > 0);
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  ngOnInit() {

    const getPartsListData = this.store.pipe(
      select(selectPartsList),
      map((data: PartsListState) => data.partsData)
    ).subscribe(data => {
      if (!isEmpty(data)) {
        this.database.updatePartsListTreeWithNumber(data);
      }
    });
    this.subscriptions.push(getPartsListData);

    this.database.getCurrentPartsList().subscribe(result => {
      this.database.updatePartsListTreeWithNumber(result);
    });

    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<PartsListFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    const database = combineLatest([
      this.database.dataChange.pipe(
        tap(data => {
          if ( this.originalTreeValue === '' ) {
            this.originalTreeValue = JSON.stringify(data);
          }
        })
      ),
      this.store.pipe(
        select(selectPartsList),
        map((data: PartsListState) => data.partsData)
      )
    ]).subscribe(([data, partsListData]) => {
      this.dataSource.data = [];
      if (this.isValueUpdated) {
        this.dataSource.data = data;
      } else {
        this.dataSource.data = this.database.buildPartsListTree(partsListData.parts, 0);
      }
      const sendData: any = {};
      sendData.isPartsNumberChanged = this.originalTreeValue !== JSON.stringify(data);
      const partsData: any = {};
      partsData.name = '';

      if (partsListData !== undefined) {
        partsData.name = partsListData.name || '';
      } else {
        partsData.name = this.partsListName;
      }

      partsData.partsNumbers = this.handlePartsNumbers(data);
      sendData.partsValue = partsData;
      this.updatePartslistTree.emit(sendData);
    });
    this.subscriptions.push(database);
  }

  handlePartsNumbers(partsListData) {
    const data = [];
    partsListData.map(item => {
      const subItem: any = {};
      subItem.name = item.name;
      subItem.id = item.id;
      subItem.partsNumbers = item.partsNumbers;
      data.push(subItem);
      if ( item.children && item.children.length > 0 ) {
        const recursiveData = this.handlePartsNumbers(item.children);
        recursiveData.map(subData => data.push(subData));
      }
    });
    return data;
  }

  stringToNumber(value) {
    return Number(value);
  }

  /** Update the node when create */
  updateNumberOfPartsList(node: PartsListFlatNode, value: number) {
    if ( Number(node.partsNumbers) !== value ) {
      const nestedNode = this.flatNodeMap.get(node);
      this.isValueUpdated = true;
      this.database.updateNumberWithPartsListNode(nestedNode, value);
      this.treeControl.expand(node);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
